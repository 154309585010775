import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class CirugiaService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }

    // Nuevo paciente
    public AddCirugiaPaciente(datos) {
      let params = JSON.stringify(datos)
      let headers = new Headers({ 'Content-Type': 'application/json' });
      return this.http.post(this.url + 'paciente/add',params, { headers: headers })
        .map(res => res.json());
    }
      //agrega una nueva consulta de pago 
  public AddCirugiaPago(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'detalle_pago/add',params, { headers: headers })
      .map(res => res.json());
  }

  // Agrega un nueva consulta general
  public AddCirugiaGenerales(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'cirugia/add',params, { headers: headers })
      .map(res => res.json());
  }

  //Agregar ayudantia
  
  public AddProveedor(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'proveedor/add',params, { headers: headers })
      .map(res => res.json());
  }

  //Obtener Imagenes de Cirugia Pago
  public GetImagenesTipo(token,tipo,cirugia){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url +'imagen/get-img-tipo/'+cirugia+'/'+tipo,{ headers : headers })
    .map(res => res.json())
  }

  //Eliminar la imagen de cirugia pago
  public EliminarImagenDetallePago(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url +'imagen/update',params,{headers : headers})
    .map(res => res.json())

  }


  ///////////////////SERVICIOS PARA ACTUALIZAR DATOS DE UNA CIRUGIA , PAGO, PACIENTE////////////////////////
  public updatePaciente(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.post(this.url+'paciente/update',params,{ headers : headers})
    .map(res => res.json())
  }

  public updateCirugia(datos){
    let params =  JSON.stringify(datos);
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.post(this.url+'cirugia/update',params,{ headers : headers})
    .map(res => res.json())
  }

  public updatePago(datos){
    let params =  JSON.stringify(datos);
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.post(this.url+'detalle_pago/update',params,{ headers : headers})
    .map(res => res.json())
  }

  public updateProveedor(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.post(this.url+'proveedor/update',params,{ headers : headers})
    .map(res => res.json());
  }
}

