import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class DatosEventoService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getAnestesia(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_anestesia/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    getProcedimiento(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    getPersonal(token, usuario, tipo) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_personal/getbyusuarioid/' + usuario + '/' + tipo, { headers: headers })
            .map(res => res.json());
    }
    getProveedor(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'proveedor/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    AddImagenCirugia(url_liga, params, file, tipo) {
        let _url = this.url;
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            var xhr = new XMLHttpRequest();
            if (file) {
                if (tipo == 1) {
                    formData.append('image', file, file.name);
                }
                else if (tipo == 2) {
                    formData.append('pdf', file, file.name);
                }
            }
            for (let key of params) {
                let tKey = key;
                for (const i in tKey) {
                    formData.append(i, key[i]);
                }
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', _url + url_liga, true);
            xhr.send(formData);
        });
    }
    getCirugia(token, cirugia) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cirugia/getbyid/' + cirugia, { headers: headers })
            .map(res => res.json());
    }
    getPaciente(token, paciente) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'paciente/getbyid/' + paciente, { headers: headers })
            .map(res => res.json());
    }
    getDetallePago(token, pago) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'detalle_pago/getbyid/' + pago, { headers: headers })
            .map(res => res.json());
    }
    getProveedorAyudantia(token, pago) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'proveedor/getbyusuarioid/' + pago, { headers: headers })
            .map(res => res.json());
    }
    getConsulta(token, consulta) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'consulta/getbyid/' + consulta, { headers: headers })
            .map(res => res.json());
    }
    // public getProcedimiento(token, usuario){
    //   let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    //   return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers : headers })
    //   .map(res => res.json())
    // }
    DeleteCirugia(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'cirugia/delete', params, { headers: headers })
            .map(res => res.json());
    }
}
DatosEventoService.ngInjectableDef = i0.defineInjectable({ factory: function DatosEventoService_Factory() { return new DatosEventoService(i0.inject(i1.Http)); }, token: DatosEventoService, providedIn: "root" });
