<section *ngIf="mostrar_calendario">
  <div class="calendariomes">
    <div class="row pt-4">
      <div class="col">
        <button type="button" [ngClass]="dia_calendario ? 'btn btn-form-conf-barra-activo btn-block btn-sm pointer' :  'btn btn-form-conf-barra btn-block btn-sm pointer' "
          (click)="SelectedCalendario(1)">DIA</button>
      </div>
      <div class="col">
        <button type="button" [ngClass]="semana_calendario ? 'btn btn-form-conf-barra-activo btn-block btn-sm pointer' :  'btn btn-form-conf-barra btn-block btn-sm pointer' "
          (click)="SelectedCalendario(2)">SEMANA</button>
      </div>
      <div class="col">
        <button type="button" [ngClass]="mes_calendario ? 'btn btn-form-conf-barra-activo btn-block btn-sm pointer' :  'btn btn-form-conf-barra btn-block btn-sm pointer' "
          (click)="SelectedCalendario(3)">MES</button>
      </div>
      <div class="col">
        <button type="button" [ngClass]="anio_calendario ? 'btn btn-form-conf-barra-activo btn-block btn-sm pointer' :  'btn btn-form-conf-barra btn-block btn-sm pointer' "
          (click)="SelectedCalendario(4)">AÑO</button>
      </div>
      <div class="col">
          <button type="button" class="btn btn-form-conf-barra btn-block btn-sm pointe"  style ="color: white;   background-color:#7c1d12" (click)="resetComponent()" >HOY</button>
        </div>
    </div>
  </div>

  <app-calendario-dia *ngIf="dia_calendario"></app-calendario-dia>


  <app-calendario-semana *ngIf="semana_calendario"></app-calendario-semana>

  <!-- Div para mantener el fondo en las dos secciones -->

  <section *ngIf="mes_calendario">

    <div class="">
      <div class="row">
        <div class="col-6" style="flex: 0 0 60%; max-width: 900px; min-width: 900px;">
          <div class="row text-center" style="margin-left: 50px;margin-top: 19px;">
            <div class="col-md-4" style="max-width: 9%;">
              <div style="margin-left : 95%">
                <label class="pointer flecha-calendar" (click)="changeCalendar(-1)">
                  < </label>
              </div>
            </div>
            <div class="col-md-4" style="margin-top: 6px;">
              <h3 id="mes" *ngIf="mostrar_mes" style="font-weight: bold; color:#3C615F; font-size: 20px;">{{ calendar_title | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }}</h3>
              <h3 id="mes" *ngIf="!mostrar_mes" style="font-weight: bold; color:#3C615F; font-size: 20px ">{{ calendar_title_default | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }}</h3>
            </div>
            <div class="col-md-4" style="padding-left: 0px;">
              <div style="margin-right: 95%;">
                <label class="pointer flecha-calendar" (click)="changeCalendar(1)">
                  > </label>
              </div>
            </div>
          </div>
          <br>
          <!--[activeDayIsOpen]="activeDayIsOpen"-->
          <div [ngSwitch]="view" style="color:#3C615F !important; margin-left: 9%;">
            <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
              (beforeViewRender)="setCSSCalendar()" [locale]="locale" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
          </div>

        </div>
        <div style="margin: 3%; width: 31%;" *ngIf="eventos_dia">
          <div class="row">
          <div class="col-10">
            <h3 style="font-weight: bold; color: #3C615F; text-align: -webkit-right;font-size: 20px;">{{fecha_selected_calendario}}</h3>
            <hr style="color: #3C615F; background-color: #3C615F;height: 3px; width : 138%;">
            <table class="pointer" style=" width: 624px;">
              <tr *ngFor="let eventos of eventos_dia " (click)="handleEvent(eventos)">
                <td rowspan="1" colspan="1" style="    font-family: 'Open Sans', sans-serif;
                  font-size: 12px;
                  font-weight: 600;
                  color: #3C615F;"> {{ eventos.cirugia_hora }}
                  <br>
                  <img *ngIf="eventos.tipo == 2" src="../../../../assets/images/menu-consulta-help.png" width="23" height="auto">
                  <img *ngIf="eventos.tipo == 1" src="../../../../assets/images/menu-cirugia-help.png" width="23" height="auto"> </td>
                <td style="    font-family: 'Open Sans', sans-serif;
                  font-size: 12px;
                  color: #3C615F; width: 60%;"> <span style="font-weight: 600;">{{ eventos.paciente_nombre }}</span>
                  <br> {{ eventos.persona_desc }} / {{ eventos.anestesiologo_desc }}
                  <br> {{ eventos.proce_desc }} / {{ eventos.hospital_desc }} / {{ eventos.anestesia_desc}}
                  <br> </td>
                <td>
                  <img *ngIf="eventos.detpg_pagada == 1" src="../../../assets/images/icon-pago-over.png" width="23" height="auto">
                  <img *ngIf="eventos.detpg_pagada == 0" src="../../../assets/images/icon-pago-grey.png" width="23" height="auto"> </td>
                <td>
                  <img *ngIf="eventos.detpg_depositado == 1" src="../../../assets/images/icon-deposito-over.png" width="23"
                    height="auto">
                  <img *ngIf="eventos.detpg_depositado == 0" src="../../../assets/images/icon-deposit-grey.png" width="23"
                    height="auto"> </td>
                <td>
                  <img *ngIf="eventos.detpg_recibo == 0" src="../../../assets/images/icon-recibo-grey.png" width="23" height="auto">
                  <img *ngIf="eventos.detpg_recibo == 1" src="../../../assets/images/icon-recibo-over.png" width="23" height="auto"> </td>
              </tr>
            </table>

          </div>
        </div>
        </div>
      </div>
    </div>
    <ng-template #modalContent let-close="close">
      <div class="modal-header">
        <h5 class="modal-title">Event action occurred</h5>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          Action:
          <pre>{{ modalData?.action }}</pre>
        </div>
        <div>
          Event:
          <pre>{{ modalData?.event | json }}</pre>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
      </div>
    </ng-template>



  </section>
  <app-calendario-anio *ngIf="anio_calendario"></app-calendario-anio>
</section>