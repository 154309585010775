<div class="fondo-titulo-campos">
  <h6 class="titulo-campos">DATOS DEL PACIENTE</h6>
</div>
<form>
  <div class="form-row mt-3" style="padding-top: 35px;">
    <div class="form-group col-6">
      <input id="datepickerfn" name="fecha_nacimient" #name="ngModel" [(ngModel)]="datos_paciente.fecha_nacimiento" style="font-size: 12px; font-weight: 300;"
        class="btn btn-block dropdown-toggle read-on letraCuadrosFormulario flecha_m" placeholder="Fecha Nacimiento" [owlDateTimeTrigger]="dt6"
        [owlDateTime]="dt6" readonly>
      <owl-date-time [pickerType]="'calendar'" #dt6></owl-date-time>
    </div>
    <div class="form-group col-6">
      <input type="nombre" name="rfc" #name="ngModel" [(ngModel)]="datos_paciente.paciente_rfc" class="top-paciente btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="inputNombrerfc" placeholder="R.F.C.">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <input type="tel" name="tel_fijo" #name="ngModel" [(ngModel)]="datos_paciente.paciente_tel" class="btn btn-form-conf btn-block btn-lg dropdown-toggle letraCuadrosFormulario letraCuadrosLista" 
        id="inputNombretel" placeholder="Teléfono Fijo" onkeyup="this.value=this.value.replace(/\D+/g,'');" (change)="cleanPhone(1)" maxlength="15">
    </div>
    <div class="form-group col-6">
      <input type="tel" name="paciente_cel" #name="ngModel" [(ngModel)]="datos_paciente.paciente_cel" class="btn btn-form-conf btn-block btn-lg dropdown-toggle letraCuadrosFormulario letraCuadrosLista" 
        id="inputNombrecel" placeholder="Teléfono Celular" onkeyup="this.value=this.value.replace(/\D+/g,'');" (change)="cleanPhone(2)" maxlength="15">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-9">
      <input type="nombre" name="paciente_correo" #name="ngModel" [(ngModel)]="datos_paciente.paciente_correo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="inputNombrece" placeholder="Correo Electrónico">
    </div>
    <div *ngIf="!enviar_correo" class="form-group col-3">
      <a class="pointer" data-toggle="modal" data-target="#exampleModal">
        <img style="width: 97%; height: 87%;" (click)="correoEnviar(1)" src="../../../../assets/images/button-mail.png" alt=email>
        <label></label>
      </a>
    </div>
    <div *ngIf="enviar_correo" class="form-group col-3">
      <a class="pointer">
        <img style="width: 97%; height: 87%;" (click)="correoEnviar(0)" src="../../../../assets/images/mail-on.png" alt=email>
        <label></label>
      </a>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" #name="ngModel" name="direccion" maxlength="500"
        [(ngModel)]="datos_paciente.paciente_dir" id="notasTextareaDir" rows="3" placeholder="Dirección"></textarea>
    </div>
  </div>
  <div class="form-row" style="margin-top: 0px;">
    <div class="form-group col-md-12">
      <div class="fondo-titulo-campos" style="margin-bottom: 5px;">
        <h6 class="titulo-campos">AYUDANTÍAS Y PROVEEDORES</h6>
      </div>
    </div>
  </div>
  <app-ayudantia-proveedores></app-ayudantia-proveedores>


</form>

<!--modal envia info-->

<div class="modal fade bd-example-modal-sm modal-top" id="EmailEnviarCirugia" tabindex="-1" role="dialog" aria-labelledby="EmailEnviarCirugiaLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content" style="color:#3C615F">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold" id="EmailEnviarCirugiaLabel" style="margin-left: 51px; font-family: Open Sans; color:#3C615F">Enviar correo</h5>
      </div>
      <div class="modal-body font-weight-bold " style="color:#3C615F">
        <div *ngFor="let correo of correo_array" class="custom-control custom-checkbox centercheck">
          <input (change)="enviarEmail($event.target.value,correo.status)" [checked]="correo.status" #name="ngModel" [(ngModel)]="correo.status"
            [value]="correo.id_categoria" type="checkbox" class="custom-control-input" id="correo_{{correo.id_categoria}}">
          <label class="custom-control-label" for="correo_{{correo.id_categoria}}">{{correo.descripcion}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" style="margin-right: 98px;" (click)="guardarConfEmail()" class="btn btn-form font-weight-bold">Aceptar</button>
      </div>
    </div>
  </div>
</div>