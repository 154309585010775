export class CirugiaModel {
    constructor(
        public cirugia_id: number,
        public cirugia_fecha: string,
        public cirugia_hora: any,
        public cirugia_habitacion: string,
        public cirugia_duracion: any,
        public hospital_id: number,
        public anestesia_id: number,
        public proce_id: number,
        public cirujano_id: number,
        public anestesiologo_id: number,
        public cirugia_status: number,
        public cirugia_datosclinicos : string,
        public enviar_correos : number
    ) {

    }
}