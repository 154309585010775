var tablaColaborador;

export function clearSearch(){
    $('input[type=search]').val('');
    tablaColaborador.search('').draw();
}

export function initTableColaborador(tabla, columnas, dataSet){
    jQuery.extend( jQuery.fn.dataTableExt.oSort, {
        "chinese-string-asc" : function (s1, s2) {
            return s1.localeCompare(s2);
        },
     
        "chinese-string-desc" : function (s1, s2) {
            return s2.localeCompare(s1);
        }
    } )
    if(tablaColaborador) tablaColaborador.destroy();
    tablaColaborador = $('#'+tabla).DataTable( {
        language: {  
            "url": "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
        },
        data: dataSet,
        columns: columnas,
        columnDefs: [
            { type: 'chinese-string', targets: 0 }
          ],
        "rowCallback": function( row, data ) {
            $(row).find('td').css('color', '#3d615f')
            const self = this;
            $('td', row).unbind('click');
            $('td', row).bind('click', () => {
                var event = new CustomEvent("callSomeClickHandler", { "detail": data });
                window.dispatchEvent(event);
                //self.someClickHandler(data);
            });

            $('#eliminar_colaborador', row).unbind('click');
            $('#eliminar_colaborador', row).bind('click', () => {
                var event = new CustomEvent("callSomeClickHandler", { "detail": { "param": data, "accion": "eliminar_colaborador" } });
                window.dispatchEvent(event);
                //self.someClickHandler(data);
            });
            $('#actualizar_colaborador', row).unbind('click');
            $('#actualizar_colaborador', row).bind('click', () => {
                var event = new CustomEvent("callSomeClickHandler", { "detail": { "param": data, "accion": "actualizar_colaborador" } });
                window.dispatchEvent(event);
                //self.someClickHandler(data);
            });
            return row;
        }
    },);

    return tablaColaborador;
}