import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { DetallePago } from '../../../models/DetallePago';
import { DetallePagoService } from '../../../services/consulta/detalle-pago/detalle-pago.service';
import { DatosEventoService } from '../../../services/cirugia/datos-evento.service';
import { ObservableCirugia } from '../../../services/cirugia/observable-cirugia.service';
import { CirugiaService } from '../../../services/cirugia/cirugia.service';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { GLOBAL } from '../../../helpers';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { disableDebugTools } from '@angular/platform-browser';
@Component({
  selector: 'app-detalles-pago-cirugia',
  templateUrl: './detalles-pago-cirugia.component.html',
  styleUrls: ['./detalles-pago-cirugia.component.css']
})
export class DetallesPagoCirugiaComponent implements OnInit, OnDestroy {
  public consulta_pagada: boolean;
  public deposito: boolean;
  public recibo: boolean;
  public session: string;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public datos_pago: DetallePago;
  public ingresacantidad: string;
  public cat_fpago = new Array();
  public banco_desc = new Array();
  public cat_aseguradora = new Array();
  public formato: number;
  public detalle_imagen_detalle_pago: number;
  public file_imagen_detalle_pago: File;
  public url_detalle_pago: string;
  public file_imagen_detalle_pagoSecundario: File;
  public detalle_imagen_detalle_pagoSecundario: number;
  public url_detalle_pagoSecundario: string;
  public datos: any;
  private subscription_observable: Subscription = new Subscription();
  public datos_actualizar: boolean;
  public datos_cirugia: any;
  public imagen_pago_principal: any;
  public imagen_pago_secundario: any;
  public url_imagen: string;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private DetallePagoService: DetallePagoService,
    private DatosEventoService: DatosEventoService,
    private ObservableCirugia: ObservableCirugia,
    private CirugiaService: CirugiaService,
    private ObservableExtraer: ObservableExtraer,
    private router: Router
  ) {
    this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
    this.datos_pago.detpg_cantidad = null;
    this.datos_actualizar = false;
  }

  ngOnInit() {
    this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
    this.Session();
    //this.consulta_pagada = false;
    this.recibo = false;
    this.deposito = false;
    this.datos_pago.detpg_pagada = 0;
    this.datos_pago.detpg_recibo = 0;
    this.datos_pago.detpg_depositado = 0;
    this.datos_pago.detpg_tipo = 2;
    this.url_imagen = GLOBAL.url;
    if (this.datos_pago.detpg_cantidad == 0) {
      this.datos_pago.detpg_cantidad = null;
      this.ingresacantidad = "Cantidad"
    }

    const subscription = this.ObservableCirugia.verificaPagoCirugiaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos = result;
        this.verificarPago();
      }
    })

    const unsubscribe_pago = this.ObservableExtraer.datosSelectedPagoMessage.subscribe(result => {
      
      if (result != null && result != undefined && result != "") {
        this.datos_actualizar = true;
        this.datos_cirugia = result.datos_cirugia;
        this.datos_pago = result.detalle_pago;
        if(this.datos_pago){
          if(this.datos_pago.detpg_cantidad == 0)  this.datos_pago.detpg_cantidad = null;
          if(this.datos_pago.banco_id == null) this.datos_pago.banco_id = 0;
          if(this.datos_pago.aseguradora_id == null) this.datos_pago.aseguradora_id = 0;
          if(this.datos_pago.fpago_id == null) this.datos_pago.fpago_id = 0;
          if (this.datos_pago.detpg_pagada == 1) {
            this.consulta_pagada = true;
          } else if (this.datos_pago.detpg_pagada == 0) {
            this.consulta_pagada = false;
            this.datos_pago.detpg_cantidad = null;
          }
          if (this.datos_pago.detpg_depositado == 1) {
            this.deposito = true;
          } else if (this.datos_pago.detpg_depositado == 0) {
            this.deposito = false;
          }
          if (this.datos_pago.detpg_recibo == 1) {
            this.recibo = true;
          } else if (this.datos_pago.detpg_recibo == 0) {
            this.recibo = false;
          }
        } else {
          this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
          this.recibo = false;
          this.deposito = false;
          this.consulta_pagada = false;
          this.datos_pago.detpg_cantidad = null;
        }
        if (this.datos_actualizar) {
          this.verImagenPago(1);
          this.verImagenPago(2);
          //this.verImagenPago(3);
          //this.verImagenPago(4);
        }
      } else {
        this.datos_cirugia = null;
        this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
        this.imagen_pago_principal = null;
        this.datos_pago.detpg_cantidad = null;
        this.imagen_pago_secundario = null;
        this.deposito = false;
        this.recibo = false;
        this.consulta_pagada = false;

      }
    })
    this.subscription_observable.add(subscription);
    this.subscription_observable.add(unsubscribe_pago);
    this.CirugiaReciboButtons()
    this.CirugiaPagadaButtons()
    this.CirugiaDepositadoButtons()
   
  }

  ngOnDestroy() {
    this.ObservableExtraer.changedatosPagoSelected({ limpiar: true });
    this.ObservableCirugia.changeResetDatosCirugia({ limpiar: true });
    this.ObservableExtraer.changedatosAyudantiaSelected({ limpiar: true })
    if (this.subscription_observable) {
      this.subscription_observable.unsubscribe();
      this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
      this.datos_pago.detpg_cantidad = null;
    }
  }
  /**********Input RadioButon Si No*********** */
  esPagada(pagada) {
    this.consulta_pagada = pagada;
    if (this.consulta_pagada == true) {
      this.datos_pago.detpg_pagada = 1;
    } else {
      this.datos_pago.detpg_pagada = 0;
    }
  }

  esDeposito(deposito) {
    this.deposito = deposito;
    if (this.deposito == true) {
      this.datos_pago.detpg_depositado = 1;
    } else {
      this.datos_pago.detpg_depositado = 0;
    }
  }
  esRecibo(recibo) {
    this.recibo = recibo;
    if (this.recibo == true) {
      this.datos_pago.detpg_recibo = 1;
    } else {
      this.datos_pago.detpg_recibo = 0;
    }
  }
  /**********Termino Input RadioButon Si No*********** */

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
     // this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
     this.usuario_id = this.datos_session.usuario_id;
      console.log("****this.usuario_id ",this.usuario_id )
      this.getPerfilUsuario();
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.datos_pago.usuario_id = this.datos_usuario.usuario_id;
          this.getPagos();
          this.getBanco();
          this.getAseguradora();
        }
      })
  }

  //CATALOGOS
  getPagos() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;

    this.DetallePagoService.getPagos(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.cat_fpago = result.cat_fpago;
      })
  }
  getBanco() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;
    this.DetallePagoService.getBanco(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.banco_desc = result.cat_banco;
      })
  }


  getAseguradora() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;
    this.DetallePagoService.getAseguradora(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.cat_aseguradora = result.cat_aseguradora;
      })
  }

  /******************************** */
  verificarPago() {
    debugger
    this.datos.datos_evento.enviar_correo = this.datos.datos_paciente.enviar_correo;
    this.datos.datos_evento.paciente_correo = this.datos.datos_paciente.paciente_correo;
    this.datos.datos_evento.paciente_nombre = this.datos.datos_paciente.paciente_nombre;
    this.datos.datos_evento.destinatarios = this.datos.destinatarios;
    this.datos.datos_evento.usuario_id = this.datos_usuario.usuario_id;
    //Recibo si hay aseguradora?? por que?
    if(this.datos.aseguradora_id && this.datos.aseguradora_id != 0){
      this.recibo = true;
    }
    
    if (this.datos_pago.detpg_cantidad == null) {
      this.datos_pago.detpg_cantidad = 0;
    }
  
    //Validar
    if(this.datos_pago.detpg_fechapago == null) this.datos_pago.detpg_fechapago = '';
    if(this.datos_pago.detpg_fechadepo == null) this.datos_pago.detpg_fechadepo = '';
    if(this.datos_pago.detpg_fecharecibo == null) this.datos_pago.detpg_fecharecibo = '';

    if(this.datos_pago.detpg_cantidad > 0 || (this.datos_pago.detpg_fechapago != '0000-00-00' && this.datos_pago.detpg_fechapago != '') || this.datos_pago.fpago_id != 0){
      this.datos_pago.detpg_pagada = 1
    } else { this.datos_pago.detpg_pagada = 0; }
    if((this.datos_pago.detpg_fechadepo != '0000-00-00' && this.datos_pago.detpg_fechadepo != '') || this.datos_pago.banco_id != 0){
      this.datos_pago.detpg_depositado = 1;
    } else { this.datos_pago.detpg_depositado = 0; }
    if((this.datos_pago.detpg_recibonum && this.datos_pago.detpg_recibonum.length > 0) || ( this.datos_pago.detpg_fecharecibo != '0000-00-00' && this.datos_pago.detpg_fecharecibo != '') ){
          //|| (this.datos_pago.detpg_detalle != '')){
      this.datos_pago.detpg_recibo = 1;
    } else { this.datos_pago.detpg_recibo = 0; }

    if (this.datos_actualizar) {
      this.ActualizarDatosCirugia();
    } else if (!this.consulta_pagada && !this.deposito && !this.recibo) {
      //debo enviar el obserbable vacio
      // this.datos_pago.detpg_depositado = 1;
      // this.datos_pago.detpg_pagada = 1;

      this.datos.datos_paciente.usuario_id = this.datos_usuario.usuario_id;
      if (this.datos.datos_paciente.paciente_id == 0) {
        //cuando no existe un paciente lo crea
        this.CirugiaService.AddCirugiaPaciente(this.datos.datos_paciente)
          .subscribe(result => {
            if (result.valido == 1) {
              this.datos_pago.usuario_id = this.datos_usuario.usuario_id;
              let paciente_id = result.paciente.paciente_id;
              this.datos.datos_evento.paciente_id = paciente_id;
              
              this.CirugiaService.AddCirugiaPago(this.datos_pago)
                .subscribe(result => {
                  let detpg_id = result.detalle_pago[0].detpg_id;
                  this.datos.datos_evento.paciente_id = paciente_id
                  this.datos.datos_evento.detpg_id = detpg_id;
                  this.datos.datos_evento.cirugia_fecha == "" ? this.datos.datos_evento.cirugia_fecha = moment().format('YYYY-MM-DD') : this.datos.datos_evento.cirugia_fecha = this.datos.datos_evento.cirugia_fecha;
                  this.datos.datos_evento.cirugia_hora == "" ? this.datos.datos_evento.cirugia_hora = new Date() : this.datos.datos_evento.cirugia_hora = this.datos.datos_evento.cirugia_hora;
                  this.datos.datos_evento.ayudantia =[];
                  for(let key in   this.datos.datos_ayudantia){
                    this.datos.datos_evento.ayudantia.push(this.datos.datos_ayudantia[key].ayudantia_id);
                  }
                  this.CirugiaService.AddCirugiaGenerales(this.datos.datos_evento)
                    .subscribe(result => {
                      let cirugia_id = result.cirugia[0].cirugia_id;
                      if (this.datos.datos_ayudantia != 0) {
                        for (let key in this.datos.datos_ayudantia) {
                          this.datos.datos_ayudantia[key].cirugia_id = cirugia_id;
                        }
                        this.CirugiaService.AddProveedor(this.datos.datos_ayudantia)
                          .subscribe(result => {
                            this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                            swal('Muy Bien', 'Se guardo correctamente!', 'success');
                            this.router.navigate(['/home']);
                            this.GuardarArchivoImagenSecundario(detpg_id);
                            this.GuardarArchivoImagen(detpg_id);
                            this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                            this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                            this.consulta_pagada = true;
                            this.recibo = true;
                            this.deposito = true;
                            this.datos_pago.detpg_pagada = 1;
                            this.datos_pago.detpg_recibo = 1;
                            this.datos_pago.detpg_depositado = 1;
                          })
                      } else {
                        this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                        swal('Muy Bien', 'Se guardo correctamente!!', 'success');
                        this.router.navigate(['/home']);
                        this.GuardarArchivoImagenSecundario(detpg_id);
                        this.GuardarArchivoImagen(detpg_id);
                        this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                        this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                        this.consulta_pagada = true;
                        this.recibo = true;
                        this.deposito = true;
                        this.datos_pago.detpg_pagada = 1;
                        this.datos_pago.detpg_recibo = 1;
                        this.datos_pago.detpg_depositado = 1;
                      }
                    })
                })
            }

          })

        //cuando si existe el paciente
      } else if (this.datos.datos_paciente.paciente_id != 0) {
        let pago = this.datos_pago;
        //   this.datos_pago.usuario_id = this.datos_usuario.usuario_id;
        let paciente_id = this.datos.datos_paciente.paciente_id;
        this.datos.datos_evento.paciente_id = paciente_id
        this.CirugiaService.AddCirugiaPago(pago)
          .subscribe(result => {
            this.datos.datos_evento.detpg_id = result.detalle_pago[0].detpg_id;
            this.datos.datos_evento.cirugia_fecha == "" ? this.datos.datos_evento.cirugia_fecha = moment().format('YYYY-MM-DD') : this.datos.datos_evento.cirugia_fecha = this.datos.datos_evento.cirugia_fecha;
            this.datos.datos_evento.cirugia_hora == "" ? this.datos.datos_evento.cirugia_hora = new Date() : this.datos.datos_evento.cirugia_hora = this.datos.datos_evento.cirugia_hora;
            this.datos.datos_evento.ayudantia =[];
            for(let key in   this.datos.datos_ayudantia){
              this.datos.datos_evento.ayudantia.push(this.datos.datos_ayudantia[key].ayudantia_id);
            }
            this.CirugiaService.AddCirugiaGenerales(this.datos.datos_evento)
              .subscribe(result => {
                let cirugia_id = result.cirugia[0].cirugia_id;
                if (this.datos.datos_ayudantia != 0) {
                  for (let key in this.datos.datos_ayudantia) {
                    this.datos.datos_ayudantia[key].cirugia_id = cirugia_id;
                  }
                  this.CirugiaService.AddProveedor(this.datos.datos_ayudantia)
                    .subscribe(result => {
                      this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                      swal('Muy Bien', 'Se guardo correctamente!!!', 'success');
                      this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                      this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                      this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                      this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                      this.consulta_pagada = true;
                      this.recibo = true;
                      this.deposito = true;
                      this.datos_pago.detpg_pagada = 1;
                      this.datos_pago.detpg_recibo = 1;
                      this.datos_pago.detpg_depositado = 1;
                      this.router.navigate(['/home']);
                    })

                } else {

                  this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                  swal('Muy Bien', 'Se guardo correctamente!!!!', 'success')
                  this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                  this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                  this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                  this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                  this.consulta_pagada = true;
                  this.recibo = true;
                  this.deposito = true;
                  this.datos_pago.detpg_pagada = 1;
                  this.datos_pago.detpg_recibo = 1;
                  this.datos_pago.detpg_depositado = 1;
                  this.router.navigate(['/home']);
                }
              })
          })
      }
    } else if (this.usuario_id == "" || this.usuario_id == null || this.usuario_id == undefined) {
      swal("Error", "Vuelva Iniciar Sesión", "error");
    } else {
      //debo enviar el obserbable
      // this.datos_pago.detpg_depositado = 1;
      // this.datos_pago.detpg_pagada = 1;
      this.datos.datos_paciente.usuario_id = this.datos_usuario.usuario_id;;
      if (this.datos.datos_paciente.paciente_id == 0) {
        //cuando no existe un paciente lo crea.
        let datos_pago = this.datos_pago;
        this.CirugiaService.AddCirugiaPaciente(this.datos.datos_paciente)
          .subscribe(result => {
            if (result.valido == 1) {
              // this.datos_pago.usuario_id = this.datos_usuario.usuario_id;
              let paciente_id = result.paciente.paciente_id;

              this.CirugiaService.AddCirugiaPago(datos_pago)
                .subscribe(result => {
                  if (result.valido == 1) {
                    let detpg_id = result.detalle_pago[0].detpg_id;
                    this.datos.datos_evento.paciente_id = paciente_id
                    this.datos.datos_evento.detpg_id = detpg_id
                    this.datos.datos_evento.cirugia_fecha == "" ? this.datos.datos_evento.cirugia_fecha = moment().format('YYYY-MM-DD') : this.datos.datos_evento.cirugia_fecha = this.datos.datos_evento.cirugia_fecha;
                    this.datos.datos_evento.cirugia_hora == "" ? this.datos.datos_evento.cirugia_hora = new Date() : this.datos.datos_evento.cirugia_hora = this.datos.datos_evento.cirugia_hora;
                    this.datos.datos_evento.ayudantia =[];
                    for(let key in   this.datos.datos_ayudantia){
                      this.datos.datos_evento.ayudantia.push(this.datos.datos_ayudantia[key].ayudantia_id);
                    }
                    this.CirugiaService.AddCirugiaGenerales(this.datos.datos_evento)
                      .subscribe(result => {
                        let cirugia_id = result.cirugia[0].cirugia_id;
                        if (this.datos.datos_ayudantia != 0) {
                          for (let key in this.datos.datos_ayudantia) {
                            this.datos.datos_ayudantia[key].cirugia_id = cirugia_id;
                          }
                          this.CirugiaService.AddProveedor(this.datos.datos_ayudantia)
                            .subscribe(result => {
                              this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                              swal('Muy Bien', 'Se guardo correctamente', 'success');
                              this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                              this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                              this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                              this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                              this.consulta_pagada = true;
                              this.recibo = true;
                              this.deposito = true;
                              this.datos_pago.detpg_pagada = 1;
                              this.datos_pago.detpg_recibo = 1;
                              this.datos_pago.detpg_depositado = 1;
                              this.router.navigate(['/home']);
                            })
                        } else {
                          this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                          swal('Muy Bien', 'Se guardo correctamente', 'success');
                          this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                          this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                          this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                          this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                          this.consulta_pagada = true;
                          this.recibo = true;
                          this.deposito = true;
                          this.datos_pago.detpg_pagada = 1;
                          this.datos_pago.detpg_recibo = 1;
                          this.datos_pago.detpg_depositado = 1;
                          this.router.navigate(['/home']);
                        }
                      })
                  }
                })

            }
          })
        //cuando si existe el paciente
      } else if (this.datos.datos_paciente.paciente_id != 0) {
        this.datos_pago.usuario_id = this.datos_usuario.usuario_id;
        let paciente_id = this.datos.datos_paciente.paciente_id;
        this.CirugiaService.AddCirugiaPago(this.datos_pago)
          .subscribe(result => {
            if (result.valido == 1) {
              let detpg_id = result.detalle_pago[0].detpg_id;
              this.datos.datos_evento.paciente_id = paciente_id
              this.datos.datos_evento.detpg_id = detpg_id
              this.datos.datos_evento.cirugia_fecha == "" ? this.datos.datos_evento.cirugia_fecha = moment().format('YYYY-MM-DD') : this.datos.datos_evento.cirugia_fecha = this.datos.datos_evento.cirugia_fecha;
              this.datos.datos_evento.cirugia_hora == "" ? this.datos.datos_evento.cirugia_hora = new Date() : this.datos.datos_evento.cirugia_hora = this.datos.datos_evento.cirugia_hora;
              this.datos.datos_evento.ayudantia =[];
              for(let key in   this.datos.datos_ayudantia){
                this.datos.datos_evento.ayudantia.push(this.datos.datos_ayudantia[key].ayudantia_id);
              }
              this.CirugiaService.AddCirugiaGenerales(this.datos.datos_evento)
                .subscribe(result => {
                  let cirugia_id = result.cirugia[0].cirugia_id;
                  if (this.datos.datos_ayudantia != 0) {
                    for (let key in this.datos.datos_ayudantia) {
                      this.datos.datos_ayudantia[key].cirugia_id = cirugia_id;
                    }
                    this.CirugiaService.AddProveedor(this.datos.datos_ayudantia)
                      .subscribe(result => {
                        this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                        swal('Muy Bien', 'Se guardo correctamente', 'success');
                        this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                        this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                        this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                        this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                        this.consulta_pagada = true;
                        this.recibo = true;
                        this.deposito = true;
                        this.datos_pago.detpg_pagada = 1;
                        this.datos_pago.detpg_recibo = 1;
                        this.datos_pago.detpg_depositado = 1;
                        this.router.navigate(['/home']);
                      })
                  } else {
                    let cirugia_id = result.cirugia[0].cirugia_id;
                    this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                    swal('Muy Bien', 'Se guardo correctamente', 'success');
                    this.GuardarArchivoImagenSecundario(this.datos.datos_evento.detpg_id);
                    this.GuardarArchivoImagen(this.datos.datos_evento.detpg_id);
                    this.ObservableCirugia.changeResetDatosCirugia(cirugia_id);
                    this.ObservableCirugia.changeResetDatosPaciente(cirugia_id);
                    this.consulta_pagada = true;
                    this.recibo = true;
                    this.deposito = true;
                    this.datos_pago.detpg_pagada = 1;
                    this.datos_pago.detpg_recibo = 1;
                    this.datos_pago.detpg_depositado = 1;
                    this.router.navigate(['/home']);
                  }

                })
            }
          })
      }
    }
    //
  }
  /**********Subir Imagen***********/
  SubirImagenDetallePago($event) {
    //formato 1 imagen, tipo 2 archivo
    //detalle_imagen_detalle_pago 1 consulta 2 cirugia 3 pago

    this.formato = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_detalle_pago = 3;
    this.file_imagen_detalle_pago = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_detalle_pago = event.target.result;
    };
    reader.readAsDataURL(file);
  }
  //Eliminar Imagen Evento
  @ViewChild('ImagenDetallePago')
  ImagenDetallePago: any;
  EliminarImagenDetallePago() {
    if (this.datos_actualizar && this.imagen_pago_principal) {
      this.imagen_pago_principal.imagen_status = 0;
      this.CirugiaService.EliminarImagenDetallePago(this.imagen_pago_principal)
        .subscribe(result => {
          this.verImagenPago(1);
        })
    } else {
      this.url_detalle_pago = null;
      this.file_imagen_detalle_pago = null;
      this.ImagenDetallePago.nativeElement.value = "";
    }
  }
  //el guardado se realiza cuando todos los componentes en verificacion esta correcto
  GuardarArchivoImagen(valor_id) {
    if (this.file_imagen_detalle_pago != undefined) {
      let params = new Array();
      this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_detalle_pago + '/' + valor_id + '/' + this.formato, params, this.file_imagen_detalle_pago, this.formato)
        .then(
          (result: any) => {
            this.EliminarImagenDetallePago();
          })
    }
  }

  /*****************************/

  /**********Subir Imagen***********/
  SubirImagenDetallePagoSecundario($event) {
    //formato 1 imagen, tipo 2 archivo
    //detalle_imagen_detalle_pagoSecundario 1 consulta 2 cirugia 3 pago

    this.formato = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_detalle_pagoSecundario = 3;
    this.file_imagen_detalle_pagoSecundario = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_detalle_pagoSecundario = event.target.result;
    };
    reader.readAsDataURL(file);
  }
  //Eliminar Imagen Evento
  @ViewChild('ImagenDetallePagoSecundario')
  ImagenDetallePagoSecundario: any;
  EliminarImagenDetallePagoSecundario() {
    if (this.datos_actualizar && this.imagen_pago_secundario) {
      this.imagen_pago_secundario.imagen_status = 0;
      this.CirugiaService.EliminarImagenDetallePago(this.imagen_pago_secundario)
        .subscribe(result => {
          this.verImagenPago(2);
        })
    } else {
      this.url_detalle_pagoSecundario = null;
      this.file_imagen_detalle_pagoSecundario = null;
      this.ImagenDetallePagoSecundario.nativeElement.value = "";
    }

  }
  //el guardado se realiza cuando todos los componentes en verificacion esta correcto
  GuardarArchivoImagenSecundario(valor_id) {
    if (this.file_imagen_detalle_pagoSecundario != undefined) {
      let params = new Array();
      this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_detalle_pagoSecundario + '/' + valor_id + '/' + this.formato, params, this.file_imagen_detalle_pagoSecundario, this.formato)
        .then(
          (result: any) => {
            this.EliminarImagenDetallePagoSecundario();
          })
    }
  }

  /**************************ACTUALIZACION DE DATOS****************************************** */
  ActualizarDatosCirugia() {
   
    let pago = this.datos_pago;
    console.log("Actualizar",this.datos_pago)
    this.CirugiaService.updatePaciente(this.datos.datos_paciente)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos.datos_evento.ayudantia= [];
          for(let key in  this.datos.datos_ayudantia){
            this.datos.datos_evento.ayudantia.push(this.datos.datos_ayudantia[key].ayudantia_id);
          }
          this.CirugiaService.updateCirugia(this.datos.datos_evento)
            .subscribe(result => {
              if (result.valido == 1) {
                //Codigo si el radoibutton es no pagado no guarda los demas campos
                // if (pago.detpg_pagada == 0) {
                //   pago.detpg_cantidad = 0;
                //   pago.fpago_id = 0;
                //   pago.aseguradora_id = 0;
                //   pago.detpg_fechapago = "";
                // }
                // if (pago.detpg_depositado == 0) {
                //   pago.banco_id = 0;
                //   pago.detpg_fechadepo = "";
                // }
                // if (pago.detpg_recibo == 0) {
                //   pago.detpg_recibonum = "";
                //   pago.detpg_fecharecibo = "";
                //   pago.detpg_detalle = "";
                // }
                //Validar
              if(pago.detpg_cantidad > 0 || (pago.detpg_fechapago != '0000-00-00' && pago.detpg_fechapago != '') || pago.fpago_id != 0){
                pago.detpg_pagada = 1
              } else { pago.detpg_pagada = 0; }
              if( (pago.detpg_fechadepo != '0000-00-00' && pago.detpg_fechadepo != '') || pago.banco_id != 0){
                pago.detpg_depositado = 1;
              } else { pago.detpg_depositado = 0; }
              if((pago.detpg_recibonum && pago.detpg_recibonum.length > 0) || ( pago.detpg_fecharecibo != '0000-00-00' && pago.detpg_fecharecibo != '') ){
                pago.detpg_recibo = 1;
              } else { pago.detpg_recibo = 0; }
              
                this.CirugiaService.updatePago(pago)
                  .subscribe(result => {
                    if (result.valido == 1) {
                      if (this.datos.datos_ayudantia != 0) {
                        for (let key in this.datos.datos_ayudantia) {
                          this.datos.datos_ayudantia[key].cirugia_id = this.datos_cirugia.cirugia_id
                        }
                        this.CirugiaService.updateProveedor(this.datos.datos_ayudantia)
                          .subscribe(result => {
                            let array_ayudantias = [];
                            this.ObservableExtraer.changedatosAyudantiaSelected(array_ayudantias)
                            //    this.ObservableCirugia.changeResetDatosCirugia(this.datos_cirugia.cirugia_id );
                          })
                      }
                      this.GuardarArchivoImagenSecundarioUpdate(this.datos_pago.detpg_id);
                      this.GuardarArchivoImagenUpdate(this.datos_pago.detpg_id);
                      this.router.navigate(['/home']);
                      swal({ title: "Muy Bien! sus datos han sido Actualizados", text: result.error, type: "success", timer: 2000, showConfirmButton: false });
                    }
                  })
              }
            })
        }
      })

  }
  verImagenPago(no_imagen) {
    if (this.datos_actualizar) {
      let imagen_tipo = 3;
      this.CirugiaService.GetImagenesTipo(this.token.token, imagen_tipo, this.datos_cirugia.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            if (no_imagen == 1) {
              this.imagen_pago_principal = result.imagenes[0];
            } else if (no_imagen == 2) {
              this.imagen_pago_secundario = result.imagenes[1];
            } else if (no_imagen == 3){
              this.imagen_pago_principal = result.imagenes[0];
              if(result.imagenes.length == 2) this.imagen_pago_secundario = result.imagenes[1];
            }
          }
        })
    }

  }
  /*****************************/
  ///IMAGENES ACTUALIZAR/

  GuardarArchivoImagenSecundarioUpdate(valor_id) {
    if (this.file_imagen_detalle_pagoSecundario != undefined) {
      let params = new Array();
      if (this.imagen_pago_secundario != undefined) {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen-update/' + this.detalle_imagen_detalle_pagoSecundario + '/' + valor_id + '/' + this.formato + '/' + this.imagen_pago_secundario.imagen_id, params, this.file_imagen_detalle_pagoSecundario, this.formato)
          .then(
            (result: any) => {
              this.url_detalle_pagoSecundario = null;
              this.file_imagen_detalle_pagoSecundario = null;
              this.ImagenDetallePagoSecundario.nativeElement.value = "";
            })
      } else {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_detalle_pagoSecundario + '/' + valor_id + '/' + this.formato, params, this.file_imagen_detalle_pagoSecundario, this.formato)
          .then(
            (result: any) => {
              this.url_detalle_pagoSecundario = null;
              this.file_imagen_detalle_pagoSecundario = null;
              this.ImagenDetallePagoSecundario.nativeElement.value = "";
            })
      }

    }
  }

  GuardarArchivoImagenUpdate(valor_id) {
    if (this.file_imagen_detalle_pago != undefined) {
      let params = new Array();
      if (this.imagen_pago_principal != undefined) {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen-update/' + this.detalle_imagen_detalle_pago + '/' + valor_id + '/' + this.formato + '/' + this.imagen_pago_principal.imagen_id, params, this.file_imagen_detalle_pago, this.formato)
          .then(
            (result: any) => {
              this.url_detalle_pago = null;
              this.file_imagen_detalle_pago = null;
              this.ImagenDetallePago.nativeElement.value = "";
            })
      } else {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_detalle_pago + '/' + valor_id + '/' + this.formato, params, this.file_imagen_detalle_pago, this.formato)
          .then(
            (result: any) => {
              this.url_detalle_pago = null;
              this.file_imagen_detalle_pago = null;
              this.ImagenDetallePago.nativeElement.value = "";
            })
      }

    }
  }
    //cambio y limpeador de inputs
    CirugiaPagadaButtons() {
   
      if ((this.datos_pago.detpg_fechapago != "0000-00-00" && this.datos_pago.detpg_fechapago != '' && this.datos_pago.detpg_fechapago != null) || (this.datos_pago.detpg_cantidad != 0 && this.datos_pago.detpg_cantidad) || this.datos_pago.fpago_id != 0  ) {
        this.consulta_pagada = true;
      } else {
        this.consulta_pagada = false;
      }
    }
  
    CirugiaDepositadoButtons() {
    // se selecciona el input radio pagado automatico al detectar campos llenos
      if (this.datos_pago.banco_id != 0  ||(this.datos_pago.detpg_fechadepo != '' && this.datos_pago.detpg_fechadepo != "0000-00-00" && this.datos_pago.detpg_fechadepo != null )) {
        this.deposito = true;
      } else {
        this.deposito = false;
      }
    }
  
    CirugiaReciboButtons(){
    console.log("datos", this.datos_pago)
    if ( (this.datos_pago.detpg_recibonum != '' && this.datos_pago.detpg_recibonum != null )  || ( this.datos_pago.detpg_fecharecibo != '' && this.datos_pago.detpg_fecharecibo != "0000-00-00" && this.datos_pago.detpg_fecharecibo != null ) ){
         //|| (this.datos_pago.detpg_detalle != '' && this.datos_pago.detpg_detalle != null)) {
      this.recibo = true;
    } else {
      this.recibo = false;
    }
  }

  clearValuePago(){
    this.datos_pago.detpg_fechapago = '';
    this.CirugiaPagadaButtons();
  }

  clearValueDeposito(){
    this.datos_pago.detpg_fechadepo = '';
    this.CirugiaDepositadoButtons();
  }

  clearValueRecibo(){
    this.datos_pago.detpg_fecharecibo = '';
    this.CirugiaReciboButtons();
  }
}