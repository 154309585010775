import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from '../configuracion/configuracion.service';

@Component({
  selector: 'app-cirugia',
  templateUrl: './cirugia.component.html',
  styleUrls: ['./cirugia.component.css']
})
export class CirugiaComponent implements OnInit {
  public mostrar_cirugia: boolean;
  public perfil_menu;
  public session: string;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario;
  constructor(private ConfiguracionService: ConfiguracionService) { }

  ngOnInit() {
    this.mostrar_cirugia = false;
    this.Session();
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
      this.MostrarMenu();
    }
  }
  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == 2) {
        this.mostrar_cirugia = true;

      }
    }
  }
  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];

        }
      })
  }
}
