export class BuscadorHeaderModel {
constructor(
   public texto: string,
   public cirugia_fecha_ini: string,
   public cirugia_fecha_fin: string,
   public paciente: string,
   public edad: number,
   public hospital_id: number,
   public anestesia_id: number,
   public proce_id: number,
   public cirujano_id: number,
   public anestesiologo_id: number,
   public cirugia_status: number,
   public cirugia_datosclinicos: string,
   public tipo_filtro: number,
   public usuario_id: number,
   public detpg_detalle : '',
   public tipo_busqueda : number
) { }
}
