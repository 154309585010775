<section *ngIf="datos_session.acceso_pago == 1">

	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="mr-2 flex-shrink-1"><img src="../../../assets/images/icon-pago.png" width="auto" height="20px"></div>
        <div class="p-0 w-100"><p class="consulta">¿Consulta Pagada?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input class="custom-control-input" name="radios4" type="radio" disabled = true; (change)="esPagada(consulta_pagada)" [(ngModel)]="consulta_pagada" [value]="true"
            id="radios4">
            <label class="custom-control-label consulta p-1" for="radios4">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" name="radios5" disabled = true;  (change)="esPagada(consulta_pagada)" [(ngModel)]="consulta_pagada" [value]="false"
            id="radios5">  
            <label class="custom-control-label consulta p-1" for="radios5">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>





  <div class="form-row" style="margin-top: 3px;">
    <div disabled="datos_pago.detpg_pagada == 0" class="form-group col-6">
      <input type="number" name="cantidad_pago" (input)="ConsutaPagadaButtons()" #name="ngModel" [(ngModel)]="datos_pago.detpg_cantidad" min=0 max=9999999999 class="btn btn-form-conf btn-block btn-lg"
        placeholder="Cantidad">
    </div>
    <div class="form-group col-6">
      <select #name="ngModel" [(ngModel)]="datos_pago.fpago_id" (change)="ConsutaPagadaButtons()" class="btn btn-block  letraCuadrosFormulario letraCuadrosLista"
        name="forma_pago" id="forma de pago">
        <option class="label-center" [value]="0">Forma de Pago / Agregar en Configuración</option>
        <option *ngFor="let pago of cat_fpago" [value]="pago.fpago_id">{{pago.fpago_desc}}</option>
      </select>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select class="text-align: left btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"  (change)="ConsutaPagadaButtons()" [(ngModel)]="datos_pago.aseguradora_id"
        #name="ngModel" name="aseguradora" id="aseguradora">
        <option class="label-center " [value]="0">Aseguradora / Agregar en Configuración</option>
        <option *ngFor="let aseguradora of cat_aseguradora" [value]="aseguradora.aseguradora_id">{{aseguradora.aseguradora_desc}}</option>
      </select>
    </div>
    <div class="form-group col-6">

      <div class="input-group mb-3">
        <input type="text" class="form-control read-on" style="font-size: 12px;"
        (dateTimeInput)="ConsutaPagadaButtons()" name="fecha_pago"  #name="ngModel" [(ngModel)]="datos_pago.detpg_fechapago" 
         placeholder="Fecha De Pago" [owlDateTimeTrigger]="dt2" 
         [owlDateTime]="dt2" readonly>
        <div class="input-group-append">
          <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
          <button class="btn read-on btn-default" type="button" (click)="clearValuePago()"  style="font-size: 17px; color: #b9b9b9;"><i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-deposit.png" width="auto" height="20px"></div>
        <div class="p-1 w-100"><p class="consulta">¿Depositado?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" name="radios7" class="custom-control-input" name="radios7" disabled = true;  [(ngModel)]="deposito" (change)="esDeposito(deposito)"
             [value]="true" id="radios7" #name="ngModel">
            <label class="custom-control-label consulta p-1" for="radios7">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" name="radios8" class="custom-control-input" name="radios8" disabled = true;  [(ngModel)]="deposito" (change)="esDeposito(deposito)"
            [value]="false"  id="radios8" #name="ngModel">  
            <label class="custom-control-label consulta p-1" for="radios8">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>

  
  <div class="form-row">
    <div class="form-group col-6">
      <select #name="ngModel" (change)="ConsutaDepositadoButtons()" [(ngModel)]="datos_pago.banco_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        name="banco_rec" id="banco_Rec">
        <option [value]="0">Banco Receptor / Agregar en Configuración</option>
        <option *ngFor="let banco of banco_desc" [value]="banco.banco_id">{{banco.banco_desc}}</option>
      </select>
      <!-- Requiere el script al final de la página-->

    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <input type="text" class="form-control read-on"  aria-describedby="button-addon2" style="font-size: 12px;"
        (dateTimeInput)="ConsutaDepositadoButtons()" id="datepicker3" #name="ngModel" [(ngModel)]="datos_pago.detpg_fechadepo"  placeholder="Fecha" [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" readonly>
        <div class="input-group-append">
          <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
          <button class="btn read-on btn-default" type="button" (click)="clearValueDeposito()" id="button-addon2" style="font-size: 17px; color: #b9b9b9;"><i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      </div>
    </div>
  </div>

	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-recibo.png" width="auto" height="20px"></div>
        <div class="p-1 w-100"><p class="consulta">¿Recibo?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled = true;  [disabled]="datos_session.acceso_pago == 0" [(ngModel)]="recibo" (change)="esRecibo(recibo)" [value]="true" 
            name="radios10" id="radios10" #name="ngModel">
            <label class="custom-control-label consulta p-1" for="radios10">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled = true; [disabled]="datos_session.acceso_pago == 0" [(ngModel)]="recibo" (change)="esRecibo(recibo)" [value]="false" 
            name="radios11" id="radios11" value="option2" #name="ngModel">  
            <label class="custom-control-label consulta p-1" for="radios11">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>


  <div class="form-row">
    <div class="form-group col-6">
      <input (input)="ConsutaReciboButtons()"  type="text" placeholder="No de Recibo" #name="ngModel" [(ngModel)]="datos_pago.detpg_recibonum" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista">
    </div>
    <div class="form-group col-6">

      <div class="input-group mb-3">
        <input type="text" class="form-control read-on" style="font-size: 12px;"
        (dateTimeInput)="ConsutaReciboButtons()" id="datepicker4" name="fecha_recibo" #name="ngModel" [(ngModel)]="datos_pago.detpg_fecharecibo" 
         placeholder="Fecha De Recibo" [owlDateTimeTrigger]="dt4"
        [owlDateTime]="dt4" readonly>
        <div class="input-group-append">
          <owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time>
          <button class="btn read-on btn-default" type="button" (click)="clearValueRecibo()"  style="font-size: 17px; color: #b9b9b9;"><i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      </div>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea (input)="ConsutaReciboButtons()"  [disabled]="datos_session.acceso_pago == 0" class=" btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        #name="ngModel" [(ngModel)]="datos_pago.detpg_detalle" id="notasTextarea" rows="3" placeholder="Detalles Del Pago"></textarea>
    </div>
  </div>

</section>


<!--//////////////////////////////////////////////////si no tiene permisos de pagos//////////////////////////////////////////////////////////////////-->




<section *ngIf="datos_session.acceso_pago == 0">
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-pago.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      ¿Consulta Pagada?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios4">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esPagada(consulta_pagada)" class="form-check-input" [(ngModel)]="consulta_pagada"
          [value]="true" type="radio" name="radios3" id="radios4" value="Si" style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios5">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esPagada(consulta_pagada)" [(ngModel)]="consulta_pagada" [value]="false"
          class="form-check-input" type="radio" name="radios3" id="radios5">
      </div>
    </div>
  </div>
  <div class="form-row" style="margin-top: 3px;">
    <div disabled="datos_pago.detpg_pagada == 0" class="form-group col-6">
      <input [disabled]="datos_session.acceso_pago == 0" type="text" name="cantidad_pago" min=0 max=9999999999 class="btn btn-form-conf btn-block btn-lg"
        placeholder="Cantidad">
    </div>
    <div class="form-group col-6">
      <select [disabled]="datos_session.acceso_pago == 0" class="btn btn-block  letraCuadrosFormulario letraCuadrosLista" name="forma_pago"
        id="forma de pago">
        <option class="label-center" [value]="0">Forma de Pago</option>
        <option *ngFor="let pago of cat_fpago" [value]="pago.fpago_id">{{pago.fpago_desc}}</option>
      </select>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select [disabled]="datos_session.acceso_pago == 0" class="text-align: left btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        name="aseguradora" id="aseguradora">
        <option class="label-center " [value]="0">Aseguradora</option>
        <option *ngFor="let aseguradora of cat_aseguradora" [value]="aseguradora.aseguradora_id">{{aseguradora.aseguradora_desc}}</option>
      </select>
    </div>
    <div class="form-group col-6">
      <input [ngStyle]="{'opacity': consulta_pagada  == false || datos_session.acceso_pago == 0 ? '.65' : '1.65'}" [disabled]="!consulta_pagada || datos_session.acceso_pago == 0"
        name="fecha_pago" id="datepicker2" style="text-align: left; font-size: 12px; font-weight: 300;" class="label-cente read-on top-input form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha De Pago" readonly>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-deposit.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      ¿Depositado?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios7">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esDeposito(deposito)" [value]="true" class="form-check-input"
          type="radio" name="radios6" id="radios7" value=1 style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios8">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esDeposito(deposito)" [value]="false" class="form-check-input"
          type="radio" name="radios6" id="radios8" value=0>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select [disabled]="!deposito || datos_session.acceso_pago == 0" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        name="banco_rec" id="banco_Rec">
        <option [value]="0">Banco Receptor</option>
        <option *ngFor="let banco of banco_desc" [value]="banco.banco_id">{{banco.banco_desc}}</option>
      </select>
      <!-- Requiere el script al final de la página-->

    </div>
    <div class="form-group col-6">
      <input [ngStyle]="{'opacity': deposito  == false || datos_session.acceso_pago == 0 ?  '.65' : '1.65'}" [disabled]="!deposito  || datos_session.acceso_pago == 0"
        id="datepicker3" style="font-size: 12px; font-weight: 300;" class="read-on label-center top-input form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha" readonly>


    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-recibo.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      ¿Recibo?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios10">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esRecibo(recibo)" [value]="true" class="form-check-input" type="radio"
          name="radios9" id="radios10" style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios11">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esRecibo(recibo)" [value]="false" class="form-check-input" type="radio"
          name="radios9" id="radios11" value="option2">
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <input [disabled]="!recibo ||  datos_session.acceso_pago == 0" type="text" placeholder="No de Recibo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista">
    </div>
    <div disabled="true" class="form-group col-6">
      <input [ngStyle]="{'opacity': recibo  == false  || datos_session.acceso_pago == 0 ? '.65' : '1.65'}" [disabled]="!recibo || datos_session.acceso_pago == 0"
        id="datepicker4" name="fecha_recibo" style="font-size: 12px; font-weight: 300;" class="top-input read-on label-center form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha De Recibo" readonly>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea [disabled]="datos_session.acceso_pago == 0" class=" btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="notasTextarea" rows="3" placeholder="Detalles Del Pago"></textarea>
    </div>
  </div>

</section>