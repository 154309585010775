export class UsuarioModel {
    constructor(
        public usuario_id : number,
        public usuario_nombre : string,
        public usuario_apellidos : string,
        public fecha_nacimiento : string,
        public especiadidad_id : number,
        public cedula_profesional : string,
        public usuario_email : string,
        public usuario_pw : string,
        public papp_id : number,
        public usuid_padre : number,
        public usuario_status : number,
        public usuario_pw_confirm : string,
        public codigo_confirmacion : string,
        public terminos_condiciones : number,
        public foto_url : string,
        public accesso_pago : any
    ){

    }
}