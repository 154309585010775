export class ConsultaModel{
    constructor(
        public consulta_id : number,
        public consulta_fecha : string,
        public consulta_hora : any,
        public constulata_duracion : any,
        public hospital_id : number,
        public consulta_datosclin : string,
        public  consulta_status : number,
        public usuario_id : any
    ){

    }
}