import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from '../../models/UsuarioModel';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { EducacionService } from '../../services/educacion/educacion.service';
import { GLOBAL } from '../../helpers';
import { intervalCarruselEducacion } from '../../../assets/js/carrusel-educacion'
@Component({
  selector: 'app-educacion',
  templateUrl: './educacion.component.html',
  styleUrls: ['./educacion.component.css']
})
export class EducacionComponent implements OnInit {
  public session;
  public datos_session;
  public usuario_id;
  public token;
  public datos_usuario: UsuarioModel;
  public array_img_capacitaciont1 = new Array();
  public array_img_capacitacion_t2 = new Array();
  public url_imagen: string;
  public perfil_menu;
  public mostrar_educacion: boolean;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private EducacionService: EducacionService
  ) { }

  ngOnInit() {
    this.mostrar_educacion = false;
    this.datos_usuario = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.Session();
    this.url_imagen = GLOBAL.url;
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
      this.MostrarMenu();
    }
  }

  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == "6") {
        this.mostrar_educacion = true;
      }
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getEducacionTipo1();
          this.getEducacionTipo2();
        }
      })
  }

  //tipo 2 carrusel puede añadirse mas , tipo 1 (2 imagenes unicamente)
  getEducacionTipo1() {
    let capacitacion_tipo = 1;
    let especialidad_id = this.datos_usuario.especiadidad_id;
    let especialidad = { capacitacion_tipo: capacitacion_tipo, especialidad_id: especialidad_id }
    this.EducacionService.getEducacion(this.token.token, especialidad)
      .subscribe(result => {
        if (result.valido == 1) {
          this.array_img_capacitaciont1 = result.educacion;
        }
      })
  }
  getEducacionTipo2() {

    let capacitacion_tipo = 2;
    let especialidad_id = this.datos_usuario.especiadidad_id;
    let especialidad = { capacitacion_tipo: capacitacion_tipo, especialidad_id: especialidad_id }
    this.EducacionService.getEducacion(this.token.token, especialidad)
      .subscribe(result => {
        if (result.valido == 1) {
          this.array_img_capacitacion_t2 = result.educacion;
          intervalCarruselEducacion()
        }
      })
  }
}
