export  class BusquedaGeneralModel {
    cirugia_fecha_ini : any = '';
    cirugia_fecha_fin : any  = '';
    paciente : string  = '';
    paciente_edad : any  = '' ;
    hospital_id : any  = '';
    cirujano_id : any  = '';
    proce_id : any  = '';
    anestesiologo_id : any  = '';
    anestesia_id : any  = '';
    cirugia_datosclinicos : string  = '';
    proveedor_id : any  = '';
    proveedor_monto : any  = '';
    proveedor_recibo : any  = '';
    proveedor_fecha_ini : any  = ''; 
    proveedor_fecha_fin : any  = ''; 
    proveedor_det : string  = '';
    pagada : any  = '';
    cantidad : any  = '';
    usuarioidPadre : number;
    id_formapago : string  = '';
    aseguradora_id : any  = '';
    fecha_pago_ini : any  = '';
    fecha_pago_fin : any  = '';
    depositado : any  = '';
    recibo : any  = '';
    id_banco : any  = '';
    fecha_deposito_ini : any  = '';
    fecha_deposito_fin : any  = '';
    num_recibo : any  = '';
    fecha_recibo_ini : any  = '';
    fecha_recibo_fin : any  = '' ;
    detalle_pago : string   = '';
    tipo_busqueda : string = '';
    usuario_id : number;
    tipo_envio :any;
    texto  : string = '' ;
    cirugiaSearch = true;
    consultaSearch = true;
    cirugia_search = '';
    consulta_search = '';
}