import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class EstadisticasService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getEstadisticas(token, usuario_id) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'usuario/get-estadisticas/' + usuario_id, { headers: headers })
            .map(res => res.json());
    }
}
EstadisticasService.ngInjectableDef = i0.defineInjectable({ factory: function EstadisticasService_Factory() { return new EstadisticasService(i0.inject(i1.Http)); }, token: EstadisticasService, providedIn: "root" });
