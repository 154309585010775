import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import 'flatpickr/dist/flatpickr.css'; // you may need to adjust the css import depending on your build tool
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as i0 from "@angular/core";
//
// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl {
    constructor() {
        super(...arguments);
        /** A label for the cancel button */
        this.cancelBtnLabel = 'Cancelar';
        /** A label for the set button */
        this.setBtnLabel = 'Aceptar';
    }
}
DefaultIntl.ngInjectableDef = i0.defineInjectable({ factory: function DefaultIntl_Factory() { return new DefaultIntl(); }, token: DefaultIntl, providedIn: "root" });
;
registerLocaleData(localeEs);
const ɵ0 = adapterFactory;
export class AppModule {
}
export { ɵ0 };
