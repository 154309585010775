import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import *  as moment from 'moment';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns'
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ObservableCalendario } from '../../../services/calendario/obserbable-calendario.service';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { CalendarioService } from '../../../services/calendario/calendario.service';
import { DatosEventoService } from '../../../services/cirugia/datos-evento.service';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { ObservableService } from 'src/app/observable.service';
import { identifierModuleUrl } from '@angular/compiler';
import localeEsMX from '@angular/common/locales/es-MX';
const colors: any = {
  verdevarioseventos: {
    primary: '#3c615f',
    secondary: 'rgb(235, 187, 80);'
  },
  verdepocoseventos: {
    primary: '#7c9695',
    secondary: 'rgb(235, 187, 80);'
  },
  diaactual: {
    primary: '#3c615f',
    secondary: '#FDF1BA'
  },
};
@Component({
  selector: 'app-calendario-anio',
  templateUrl: './calendario-anio.component.html',
  styleUrls: ['./calendario-anio.component.css']
})
export class CalendarioAnioComponent implements OnInit, OnDestroy {
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public session: any;
  public datos_calendario = new Array();
  public mes_numero;
  public datos_paciente;
  locale: string = 'es';
  public datos_proveedor;
  public datos_cirugia;
  public detalle_pago;
  public paciente_id: number;
  public datos_consulta: any;
  public mes_selected;
  public datos_fechas;
  public enero_selected: boolean;
  public febrero_selected: boolean;
  public marzo_selected: boolean;
  public abril_selected: boolean;
  public mayo_selected: boolean;
  public junio_selected: boolean;
  public julio_selected: boolean;
  public agosto_selected: boolean;
  public septiembre_selected: boolean;
  public octubre_selected: boolean;
  public noviembre_selected: boolean;
  public diciembre_selected: boolean;
  public anios;
  public anio_selected;
  public err_calendario;
  public calendar_title;
  public fecha_año_selected = new Array();
  public mostrar_mes: boolean;
  public contador_anio = new Array();
  public mes_actual
  public mostrar_fecha;
  public fecha_hoy
  public loading;
  public anio_calendario: string;
  viewDate3: Date = new Date();
  activeDayIsOpen: boolean = false;
  activeDayIsOpen2: boolean = false;
  activeDayIsOpen3: boolean = false;
  activeDayIsOpen4: boolean = false;
  activeDayIsOpen5: boolean = false;
  activeDayIsOpen6: boolean = false;
  activeDayIsOpen7: boolean = false;
  activeDayIsOpen8: boolean = false;
  activeDayIsOpen9: boolean = false;
  activeDayIsOpen10: boolean = false;
  activeDayIsOpen11: boolean = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewDate2: Date = new Date();
  viewDate4: Date = new Date();
  viewDate5: Date = new Date();
  viewDate6: Date = new Date();
  viewDate7: Date = new Date();
  viewDate8: Date = new Date();
  viewDate9: Date = new Date();
  viewDate10: Date = new Date();
  viewDate11: Date = new Date();
  actions_consulta: CalendarEventAction[] = [
    {
      label: ' <img  src="../../../../assets/images/menu-consulta-help.png" width="18" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      }
    }
  ];
  actions_cirugia: CalendarEventAction[] = [
    {
      label: ' <img  src="../../../../assets/images/menu-cirugia-help.png" width="18" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      },
    }
  ];

  refresh: Subject<any> = new Subject();
  CargaFechas() {

  }
  events: CalendarEvent[] = [

  ];

  constructor(
    private ObservableCalendario: ObservableCalendario,
    private ConfiguracionService: ConfiguracionService,
    private CalendarioService: CalendarioService,
    private DatosEventoService: DatosEventoService,
    private router: Router,
    private ObservableExtraer: ObservableExtraer,
    private ObservableService: ObservableService
  ) {



  }

  ngOnInit() {
    //flechas select anio 
    let anio_actual = new Date().getFullYear();
    this.contador_anio.push(anio_actual);
    this.mes_actual = new Date().getMonth();
    this.mesActual(this.mes_actual, anio_actual);
    this.mes_selected = 0;
    this.ObservableCalendario.calendarioSelectedMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        //1 es igual a dia, 2 igual  a semana , 3 igual a mes y 4 igual a ño
        if (result.numero == 4) {
          this.datos_fechas = result.datos_calendario;
          this.GetCalendarioByUsuarioID({ mes_selected: this.mes_selected, datos_calendario: this.datos_fechas });
        }
        this.Session();
      }
    })
    this.getAnios();
    this.anioSelected(moment().year());
  }

  mesActual(mes_actual, anio_actual) {

    if (mes_actual < 6) {
      this.viewDate = new Date();
      this.viewDate.setMonth(0)
      this.viewDate.setFullYear(anio_actual);
      this.viewDate2 = new Date();
      this.viewDate2.setMonth(1)
      this.viewDate2.setFullYear(anio_actual);
      this.viewDate3 = new Date();
      this.viewDate3.setMonth(2);
      this.viewDate3.setFullYear(anio_actual);
      this.viewDate4 = new Date();
      this.viewDate4.setMonth(3);
      this.viewDate4.setFullYear(anio_actual);
      this.viewDate5 = new Date();
      this.viewDate5.setMonth(4);
      this.viewDate5.setFullYear(anio_actual);
      this.viewDate6 = new Date();
      this.viewDate6.setMonth(5);
      this.viewDate6.setFullYear(anio_actual);
      this.mes_actual = 7;
    } else {
      this.viewDate = new Date();
      this.viewDate.setMonth(6)
      this.viewDate.setFullYear(anio_actual);
      this.viewDate2 = new Date();
      this.viewDate2.setMonth(7)
      this.viewDate2.setFullYear(anio_actual);
      this.viewDate3 = new Date();
      this.viewDate3.setMonth(8);
      this.viewDate3.setFullYear(anio_actual);
      this.viewDate4 = new Date();
      this.viewDate4.setMonth(9);
      this.viewDate4.setFullYear(anio_actual);
      this.viewDate5 = new Date();
      this.viewDate5.setMonth(10);
      this.viewDate5.setFullYear(anio_actual);
      this.viewDate6 = new Date();
      this.viewDate6.setMonth(11);
      this.viewDate6.setFullYear(anio_actual);
      this.mes_actual = 1;
    }
    // this.titleView = this.viewDate.toLocaleDateString("es-ES", { month: 'long' }).toLocaleUpperCase();
    // this.titleView2 = this.viewDate2.toLocaleDateString("es-ES", { month: 'long' }).toLocaleUpperCase();
  }
  ngOnDestroy() {


  }

  getAnios() {
    this.anios = [];
    for (var i = 2015; i <= 2040; i++) {
      this.anios.push({ anio: i })
    }

  }

  MesSelected(mes_selected) {
    this.mes_selected = parseInt(mes_selected);
    this.GetCalendarioByUsuarioID({ mes_selected: this.mes_selected, datos_calendario: this.datos_fechas })
  }

  anioSelected(anio_selected) {
    this.anio_selected = parseInt(anio_selected);
    this.GetCalendarioByUsuarioID({ anio_selected: this.anio_selected, datos_calendario: this.datos_fechas })
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.getPerfilUsuario();
    }
  }


  getPerfilUsuario() {
    this.usuario_id = this.datos_session.usuario_id
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];

        }
      })
  }
  GetCalendarioByUsuarioID(datos_calendario) {
    this.anio_calendario = datos_calendario.anio_selected;
    this.events = [];
    this.datos_calendario = datos_calendario.datos_calendario;
    //fechas en numero
    this.mes_numero = this.mes_selected;

    this.ClasesCalendario();
  }

  ClasesCalendario() {

    let array_fechas = {};
    var contador = 0;
    for (let key in this.datos_calendario) {
      let llave = this.datos_calendario[key].cirugia_fecha;
      if (!array_fechas[llave]) array_fechas[llave] = 0;
      array_fechas[llave]++;
    }
    for (let key in this.datos_calendario) {
      let mes_consulta = moment(this.datos_calendario[key].cirugia_fecha).month()
      let anio_consulta = moment(this.datos_calendario[key].cirugia_fecha).year()

      // if (anio_consulta == this.anio_selected) {
      //   if (mes_consulta == this.mes_numero) {
      this.refresh.next();
      let fecha_string = this.datos_calendario[key].cirugia_fecha.split('-');
      let año = fecha_string[0];
      let ano_siguiente = parseInt(fecha_string[0] + 1);
      let mes = fecha_string[1];
      let dia = fecha_string[2];
      let año_inicio = parseInt(año);
      let mes_inicio = parseInt(mes) - 1;
      let dia_inicio = parseInt(dia);
      let fecha_inicio = new Date(año_inicio, mes_inicio, dia_inicio);
      let fecha_siguiente = new Date(año_inicio, 1, dia_inicio);
      let color;
      var label_imagen;
      if (this.datos_calendario[key].tipo == 1) {
        label_imagen = this.actions_cirugia;
      } else if (this.datos_calendario[key].tipo == 2) {
        label_imagen = this.actions_consulta;
      }
      if (this.datos_calendario[key].detpg_pagada == 1) {
        var icono_pago = '<img src="../../../assets/images/icon-pago-over.png" width="23" height="auto">';
      } else {
        icono_pago = '<img src="../../../assets/images/icon-pago-grey.png" width="23" height="auto">';
      }
      if (this.datos_calendario[key].detpg_depositado == 1) {
        var icono_depositado = '<img src="../../../assets/images/icon-deposito-over.png" width="23" height="auto">';
      } else {
        icono_depositado = '<img src="../../../assets/images/icon-deposit-grey.png" width="23" height="auto">';
      }
      if (this.datos_calendario[key].detpg_recibo == 1) {
        var icono_recibo = '<img src="../../../assets/images/icon-recibo-over.png" width="23" height="auto">';
      } else {
        icono_recibo = '<img src="../../../assets/images/icon-recibo-grey.png" width="23" height="auto">';
      }
      if (array_fechas[this.datos_calendario[key].cirugia_fecha] >= 4) {
        color = colors.verdevarioseventos;
      }
      if (array_fechas[this.datos_calendario[key].cirugia_fecha] < 4) {
        color = colors.verdepocoseventos;
      }
      // let fecha_hoy = new Date();
      // if (fecha_inicio.toDateString() == fecha_hoy.toDateString()) {
      //   color = colors.diaactual;
      // }
      // this.viewDate = fecha_inicio
      // this.viewDate2 = fecha_siguiente
      let evento =
      {
        start: fecha_inicio,
        end: fecha_inicio,
        title: '<table class="table table-sm">' +
          '<tr>' +
          '<td>Horario: ' + this.datos_calendario[key].cirugia_hora + '</td>' +
          '<td >Paciente: ' + this.datos_calendario[key].paciente_nombre + '</td>' +
          '<td >' + this.datos_calendario[key].persona_desc + '/ ' + this.datos_calendario[key].anestesiologo_desc + '<br>' +
          '' + this.datos_calendario[key].proce_desc + ' / ' + this.datos_calendario[key].hospital_desc + ' / ' + this.datos_calendario[key].anestesia_desc + '</td>' +
          '</td>' +
          '<td>' +
          '' + icono_pago + '' +
          '</td>' +
          '<td>' +
          '' + icono_depositado + '' +
          '</td>' +
          '<td>' +
          '' + icono_recibo + '' +
          '</td>' +
          '</tr>' +
          '</table>',
        color: color,
        datos: this.datos_calendario[key],
        actions: label_imagen,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: false
      };
      this.events.push(evento);
      this.refresh.next();
      this.loading = false;
      //}
      //      }
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }, mes): void {
    let viewSelect;
    let activeSelect;
    if (mes == 1) { viewSelect = this.viewDate; activeSelect = this.activeDayIsOpen; }
    if (mes == 2) { viewSelect = this.viewDate2; activeSelect = this.activeDayIsOpen2; }
    if (mes == 3) { viewSelect = this.viewDate3; activeSelect = this.activeDayIsOpen3; }
    if (mes == 4) { viewSelect = this.viewDate4; activeSelect = this.activeDayIsOpen4; }
    if (mes == 5) { viewSelect = this.viewDate5; activeSelect = this.activeDayIsOpen5; }
    if (mes == 6) { viewSelect = this.viewDate6; activeSelect = this.activeDayIsOpen6; }
    if (mes == 7) { viewSelect = this.viewDate7; activeSelect = this.activeDayIsOpen7; }
    if (mes == 8) { viewSelect = this.viewDate8; activeSelect = this.activeDayIsOpen8; }
    if (mes == 9) { viewSelect = this.viewDate9; activeSelect = this.activeDayIsOpen9; }
    if (mes == 10) { viewSelect = this.viewDate10; activeSelect = this.activeDayIsOpen10; }
    if (mes == 11) { viewSelect = this.viewDate11; activeSelect = this.activeDayIsOpen11; }
    if (isSameMonth(date, viewSelect)) {
      viewSelect = date;
      if (
        (isSameDay(viewSelect, date) && activeSelect === true) || events.length === 0
      ) {
        activeSelect = false;
      } else {
        activeSelect = true;
      }
    }
    if (mes == 1) { this.viewDate = viewSelect; this.activeDayIsOpen = activeSelect; }
    if (mes == 2) { this.viewDate2 = viewSelect; this.activeDayIsOpen2 = activeSelect; }
    if (mes == 3) { this.viewDate3 = viewSelect; this.activeDayIsOpen3 = activeSelect; }
    if (mes == 4) { this.viewDate4 = viewSelect; this.activeDayIsOpen4 = activeSelect; }
    if (mes == 5) { this.viewDate5 = viewSelect; this.activeDayIsOpen5 = activeSelect; }
    if (mes == 6) { this.viewDate6 = viewSelect; this.activeDayIsOpen6 = activeSelect; }
    if (mes == 7) { this.viewDate7 = viewSelect; this.activeDayIsOpen7 = activeSelect; }
    if (mes == 8) { this.viewDate8 = viewSelect; this.activeDayIsOpen8 = activeSelect; }
    if (mes == 9) { this.viewDate9 = viewSelect; this.activeDayIsOpen9 = activeSelect; }
    if (mes == 10) { this.viewDate10 = viewSelect; this.activeDayIsOpen10 = activeSelect; }
    if (mes == 11) { this.viewDate11 = viewSelect; this.activeDayIsOpen11 = activeSelect; }
  }
  loadingCalendar(dato) {
    this.loading = true;
    this.changeCalendar(dato)
  }
  changeCalendar(dato) {

    this.mostrar_mes = true;

    // this.calendar_title = viewSelect.setDate(this.viewDate.getDate() + dato)
    if (dato == -1 && this.mes_actual > 6) {
      this.contador_anio.splice(0, 1, this.contador_anio[0] - 1);

    } else if (dato == 1 && this.mes_actual < 6) {
      this.contador_anio.splice(0, 1, this.contador_anio[0] + 1);
    }

    this.calendar_title = this.contador_anio[0];//AÑO
    if (this.mes_actual > 6) {
      this.mesActual(this.mes_actual, this.calendar_title);
    } else {
      this.mesActual(this.mes_actual, this.calendar_title);
    }
    this.GetCalendarioByUsuarioID({ anio_selected: this.calendar_title, datos_calendario: this.datos_fechas });
  }

  handleEvent(action: string, event): void {
    // tipo 1 cirugia , 2 consulta
    let resultado_busqueda = event.datos;
    if (resultado_busqueda.tipo == 1) {
      this.DatosEventoService.getCirugia(this.token.token, resultado_busqueda.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_cirugia = result.cirugia[0];
            this.paciente_id = this.datos_cirugia.paciente_id;
            this.DatosEventoService.getProveedorAyudantia(this.token.token, resultado_busqueda.cirugia_id)
              .subscribe(result => {
                if (result.valido == 1) {
                  this.datos_proveedor = result.cat_proveedor;
                  this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
                    .subscribe(result => {
                      this.datos_paciente = result.paciente[0];
                      if (result.valido == 1) {
                        let pago_id = this.datos_cirugia.detpg_id;
                        this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                          .subscribe(result => {
                            if (result.valido == 1) {
                              this.detalle_pago = result.detalle_pago[0];
                              this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPagoSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, actualizar: true })
                              this.ObservableExtraer.changedatosAyudantiaSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, datos_proveedor: this.datos_proveedor })
                              this.router.navigate(['/cirugia'])
                              // 1 consulta 2 cirugia manda el color de cambio de menu(icono)
                              this.ObservableService.changeVistaMenu(2)
                            }
                          })
                      }
                    })
                }
              })
          }
        })
    } else if (resultado_busqueda.tipo == 2) {
      //resultado_busqueda.cirugia_id en tipo 2  es id_consutlta
      let id_consulta = resultado_busqueda.cirugia_id;
      this.DatosEventoService.getConsulta(this.token.token, id_consulta)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_consulta = result.consulta[0];
            this.paciente_id = this.datos_consulta.paciente_id;
            this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
              .subscribe(result => {
                this.datos_paciente = result.paciente[0];
                if (result.valido == 1) {
                  let pago_id = this.datos_consulta.detpg_id;
                  this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                    .subscribe(result => {
                      if (result.valido == 1) {
                        this.detalle_pago = result.detalle_pago[0];
                        this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPagoSelected({ datos_consulta: this.datos_consulta, detalle_pago: this.detalle_pago, actualizar: true })
                        this.router.navigate(['/consulta'])
                        // 1 consulta 2 cirugia
                        this.ObservableService.changeVistaMenu(1)
                      }
                    })
                }
              })
          }
        })
    }

  }
}
