import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../../helpers';
import * as moment from "moment";
@Injectable({
  providedIn: 'root'
})
export class DatosGeneralesService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }


  public getHospital(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_hospital/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  //provicional
  public getPacientes(id,token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'paciente/getbyusuarioid/'+id, { headers: headers })
      .map(res => res.json());
  }
  // Nuevo paciente
  public AddConsultaPaciente(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'paciente/add',params, { headers: headers })
      .map(res => res.json());
  }
// Agrega un nueva consulta general
  public AddConsultaGenerales(datos) {
    console.log("AddConsultaGenerales",datos)
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'consulta/add',params, { headers: headers })
      .map(res => res.json());
  }
  //agrega una nueva consulta de pago 
  public AddConsultaPago(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'detalle_pago/add',params, { headers: headers })
      .map(res => res.json());
  }
  
  // BUSCADOR por datos generales
  public BuscarPago(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'cirugia/busqueda-datos-generales',params, { headers: headers })
      .map(res => res.json());
  }
  // Buscador vista buscar de detalles de pago
  
  public BuscarDetallePago(datos) {
    console.log("DATOS ENVIADOS",datos)
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'cirugia/busqueda-datos-pago',params, { headers: headers })
      .map(res => res.json());
  }

  // elimina con el estatus de la cosulta
  public DeleteConsulta(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'consulta/delete',params, { headers: headers })
      .map(res => res.json());
  }

  //vista bsuqueda general
  public BuscarDatos(datos) {
    let params = JSON.stringify(datos)
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'cirugia/busqueda',params, { headers: headers })
      .map(res => res.json());
  }


 
}
