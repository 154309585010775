import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConsultaService } from '../../services/consulta/consulta.service';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { ObservableService } from '../../observable.service';
import { Subscription } from 'rxjs/Subscription';
import { OnDestroy } from "@angular/core";
import { ObservableExtraer } from '../../services/extraer/extraer-observable.service';
import { GLOBAL } from '../../helpers';
@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})
export class ConsultaComponent implements OnInit, OnDestroy {
  public perfil_file_imagen: File;
  public perfil_file_archivo: File;
  public perfil_file_imagen_pago: File;
  public perfil_file_imagen_pago2: File;
  public url_perfil: string;
  public mostrar_consulta: boolean;
  public url_detalle_pago: string;
  public session: string;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario;
  public imagen_tipo: number;
  public tipo;
  public perfil_menu;
  public url_detalle_pago2;
  public url_consulta_img;
  public detalle_imagen_tipo;
  public detalle_imagen_tipo_pago;
  public detalle_imagen_tipo_pago2;
  private subscription: Subscription = new Subscription();
  private subscription_consulta: Subscription = new Subscription();
  public datos_actualizar: boolean;
  public consulta_id: number;
  public imagen_general_principal: any;
  public imagen_general_secundario: any;
  public imagen_pago_principal: any;
  public imagen_pago_secundario: any;
  public datos_consulta;
  public url_imagen: string;
  constructor(
    private ConsultaService: ConsultaService,
    private ConfiguracionService: ConfiguracionService,
    private ObservableService: ObservableService,
    private ObservableExtraer: ObservableExtraer
  ) {
    const unsubscribe = this.ObservableExtraer.datosSelectedGeneralMessage.subscribe(result => {
      if (result != undefined && result != null && result != "") {
        this.datos_consulta = result.datos_consulta;
        this.datos_actualizar = true;
      }
    })
    const subscription = this.ObservableService.datosConsultaMessage.subscribe(result => {
      if (result) {

        this.consulta_id = result.consulta_id;
        this.GuardarArchivoImagenUpdate(this.consulta_id);
        this.GuardarArchivoImagenUpdate2(this.consulta_id);
        this.GuardarPagoImagenUpdate(this.consulta_id);
        this.GuardarPagoImagenUpdate2(this.consulta_id);
        if (result.actualizar != undefined) {
          this.datos_actualizar = true;
        } else {
          this.datos_actualizar = false;
          this.GuardarArchivoImagen(result);
          this.GuardarArchivoImagen2(result);
          this.GuardarArchivoImagenPago(result);
          this.GuardarArchivoImagenPago2(result);
        }
      }
    });

    this.subscription.add(subscription);
    this.subscription_consulta.add(unsubscribe);
  }

  ngOnInit() {
    this.mostrar_consulta = false;
    this.Session();
    this.url_imagen = GLOBAL.url;
    if (this.datos_actualizar) {
      this.verImagenEvento(1);
      this.verImagenEvento(2);
      this.verImagenEvento(3);
      this.verImagenEvento(4);
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription_consulta.unsubscribe();
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
      this.MostrarMenu();
    }
  }
  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == 1) {
        this.mostrar_consulta = true;

      }
    }
  }
  getPerfilUsuario() {
    // this.usuario_id = this.datos_session.usuario_id
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];

        }
      })
  }
  SubirImagenconsulta($event, tipo) {

    //tipo 1 imagen, tipo 2 archivo
    this.tipo = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_tipo = 1;
    //imagen_tipo 1 consulta 2 cirugia
    this.perfil_file_imagen = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_perfil = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  SubirImagenConsulta2($event, tipo) {

    //tipo 1 imagen, tipo 2 archivo
    this.tipo = 1;
    let file: File = $event.target.files[0];
    this.imagen_tipo = 1;
    //imagen_tipo 1 consulta 2 cirugia 3 Pago
    this.perfil_file_archivo = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_consulta_img = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  SubirImagenDetallePago($event, tipo) {

    //tipo 1 imagen, tipo 2 archivo 
    this.tipo = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_tipo_pago = 3;
    //imagen_tipo 1 consulta 2 cirugia 3 Pago
    this.perfil_file_imagen_pago = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_detalle_pago = event.target.result;

    };
    reader.readAsDataURL(file);
  }
  SubirImagenDetallePago2($event, tipo) {

    //tipo 1 imagen, tipo 2 archivo 
    this.tipo = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_tipo_pago2 = 3;
    //imagen_tipo 1 consulta 2 cirugia 3 Pago
    this.perfil_file_imagen_pago2 = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_detalle_pago2 = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  GuardarArchivoImagen(valor_id) {

    if (this.perfil_file_imagen_pago != undefined) {

      let params = new Array();
      this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo_pago + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen_pago, this.tipo)
        .then(
          (result: any) => {
            this.EliminarDetallePagoImg();
          })
    }

  }

  GuardarArchivoImagen2(valor_id) {

    if (this.perfil_file_imagen_pago2 != undefined) {

      let params = new Array();
      this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo_pago2 + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen_pago2, this.tipo)
        .then(
          (result: any) => {
            this.EliminarDetallePagoImg2();
          })
    }
  }

  GuardarArchivoImagenPago(valor_id) {

    if (this.perfil_file_imagen != undefined) {

      let params = new Array();
      this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen, this.tipo)
        .then(
          (result: any) => {
            this.EliminarDatoGeneralImg();
          })
    }
  }
  GuardarArchivoImagenPago2(valor_id) {

    if (this.perfil_file_archivo != undefined) {

      let params = new Array();
      this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.imagen_tipo + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_archivo, this.tipo)
        .then(
          (result: any) => {
            this.EliminarDatoGeneralImg2();
          })
    }
  }
  @ViewChild('myInput')
  myInputVariable: any;
  EliminarDetallePagoImg() {
    if (this.datos_actualizar && this.imagen_pago_principal) {
      this.imagen_pago_principal.imagen_status = 0;
      this.ConsultaService.EliminarImagenDetallePago(this.imagen_pago_principal)
        .subscribe(result => {
          this.verImagenEvento(3);
        })
    } else {
      this.url_detalle_pago = null;
      this.perfil_file_imagen_pago = null;
      this.myInputVariable.nativeElement.value = "";
    }
  }
  @ViewChild('elimnardetallepago2')
  elimnardetallepago2: any;
  EliminarDetallePagoImg2() {
    if (this.datos_actualizar && this.imagen_pago_secundario) {
      this.imagen_pago_secundario.imagen_status = 0;
      this.ConsultaService.EliminarImagenDetallePago(this.imagen_pago_secundario)
        .subscribe(result => {
          this.verImagenEvento(4);
        })
    } else {
      this.url_detalle_pago2 = null;
      this.perfil_file_imagen_pago2 = null;
      this.elimnardetallepago2.nativeElement.value = "";
    }

  }
  @ViewChild('elimnardatogeneralimg')
  elimnardatogeneralimg: any;
  EliminarDatoGeneralImg() {
    if (this.datos_actualizar && this.imagen_general_principal) {
      this.imagen_general_principal.imagen_status = 0;
      this.ConsultaService.EliminarImagenDetallePago(this.imagen_general_principal)
        .subscribe(result => {
          this.verImagenEvento(1);
        })
    } else {
      this.url_perfil = null;
      this.perfil_file_imagen = null;
      this.elimnardatogeneralimg.nativeElement.value = "";
    }

  }
  @ViewChild('eliminarimagendatog2')
  eliminarimagendatog2: any;
  EliminarDatoGeneralImg2() {
    if (this.datos_actualizar && this.imagen_general_secundario) {
      this.imagen_general_secundario.imagen_status = 0;
      this.ConsultaService.EliminarImagenDetallePago(this.imagen_general_secundario)
        .subscribe(result => {
          this.verImagenEvento(2);
        })
    } else {
      this.url_consulta_img = null;
      this.perfil_file_archivo = null;
      this.eliminarimagendatog2.nativeElement.value = "";
    }

  }


  verImagenEvento(no_imagen) {
    if (this.datos_actualizar && (no_imagen == 1 || no_imagen == 2)) {
      let imagen_tipo_consulta = 1;
      this.ConsultaService.GetImagenesTipo(this.token.token, imagen_tipo_consulta, this.datos_consulta.consulta_id)
        .subscribe(result => {
          if (result.valido == 1) {
            if (no_imagen == 1) {
              this.imagen_general_principal = result.imagenes[0];
            } else if (no_imagen == 2) {
              this.imagen_general_secundario = result.imagenes[1];
            }
          }
        })
    } else if (this.datos_actualizar && (no_imagen == 3 || no_imagen == 4)) {
      let imagen_tipo_pago = 3;
      this.ConsultaService.GetImagenesTipo(this.token.token, imagen_tipo_pago, this.datos_consulta.consulta_id)
        .subscribe(result => {
          if (result.valido == 1) {
            if (no_imagen == 3) {
              this.imagen_pago_principal = result.imagenes[0];
            } else if (no_imagen == 4) {
              this.imagen_pago_secundario = result.imagenes[1];
            }
          }
        })
    }
  }


  /*****************************/
  ///IMAGENES ACTUALIZAR/

  GuardarArchivoImagenUpdate(valor_id) {
    if (this.perfil_file_imagen != undefined) {
      let params = new Array();
      if (this.imagen_general_principal != undefined) {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen-update/' + this.detalle_imagen_tipo + '/' + valor_id + '/' + this.tipo + '/' + this.imagen_general_principal.imagen_id, params, this.perfil_file_imagen, this.tipo)
          .then(
            (result: any) => {
              this.url_perfil = null;
              this.perfil_file_imagen = null;
              this.elimnardatogeneralimg.nativeElement.value = "";
            })
      } else {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen, this.tipo)
          .then(
            (result: any) => {
              this.url_perfil = null;
              this.perfil_file_imagen = null;
              this.elimnardatogeneralimg.nativeElement.value = "";
            })
      }

    }
  }

  GuardarArchivoImagenUpdate2(valor_id) {
    if (this.perfil_file_archivo != undefined) {
      let params = new Array();
      if (this.imagen_general_secundario != undefined) {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen-update/' + this.imagen_tipo + '/' + valor_id + '/' + this.tipo + '/' + this.imagen_general_secundario.imagen_id, params, this.perfil_file_archivo, this.tipo)
          .then(
            (result: any) => {
              this.url_consulta_img = null;
              this.perfil_file_archivo = null;
              this.eliminarimagendatog2.nativeElement.value = "";
            })
      } else {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.imagen_tipo + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_archivo, this.tipo)
          .then(
            (result: any) => {
              this.url_consulta_img = null;
              this.perfil_file_archivo = null;
              this.eliminarimagendatog2.nativeElement.value = "";
            })
      }

    }
  }
  /***************ACTUALIZAR IMAGENES DE PAGO  ***************************** */
  GuardarPagoImagenUpdate(valor_id) {
    if (this.perfil_file_imagen_pago != undefined) {
      let params = new Array();
      if (this.imagen_pago_principal != undefined) {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen-update/' + this.detalle_imagen_tipo_pago + '/' + valor_id + '/' + this.tipo + '/' + this.imagen_pago_principal.imagen_id, params, this.perfil_file_imagen_pago, this.tipo)
          .then(
            (result: any) => {
              this.url_detalle_pago = null;
              this.perfil_file_imagen_pago = null;
              this.myInputVariable.nativeElement.value = "";
            })
      } else {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo_pago + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen_pago, this.tipo)
          .then(
            (result: any) => {
              this.url_detalle_pago = null;
              this.perfil_file_imagen_pago = null;
              this.myInputVariable.nativeElement.value = "";
            })
      }

    }
  }

  GuardarPagoImagenUpdate2(valor_id) {
    if (this.perfil_file_imagen_pago2 != undefined) {
      let params = new Array();
      if (this.imagen_pago_secundario != undefined) {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen-update/' + this.detalle_imagen_tipo_pago2 + '/' + valor_id + '/' + this.tipo + '/' + this.imagen_pago_secundario.imagen_id, params, this.perfil_file_imagen_pago2, this.tipo)
          .then(
            (result: any) => {
              this.url_detalle_pago2 = null;
              this.perfil_file_imagen_pago2 = null;
              this.elimnardetallepago2.nativeElement.value = "";
            })
      } else {
        this.ConsultaService.AddImagenConsulta('consulta_img/consulta-archivos-imagen/' + this.detalle_imagen_tipo_pago2 + '/' + valor_id + '/' + this.tipo, params, this.perfil_file_imagen_pago2, this.tipo)
          .then(
            (result: any) => {
              this.url_detalle_pago2 = null;
              this.perfil_file_imagen_pago2 = null;
              this.elimnardetallepago2.nativeElement.value = "";
            })
      }

    }
  }
}


