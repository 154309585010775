import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export class ObservableCirugia {

    private datosPacienteCirugiaIDSource = new BehaviorSubject<any>("");
    public datosPacienteCirugiaMessage = this.datosPacienteCirugiaIDSource.asObservable();

    private datosAyudantiasIDSource = new BehaviorSubject<any>("");
    public datosAyudantiaMessage = this.datosAyudantiasIDSource.asObservable();


    private verificaDatosPacienteCirugiaIDSource = new BehaviorSubject<any>("");
    public verificaDatosPacienteCirugiaMessage = this.verificaDatosPacienteCirugiaIDSource.asObservable();

    private verificaPagoCirugiaIDSource = new BehaviorSubject<any>("");
    public verificaPagoCirugiaMessage = this.verificaPagoCirugiaIDSource.asObservable();

    private resetDatosCirugiaIDSource = new BehaviorSubject<any>("");
    public resetDatosCirugiaMessage = this.resetDatosCirugiaIDSource.asObservable();

    private resetDatosPacienteIDSource = new BehaviorSubject<any>("");
    public resetDatosPacienteMessage = this.resetDatosPacienteIDSource.asObservable();
    //intercambia datos del paciente evento con paciente
    public changedatosPacienteCirugia(datos) {
        if (datos && datos.limpiar == undefined) {
            this.datosPacienteCirugiaIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true ){
            //this.datosPacienteCirugiaIDSource.complete();
            this.datosPacienteCirugiaIDSource.next(null);
        }
       
    }

    public changedatosAyudantia(datos) {
        if (datos && datos.limpiar == undefined) {
            this.datosAyudantiasIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true ){
            //this.datosAyudantiasIDSource.complete();
            this.datosAyudantiasIDSource.next(null);
        }
      
    }
    //verifica datos de paciente llenos
    public chengeVerificaDatosPacienteCirugia(datos) {
        if (datos && datos.limpiar == undefined) {
            this.verificaDatosPacienteCirugiaIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true ){
            //this.verificaDatosPacienteCirugiaIDSource.complete();
            this.verificaDatosPacienteCirugiaIDSource.next(null);
        }
      //  t
    }

    // envia lo datos de evento despeus de verificar que esten bien
    public changeVerificaPagoCirugia(datos) {
        if (datos && datos.limpiar == undefined ) {
            this.verificaPagoCirugiaIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true ){
           // this.verificaPagoCirugiaIDSource.complete();
           this.verificaPagoCirugiaIDSource.next(null);
        }
       
    }

    //Reinicia los valores llenados de cirugia e envio el id de cirugia
    public changeResetDatosCirugia(datos) {
        if (datos) {
            this.resetDatosCirugiaIDSource.next(datos);
        }
     //   this.resetDatosCirugiaIDSource.complete();
    }
    //Reiniciar los valores llenados de cirugia paciente 
    public changeResetDatosPaciente(datos) {
        if (datos) {
            this.resetDatosPacienteIDSource.next(datos);
        }
       // this.resetDatosPacienteIDSource.complete();
    }
}

