import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class IndexService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getEspecialidad() {
        //consejo dejar solo los links que co autorizacion de esta forma, que no continen una session
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.get(this.url + 'cat_especialidad/get', { headers: headers })
            .map(res => res.json());
    }
    AddUsuario(d) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(d);
        return this.http.post(this.url + 'usuario/add-usuario', params, { headers: headers })
            .map(res => res.json());
    }
    ActivarCodigo(d) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.get(this.url + 'usuario/usuario-verificacodigo_cd/' + d, { headers: headers })
            .map(res => res.json());
    }
    Login(d) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.get(this.url + 'usuario/get-usuario-login/' + d.usuario_pw + '/' + d.usuario_email, { headers: headers })
            .map(res => res.json());
    }
    RecoberyPw(d) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.get(this.url + 'usuario/get-recovery_pw/' + d, { headers: headers })
            .map(res => res.json());
    }
    ResetPw(d) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(d);
        return this.http.post(this.url + 'usuario/reset_pw_recovery', params, { headers: headers })
            .map(res => res.json());
    }
    getAvisos() {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.get(this.url + 'usuario/get-avisos', { headers: headers })
            .map(res => res.json());
    }
    getPerfilMenuApp(perfil, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'per_menuapp/getbyid/' + perfil, { headers: headers })
            .map(res => res.json());
    }
}
IndexService.ngInjectableDef = i0.defineInjectable({ factory: function IndexService_Factory() { return new IndexService(i0.inject(i1.Http)); }, token: IndexService, providedIn: "root" });
