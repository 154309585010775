import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export class ObservableCalendario {

    private calendarioSelectedIdSource = new BehaviorSubject<any>("");
    public calendarioSelectedMessage = this.calendarioSelectedIdSource.asObservable();



    //intercambia datos del paciente evento con paciente
    public changeCalendarioSelected(datos) {
        if (datos && datos.limpiar == undefined) {
            this.calendarioSelectedIdSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true ){
            //this.calendarioSelected.complete();
            this.calendarioSelectedIdSource.next(null);
        }
       
    }

}

