export class  PacienteModel{
    constructor (
        public paciente_id : number,
        public paciente_nombre : string,
        public paciente_edad : any,
        public paciente_rfc : string,
        public paciente_correo : string,
        public paciente_dir : string,
        public paciente_tel : string,
        public paciente_cel : string,
        public paciente_status : string,
        public fecha_nacimiento,
        public enviar_correo : number
    ){

    }
}