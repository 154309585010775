<div *ngIf="datos_session.acceso_pago == 1">
  <div class="fondo-titulo-campos">
    <h6 class="titulo-campos">DETALLES DE PAGO</h6>
  </div>

	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-pago.png" width="auto" height="20px"></div>
        <div class="p-1 w-100"><p class="consulta">Cirugía Pagada?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled ="true" (change)="esPagada(consulta_pagada)" [(ngModel)]="consulta_pagada" name="radios3"
            id="radios4" [value]="true">
            <label class="custom-control-label consulta p-1" for="radios4">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled ="true" (change)="esPagada(consulta_pagada)" [(ngModel)]="consulta_pagada" name="radios3"
            id="radios5" [value]="false">  
            <label class="custom-control-label consulta p-1" for="radios5">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>

  <div class="form-row" style="margin-top: 3px;">
    <div class="form-group col-6">
      <input  (input)="CirugiaPagadaButtons()" name="cantidad_pago" #name="ngModel" [(ngModel)]="datos_pago.detpg_cantidad" type="number" class="btn btn-form-conf btn-block btn-lg"
        placeholder="{{ingresacantidad}}">
    </div>
    <div class="form-group col-6">
      <select #name="ngModel" [(ngModel)]="datos_pago.fpago_id" (change)="CirugiaPagadaButtons()" class="btn btn-form-conf btn-block btn-lg" name="forma_pago" id="forma de pago">
        <option [value]="0"> Forma de Pago / Agregar en Configuración</option>
        <option *ngFor="let pago of cat_fpago" [value]="pago.fpago_id">{{pago.fpago_desc}}</option>
      </select>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select   class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" [(ngModel)]="datos_pago.aseguradora_id"
        #name="ngModel" name="aseguradora" id="aseguradora">
        <option [value]="0">Aseguradora / Agregar en Configuración</option>
        <option *ngFor="let aseguradora of cat_aseguradora" [value]="aseguradora.aseguradora_id">
          {{aseguradora.aseguradora_desc}}</option>
      </select>
    </div>
    <div class="form-group col-6">
        <div class="trigger input-group-btn" style="display: flex;">
            <input (dateTimeInput)="CirugiaPagadaButtons()"  name="fecha_pago" #name="ngModel" [(ngModel)]="datos_pago.detpg_fechapago" id="datepicker2" style="font-size: 12px; font-weight: 3000;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 127px;"
            class="read-on form-control letraCuadrosFormulario flecha_m" placeholder="Fecha De Pago" [owlDateTimeTrigger]="dt2"
            [owlDateTime]="dt2" readonly>
          <button class="read-on btn btn-default" type="button" (click)="clearValuePago()" style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 4px;">
            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      
      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
    </div>
  </div>


	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-deposit.png" width="auto" height="20px"></div>
        <div class="p-1 w-100"><p class="consulta">¿Depositado?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled = "true" [(ngModel)]="deposito" (change)="esDeposito(deposito)" #name="ngModel" name="radios6"
            id="radios7" [value]="true">
            <label class="custom-control-label consulta p-1" for="radios7">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled ="true" [(ngModel)]="deposito" (change)="esDeposito(deposito)" #name="ngModel" name="radios6"
            id="radios8" [value]="false">  
            <label class="custom-control-label consulta p-1" for="radios8">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>


  <div class="form-row">
    <div class="form-group col-6">
      <select (change)="CirugiaDepositadoButtons()"  #name="ngModel" [(ngModel)]="datos_pago.banco_id" class="btn btn-form-conf btn-block btn-lg" name="banco_rec" id="banco_Rec">
        <option [value]="0">Banco Receptor / Agregar en Configuración</option>
        <option *ngFor="let banco of banco_desc" [value]="banco.banco_id">{{banco.banco_desc}}</option>
      </select>
      <!-- Requiere el script al final de la página-->

    </div>
    <div class="form-group col-6">
        <div class="trigger input-group-btn" style="display: flex;">
            <input (dateTimeInput)="CirugiaDepositadoButtons()" id="datepicker3" #name="ngModel" [(ngModel)]="datos_pago.detpg_fechadepo" style="font-size: 12px; font-weight: 3000;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 127px;"
            class="top-medio read-on form-control letraCuadrosFormulario flecha_m" placeholder="Fecha" [owlDateTimeTrigger]="dt3"
            [owlDateTime]="dt3" readonly>
          <button class="read-on btn btn-default" type="button" (click)="clearValueDeposito()" style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;">
            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      
      <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>

    </div>
  </div>


	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-recibo.png" width="auto" height="20px"></div>
        <div class="p-1 w-100"><p class="consulta">¿Recibo?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled = "true" [(ngModel)]="recibo" (change)="esRecibo(recibo)" #name="ngModel" name="radios9"
            id="radios10" [value]="true">
            <label class="custom-control-label consulta p-1" for="radios10">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" disabled ="true" [(ngModel)]="recibo" (change)="esRecibo(recibo)" #name="ngModel" name="radios9"
            id="radios11" [value]="false">  
            <label class="custom-control-label consulta p-1" for="radios11">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>

  <div class="form-row">
    <div class="form-group col-6">
      <input  (input)="CirugiaReciboButtons()" type="text" placeholder="No de Recibo" #name="ngModel" [(ngModel)]="datos_pago.detpg_recibonum" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista">
    </div>
    <div class="form-group col-6">
        <div class="trigger input-group-btn" style="display: flex;">
            <input (dateTimeInput)="CirugiaReciboButtons()" id="datepicker4" name="fecha_recibo" #name="ngModel" [(ngModel)]="datos_pago.detpg_fecharecibo"  style="font-size: 12px; font-weight: 3000;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 127px;"
            class="read-on top-medio form-control letraCuadrosFormulario flecha_m" placeholder="Fecha De Recibo" [owlDateTimeTrigger]="dt4"
            [owlDateTime]="dt4" readonly>
          <button class="read-on btn btn-default" type="button" (click)="clearValueRecibo()" style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;">
            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
        </div>
      <owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea (input)="CirugiaReciboButtons()"  class="form-control letraCuadrosFormulario" #name="ngModel" [(ngModel)]="datos_pago.detpg_detalle" id="notasTextarea"
        rows="3" placeholder="Detalles Del Pago"></textarea>
    </div>

    <div class="row offset-1">
      <div class="col-4">
        <input #ImagenDetallePago (change)="SubirImagenDetallePago($event);" type="file" name="file" id="file" class="inputfile">
        <label>
          <div class="pg-empty-placeholder">
            <img *ngIf="url_detalle_pago" [src]="url_detalle_pago" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
            <img *ngIf="imagen_pago_principal" src="{{url_imagen+imagen_pago_principal.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
          </div>
        </label>
        <label class="imagen-add" *ngIf="file_imagen_detalle_pago" for="1">Se ha Agregado un archivo</label>
      </div>
      <div class="col-4">
        <input #ImagenDetallePagoSecundario (change)="SubirImagenDetallePagoSecundario($event);" type="file" name="file" id="file"
          class="inputfile">
        <label>
          <div class="pg-empty-placeholder">
            <img *ngIf="url_detalle_pagoSecundario" [src]="url_detalle_pagoSecundario" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
            <img *ngIf="imagen_pago_secundario" src="{{url_imagen+imagen_pago_secundario.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
          </div>
        </label>
        <label class="imagen-add" *ngIf="file_imagen_detalle_pagoSecundario" for="1">Se ha Agregado un archivo</label>
      </div>
      <div class="col-1"></div>
      <div class="col-4">
        <!--ImagenDetallePago-->
        <img data-toggle="modal" (click)="verImagenPago(1)" *ngIf="url_detalle_pago || imagen_pago_principal" data-target="#imagenDetallePagoModal"
          src="../../../assets/images/box-image.png" width="auto" height="80px">
          
      </div>
      <div class="col-3">
        <img data-toggle="modal" (click)="verImagenPago(2)" *ngIf=" url_detalle_pagoSecundario ||   imagen_pago_secundario" data-target="#ImagenDetallePagoSecundarioModal"
          src="../../../assets/images/box-image.png" width="auto" height="80px">
          
      </div>
    </div>
  </div>
</div>















<!--aca se añade detalle de pago no visible para los que no tienen acceso////////////////////////////////////////////////////////////////////////////////////-->
<div *ngIf="datos_session.acceso_pago == 0">
  <div class="fondo-titulo-campos">
    <h6 class="titulo-campos">DETALLES DE PAGO</h6>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-pago.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      Cirugía Pagada?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios4">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esPagada(consulta_pagada)" class="form-check-input" type="radio"
          name="radios3" id="radios4" [value]="true" style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios5">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esPagada(consulta_pagada)" class="form-check-input" type="radio"
          name="radios3" id="radios5" [value]="false">
      </div>
    </div>
  </div>
  <div class="form-row" style="margin-top: 3px;">
    <div disabled="datos_pago.detpg_pagada == 0" class="form-group col-6">
      <input [disabled]="datos_session.acceso_pago == 0" min=0 name="cantidad_pago" type="text" class="btn btn-form-conf btn-block btn-lg"
        placeholder="{{ingresacantidad}}">
    </div>
    <div class="form-group col-6">
      <select [disabled]="datos_session.acceso_pago == 0" class="btn btn-form-conf btn-block btn-lg" name="forma_pago" id="forma de pago">
        <option [value]="0"> Forma de Pago</option>
        <option *ngFor="let pago of cat_fpago" [value]="pago.fpago_id">{{pago.fpago_desc}}</option>
      </select>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select [disabled]="datos_session.acceso_pago == 0" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        name="aseguradora" id="aseguradora">
        <option [value]="0">Aseguradora</option>
        <option *ngFor="let aseguradora of cat_aseguradora" [value]="aseguradora.aseguradora_id">
          {{aseguradora.aseguradora_desc}}</option>
      </select>
    </div>
    <div class="form-group col-6">
      <input [disabled]="datos_session.acceso_pago == 0" [ngStyle]="{'opacity': consulta_pagada  == false ? '.65' : '1.65'}" name="fecha_pago"
        id="datepicker2" style="margin-top : 0px;font-size: 12px; font-weight: 300;" class="read-on form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha De Pago" readonly>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-deposit.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      ¿Depositado?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios7">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esDeposito(deposito)" class="form-check-input" type="radio"
          name="radios6" id="radios7" [value]="true" style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios8">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esDeposito(deposito)" class="form-check-input" type="radio"
          name="radios6" id="radios8" [value]="false">
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <select [disabled]="datos_session.acceso_pago == 0" class="btn btn-form-conf btn-block btn-lg" name="banco_rec" id="banco_Rec">
        <option [value]="0">Banco Receptor</option>
        <option *ngFor="let banco of banco_desc" [value]="banco.banco_id">{{banco.banco_desc}}</option>
      </select>
      <!-- Requiere el script al final de la página-->

    </div>
    <div class="form-group col-6">
      <input [disabled]="datos_session.acceso_pago == 0" [ngStyle]="{'opacity': deposito  == false ? '.65' : '1.65'}" [disabled]="!deposito"
        id="datepicker3" style="font-size: 12px; font-weight: 300;" class="top-medio read-on form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha" readonly>


    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1">
      <img src="../../../assets/images/icon-recibo.png" width="auto" height="20px">
    </div>
    <div class="form-group consulta col-md-6">
      ¿Recibo?
    </div>
    <div class="form-group col-md-4" style="display: flex;">
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios10">
          Si
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esRecibo(recibo)" class="form-check-input" type="radio" name="radios9"
          id="radios10" [value]="true" style="margin-left: 6px;">
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label consulta pr-1" for="radios11">
          No
        </label>
        <input [disabled]="datos_session.acceso_pago == 0" (change)="esRecibo(recibo)" class="form-check-input" type="radio" name="radios9"
          id="radios11" [value]="false">
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <input [disabled]="datos_session.acceso_pago == 0" type="text" placeholder="No de Recibo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista">
    </div>
    <div class="form-group col-6">
      <input [ngStyle]="{'opacity': recibo  == false ? '.65' : '1.65'}" [disabled]="datos_session.acceso_pago == 0" id="datepicker4"
        name="fecha_recibo" style="font-size: 12px; font-weight: 300;" class="read-on top-medio form-control letraCuadrosFormulario flecha_m"
        placeholder="Fecha De Recibo" readonly>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea [disabled]="datos_session.acceso_pago == 0" class=" btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="notasTextarea" rows="3" placeholder="Detalles Del Pago"></textarea>
    </div>
  </div>
</div>
<!--aca termina  añade detalle de pago no visible para los que no tienen acceso-->
<!--Para subir un nuevo archivo-->

<!--Modal dato evento imagenDetallePagoModal -->
<div class="modal fade pg-show-modal" id="imagenDetallePagoModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
  data-pg-name="Colaboradores">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="display: unset!important;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title text-center color-titulos" class="modal-title text-center" style="font-family: Open Sans;">Imagen 1</h4>
      </div>
      <div class="modal-body">
        <img class="mx-auto d-block" *ngIf="url_detalle_pago" [src]="url_detalle_pago" width="100%">
      </div>
      <div *ngIf="datos_actualizar && !url_detalle_pago " class="modal-body">
        <img class="mx-auto d-block" *ngIf="imagen_pago_principal" src="{{url_imagen+imagen_pago_principal.imagen_url}}" width="100%">
      </div>
      <div class="modal-footer">
        <button type="button" (click)="EliminarImagenDetallePago()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<!--Termina Modal dato evento ImagenDetallePagoSecundarioModal -->
<div class="modal fade pg-show-modal" id="ImagenDetallePagoSecundarioModal" tabindex="-1" role="dialog" aria-hidden="true"
  data-backdrop="true" data-pg-name="Colaboradores">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="display: unset!important;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 2</h4>
      </div>
      <div class="modal-body">
        <img class="mx-auto d-block" *ngIf="url_detalle_pagoSecundario" [src]="url_detalle_pagoSecundario" width="100%">
      </div>
      <div *ngIf="datos_actualizar && !url_detalle_pagoSecundario" class="modal-body">
        <img class="mx-auto d-block" *ngIf="imagen_pago_secundario" src="{{url_imagen+imagen_pago_secundario.imagen_url}}" width="100%">
      </div>
      <div class="modal-footer">
        <button type="button" (click)="EliminarImagenDetallePagoSecundario()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
      </div>
    </div>
  </div>
</div>