import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class DetallePagoService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getPagos(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_fpago/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    getBanco(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_banco/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    getAseguradora(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_aseguradora/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
}
DetallePagoService.ngInjectableDef = i0.defineInjectable({ factory: function DetallePagoService_Factory() { return new DetallePagoService(i0.inject(i1.Http)); }, token: DetallePagoService, providedIn: "root" });
