import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export class ObservableBusqueda {

    private datosMenuTipoIDSource = new BehaviorSubject<any>("");
    public datosMenuTipoMessage = this.datosMenuTipoIDSource.asObservable();

    private tipoBusquedaIDSource = new BehaviorSubject<any>("");
    public tipoBusquedaMessage = this.tipoBusquedaIDSource.asObservable();


    private tipoEnvioIDSource = new BehaviorSubject<any>("");
    public tipoEnvioArchivoMessage = this.tipoEnvioIDSource.asObservable();

    private resultadoBusquedaIDSource = new BehaviorSubject<any>("");
    public resultadoBusquedaMessage = this.resultadoBusquedaIDSource.asObservable();
    //datos generales pago busqueda
    private pagoGeneralIDSource = new BehaviorSubject<any>("");
    public datogeneralBMessage = this.pagoGeneralIDSource.asObservable();

    //datos detalle pago busqueda
    private detallePagoBusquedaIdSource = new BehaviorSubject<any>("");
    public detallePMessage = this.detallePagoBusquedaIdSource.asObservable();



    public changeBusquedaGenera(datos) {
        if (datos && datos.limpiar == undefined) {
            this.pagoGeneralIDSource.next(datos);
        }
        if (datos.limpiar == true) {
            this.pagoGeneralIDSource.next(null);
        }
    }


    public changeBusquedaPago(datos) {
        if (datos && datos.limpiar == undefined) {
            this.detallePagoBusquedaIdSource.next(datos);
        }
        if (datos.limpiar == true) {
            this.detallePagoBusquedaIdSource.next(null);
        }
    }


    public changedatosTipoMenu(datos) {
        if (datos && datos.limpiar == undefined) {
            this.datosMenuTipoIDSource.next(datos);
        }
        if (datos.limpiar != undefined && datos.limpiar == true) {
            //this.datosMenuTipoIDSource.complete();
            this.datosMenuTipoIDSource.next(null);
        }

    }
    //Filtro de busqueda al buscar
    public changeTipoBusqueda(datos) {
        if (datos && datos.limpiar == undefined) {
            this.tipoBusquedaIDSource.next(datos);
        }
        if (datos.limpiar != undefined && datos.limpiar == true) {
            this.tipoBusquedaIDSource.next(null);
        }
    }

    public changetipoEnvioArchivo(datos) {
        if (datos && datos.limpiar == undefined) {
            this.tipoEnvioIDSource.next(datos);
        }
        if (datos.limpiar != undefined && datos.limpiar == true) {
            this.tipoEnvioIDSource.next(null);
        }
    }
    public changeResultadoBusqueda(datos) {
        if (datos && datos.limpiar == undefined) {
            this.resultadoBusquedaIDSource.next(datos);
        }
        if (datos.limpiar != undefined && datos.limpiar == true) {
            this.resultadoBusquedaIDSource.next(null);
        }
    }

}