/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirmar-pw";
import * as i2 from "@angular/router";
var styles_ConfirmarPw = [];
var RenderType_ConfirmarPw = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmarPw, data: {} });
export { RenderType_ConfirmarPw as RenderType_ConfirmarPw };
export function View_ConfirmarPw_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["for", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["HOla"]))], null, null); }
export function View_ConfirmarPw_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmar-pw", [], null, null, null, View_ConfirmarPw_0, RenderType_ConfirmarPw)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmarPw, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmarPwNgFactory = i0.ɵccf("confirmar-pw", i1.ConfirmarPw, View_ConfirmarPw_Host_0, {}, {}, []);
export { ConfirmarPwNgFactory as ConfirmarPwNgFactory };
