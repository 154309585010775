<!-- Div para mantener el fondo en las dos secciones -->
<section *ngIf="mostrar_pendientes" class="containerfondo">
    <div class="container-fluid" style="padding-bottom: 80px;">
        <!-- Notas -->
        <section>
            <div class="container pt-5">
                <div class="row">

                    <div class="col-7 offset-1">
                        <div *ngFor="let  pendientes of pendientes_list">
                            <div class="fondonotasgray">
                                <div class="row align-items-center">
                                    <div *ngIf="pendientes.pendiente_fav == 1" class="col col-lg-2 text-right">
                                        <img src="../../../assets/images/icon-star.png" (click)="UpdateFavDel(pendientes)" width="16px" height="auto" alt="MedBox"
                                            class="img-fluid pointer">
                                    </div>
                                    <div *ngIf="pendientes.pendiente_fav == 0" class="col col-lg-2 text-right">
                                        <img src="../../../assets/images/icon-star-off.png" (click)="UpdateFavAdd(pendientes)" width="16px" height="auto" alt="MedBox"
                                            class="img-fluid pointer">
                                    </div>
                                    <div class="col md-auto">
                                        <p class="detallecita text-left">
                                            <span class="font-weight-bold">{{pendientes.pendiente_fecha | date: 'dd/MM/yyyy' }}</span>
                                            {{pendientes.pendiente_desc}}</p>
                                    </div>
                                    <div class="col col-lg-2">
                                        <img (click)="DeleteFav(pendientes)" src="../../../assets/images/icon-trash.png" width="13px" height="auto" alt="MedBox"
                                            class="img-fluid pointer">
                                    </div>
                                </div>
                            </div>
                            <br>

                            <br>
                        </div>
                    </div>
                    <div class="col-4">
                        <form>
                            <div class="form-group">
                                <textarea (input)="LongitudDescripcion()" #name="ngModel" name="notas_area" [(ngModel)]="datos_pendientes.pendiente_desc"
                                    class="letraCuadrosFormulario form-control" id="notasTextarea" rows="16" placeholder="Escribir nota...(140 caracteres)"
                                    maxlength="140" style="font-family: Open Sans; height: 150px; color: white; text-align: left;"></textarea>
                                <div *ngIf="isMaxLength">
                                    <label for="error_longitud" style="color:red">
                                        La longitud máxima del pendiente es de 140 caracteress</label>
                                </div>
                            </div>
                            <button type="submit" (click)="AddPendiente()" class="btn btn-form btn-block btn-lg font-weight-bold">AGREGAR NOTA
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        </section>
        <!-- Cierra el fondo -->
    </div>

</section>