export class ProveedorModel{
    constructor(
        public proveedor_id : number,
        public proveedor_nombre : string,
        public proveedor_monto : number,
        public proveedor_recibo : string,
        public proveedor_fecha : string,
        public proveedor_det : string,
        public ayudantia_id : number,
        public proveedor_status : number
    ){

    }
}