/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendario-semana.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/angular-calendar/angular-calendar.ngfactory";
import * as i4 from "angular-calendar";
import * as i5 from "./calendario-semana.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../configuracion/configuracion.service";
import * as i8 from "../../../services/calendario/obserbable-calendario.service";
import * as i9 from "../../../services/cirugia/datos-evento.service";
import * as i10 from "../../../services/extraer/extraer-observable.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../observable.service";
var styles_CalendarioSemanaComponent = [i0.styles];
var RenderType_CalendarioSemanaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarioSemanaComponent, data: {} });
export { RenderType_CalendarioSemanaComponent as RenderType_CalendarioSemanaComponent };
function View_CalendarioSemanaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Event action occurred"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Action: "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Event: "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.modalData == null) ? null : _co.modalData.action); _ck(_v, 10, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(((_co.modalData == null) ? null : _co.modalData.event))); _ck(_v, 14, 0, currVal_1); }); }
function View_CalendarioSemanaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["id", "mes"], ["style", "font-weight: bold; color:#3C615F;font-size: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateMount; _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarioSemanaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mwl-calendar-week-view", [["weekStartsOn", "1"]], null, [[null, "eventClicked"], [null, "eventTimesChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClicked" === en)) {
        var pd_0 = (_co.handleEvent("Clicked", $event.event) !== false);
        ad = (pd_0 && ad);
    } if (("eventTimesChanged" === en)) {
        var pd_1 = (_co.eventTimesChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CalendarWeekViewComponent_0, i3.RenderType_CalendarWeekViewComponent)), i1.ɵdid(1, 770048, null, 0, i4.CalendarWeekViewComponent, [i1.ChangeDetectorRef, i4.CalendarUtils, i1.LOCALE_ID, i4.DateAdapter], { viewDate: [0, "viewDate"], events: [1, "events"], refresh: [2, "refresh"], locale: [3, "locale"], weekStartsOn: [4, "weekStartsOn"], dayStartHour: [5, "dayStartHour"], dayEndHour: [6, "dayEndHour"] }, { eventClicked: "eventClicked", eventTimesChanged: "eventTimesChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; var currVal_1 = _co.events; var currVal_2 = _co.refresh; var currVal_3 = _co.locale; var currVal_4 = "1"; var currVal_5 = 1; var currVal_6 = 24; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_CalendarioSemanaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalContent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "section", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 19, "div", [["class", "calendariosem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [[1, 2], ["modalContent", 2]], null, 0, null, View_CalendarioSemanaComponent_1)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["style", "margin-left : 95%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["class", "pointer flecha-calendar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeCalendar((0 - 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" < "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarioSemanaComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["style", "margin-left : -95%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "label", [["class", "pointer flecha-calendar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeCalendar(7) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" > "])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(21, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarioSemanaComponent_3)), i1.ɵdid(23, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mostrar_mes; _ck(_v, 15, 0, currVal_0); var currVal_1 = _co.view; _ck(_v, 21, 0, currVal_1); var currVal_2 = _co.CalendarView.Week; _ck(_v, 23, 0, currVal_2); }, null); }
export function View_CalendarioSemanaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendario-semana", [], null, null, null, View_CalendarioSemanaComponent_0, RenderType_CalendarioSemanaComponent)), i1.ɵdid(1, 245760, null, 0, i5.CalendarioSemanaComponent, [i6.NgbModal, i7.ConfiguracionService, i8.ObservableCalendario, i9.DatosEventoService, i10.ObservableExtraer, i11.Router, i12.ObservableService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarioSemanaComponentNgFactory = i1.ɵccf("app-calendario-semana", i5.CalendarioSemanaComponent, View_CalendarioSemanaComponent_Host_0, {}, {}, []);
export { CalendarioSemanaComponentNgFactory as CalendarioSemanaComponentNgFactory };
