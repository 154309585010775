import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class CirugiaService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    // Nuevo paciente
    AddCirugiaPaciente(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'paciente/add', params, { headers: headers })
            .map(res => res.json());
    }
    //agrega una nueva consulta de pago 
    AddCirugiaPago(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'detalle_pago/add', params, { headers: headers })
            .map(res => res.json());
    }
    // Agrega un nueva consulta general
    AddCirugiaGenerales(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'cirugia/add', params, { headers: headers })
            .map(res => res.json());
    }
    //Agregar ayudantia
    AddProveedor(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'proveedor/add', params, { headers: headers })
            .map(res => res.json());
    }
    //Obtener Imagenes de Cirugia Pago
    GetImagenesTipo(token, tipo, cirugia) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'imagen/get-img-tipo/' + cirugia + '/' + tipo, { headers: headers })
            .map(res => res.json());
    }
    //Eliminar la imagen de cirugia pago
    EliminarImagenDetallePago(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'imagen/update', params, { headers: headers })
            .map(res => res.json());
    }
    ///////////////////SERVICIOS PARA ACTUALIZAR DATOS DE UNA CIRUGIA , PAGO, PACIENTE////////////////////////
    updatePaciente(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'paciente/update', params, { headers: headers })
            .map(res => res.json());
    }
    updateCirugia(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'cirugia/update', params, { headers: headers })
            .map(res => res.json());
    }
    updatePago(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'detalle_pago/update', params, { headers: headers })
            .map(res => res.json());
    }
    updateProveedor(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'proveedor/update', params, { headers: headers })
            .map(res => res.json());
    }
}
CirugiaService.ngInjectableDef = i0.defineInjectable({ factory: function CirugiaService_Factory() { return new CirugiaService(i0.inject(i1.Http)); }, token: CirugiaService, providedIn: "root" });
