import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class EducacionService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getEducacion(token, especialidad) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'capacitacion/get-img-tipo/' + especialidad.capacitacion_tipo + '/' + especialidad.especialidad_id, { headers: headers })
            .map(res => res.json());
    }
}
EducacionService.ngInjectableDef = i0.defineInjectable({ factory: function EducacionService_Factory() { return new EducacionService(i0.inject(i1.Http)); }, token: EducacionService, providedIn: "root" });
