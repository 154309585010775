import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
@Injectable({
  providedIn: 'root'
})
export class ConsultaService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }
  public AddImagenConsulta(url_liga: string, params: Array<string>, file: File, tipo: number) {
    let _url = this.url;
    return new Promise(function (resolve, reject) {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      if (file) {
        if (tipo == 1) {
          formData.append('image', file, file.name);
        } else if (tipo == 2) {
          formData.append('pdf', file, file.name);
        }

      }
      for (let key of params) {
        let tKey: any = key;
        for (const i in tKey) {
          formData.append(i, key[i]);
        }
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open('POST', _url + url_liga, true);
      xhr.send(formData);
    })
  }

  ///////////////////SERVICIOS PARA ACTUALIZAR DATOS DE UNA COSNULTA , PAGO, PACIENTE////////////////////////
  public updatePaciente(datos) {
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'paciente/update', params, { headers: headers })
      .map(res => res.json())
  }

  public updateConsulta(datos) {
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'consulta/update', params, { headers: headers })
      .map(res => res.json())
  }

  public updatePago(datos) {
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'detalle_pago/update', params, { headers: headers })
      .map(res => res.json())
  }
  //Obtener Imagenes de consulta Pago
  public GetImagenesTipo(token,tipo,consulta){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url +'imagen/get-img-tipo-consulta/'+consulta+'/'+tipo,{ headers : headers })
    .map(res => res.json())
  }


    //Eliminar la imagen de consulta pago
    public EliminarImagenDetallePago(datos){
      let params = JSON.stringify(datos);
      let headers = new Headers({ 'Content-Type': 'application/json' });
      return this.http.post(this.url +'imagen/update',params,{headers : headers})
      .map(res => res.json())
  
    }
}