export class RecoveryPwModel{
constructor(
    public usuario_email : string,
    public codigo_recovery : string,
    public usuario_rec_codigo : string,
    public usuario_pw : string,
    public usuario_pw_confirm : string
){
    
}
}