import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { PacienteModel } from '../../../models/PacienteModel';
import swal from 'sweetalert2';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { ObservableCirugia } from '../../../services/cirugia/observable-cirugia.service';
import * as moment from 'moment';
declare var $: any;
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { isRegExp } from 'util';
@Component({
  selector: 'app-datos-paciente-cirugia',
  templateUrl: './datos-paciente-cirugia.component.html',
  styleUrls: ['./datos-paciente-cirugia.component.css']
})
export class DatosPacienteCirugiaComponent implements OnInit, OnDestroy {
  public datos_session: any;
  mailText: string = "";
  public token: any;
  public session: string;
  public usuario_id: string;
  public datos_usuario: any;
  public datos: any;
  public datos_cirugia: any;
  public datos_paciente: PacienteModel;
  public enviar_correo: boolean;
  public ayudantia: any;
  public correo_array = new Array();
  public correo_selected = [];
  public saveUsername;
  public correo = new Array();
  private subscription: Subscription = new Subscription();
  private subscription_verifica_paciente: Subscription = new Subscription();
  private subscription_resetpaciente: Subscription = new Subscription();
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private ObservableCirugia: ObservableCirugia,
    private ObservableExtraer: ObservableExtraer
  ) {
    this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
    console.log("this.datos_paciente_1",this.datos_paciente)
    this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });
  }

  ngOnInit() {
    this.correo_array = [{ id_categoria: 6, descripcion: 'Mi Usuario', status: false }, { id_categoria: 1, descripcion: 'Todos', status: false }, { id_categoria: 2, descripcion: 'Cirujano', status: false }, { id_categoria: 3, descripcion: 'Anestesiólogo', status: false }, { id_categoria: 4, descripcion: 'Ayudantías / Proveedores', status: false }, { id_categoria: 5, descripcion: 'Colaboradores', status: false }, { id_categoria: 7, descripcion: 'Ninguno', status: true }];
    this.Session();

    //this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });

    this.ObservableCirugia.chengeVerificaDatosPacienteCirugia({ limpiar: true });

    const subscription = this.ObservableCirugia.datosPacienteCirugiaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos_paciente.enviar_correo = 0;
        if (result.paciente_id != 0) {
          this.datos_paciente = result;
          //console.log("this.datos_paciente",this.datos_paciente)
          let fecha_formateada = moment(this.datos_paciente.fecha_nacimiento).format('YYYY-M-DD HH:mm:ss');
          this.datos_paciente.fecha_nacimiento = fecha_formateada;
          if(this.datos_paciente.paciente_dir == 'Dirección') this.datos_paciente.paciente_dir = '';
          let split_fecha_inicio = this.datos_paciente.fecha_nacimiento.split('-');
          let año_i = split_fecha_inicio[0];
          let mes_i = split_fecha_inicio[1];
          let dia_i = split_fecha_inicio[2];
          let año_inicio = parseInt(año_i);
          let mes_inicio = parseInt(mes_i) - 1;
          let dia_inicio = parseInt(dia_i);
          let fecha_inicio_1 = new Date(año_inicio, mes_inicio, dia_inicio);
          this.datos_paciente.fecha_nacimiento = fecha_inicio_1;

        } else {
          this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
          //console.log("this.datos_paciente_2",this.datos_paciente)
          this.correo_array = [{ id_categoria: 6, descripcion: 'Mi Usuario', status: false }, { id_categoria: 1, descripcion: 'Todos', status: false }, { id_categoria: 2, descripcion: 'Cirujano', status: false }, { id_categoria: 3, descripcion: 'Anestesiólogo', status: false }, { id_categoria: 4, descripcion: 'Ayudantías / Proveedores', status: false }, { id_categoria: 5, descripcion: 'Colaboradores', status: false }, { id_categoria: 7, descripcion: 'Ninguno', status: true }]
        }
      } else {
        this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
        //console.log("this.datos_paciente_3",this.datos_paciente)
        this.correo_array = [{ id_categoria: 6, descripcion: 'Mi Usuario', status: false }, { id_categoria: 1, descripcion: 'Todos', status: false }, { id_categoria: 2, descripcion: 'Cirujano', status: false }, { id_categoria: 3, descripcion: 'Anestesiólogo', status: false }, { id_categoria: 4, descripcion: 'Ayudantías / Proveedores', status: false }, { id_categoria: 5, descripcion: 'Colaboradores', status: false }, { id_categoria: 7, descripcion: 'Ninguno', status: true }]
      }
    })
    this.ObservableExtraer.datosSelectedPacienteMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (result.datos_paciente.paciente_id != 0) {
          this.datos_paciente = result.datos_paciente;
          //console.log("this.datos_paciente",this.datos_paciente)
          let fecha_formateada = moment(this.datos_paciente.fecha_nacimiento).format('YYYY-M-DD HH:mm:ss');
          this.datos_paciente.fecha_nacimiento = fecha_formateada;
          if(this.datos_paciente.paciente_dir == 'Dirección') this.datos_paciente.paciente_dir = '';

        } else {
          this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
          //console.log("this.datos_paciente_4",this.datos_paciente)
        }
      }
    })
    const subscription_verifica_paciente = this.ObservableCirugia.verificaDatosPacienteCirugiaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos_paciente.paciente_nombre = result.datos_paciente.paciente_nombre;
        this.datos_paciente.paciente_edad = result.datos_paciente.paciente_edad;
        this.datos_cirugia = result.datos_evento;
        this.ayudantia = result.datos_ayudantia;
        this.verificarDatos();
      }

    })
    const subscription_resetpaciente = this.ObservableCirugia.resetDatosPacienteMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        //this.enviar_correo = false;
        //this.correo_array = [{ id_categoria: 6, descripcion: 'Mi Usuario', status: false }, { id_categoria: 1, descripcion: 'Todos', status: false }, { id_categoria: 2, descripcion: 'Cirujano', status: false }, { id_categoria: 3, descripcion: 'Anestesiólogo', status: false }, { id_categoria: 4, descripcion: 'Ayudantías / Proveedores', status: false }, { id_categoria: 5, descripcion: 'Colaboradores', status: false }, { id_categoria: 7, descripcion: 'Ninguno', status: true }];
        //this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
        //console.log("this.datos_paciente_5",this.datos_paciente)
      }
    })
    this.subscription.add(subscription);
    this.subscription_verifica_paciente.add(subscription_verifica_paciente);
    this.subscription_resetpaciente.add(subscription_resetpaciente);
  }
  ngOnDestroy() {
    this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });
    this.ObservableExtraer.changedatosPacienteSelected({ limpiar: true });
    if (this.subscription_verifica_paciente) {
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      //console.log("this.datos_paciente_6",this.datos_paciente)
      this.subscription_verifica_paciente.unsubscribe();
      this.ObservableCirugia.changeVerificaPagoCirugia({ limpiar: true });
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      //console.log("this.datos_paciente_7",this.datos_paciente)
    }
    if (this.subscription_resetpaciente) {
      this.subscription_resetpaciente.unsubscribe();
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      //console.log("this.datos_paciente_8",this.datos_paciente)
    }
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
    }


  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
        }
      })
  }
  verificarDatos() {
    this.cleanPhone(1);
    this.cleanPhone(2);
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //|| (!isNaN(this.datos_paciente.fecha_nacimiento.getTime())) == false
    if (this.datos_paciente.paciente_correo != "") {
      if (!emailRegex.test(this.datos_paciente.paciente_correo)) {
        swal("Error", "El correo es Invalido", "error");
      } else {
        $('#EmailEnviarCirugia').modal({ backdrop: 'static', keyboard: false })
        $('#EmailEnviarCirugia').modal('show');
        this.datos = { datos_paciente: this.datos_paciente, datos_evento: this.datos_cirugia, datos_ayudantia: this.ayudantia }
        //this.ObservableCirugia.changeVerificaPagoCirugia(this.datos);
      }
    } else {
      $('#EmailEnviarCirugia').modal({ backdrop: 'static', keyboard: false })
      // if (this.datos_paciente.enviar_correo == 1) {
      $('#EmailEnviarCirugia').modal('show');
      // } else {
      //   this.datos = { datos_paciente: this.datos_paciente, datos_evento: this.datos_cirugia, datos_ayudantia: this.ayudantia }
      //   this.ObservableCirugia.changeVerificaPagoCirugia(this.datos);
      // }
    }
  }

  correoEnviar(enviar) {
    //1 enviar 0 no enviar
    if (enviar == 1) {
      this.enviar_correo = true;
      this.datos_paciente.enviar_correo = 1;
      this.abrirCorreo()
    } else if (enviar == 0) {
      this.enviar_correo = false;
      this.datos_paciente.enviar_correo = 0;
    }
  }

  abrirCorreo() {
    this.mailText = "mailto:" + this.datos_paciente.paciente_correo + "?subject=Notificación de Consulta&body=";
    window.location.href = this.mailText;
  }

  enviarEmail(categoria_selected, status) {
    if (categoria_selected == 1) {
      if (status) {
        this.verificacionChecksEmail(true, "7", categoria_selected);
        this.verificacionChecksEmail(false, "1,2,3,4,5,6", categoria_selected);
      } else {
        this.verificacionChecksEmail(false, "7", categoria_selected)
      }
    } else if (categoria_selected == 7) {
      if (status) this.verificacionChecksEmail(false, "", categoria_selected)
    } else {
      this.verificacionChecksEmail(false, "2,3,4,5,6", categoria_selected)
    }




  }

  verificacionChecksEmail(isCkeck, notCheck, categoria_selected) {
    for (let key in this.correo_array) {
      if (!notCheck.includes(this.correo_array[key].id_categoria) && categoria_selected != this.correo_array[key].id_categoria)
        this.correo_array[key].status = isCkeck;
    }
  }

  guardarConfEmail() {
    this.datos = { datos_paciente: this.datos_paciente, datos_evento: this.datos_cirugia, datos_ayudantia: this.ayudantia, destinatarios: this.correo_array }
    this.ObservableCirugia.changeVerificaPagoCirugia(this.datos);
    $('#EmailEnviarCirugia').modal('hide');
  }

  cleanPhone(type){
    if(type == 1){
      this.datos_paciente.paciente_tel = this.datos_paciente.paciente_tel.replace(/\D+/g,'');
    }
    if(type == 2){
      this.datos_paciente.paciente_cel = this.datos_paciente.paciente_cel.replace(/\D+/g,'');
    }
  }

}
