import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { DetallePagoService } from '../../../services/consulta/detalle-pago/detalle-pago.service';
import { DetallePago } from '../../../models/DetallePago';
import swal from 'sweetalert2';
import { ObservableService } from '../../../observable.service';
import { Subscription } from 'rxjs/Subscription';
import { DatosGeneralesService } from '../../../services/consulta/datos-generales/datos-generales.service';
import { PacienteModel } from '../../../models/PacienteModel';
import { ConsultaModel } from '../../../models/ConsultaModel';
import { OnDestroy } from "@angular/core";
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { ConsultaService } from '../../../services/consulta/consulta.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-detalle-pago',
  templateUrl: './detalle-pago.component.html',
  styleUrls: ['./detalle-pago.component.css']
})
export class DetallePagoComponent implements OnInit, OnDestroy {
  public consulta_pagada: boolean;
  public deposito: boolean;
  public cat_fpago = new Array();
  public banco_desc = new Array();
  public cat_aseguradora = new Array();
  public session;
  public datos_session;
  public token;
  public usuario_id;
  public datos_usuario;
  public datos_pago: DetallePago;
  private subscription: Subscription = new Subscription();
  public datos_paciente: PacienteModel;
  public datos_consulta: ConsultaModel;
  public recibo: boolean;
  private subscription_observable: Subscription = new Subscription();
  public ingresacantidad: string;
  public datos_actualizar: boolean;
  public datos: any;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private DetallePagoService: DetallePagoService,
    private ObservableService: ObservableService,
    private DatosGeneralesService: DatosGeneralesService,
    private ObservableExtraer: ObservableExtraer,
    private ConsultaService: ConsultaService,
    private router: Router

  ) {
    this.datos_actualizar = false;
    this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
    this.datos_pago.detpg_cantidad = null;
    this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
    this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
    const subscription = this.ObservableService.esCorrectoGeneralesConsultaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (result.valido == 1) {
          this.datos = result;
          this.GuardarPago(result);
        }
      }

    });
    this.subscription.add(subscription);

  }

  ngOnInit() {

    this.Session();
    this.deposito = false;
    this.recibo = false;
    this.consulta_pagada = false;
    this.datos_pago.detpg_pagada = 0;
    this.datos_pago.detpg_depositado = 0;
    this.datos_pago.detpg_recibo = 0;

    console.log("this.usuario_id ", this.usuario_id)
    if (this.datos_pago.detpg_cantidad == 0) {
      this.datos_pago.detpg_cantidad = null;
      this.ingresacantidad = "Cantidad"
    }
    const unsubscribe_pago = this.ObservableExtraer.datosSelectedPagoMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos_consulta = result.datos_cirugia;
        this.datos_pago = result.detalle_pago;
        if (this.datos_pago.detpg_pagada == 1) {
          this.consulta_pagada = true;
        } else if (this.datos_pago.detpg_pagada == 0) {
          this.consulta_pagada = false;
        }
        if (this.datos_pago.detpg_depositado == 1) {
          this.deposito = true;
        } else if (this.datos_pago.detpg_depositado == 0) {
          this.deposito = false;
        }
        if (this.datos_pago.detpg_recibo == 1) {
          this.recibo = true;
        } else if (this.datos_pago.detpg_recibo == 0) {
          this.recibo = false;
        }
        if (this.datos_pago.detpg_cantidad == 0) {
          this.datos_pago.detpg_cantidad = null;
        }
        this.datos_actualizar = true;
      } else {
        this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
        this.datos_pago =  new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
        this.datos_pago.detpg_cantidad = null;
        this.ingresacantidad = "Cantidad"
      }
    })
    this.subscription_observable.add(unsubscribe_pago);

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription_observable.unsubscribe();
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      // this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.usuario_id = this.datos_session.usuario_id;
      this.getPerfilUsuario();
    }
    console.log("this.session", this.datos_session)
  }


  getPerfilUsuario() {

    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        console.log("RESULT", result)
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          console.log("this.datos_usuario ", this.datos_usuario)
          this.getPagos();
          this.getBanco();
          this.getAseguradora();
          this.ConsutaReciboButtons()
          this.ConsutaPagadaButtons()
          this.ConsutaDepositadoButtons()
        }
      })
  }
  //CATALOGOS
  getPagos() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;
    this.DetallePagoService.getPagos(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.cat_fpago = result.cat_fpago;
      })
  }

  getBanco() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;
    this.DetallePagoService.getBanco(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.banco_desc = result.cat_banco;
      })
  }


  getAseguradora() {
    let usuario_consulta = 0;
    if(this.datos_usuario.usuid_padre == 0) usuario_consulta = this.datos_usuario.usuario_id;
    else usuario_consulta = this.datos_usuario.usuid_padre;
    this.DetallePagoService.getAseguradora(this.token.token, usuario_consulta)
      .subscribe(result => {
        this.cat_aseguradora = result.cat_aseguradora;
      })
  }
  // Radio Buttons
  esPagada(event) {
    this.consulta_pagada = event
    if (this.consulta_pagada == true) {
      this.datos_pago.detpg_pagada = 1;
    } else {
      this.datos_pago.detpg_pagada = 0;
    }
  }
  esDeposito(deposito) {
    this.deposito = deposito;
    if (this.deposito == true) {
      this.datos_pago.detpg_depositado = 1;
    } else {
      this.datos_pago.detpg_depositado = 0;
    }
  }

  esRecibo(recibo) {
    this.recibo = recibo;
    if (this.recibo == true) {
      this.datos_pago.detpg_recibo = 1;
    } else {
      this.datos_pago.detpg_recibo = 0;
    }
  }

  GuardarPago(datos_consulta) {
    //this.datos_pago.detpg_tipo 1 = consulta, 2 = cirugia
    datos_consulta.datos_generales.usuario_id = this.usuario_id;

    if (datos_consulta.datos_generales.constulata_duracion != "") {
      let split_duracion = datos_consulta.datos_generales.constulata_duracion;
      let datetext = split_duracion.getHours() + ":" + split_duracion.getMinutes()
      datos_consulta.datos_generales.consulta_duracion = datetext;
    }
    this.datos_pago.detpg_tipo = 1;
    datos_consulta.datos_generales.enviar_correo = datos_consulta.destinatarios;
    datos_consulta.datos_generales.paciente_correo = datos_consulta.datos_paciente.paciente_correo;
    datos_consulta.datos_generales.paciente_nombre = datos_consulta.datos_paciente.paciente_nombre;
    if (!this.consulta_pagada && (this.datos_pago.detpg_cantidad == null)) {
      this.datos_pago.detpg_cantidad == 0;
    }
    
    if(this.datos_pago.detpg_cantidad > 0 || (this.datos_pago.detpg_fechapago != '0000-00-00' && this.datos_pago.detpg_fechapago != '') || this.datos_pago.fpago_id != 0){
      this.datos_pago.detpg_pagada = 1
    } else { this.datos_pago.detpg_pagada = 0; }
    if( (this.datos_pago.detpg_fechadepo != '0000-00-00' && this.datos_pago.detpg_fechadepo != '') || this.datos_pago.banco_id != 0){
      this.datos_pago.detpg_depositado = 1;
    } else { this.datos_pago.detpg_depositado = 0; }
    if(this.datos_pago.detpg_recibonum.length > 0 || (this.datos_pago.detpg_fecharecibo != '0000-00-00' && this.datos_pago.detpg_fecharecibo != '')){
      this.datos_pago.detpg_recibo = 1;
    } else { this.datos_pago.detpg_recibo = 0; }

    if (this.datos_actualizar) {
      this.ActualizarDatosConsulta();
    } else if (this.datos_pago.detpg_pagada == 0 && this.datos_pago.detpg_depositado == 0 && this.datos_pago.detpg_recibo == 0) {
      if (datos_consulta.datos_paciente.paciente_id == 0) {
        datos_consulta.datos_paciente.usuario_id = this.usuario_id;
        this.DatosGeneralesService.AddConsultaPaciente(datos_consulta.datos_paciente)
          .subscribe(result => {
            let paciente_id = result.paciente.paciente_id;
            this.datos_pago.usuario_id = this.usuario_id;
            datos_consulta.datos_generales.paciente_id = paciente_id
            this.DatosGeneralesService.AddConsultaPago(this.datos_pago)
              .subscribe(result => {
                if (result.valido == 1) {
                  let detpg_id = result.detalle_pago[0].detpg_id;
                  datos_consulta.datos_generales.paciente_id = paciente_id
                  datos_consulta.datos_generales.detpg_id = detpg_id
                  datos_consulta.datos_generales.consulta_fecha == "" ? datos_consulta.datos_generales.consulta_fecha = moment().format('YYYY-MM-DD') : datos_consulta.datos_generales.consulta_fecha = datos_consulta.datos_generales.consulta_fecha;
                  datos_consulta.datos_generales.consulta_hora == "" ? datos_consulta.datos_generales.consulta_hora = new Date() : datos_consulta.datos_generales.consulta_hora = datos_consulta.datos_generales.consulta_hora;
                  this.DatosGeneralesService.AddConsultaGenerales(datos_consulta.datos_generales)
                    .subscribe(result => {
                      let consulta_id = result.consulta[0].consulta_id;
                      this.ObservableService.changedatosConsulta(consulta_id);
                      this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
                      this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
                      this.ObservableService.changeConsultaReset({ limpiar: 1 })
                      this.deposito = true;
                      this.recibo = true;
                      this.consulta_pagada = true;
                      this.datos_pago.detpg_pagada = 1;
                      this.datos_pago.detpg_recibo = 1;
                      this.datos_pago.detpg_depositado = 1;
                      this.router.navigate(['/home'])
                      swal('Muy Bien', 'Se guardo correctamente', 'success')
                    })
                }
              })
          })
      } else if (datos_consulta.datos_paciente.paciente_id != 0) {
        this.datos_pago.usuario_id = this.usuario_id;
        let paciente_id = datos_consulta.datos_paciente.paciente_id;
        datos_consulta.datos_generales.paciente_id = paciente_id
        datos_consulta.datos_generales.consulta_fecha == "" ? datos_consulta.datos_generales.consulta_fecha = moment().format('YYYY-MM-DD') : datos_consulta.datos_generales.consulta_fecha = datos_consulta.datos_generales.consulta_fecha;
        datos_consulta.datos_generales.consulta_hora == "" ? datos_consulta.datos_generales.consulta_hora = new Date() : datos_consulta.datos_generales.consulta_hora = datos_consulta.datos_generales.consulta_hora;
        this.DatosGeneralesService.AddConsultaGenerales(datos_consulta.datos_generales)
          .subscribe(result => {
            let consulta_id = result.consulta[0].consulta_id;
            this.ObservableService.changedatosConsulta(consulta_id);
            this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
            this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
            this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
            this.ObservableService.changeConsultaReset({ limpiar: 1 })
            this.deposito = true;
            this.recibo = true;
            this.consulta_pagada = true;
            this.datos_pago.detpg_pagada = 1;
            this.datos_pago.detpg_recibo = 1;
            this.datos_pago.detpg_depositado = 1;
            this.router.navigate(['/home'])
            swal('Muy Bien', 'Se guardo correctamente', 'success')
          })
      }
    } else {
      if (datos_consulta.datos_paciente.paciente_id == 0) {
        datos_consulta.datos_paciente.usuario_id = this.usuario_id;
        this.DatosGeneralesService.AddConsultaPaciente(datos_consulta.datos_paciente)
          .subscribe(result => {
            if (result.valido == 1) {
              this.datos_pago.usuario_id = this.usuario_id;
              let paciente_id = result.paciente.paciente_id;
              this.DatosGeneralesService.AddConsultaPago(this.datos_pago)
                .subscribe(result => {
                  if (result.valido == 1) {
                    let detpg_id = result.detalle_pago[0].detpg_id;
                    datos_consulta.datos_generales.paciente_id = paciente_id
                    datos_consulta.datos_generales.detpg_id = detpg_id
                    datos_consulta.datos_generales.consulta_fecha == "" ? datos_consulta.datos_generales.consulta_fecha = moment().format('YYYY-MM-DD') : datos_consulta.datos_generales.consulta_fecha = datos_consulta.datos_generales.consulta_fecha;
                    datos_consulta.datos_generales.consulta_hora == "" ? datos_consulta.datos_generales.consulta_hora = new Date() : datos_consulta.datos_generales.consulta_hora = datos_consulta.datos_generales.consulta_hora;
                    this.DatosGeneralesService.AddConsultaGenerales(datos_consulta.datos_generales)
                      .subscribe(result => {
                        let consulta_id = result.consulta[0].consulta_id;
                        this.ObservableService.changedatosConsulta(consulta_id);
                        this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                        this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
                        this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
                        this.ObservableService.changeConsultaReset({ limpiar: 1 })
                        this.deposito = true;
                        this.recibo = true;
                        this.consulta_pagada = true;
                        this.datos_pago.detpg_pagada = 1;
                        this.datos_pago.detpg_recibo = 1;
                        this.datos_pago.detpg_depositado = 1;
                        this.router.navigate(['/home'])
                        swal('Muy Bien', 'Se guardo correctamente', 'success')
                      })
                  }
                })
            }
          })
      } else if (datos_consulta.datos_paciente.paciente_id != 0) {
        this.datos_pago.usuario_id = this.usuario_id;
        let paciente_id = datos_consulta.datos_paciente.paciente_id;
        this.DatosGeneralesService.AddConsultaPago(this.datos_pago)
          .subscribe(result => {
            if (result.valido == 1) {
              let detpg_id = result.detalle_pago[0].detpg_id;
              datos_consulta.datos_generales.paciente_id = paciente_id
              datos_consulta.datos_generales.detpg_id = detpg_id
              datos_consulta.datos_generales.consulta_fecha == "" ? datos_consulta.datos_generales.consulta_fecha = moment().format('YYYY-MM-DD') : datos_consulta.datos_generales.consulta_fecha = datos_consulta.datos_generales.consulta_fecha;
              datos_consulta.datos_generales.consulta_hora == "" ? datos_consulta.datos_generales.consulta_hora = new Date() : datos_consulta.datos_generales.consulta_hora = datos_consulta.datos_generales.consulta_hora;
              this.DatosGeneralesService.AddConsultaGenerales(datos_consulta.datos_generales)
                .subscribe(result => {
                  let consulta_id = result.consulta[0].consulta_id;
                  this.ObservableService.changedatosConsulta(consulta_id);
                  this.ObservableService.changeConsultaReset({ limpiar: 1 })
                  this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                  this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
                  this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
                  this.deposito = true;
                  this.recibo = true;
                  this.consulta_pagada = true;
                  this.datos_pago.detpg_pagada = 1;
                  this.datos_pago.detpg_recibo = 1;
                  this.datos_pago.detpg_depositado = 1;
                  this.router.navigate(['/home'])
                  swal('Muy Bien', 'Se guardo correctamente', 'success')
                })
            }
          })
      }
    }
    //this.router.navigate(['/calendario']);
  }

  ActualizarDatosConsulta() {
    this.ConsultaService.updatePaciente(this.datos.datos_paciente)
      .subscribe(result => {
        if (result.valido == 1) {
          this.ConsultaService.updateConsulta(this.datos.datos_generales)
            .subscribe(result => {
              if (result.valido == 1) {
                //validaba si el radio button estaba en no , limpiaba los campos
                // if (this.datos_pago.detpg_pagada == 0) {
                //   this.datos_pago.detpg_cantidad = 0;
                //   this.datos_pago.fpago_id = 0;
                //   this.datos_pago.aseguradora_id = 0;
                //   this.datos_pago.detpg_fechapago = "";
                // }
                // if (this.datos_pago.detpg_depositado == 0) {
                //   this.datos_pago.banco_id = 0;
                //   this.datos_pago.detpg_fechadepo = "";
                // }
                // if (this.datos_pago.detpg_recibo == 0) {
                //   this.datos_pago.detpg_recibonum = "";
                //   this.datos_pago.detpg_fecharecibo = "";
                //   this.datos_pago.detpg_detalle = "";
                // }
                if(this.datos_pago.detpg_cantidad > 0 || (this.datos_pago.detpg_fechapago != '0000-00-00' && this.datos_pago.detpg_fechapago != '') || this.datos_pago.fpago_id != 0){
                  this.datos_pago.detpg_pagada = 1
                } else { this.datos_pago.detpg_pagada = 0; }
                if( (this.datos_pago.detpg_fechadepo != '0000-00-00' && this.datos_pago.detpg_fechadepo != '') || this.datos_pago.banco_id != 0){
                  this.datos_pago.detpg_depositado = 1;
                } else { this.datos_pago.detpg_depositado = 0; }
                if(this.datos_pago.detpg_recibonum.length > 0 || (this.datos_pago.detpg_fecharecibo != '0000-00-00' && this.datos_pago.detpg_fecharecibo != '')){
                  this.datos_pago.detpg_recibo = 1;
                } else { this.datos_pago.detpg_recibo = 0; }


                this.datos_pago.detpg_tipo = 1;
                console.log("ENVIANDOPAGOS",this.datos_pago)
                this.ConsultaService.updatePago(this.datos_pago)
                  .subscribe(result => {
                    if (result.valido == 1) {
                      this.ObservableService.changedatosConsulta({ consulta_id: this.datos.datos_generales.consulta_id, actualizar: true });
                      swal({ title: "Muy Bien! sus datos han sido Actualizados!", text: result.error, type: "success", timer: 2000, showConfirmButton: false });
                      this.datos_pago = new DetallePago(0, 0, 0, 0, '', 0, 0, '', '', '', '', 0, 0, 0, 0);
                      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
                      this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
                      this.router.navigate(['/home'])
                    }
                  })
              }
            })
        }
      })
  }


  //cambio y limpeador de inputs
  ConsutaPagadaButtons() {
   
    if ((this.datos_pago.detpg_fechapago != "0000-00-00" && this.datos_pago.detpg_fechapago != '' && this.datos_pago.detpg_fechapago != null) || this.datos_pago.detpg_cantidad || this.datos_pago.fpago_id != 0 || this.datos_pago.aseguradora_id != 0 ) {
      this.consulta_pagada = true;
    } else {
      this.consulta_pagada = false;
    }
  }

  ConsutaDepositadoButtons() {
  // se selecciona el input radio pagado automatico al detectar campos llenos
    if (this.datos_pago.banco_id != 0  ||(this.datos_pago.detpg_fechadepo != '' && this.datos_pago.detpg_fechadepo != "0000-00-00" && this.datos_pago.detpg_fechadepo != null )) {
      this.deposito = true;
    } else {
      this.deposito = false;
    }
  }

  ConsutaReciboButtons(){
  console.log("datos", this.datos_pago)
  if ( (this.datos_pago.detpg_recibonum != '' && this.datos_pago.detpg_recibonum != null )  || ( this.datos_pago.detpg_fecharecibo != '' && this.datos_pago.detpg_fecharecibo != "0000-00-00" && this.datos_pago.detpg_fecharecibo != null ) ) {
         //|| (this.datos_pago.detpg_detalle != '' && this.datos_pago.detpg_detalle != null)) {
    this.recibo = true;
  } else {
    this.recibo = false;
  }
}

  clearValuePago(){
    this.datos_pago.detpg_fechapago = '';
    this.ConsutaPagadaButtons();
  }

  clearValueDeposito(){
    this.datos_pago.detpg_fechadepo = '';
    this.ConsutaDepositadoButtons();
  }

  clearValueRecibo(){
    this.datos_pago.detpg_fecharecibo = '';
    this.ConsutaReciboButtons();
  }


}
