import { Component, OnInit, OnDestroy } from '@angular/core';
import *  as moment from 'moment';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { CalendarioService } from '../../../services/calendario/calendario.service';
import { ObservableCalendario } from '../../../services/calendario/obserbable-calendario.service';
import { Subscription } from 'rxjs/Subscription';
import { DatosEventoService } from '../../../services/cirugia/datos-evento.service';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { ObservableService } from 'src/app/observable.service';
@Component({
  selector: 'app-calendario-dia',
  templateUrl: './calendario-dia.component.html',
  styleUrls: ['./calendario-dia.component.css']
})
export class CalendarioDiaComponent implements OnInit, OnDestroy {
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public session: any;
  public datos_calendario = new Array();
  public fecha_hoy;
  public mes_numero;
  private unsubscribe_fecha: Subscription = new Subscription();
  public citas_dia = new Array();
  public mostrar: boolean;
  public datos_cirugia: any;
  public paciente_id: number
  public datos_proveedor: any;
  public datos_paciente: any;
  public detalle_pago: any;
  public datos_consulta: any;
  public contador_dias = new Array();
  datos_calendario_button;
  fecha_mostrar;
  mostrar_fecha: boolean;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private CalendarioService: CalendarioService,
    private ObservableCalendario: ObservableCalendario,
    private DatosEventoService: DatosEventoService,
    private ObservableExtraer: ObservableExtraer,
    private ObservableService: ObservableService,
    private router: Router
  ) {
    this.Session()
  }

  ngOnInit() {
    this.contador_dias.push(0);
    const fecha_selected_observable = this.ObservableCalendario.calendarioSelectedMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        //1 es igual a dia, 2 igual  a semana , 3 igual a mes y 4 igual a ño
        this.datos_calendario_button = result.datos_calendario;
        if (result.numero == 1) {
          let fecha_hoy = new Date();
          this.GetCalendarioByUsuarioID(result.datos_calendario, fecha_hoy);
        }
      }
    })
    this.unsubscribe_fecha.add(fecha_selected_observable);
  }

  ngOnDestroy() {
    this.unsubscribe_fecha.unsubscribe();
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.getPerfilUsuario();
    }
  }


  getPerfilUsuario() {
    this.usuario_id = this.datos_session.usuario_id
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];

        }
      })
  }

  GetCalendarioByUsuarioID(datos_calendario, fecha_hoy) {
    this.citas_dia = [];
    this.fecha_hoy = moment().locale('es').format('LL');
    this.mes_numero = moment().month()
    this.datos_calendario = datos_calendario;
    //fechas en numero

    for (let key in this.datos_calendario) {
      //  let mes_consulta = moment(this.datos_calendario[key].cirugia_fecha).month()
      //if (mes_consulta == this.mes_numero) {
      let fecha_string = this.datos_calendario[key].cirugia_fecha.split('-');
      let año = fecha_string[0];
      let mes = fecha_string[1];
      let dia = fecha_string[2];
      let año_inicio = parseInt(año);
      let mes_inicio = parseInt(mes) - 1;
      let dia_inicio = parseInt(dia);
      let fecha_inicio = new Date(año_inicio, mes_inicio, dia_inicio);
      if (fecha_inicio.toDateString() == fecha_hoy.toDateString()) {
        this.citas_dia.push(this.datos_calendario[key])
        // }
      }
    }
  }


  BusquedaCita(resultado_busqueda) {
    if (resultado_busqueda.tipo == 1) {
      this.DatosEventoService.getCirugia(this.token.token, resultado_busqueda.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_cirugia = result.cirugia[0];
            this.paciente_id = this.datos_cirugia.paciente_id;
            this.DatosEventoService.getProveedorAyudantia(this.token.token, resultado_busqueda.cirugia_id)
              .subscribe(result => {
                if (result.valido == 1) {
                  this.datos_proveedor = result.cat_proveedor;
                  this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
                    .subscribe(result => {
                      this.datos_paciente = result.paciente[0];
                      if (result.valido == 1) {
                        let pago_id = this.datos_cirugia.detpg_id;
                        this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                          .subscribe(result => {
                            if (result.valido == 1) {
                              this.detalle_pago = result.detalle_pago[0];
                              this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPagoSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, actualizar: true })
                              this.ObservableExtraer.changedatosAyudantiaSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, datos_proveedor: this.datos_proveedor })
                              this.router.navigate(['/cirugia'])
                              // 1 consulta 2 cirugia
                              this.ObservableService.changeVistaMenu(2)
                            }
                          })
                      }
                    })
                }
              })
          }
        })
    } else if (resultado_busqueda.tipo == 2) {
      //resultado_busqueda.cirugia_id en tipo 2  es id_consutlta
      let id_consulta = resultado_busqueda.cirugia_id;
      this.DatosEventoService.getConsulta(this.token.token, id_consulta)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_consulta = result.consulta[0];
            this.paciente_id = this.datos_consulta.paciente_id;
            this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
              .subscribe(result => {
                this.datos_paciente = result.paciente[0];
                if (result.valido == 1) {
                  let pago_id = this.datos_consulta.detpg_id;
                  this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                    .subscribe(result => {
                      if (result.valido == 1) {
                        this.detalle_pago = result.detalle_pago[0];
                        this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPagoSelected({ datos_consulta: this.datos_consulta, detalle_pago: this.detalle_pago, actualizar: true })
                        this.router.navigate(['/consulta'])
                        // 1 consulta
                        this.ObservableService.changeVistaMenu(1)
                      }
                    })
                }
              })
          }
        })
    }

  }

  changeCalendar(d) {
    this.mostrar_fecha = true;
    //agrega la cantidad de dias
    if (d == 1) {
      this.contador_dias.splice(0, 1, this.contador_dias[0] + 1);

    } else {
      this.contador_dias.splice(0, 1, this.contador_dias[0] - 1);
    }
    //termina la suma de cantidad de dias
    //funcion para mostrar fecha en string en pantalla
    this.fecha_hoy = moment().locale('es').add(this.contador_dias[0], 'days').format('LL');
    this.fecha_mostrar = this.fecha_hoy;
    //termina
    let format_fecha = moment().add(this.contador_dias[0], 'days').format('YYYY-MM-DD');
    let fecha_string = format_fecha.split('-');
    let año = fecha_string[0];
    let mes = fecha_string[1];
    let dia = fecha_string[2];
    let año_inicio = parseInt(año);
    let mes_inicio = parseInt(mes) - 1;
    let dia_inicio = parseInt(dia);
    let fecha_selected = new Date(año_inicio, mes_inicio, dia_inicio);
    this.GetCalendarioByUsuarioID(this.datos_calendario_button, fecha_selected);
  }

}




