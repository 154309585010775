import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2';
import { ObservableService } from '../../../observable.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PacienteModel } from '../../../models/PacienteModel';
import * as moment from 'moment';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { DatosGeneralesService } from '../../../services/consulta/datos-generales/datos-generales.service';
import { Router } from '@angular/router';
import { ConsultaModel } from 'src/app/models/ConsultaModel';
import { ObservableExtraer } from 'src/app/services/extraer/extraer-observable.service';
declare var $: any;
@Component({
  selector: 'app-datos-paciente',
  templateUrl: './datos-paciente.component.html',
  styleUrls: ['./datos-paciente.component.css']
})
export class DatosPacienteComponent implements OnInit, OnDestroy {
  public datos_paciente: PacienteModel;
  datos_actualizar
  enviar_correo: boolean
  public datos_usuario : number;
  public datos_consulta: ConsultaModel;
  private subscription: Subscription = new Subscription();
  private subscription_paciente: Subscription = new Subscription();
  private undubscribe: Subscription = new Subscription();



  mailText: string = "";
  constructor(
    private ObservableService: ObservableService,
    private DatosGeneralesService: DatosGeneralesService,
    private ObservableExtraer: ObservableExtraer,
    private router: Router
  ) {
    const subscription = this.ObservableService.datosConsultaResetMessage.subscribe(result => {
      if (result.limpiar) {
        this.enviar_correo = false;
        this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      }

    })
    const unsubscribe = this.ObservableExtraer.datosSelectedGeneralMessage.subscribe(result => {
      if (result != undefined && result != null && result != "") {
        this.datos_actualizar = true;
        this.datos_consulta = result.datos_consulta;
      }
    })
    this.subscription.add(subscription);
    this.undubscribe.add(unsubscribe);
    this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
  }

  ngOnInit() {
    this.getObservables();
    this.enviar_correo = false;
    this.datos_paciente.enviar_correo = 0;
    this.datos_usuario = JSON.parse(localStorage.getItem('usuario'));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.undubscribe.unsubscribe();
    this.ObservableService.changeDatosCorrectoGeneralConsulta({ limpiar: true });
    this.ObservableService.changeverificaConsulta({ limpiar: true });
    this.ObservableService.changeVerificaConsulta({ limpiar: true });
    this.ObservableService.changedatosConsulta({ limpiar: true });
    this.ObservableService.changedatosPaciente({ limpiar: true });
    this.subscription_paciente.unsubscribe();
    this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
  }
  addNuevaConsulta() {
    
    this.cleanPhone(1);
    this.cleanPhone(2);
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.datos_paciente.paciente_correo != "") {
      if (!emailRegex.test(this.datos_paciente.paciente_correo)) {
        swal("Error", "El correo es Invalido", "error");
      } else {
        this.ObservableService.changeverificaConsulta({ datos_paciente: this.datos_paciente, paciente: 1 })
      }
    } else {
      this.ObservableService.changeverificaConsulta({ datos_paciente: this.datos_paciente, paciente: 1 })
    }
  }
  getObservables() {
    const unsubscribe_Datos_paciente = this.ObservableService.datosPacienteMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos_paciente = result;
        this.datos_paciente.enviar_correo = 0;
        if (this.datos_paciente.fecha_nacimiento != undefined && this.datos_paciente.fecha_nacimiento != null && this.datos_paciente.fecha_nacimiento != "") {
          let fecha_formateada = moment(this.datos_paciente.fecha_nacimiento).format('YYYY-M-DD HH:mm:ss');
          this.datos_paciente.fecha_nacimiento = fecha_formateada;
          let split_fecha_inicio = this.datos_paciente.fecha_nacimiento.split('-');
          let año_i = split_fecha_inicio[0];
          let mes_i = split_fecha_inicio[1];
          let dia_i = split_fecha_inicio[2];
          let año_inicio = parseInt(año_i);
          let mes_inicio = parseInt(mes_i) - 1;
          let dia_inicio = parseInt(dia_i);
          let fecha_inicio_1 = new Date(año_inicio, mes_inicio, dia_inicio);
          this.datos_paciente.fecha_nacimiento = fecha_inicio_1;
        }
      }
    })
    this.subscription_paciente.add(unsubscribe_Datos_paciente);
  }

  DeleteConsulta() {
    swal({
      title: 'Estas seguro?',
      text: "se eliminara tu consulta!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#9b2c18',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    })
      .then((result) => {
        if (result.value) {
          this.datos_consulta.consulta_status = 0
          this.DatosGeneralesService.DeleteConsulta(this.datos_consulta)
            .subscribe(result => {
              this.router.navigate(['/buscar'])
              this.ObservableService.changeVistaMenu(3);
              swal({
                title: 'Muy Bien!',
                text: result.error,
                type: "success",
                timer: 2500,
                showConfirmButton: false
              });

            })
        } else {
          swal("No se ha eliminado!");
        }
      });

  }

  correoEnviar(enviar) {
    //1 enviar 0 no enviar
    if (enviar == 1) {
      this.enviar_correo = true;
      this.datos_paciente.enviar_correo = 1;
      this.abrirCorreo()
    } else if (enviar == 0) {
      this.enviar_correo = false;
      this.datos_paciente.enviar_correo = 0;
    }
  }



  abrirCorreo() {
    this.mailText = "mailto:" + this.datos_paciente.paciente_correo + "?subject=Notificación de Consulta&body=";
    window.location.href = this.mailText;
  }

  cleanPhone(type){
    if(type == 1){
      this.datos_paciente.paciente_tel = this.datos_paciente.paciente_tel.replace(/\D+/g,'');
    }
    if(type == 2){
      this.datos_paciente.paciente_cel = this.datos_paciente.paciente_cel.replace(/\D+/g,'');
    }
  }
}
