export class DetallePago{
    constructor(
        public detpg_id : number,
        public detpg_tipo : number,
        public detpg_pagada : number,
        public detpg_cantidad : number,
        public detpg_fechapago : string,
        public fpago_id : number,
        public detpg_depositado : number,
        public detpg_fechadepo : string,
        public detpg_recibonum : string,
        public detpg_fecharecibo : string,
        public detpg_detalle : string,
        public usuario_id : number,
        public aseguradora_id : number,
        public banco_id : number,
        public detpg_recibo : number

    ){

    }
}