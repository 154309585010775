<!-- <button *ngIf="datos_actualizar" (click)="DeleteCirugia()" type="button"
  class="btn btn-form-conf-verde btn-lg btn-block"><span>
    <img class="" src="../../../assets/images/icon-trash-transparent.png" width="14px">
    &nbsp; Eliminar Cirugia </span></button> -->

<div class="fondo-titulo-campos">
  <h6 class="titulo-campos">DATOS DEL EVENTO</h6>
</div>
<form>

	<div class="row">
    <div class="col-md-6">
      <div class="d-flex">
        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-paciente.png" width="auto" height="24px"></div>
        <div class="p-1 w-100"><p class="consulta">¿Nuevo Paciente?</p></div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-inline">
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" [disabled]="modo_actualizar" (change)="ExisteUsuario(usuario_nuevo)" #name="ngModel" [(ngModel)]="usuario_nuevo" [value]="true"
            name="radios1" id="radios1">
            <label class="custom-control-label consulta p-1"  for="radios1">Si</label>
          </div>
        </li>
        <li class="list-inline-item">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" [disabled]="modo_actualizar" (change)="ExisteUsuario(usuario_nuevo)" [value]="false" [(ngModel)]="usuario_nuevo" 
            name="radios1" id="radios2">  
            <label class="custom-control-label consulta p-1" for="radios2">No</label>
          </div>
        </li>
      </ul>
    </div>
    </div>

  <div class="form-row">
    <div *ngIf="usuario_nuevo" class="form-group col-md-12">
      <input type="nombrepaciente" #name="ngModel" name="nom_paciente" [(ngModel)]="datos_paciente.paciente_nombre" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="nombre_paciente" placeholder="Nombre Del Paciente (Único campo obligatorio)">
    </div>
    <div *ngIf="!usuario_nuevo" style="color: white" class="form-group col-md-12">
      <ng-select *ngIf="!modo_actualizar" name="paciente" id="pacienteid" placeholder="Seleccione un Usuario" id="selecciona_usuario"
        #cboUsuario [items]="pacientes" bindLabel="paciente_nombre" bindValue="paciente_id" class="custom" (change)="SelectedUsuario(cboUsuario.itemsList._selectionModel._selected);"
        [(ngModel)]="usuario_selected">
        <ng-template ng-option-tmp let-item="item">
          {{item.paciente_nombre}}--{{item.paciente_correo}}
        </ng-template>
      </ng-select>
      <input *ngIf="modo_actualizar" type="nombrepaciente" #name="ngModel" name="nom_paciente" [(ngModel)]="datos_paciente.paciente_nombre"
        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="nombre_paciente" placeholder="Nombre Del Paciente">
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="form-group col-4 ">
      <!-- <div class="dropdown-content">
        <a *ngFor="let edad of edad_paciente"
          class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista dropdown pointer flecha_s">{{edad.edad}}</a>
      </div>
      <div class="dropdown">
        <button style="text-align: left;padding-left: 15px;" *ngIf="datos_paciente.paciente_edad == 0"
          (click)="DesplegarDropDown()" class="dropbtn flecha_s"><label for="edad"
            class="label-edad">Edad</label></button>
        <button style="text-align: left;padding-left: 15px;" *ngIf="datos_paciente.paciente_edad != 0"
          (click)="DesplegarDropDown()" class="dropbtn flecha_s"><label
            for="edad">{{datos_paciente.paciente_edad}}</label></button>
        <div *ngIf="usuarionuevo == false" id="myDropdown" class="dropdown-content">
          <a *ngFor="let edad of edad_paciente" (click)="EdadPaciente(edad)" class="pointer">{{edad.edad}}</a>
        </div>
        <div *ngIf="usuarionuevo" id="myDropdown" class="dropdown-content">
          <a *ngFor="let edad of edad_paciente" #name="ngModel" [(ngModel)]="datos_paciente.paciente_edad"
            name="edad_paciente_model" (click)="EdadPaciente(edad)" class="pointer" ngDefaultControl>{{edad.edad}}</a>
        </div>
      </div> -->
      <input OnlyNumber="true" (input)="numeroEdad()" style="margin-top: 4%;" type="text" #name="ngModel" name="edad" maxlength="3"
        [(ngModel)]="datos_paciente.paciente_edad" class=" form-control letraCuadrosLista letraCuadrosFormulario " placeholder="Edad">
    </div>
    <div class="form-group col-4">
      <input id="datepickerfc" name="cirugia_f" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_fecha" style="text-align: left;margin-top: 3px; font-size: 12px; font-weight: 300;"
        class="read-on form-control letraCuadrosFormulario flecha_s" placeholder="Fecha" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
        readonly>

      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
    </div>
    <div class="form-group col-4">
      <input name="hora" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_hora" type="text" placeholder="Hora" style="font-size: 12px; font-weight: 300;"
        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista flecha_s" [owlDateTimeTrigger]="dt2"
        [owlDateTime]="dt2" value="Hora">
      <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-8">
      <select name="hospital" #name="ngModel" [(ngModel)]="datos_cirugia.hospital_id" class="  btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="hospt">
        <option [value]="0"> Hospital/Consultorio / Agregar en Configuración</option>
        <option *ngFor="let hospital of cat_hospital " [value]="hospital.hospital_id">{{hospital.hospital_desc}}
        </option>
      </select>
    </div>
    <div class="form-group col-md-4">
      <input type="text" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_habitacion" style="font-size: 12px; font-weight: 300;"
        placeholder="Habitación" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" name="no_consultorio"
        id="no_consultorio">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-4">

      <input type="text" name="duracion" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_duracion" placeholder="Duración" style="font-size: 12px; font-weight: 300;"
        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista flecha_b" [owlDateTimeTrigger]="dt3"
        [owlDateTime]="dt3">
      <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
    </div>
    <div class="form-group col-md-8">

      <select name="anestesia" style="margin-top: 3px;" #name="ngModel" [(ngModel)]="datos_cirugia.anestesia_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="anestesiaid">
        <option [value]="0">Seleccione Tipo de Anestesia / Agregar en Configuración</option>
        <option *ngFor="let anestesia of cat_anestesia " [value]="anestesia.anestesia_id">{{anestesia.anestesia_desc}}
        </option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <select name="procedimiento" #name="ngModel" [(ngModel)]="datos_cirugia.proce_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="procedimiento">
        <option [value]="0">Procedimiento / Agregar en Configuración</option>
        <option *ngFor="let procedimiento of cat_proce " [value]="procedimiento.proce_id">{{procedimiento.proce_desc}}
        </option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <select name="cirujanos" #name="ngModel" [(ngModel)]="datos_cirugia.cirujano_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="cirujano">
        <option [value]="0">Cirujano / Agregar en Configuración </option>
        <option *ngFor="let cirujano of cat_cirujanos " [value]="cirujano.personal_id">{{cirujano.persona_desc}}
        </option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <select name="anestesiologo" #name="ngModel" [(ngModel)]="datos_cirugia.anestesiologo_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
        id="anestesiologoid">
        <option [value]="0">Anestesiólogo / Agregar en Configuración </option>
        <option *ngFor="let anestesiologo of cat_anestesiologo " [value]="anestesiologo.personal_id">
          {{anestesiologo.persona_desc}}</option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <textarea class="form-control letraCuadrosFormulario"  name="datosclinicos" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_datosclinicos == 'Datos clínicos' ? '' : datos_cirugia.cirugia_datosclinicos"
        id="notasTextarea" rows="3" placeholder="Datos Clínicos"></textarea>
    </div>
  </div>
  <div class="row offset-1">
    <div class="col-4">
      <input #imagenEvento (change)="SubirImagenEvento($event);" type="file" name="file1" id="file1" class="inputfile">
      <label>
        <div class="pg-empty-placeholder">
          <img *ngIf="url_imagen_evento" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;"
            [src]="url_imagen_evento">
          <img style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;" *ngIf="imagen_evento_principal"
            src="{{url_imagen+imagen_evento_principal.imagen_url}}">
        </div>
      </label>
      <label class="imagen-add" *ngIf="file_imagen_evento" for="1">Se ha Agregado un archivo</label>
    </div>
    <div class="col-4">
      <input #imagenEventoSecundaria (change)="SubirImagenEventoSeguncaria($event);" type="file" name="file2" id="file2" class="inputfile">
      <label>
        <div class="pg-empty-placeholder">
          <img *ngIf="url_imagen_evento_secundaria" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;"
            [src]="url_imagen_evento_secundaria">
          <img *ngIf="imagen_evento_secundario" src="{{url_imagen+imagen_evento_secundario.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
        </div>
      </label>
      <label class="imagen-add" *ngIf="file_imagen_evento_secundaria" for="1">Se ha Agregado un archivo</label>
    </div>
    <div class="col-1"></div>
    <div class="col-4">
      <!--imagenEvento-->
      <img data-toggle="modal" *ngIf="url_imagen_evento || imagen_evento_principal " (click)="verImagenEvento(1)" data-target="#imagenEventoModal"
        src="../../../assets/images/box-image.png" width="auto" height="80px">
    </div>

    <div class="col-3">
      <img data-toggle="modal" *ngIf=" url_imagen_evento_secundaria || imagen_evento_secundario " (click)="verImagenEvento(2)"
        data-target="#imagenEventoSecundariaModal" src="../../../assets/images/box-image.png" width="auto" height="80px">
    </div>
  </div>
</form>


<!--Modal dato evento imagenEventoModal -->
<div class="modal fade pg-show-modal" id="imagenEventoModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
  data-pg-name="Colaboradores">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="display: unset!important;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title text-center" class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 1</h4>
      </div>
      <div class="modal-body">
        <img class="mx-auto d-block" *ngIf="url_imagen_evento" [src]="url_imagen_evento" width="100%">
      </div>
      <div *ngIf="datos_actualizar && !url_imagen_evento " class="modal-body">
        <img class="mx-auto d-block" *ngIf="imagen_evento_principal" src="{{url_imagen+imagen_evento_principal.imagen_url}}" width="100%">
      </div>
      <div class="modal-footer">
        <button type="button" (click)="EliminarImagenEvento()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal dato evento imagenEventoSecundaria -->
<div class="modal fade pg-show-modal" id="imagenEventoSecundariaModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
  data-pg-name="Colaboradores">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="display: unset!important;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title text-center" class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 2</h4>
      </div>
      <div class="modal-body">
        <img *ngIf="url_imagen_evento_secundaria" class="mx-auto d-block" [src]="url_imagen_evento_secundaria" width="100%">
      </div>
      <div *ngIf="datos_actualizar && !url_imagen_evento_secundaria" class="modal-body">
        <img *ngIf="imagen_evento_secundario" class="mx-auto d-block" src="{{url_imagen+imagen_evento_secundario.imagen_url}}" width="100%">
      </div>
      <div class="modal-footer">
        <button type="button" (click)="EliminarImagenEventoSecundaria()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
      </div>
    </div>
  </div>
</div>