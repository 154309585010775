<!-- Div para mantener el fondo en las dos secciones -->
<div *ngIf="mostrar_cirugia" class="container-fluid containerfondo">
  <!-- Titulos -->
  <section>
    <div class="configura">
      <div class="row pt-4">
        <div class="col">
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
      </div>
      <div class="container">
        <div class="row pt-4">
          <!-- Primera columna -->
          <div class="col-md-4">
            <app-datos-evento></app-datos-evento>
          </div>
          <!-- Segunda columna -->
          <div class="col-md-4">
            <app-detalles-pago-cirugia></app-detalles-pago-cirugia>
          </div>
          <!-- Tercera columna -->
          <div class="col-md-4">
            <app-datos-paciente-cirugia></app-datos-paciente-cirugia>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Cierra el fondo -->
</div>
<div>

</div>