import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
//Importacion de Vista
import { IndexComponent } from './components/index/index.component';
import { ObservableService } from './observable.service';
import { ObservableCirugia } from './services/cirugia/observable-cirugia.service';
import { ObservableBusqueda } from './services/buscar/buscar-obserbable.service';
import { ObservableExtraer } from './services/extraer/extraer-observable.service';
import { ObservableCalendario } from './services/calendario/obserbable-calendario.service';
//

//
import { DateTimeAdapter } from 'ng-pick-datetime';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import 'flatpickr/dist/flatpickr.css'; // you may need to adjust the css import depending on your build tool
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http'
import { routing, appRoutingProviders, appRoutes } from './app.routing';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OwlDateTimeIntl,OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MonthCalendarModule } from 'simple-angular-calendar';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { ConsultaComponent } from './components/consulta/consulta.component';
import { HeaderComponent } from './components/header/header.component';
import { CirugiaComponent } from './components/cirugia/cirugia.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { PendientesComponent } from './components/pendientes/pendientes.component';
import { EducacionComponent } from './components/educacion/educacion.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { EstadisticasComponent } from './components/estadisticas/estadisticas.component';
import { DatosGeneralesComponent } from './components/consulta/datos-generales/datos-generales.component';
import { DetallePagoComponent } from './components/consulta/detalle-pago/detalle-pago.component';
import { DatosPacienteComponent } from './components/consulta/datos-paciente/datos-paciente.component';
import { DatosEventoComponent } from './components/cirugia/datos-evento/datos-evento.component';
import { DetallesPagoCirugiaComponent } from './components/cirugia/detalles-pago-cirugia/detalles-pago-cirugia.component';
import { DatosPacienteCirugiaComponent } from './components/cirugia/datos-paciente-cirugia/datos-paciente-cirugia.component';
import { AyudantiaProveedoresComponent } from './components/cirugia/ayudantia-proveedores/ayudantia-proveedores.component';
import { DetallePagoBuscarComponent } from './components/buscar/detalle-pago-buscar/detalle-pago-buscar.component';
import { ExtraerArchivosBuscarComponent } from './components/buscar/extraer-archivos-buscar/extraer-archivos-buscar.component';
import { AyudantiaProveedoresBuscarComponent } from './components/buscar/ayudantia-proveedores-buscar/ayudantia-proveedores-buscar.component';
import { DatosGeneralesBuscarComponent } from './components/buscar/datos-generales-buscar/datos-generales-buscar.component';
import { CalendarioDiaComponent } from './components/calendario/calendario-dia/calendario-dia.component';
import { CalendarioSemanaComponent } from './components/calendario/calendario-semana/calendario-semana.component';
import { CalendarioAnioComponent } from './components/calendario/calendario-anio/calendario-anio.component';
import { OnlyNumber } from './directives/numberininputtext.directive';
import { ConfirmarPw } from './components/confirmar-pw/confirmar-pw';
import { BusquedaGeneralComponent } from './components/busqueda-general/busqueda-general.component';

//

// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl  {
  /** A label for the cancel button */
  cancelBtnLabel= 'Cancelar'

  /** A label for the set button */
  setBtnLabel= 'Aceptar'
};

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HomeComponent,
    ConsultaComponent,
    HeaderComponent,
    CirugiaComponent,
    BuscarComponent,
    CalendarioComponent,
    PendientesComponent,
    EducacionComponent,
    ConfiguracionComponent,
    EstadisticasComponent,
    DatosGeneralesComponent,
    DetallePagoComponent,
    DatosPacienteComponent,
    DatosEventoComponent,
    DetallesPagoCirugiaComponent,
    DatosPacienteCirugiaComponent,
    AyudantiaProveedoresComponent,
    DetallePagoBuscarComponent,
    ExtraerArchivosBuscarComponent,
    AyudantiaProveedoresBuscarComponent,
    DatosGeneralesBuscarComponent,
    CalendarioDiaComponent,
    CalendarioSemanaComponent,
    CalendarioAnioComponent,
    OnlyNumber,
    ConfirmarPw,
    BusquedaGeneralComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpModule,
    FormsModule,
    routing,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    DataTablesModule,
    MonthCalendarModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbAlertModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: "es" },ObservableCirugia, ObservableService, ObservableBusqueda, ObservableExtraer, ObservableCalendario, appRoutingProviders,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
