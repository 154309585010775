<!-- <button *ngIf="datos_actualizar" (click)="DeleteConsulta()" type="button" class="btn btn-form-conf-verde btn-block btn-md">Eliminar
    Consulta
    <span style="position: absolute;right: 238px">
        &nbsp;  <img src="../../../assets/images/icon-trash-transparent.png" width="14px">
    </span>
  </button> -->

<div class="fondo-titulo-campos">
  <h4 class="titulo-campos">DATOS GENERALES</h4>
</div>
<div class="row">
<div class="col-md-6">
  <div class="d-flex">
    <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-paciente.png" width="auto" height="24px"></div>
    <div class="p-1 w-100"><p class="consulta">¿Nuevo Paciente?</p></div>
  </div>
</div>
<div class="col-md-6 text-right">
  <ul class="list-inline">
    <li class="list-inline-item">
      <div class="custom-control custom-radio">
        <input type="radio" name="radios1" id="radios1" class="custom-control-input" [disabled]="datos_actualizar" (change)="ExisteUsuario(default_existe_usuario)" [(ngModel)]="default_existe_usuario"
        [value]="true">
        <label class="custom-control-label consulta p-1" for="radios1">Si</label>
      </div>
    </li>
    <li class="list-inline-item">
      <div class="custom-control custom-radio">
        <input type="radio" name="radios2" id="radios2" class="custom-control-input" [disabled]="datos_actualizar" (change)="ExisteUsuario(default_existe_usuario)" [(ngModel)]="default_existe_usuario"
        [value]="false">
        <label class="custom-control-label consulta p-1" for="radios2">No</label>
      </div>
    </li>
  </ul>
</div>
</div>


<div class="form-row">
  <div *ngIf="!default_existe_usuario" class="form-group col-md-12">
    <input *ngIf="datos_actualizar" type="text" id="nombre" #ame="ngModel" [(ngModel)]="datos_paciente.paciente_nombre" name="nombre_p"
      placeholder="Nombre del Paciente " class="btn btn-block   letraCuadrosLista" type="text">
    <ng-select *ngIf="!datos_actualizar" id="selecciona_usuario" #cboUsuario [items]="pacientes" placeholder="Seleccione un Usuario"
      bindLabel="paciente_nombre" bindValue="paciente_id" class="custom" [(ngModel)]="usuario_selected" (change)="SelectedUsuario(cboUsuario.itemsList._selectionModel._selected);">
      <ng-template ng-option-tmp let-item="item">
        {{item.paciente_nombre}}--{{item.paciente_correo}}
      </ng-template>
    </ng-select>
  </div>
</div>
<div *ngIf="default_existe_usuario" class="form-row">
  <div class="form-group col-md-12">
    <input type="text" id="nombre" #ame="ngModel" [(ngModel)]="datos_paciente.paciente_nombre" name="nombre_p" placeholder="Nombre del Paciente (Único campo obligatorio) "
      class="btn btn-block   letraCuadrosLista">
  </div>
</div>
<div class="form-row align-items-center">
  <div class="form-group col-4 ">
    <!-- <div class="dropdown-content">
      <a *ngFor="let edad of edad_paciente" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista dropdown pointer flecha_s">{{edad.edad}}</a>
    </div> -->
    <!-- <div class="dropdown">
      <button style="text-align: left;padding-left: 15px;" *ngIf="datos_paciente.paciente_edad == 0" (click)="myFunction()" class="dropbtn flecha_s">
        <label class="label-center " for="edad">Edad</label>
      </button>
      <button style="text-align: left;padding-left: 15px;" *ngIf="datos_paciente.paciente_edad != 0" (click)="myFunction()" class="dropbtn flecha_s">
        <label for="edad">{{datos_paciente.paciente_edad}}</label>
      </button>
      <div *ngIf="usuarionuevo == false" id="myDropdown" class="dropdown-content">
        <a *ngFor="let edad of edad_paciente" (click)="EdadPaciente(edad)" class="pointer">{{edad.edad}}</a>
      </div>
      <div *ngIf="usuarionuevo" id="myDropdown" class="dropdown-content">
        <a *ngFor="let edad of edad_paciente" #name="ngModel" [(ngModel)]="datos_paciente.paciente_edad" name="edad_paciente_model"
          (click)="EdadPaciente(edad)" class="pointer" ngDefaultControl>{{edad.edad}}</a>
      </div>
    </div> -->
    <input OnlyNumber="true" (input)="numeroEdad()" style="margin-top: 4%;" type="text" #name="ngModel" name="edad" maxlength="3"
      [(ngModel)]="datos_paciente.paciente_edad" class="btn btn-block   letraCuadrosLista letraCuadrosFormulario " placeholder="Edad">
  </div>
  <div class="form-group col-4">

    <input #name="ngModel" name="fecha_nacimiento" [(ngModel)]="datos_consulta.consulta_fecha" id="datepicker1" style="font-size: 12px; font-weight: 300;"
      class="top-input read-on form-control letraCuadrosLista letraCuadrosFormulario flecha_m label-center " placeholder="Fecha"
      [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" readonly>


    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
  </div>
  <div class="form-group col-4">

    <!-- Requiere el script al final de la página-->
    <input type="text" name="hora" #name="ngModel" [(ngModel)]="datos_consulta.consulta_hora" placeholder="Hora" style="font-size: 12px; font-weight: 300;"
      class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista flecha_s" [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6"
      value="Hora" readonly>
    <owl-date-time [pickerType]="'timer'" #dt6></owl-date-time>
  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-12">

    <input type="text" name="duracion" #name="ngModel" [(ngModel)]="datos_consulta.constulata_duracion" placeholder="Duración"
      style="font-size: 12px; font-weight: 300;" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista flecha_b"
      [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly>
    <owl-date-time [pickerType]="'timer'" #dt7></owl-date-time>
    <!-- Requiere el script al final de la página-->

  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-12">
    <select name="hospital" #name="ngModel" [(ngModel)]="datos_consulta.hospital_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
      name="hospital" id="hospt">
      <option class="label-center " [value]="0">Hospital/Consultorio / Agregar en Configuración </option>
      <option *ngFor="let hospital of cat_hospital " [value]="hospital.hospital_id">{{hospital.hospital_desc}}</option>
    </select>
    <!-- Requiere el script al final de la página-->

  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-12">
    <textarea name="datos_clinicos" #name="ngModel" [(ngModel)]="datos_consulta.consulta_datosclin" class="form-control letraCuadrosFormulario"
      id="notasTextarea" rows="3" placeholder="Datos Clínicos"></textarea>
  </div>
</div>

<!--modal envia info-->
<div class="modal fade bd-example-modal-sm modal-top" id="EmailEnviar" tabindex="-1" role="dialog" aria-labelledby="EmailEnviarLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content" style="color:#3C615F">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold" id="EmailEnviarLabel" style="font-family: Open Sans; color:#3C615F; margin-left: 51px;">Enviar correo</h5>
      </div>
      <div class="modal-body font-weight-bold" style="color:#3C615F">
        <div *ngFor="let correo of correo_array" class="custom-control custom-checkbox centercheck">
          <input (change)="enviarEmail($event.target.value)" [checked]="correo.status" [value]="correo.id_categoria" name="envio_correo"
            type="checkbox" class="custom-control-input" id="correo_{{correo.id_categoria}}">
          <label class="custom-control-label" for="correo_{{correo.id_categoria}}">{{correo.descripcion}}</label>
        </div>
      </div>
      <div class="modal-footer" style="justify-content: center">
        <button type="button" (click)="guardarConfEmail()" class="btn btn-form font-weight-bold">Aceptar</button>
      </div>
    </div>
  </div>
</div>