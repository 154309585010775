import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class AyudantiaProveedoresService {

  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }


  public getAyudantia(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public BuscarPagoAyudantia(datos){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'cirugia/busqueda-datos-proveedor',params, { headers: headers })
      .map(res => res.json());
  }
  public getAyudantiaByID(cirugia, token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + cirugia, { headers: headers })
      .map(res => res.json());
  }

  public UpdateAyudantia(datos){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'proveedor/update',params, { headers: headers })
      .map(res => res.json());
  }

  public eliminarAyudantia(datos){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'proveedor/delete',params, { headers: headers })
      .map(res => res.json());
  }
  public getProveedorByID(usuario, token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'proveedor/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public AddProveedor(datos,cirugia){
    datos.cirugia_id = cirugia;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'proveedor/add',params, { headers: headers })
      .map(res => res.json());
  }

  
}


