/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/header/header.component.ngfactory";
import * as i3 from "./components/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "./observable.service";
import * as i6 from "./services/consulta/datos-generales/datos-generales.service";
import * as i7 from "./services/buscar/buscar-obserbable.service";
import * as i8 from "./services/cirugia/observable-cirugia.service";
import * as i9 from "./services/extraer/extraer-observable.service";
import * as i10 from "@angular/common";
import * as i11 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.HeaderComponent, [i4.Router, i5.ObservableService, i6.DatosGeneralesService, i7.ObservableBusqueda, i8.ObservableCirugia, i9.ObservableExtraer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.href.url != "/"); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i11.AppComponent, [i4.Router], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
