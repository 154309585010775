export function CarruselEducacion(){
    if ($('.educacion_carrusel').hasClass('slick-initialized')) {
         $('.educacion_carrusel').slick('destroy');
     } 
     $(document).ready(function(){

         
         $('.educacion_carrusel').slick({
             dots: true,
             slidesToShow: 1,
             slidesToScroll: 1,
             autoplay: true,
             autoplaySpeed: 7000,
             dotsClass: 'slick-dots',
             swipe: true,
             variableWidth: true,
             heigth : '100px', 
             width : '100px'
         });
      
     });
   
   }
  

export function intervalCarruselEducacion (){
   //var  myVar = setTimeout(Carruseleducacion_carrusel, 2000);

   CarruselEducacion();
}