
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers'

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
   }

  public GetCalendarioByUsuarioID(token,usuario_id){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cirugia/get-calendario/'+usuario_id, { headers: headers })
      .map(res => res.json());
  }


}
