import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
@Injectable({
  providedIn: 'root'
})
export class DatosEventoService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }
  public getAnestesia(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_anestesia/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public getProcedimiento(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json())
  }

  public getPersonal(token, usuario, tipo) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_personal/getbyusuarioid/' + usuario + '/' + tipo, { headers: headers })
      .map(res => res.json())
  }


  public getProveedor(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'proveedor/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json())
  }
  public AddImagenCirugia(url_liga: string, params: Array<string>, file: File, tipo: number) {
    let _url = this.url;
    return new Promise(function (resolve, reject) {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      if (file) {
        if (tipo == 1) {
          formData.append('image', file, file.name);
        } else if (tipo == 2) {
          formData.append('pdf', file, file.name);
        }

      }
      for (let key of params) {
        let tKey: any = key;
        for (const i in tKey) {
          formData.append(i, key[i]);
        }
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open('POST', _url + url_liga, true);
      xhr.send(formData);
    })
  }

  public getCirugia(token, cirugia) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cirugia/getbyid/' + cirugia, { headers: headers })
      .map(res => res.json());
  }

  public getPaciente(token, paciente) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'paciente/getbyid/' + paciente, { headers: headers })
      .map(res => res.json());
  }

  public getDetallePago(token, pago) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'detalle_pago/getbyid/' + pago, { headers: headers })
      .map(res => res.json());
  }
  public getProveedorAyudantia(token, pago) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'proveedor/getbyusuarioid/' + pago, { headers: headers })
      .map(res => res.json());
  }

  public getConsulta(token, consulta) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'consulta/getbyid/' + consulta, { headers: headers })
      .map(res => res.json());
  }

  // public getProcedimiento(token, usuario){
  //   let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
  //   return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers : headers })
  //   .map(res => res.json())
  // }
  public DeleteCirugia(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'cirugia/delete', params, { headers: headers })
      .map(res => res.json());
  }

}
