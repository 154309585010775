import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export class ObservableService {

    private datosPacienteIDSource = new BehaviorSubject<any>("");
    public datosPacienteMessage = this.datosPacienteIDSource.asObservable();


    private esCorrectoGeneralesConsultaIDSource = new BehaviorSubject<any>("");
    public esCorrectoGeneralesConsultaMessage = this.esCorrectoGeneralesConsultaIDSource.asObservable();


    private VerificaConsultaIDSource = new BehaviorSubject<any>("");
    public VerificaConsultaMessage = this.VerificaConsultaIDSource.asObservable();


    private datosGeneralesConsultaIDSource = new BehaviorSubject<any>("");
    public datosGeneralesConsultaMessage = this.datosGeneralesConsultaIDSource.asObservable();
    // consulta resultado de id 
    private datosConsultaIDSource = new BehaviorSubject<any>("");
    public datosConsultaMessage = this.datosConsultaIDSource.asObservable();

    //Resetea todos los formularios
    private datosConsultaResetIDSource = new BehaviorSubject<any>("");
    public datosConsultaResetMessage = this.datosConsultaResetIDSource.asObservable();

    //change menu
    private vistaMenuIDSource = new BehaviorSubject<any>("");
    public vistaMenuMessage =  this.vistaMenuIDSource.asObservable();

    //cambio de pw por url
    private PasswordIDSource =  new BehaviorSubject<any>(""); 
    public PasswordResetMessage = this.PasswordIDSource.asObservable();
 

    public changedatosPaciente(datos) {
        if(datos && datos.limpiar == undefined){
        this.datosPacienteIDSource.next(datos);
    }
        if(datos.limpiar == true){
            this.datosPacienteIDSource.next(null);
        }
    }

    public changeDatosCorrectoGeneralConsulta(datos) {
        if(datos && datos.limpiar == undefined){
            this.esCorrectoGeneralesConsultaIDSource.next(datos);
        }
        if(datos.limpiar == true){
            this.esCorrectoGeneralesConsultaIDSource.next(null);
        }
    }

    public changeverificaConsulta(datos){
        if(datos && datos.limpiar == undefined){
            this.VerificaConsultaIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true){
            this.VerificaConsultaIDSource.next(null);
        }
        
    }

    public changeVerificaConsulta(datos){
        if(datos && datos.limpiar == undefined){
            this.datosGeneralesConsultaIDSource.next(datos);
        }
        if(datos.limpiar != undefined && datos.limpiar == true){
            this.datosGeneralesConsultaIDSource.next(null);
        }
    }
    // consulta resultado de id 
    public changedatosConsulta(datos){
        if(datos && datos.limpiar == undefined){
            this.datosConsultaIDSource.next(datos);
        }

        if(datos.limpiar != undefined && datos.limpiar == true){
            this.datosConsultaIDSource.next(null);   
        }
        
    }
    // Resetea todos los formularios
    public changeConsultaReset(datos){
        this.datosConsultaResetIDSource.next(datos);
    }


    //Cambio de vista
    public changeVistaMenu(datos){
        this.vistaMenuIDSource.next(datos);
    }

    //para cambiar contraseña desde url

    public changePw(datos){
        this.PasswordIDSource.next(datos);
    }

}

