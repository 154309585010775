import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConfiguracionService } from './configuracion.service';
import { UsuarioModel } from '../../models/UsuarioModel';
import swal from 'sweetalert2';
import { IndexService } from '../index/index.service';
import { GLOBAL } from '../../helpers';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CatAnestesiaModel } from '../../models/CatAnestesiaModel';
import { CatHospitalModel } from '../../models/CatHospitalModel';
import { CatAseguradora } from '../../models/CatAseguradora';
import { CatAyudantiaModel } from '../../models/CatAyudantiaModel';
import { CatPersonalModel } from '../../models/CatPersonalModel';
import { CatProceModel } from '../../models/CatProceModel';
import { CatfPagoModel } from '../../models/CatfPagoModel';
import { CatBancoModel } from '../../models/CatBancoModel';
import { isMoment } from 'moment';
import * as moment from 'moment';
import { initTableColaborador, clearSearch } from '../../../assets/js/tablas/colaborador_tabla';
import { initTablePersonal } from '../../../assets/js/tablas/personal_tabla';
import { initTableAyudantia } from '../../../assets/js/tablas/ayudantia_tabla';
import { initTableHospital } from '../../../assets/js/tablas/hospital_tabla';
import { initTableAseguradora } from '../../../assets/js/tablas/aseguradora_tabla';
import { initTableAnestesia } from '../../../assets/js/tablas/anestesia_tabla'
import { initTableProcedimiento } from '../../../assets/js/tablas/procedimiento_tabla';
import { initTablePago } from '../../../assets/js/tablas/pago_tabla';
import { initTableBanco } from '../../../assets/js/tablas/banco_tabla';
import { initTablePerfil } from '../../../assets/js/tablas/perfil_tabla';
declare var $: any;
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptionAnestesia: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public session;
  public datos_session;
  public usuario_id;
  public token;
  public datos_usuario: UsuarioModel;
  public datos_anestesia: CatAnestesiaModel;
  public datos_hospitales: CatHospitalModel;
  public datos_aseguradora: CatAseguradora;
  public datos_ayudantia: CatAyudantiaModel;
  public datos_personal: CatPersonalModel;
  public datos_procedimiento: CatProceModel;
  public datos_forma_pago: CatfPagoModel;
  public datos_banco: CatBancoModel;
  public datos_colaborador: UsuarioModel;
  public especialidades;
  public perfil_file;
  public url_perfil;
  public url_imagen;
  public columnas_anestesiologos;
  public anestesiologos;
  public hospitales;
  public aseguradoras;
  public ayudantias;
  public cat_personal;
  public cat_proce;
  public cat_fpago;
  public cat_banco;
  public permisos_asignados;
  public verificar_colaborador: boolean;
  public colaboradores;
  public columnas_colaborador;
  public columnas_personal;
  public mostrar_boton_actualizar: boolean;
  public mostrar_actualizar_personal: boolean;
  public columnas_ayudantia;
  public mostrar_actualizar_ayudantia: boolean;
  public mostrar_actualizar_hospital: boolean;
  public columnas_hospital;
  public columnas_aseguradora;
  public mostrar_actualizar_aseguradora: boolean;
  public mostrar_anestesia: boolean;
  public columnas_anestesia;
  public mostrar_actualizar_procedimiento: boolean;
  public columnas_procedimiento;
  public mostrar_actualizar_pago: boolean;
  public columnas_pago;
  public mostrar_actualizar_banco: boolean;
  public columnas_banco;
  public papp_desc: string;
  public actualizar_perfil: boolean;
  public mostrar_configuracion_perfil: boolean;
  public mostrar_configuracion_campos: boolean;
  public mostrar_configuracion_colaborador: boolean;
  public perfil_menu;
  //menos 96 meses es decir la  fecha maxima es de 18 años permitidos
  public max_fecha_nacimiento;
  /******************Confuguracion de Perfil********* */
  public perfiles_selected = new Array();
  public menu_app;
  public columnas_perfil;
  public perfiles_cargados = new Array();
  public datos_papp_id: number;
  public datos_usuario_pw;
  public datos_info;
  public desactivar_perfiles;
  acceso_pagos
  public rt_tabla_colaboradores;

  constructor(private router: Router,
    private ConfiguracionService: ConfiguracionService,
    private IndexService: IndexService

  ) {
    this.desactivar_perfiles = false;
    this.verificar_colaborador = false;
    this.url_imagen = GLOBAL.url;
    this.datos_usuario = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.datos_colaborador = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.datos_anestesia = new CatAnestesiaModel(0, '', 0, 0);
    this.datos_hospitales = new CatHospitalModel(0, '', 0, 0);
    this.datos_aseguradora = new CatAseguradora(0, '', 0, 0);
    this.datos_ayudantia = new CatAyudantiaModel(0, '', 0, 0, '');
    this.datos_personal = new CatPersonalModel(0, '', 0, 0, 0, '');
    this.datos_procedimiento = new CatProceModel(0, '', 0, 0);
    this.datos_forma_pago = new CatfPagoModel(0, '', 0, 0);
    this.datos_banco = new CatBancoModel(0, '', 0, 0);

  }
  @HostListener("window:callSomeClickHandler", ['$event.detail'])
  someClickHandler(info) {
    let datos = info.param;
    if (datos != undefined) {
      this.datos_info = info.params;

    }
    if (info.accion == 'eliminar_colaborador') {
      this.deleteColaborador(datos);
    } else if (info.accion == 'actualizar_colaborador') {
      this.updateSelectedColaborador(datos)
      // for (let key in this.menu_app) {
      //   this.menu_app[key].acceso = false;
      // }
      
           
      console.log("updateSelectedColaborador")
    } else if (info.accion == 'eliminar_personal') {
      this.deletePersonal(datos, datos.personal_tipo);
    } else if (info.accion == 'actualizar_personal') {
      this.getPersonalSelected(datos, datos.personal_tipo);
    } else if (info.accion == 'eliminar_ayudantia') {
      this.deleteAyudantia(datos);
    } else if (info.accion == 'actualizar_ayudantia') {
      this.getAyudantiaSelected(datos);
    } else if (info.accion == 'eliminar_hospital') {
      this.DeleteHospital(datos);
    } else if (info.accion == 'actualizar_hospital') {
      this.getHospitalSelected(datos);
    } else if (info.accion == 'eliminar_aseguradora') {
      this.deleteAseguradora(datos);
    } else if (info.accion == 'actualizar_aseguradora') {
      this.getAseguradoraSelected(datos);
    } else if (info.accion == 'actualizar_anestesia') {
      this.getAnestesiaSelected(datos);
    } else if (info.accion == 'eliminar_anestesia') {
      this.DeleteAnestesia(datos);
    } else if (info.accion == 'eliminar_procedimiento') {
      this.deleteProcedimiento(datos);
    } else if (info.accion == 'actualizar_procedimiento') {
      this.getProcedimientoSelected(datos);
    } else if (info.accion == 'eliminar_pago') {
      this.deleteFormaPago(datos);
    } else if (info.accion == 'actualizar_pago') {
      this.getPagoSelected(datos);
    } else if (info.accion == 'eliminar_banco') {
      this.deleteBanco(datos);
    } else if (info.accion == 'actualizar_banco') {
      this.getBancoSelected(datos);
    } else if (info.accion == 'actualizar_perfil') {
      for (let key in this.menu_app) {
        this.menu_app[key].acceso = false;
      }
      console.log("actualizar menu perfil")
      this.ActualizarMenuPerfil(datos,0);
    } else if (info.accion == 'eliminar_perfil') {
      this.DeleteMenuPerfil(datos);
    }
  }
  ngOnInit() {
    swal.setDefaults({
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-success w-25 mr-05',
      cancelButtonClass: 'btn btn-secondary w-25 ml-05'
    });
    this.mostrar_configuracion_perfil = false;
    this.mostrar_configuracion_campos = false;
    this.mostrar_configuracion_colaborador = false;
    this.getColumnasColaborador();
    this.Session();
    var fecha_actual = new Date();
    fecha_actual.setFullYear(fecha_actual.getFullYear(), fecha_actual.getMonth() - 205);
    this.max_fecha_nacimiento = fecha_actual;
    var year = fecha_actual.getFullYear()
    $('#modal_colaborador').on('shown.bs.modal', function(){
      clearSearch();
    });
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario()
      this.getEspecialidad();
      this.getPermisosAsignados();
      this.getColaboradores();
      this.MostrarMenu();
    }
  }

  MostrarMenu() {
    for (let key in this.perfil_menu) {
      // if (this.perfil_menu[key].menuapp_id == "8") {
      //   
      // } else 
      if (this.perfil_menu[key].menuapp_id == "9") {
        this.mostrar_configuracion_campos = true;
      } else if (this.perfil_menu[key].menuapp_id == "10") {
        //this.mostrar_configuracion_colaborador = true;
      }

      if(this.datos_session.usuid_padre == 0){
        this.mostrar_configuracion_perfil = true;
        this.mostrar_configuracion_colaborador = true;
      }
    }
  }

  getEspecialidad() {
    this.IndexService.getEspecialidad()
      .subscribe(result => {
        if (result.valido == 1) {
          this.especialidades = result.especialidades;
        } else {
          swal("Error", "No se pudo obtener especialidades", "error");
        }
      })
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          console.log("Si es padre mostrara todo",this.datos_usuario);
        }
      })
  }

  UpdateInfo() {
    this.datos_usuario_pw = this.datos_usuario;

    swal({
      title: 'Ingrese su contraseña, para efectuar los cambios',
      input: 'password',
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-form-conf w-49 mr-05',
      cancelButtonClass: 'btn btn-form  w-49 ml-05',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        this.datos_usuario_pw.usuario_password = login;
        this.ConfiguracionService.VerificaPw(this.datos_usuario_pw)
          .subscribe(result => {
            if (result.valido == 1 && result.estado == 1) {
              let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
              if (this.datos_usuario.usuario_nombre == null || this.datos_usuario.usuario_nombre == undefined || this.datos_usuario.usuario_nombre == '' || this.datos_usuario.usuario_nombre.trim() == '') {
                swal("Ops!", "Debe ingresar su nombre", "error");
              } else if (this.datos_usuario.usuario_apellidos == null || this.datos_usuario.usuario_apellidos == undefined || this.datos_usuario.usuario_apellidos == '' || this.datos_usuario.usuario_apellidos.trim() == '') {
                swal("Ops!", "Debe ingresar sus apellidos", "error");
              //} else if (this.datos_usuario.fecha_nacimiento == null || this.datos_usuario.fecha_nacimiento == undefined || this.datos_usuario.fecha_nacimiento == '') {
              //  swal("Ops!", "Debe Ingresar su fecha de nacimiento", "error");
              } else if (this.datos_usuario.especiadidad_id == 0 || this.datos_usuario.especiadidad_id == null || this.datos_usuario.especiadidad_id == undefined) {
                swal("Ops!", "Debe Seleccionar una Especialidad", "error")
              //} else if (this.datos_usuario.cedula_profesional == null || this.datos_usuario.cedula_profesional == undefined || this.datos_usuario.cedula_profesional == '' || this.datos_usuario.cedula_profesional.trim() == '') {
              //  swal('!Ops', 'Debe ingresar su cedula Profesional', 'error')
              } else if (!emailRegex.test(this.datos_usuario.usuario_email)) {
                swal('Ops', 'Correo Invalido', 'error');
              } else if (this.datos_usuario.usuario_pw == null || this.datos_usuario.usuario_pw == undefined || this.datos_usuario.usuario_pw.trim() == '') {
                swal('Ops!', "Debe Ingresar su Contraseña", "error")
              } else {
                this.ConfiguracionService.UpdateInfo(this.token.token, this.datos_usuario)
                  .subscribe(result => {
                    if (result.valido == 1) {
                      let params = new Array();
                      if (this.perfil_file != undefined) {
                        this.ConfiguracionService.AddImagenPerfil('usuario/usuario-updatefotoperfil/' + result.usuario.usuario_id, params, this.perfil_file)
                          .then(
                            (result: any) => {
                              swal('Muy Bien!',result.error,"success");
                            })
                      } else {
                        swal('Muy Bien!',result.error,"success");
                      }
                    } else {
                      swal("Ops!", result.error, "error");
                    }
                  })
              }
            } else {
              swal('Ops!',result.error,"error");
            }
          })
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
    })

  }

  UploadImagen($event) {
    let file: File = $event.target.files[0];
    this.perfil_file = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_perfil = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  //anestesiologos
  Anestesiologos() {
    this.getColumnasAnestesia()
    this.ConfiguracionService.getAnestesiologo(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.anestesiologos = result.cat_anestesia;
        initTableAnestesia('tabla_anestesia', this.columnas_anestesia, this.anestesiologos)
      })
  }


  AddAnestesia() {
    if (this.datos_anestesia.anestesia_desc == "" || this.datos_anestesia.anestesia_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_anestesia.usuario_id = this.datos_usuario.usuario_id;
      this.datos_anestesia.anestesia_status = 1;
      this.ConfiguracionService.AddAnestesia(this.token.token, this.datos_anestesia)
        .subscribe(result => {
          if (result.valido == 1) {
            this.Anestesiologos();
            this.datos_anestesia = new CatAnestesiaModel(0, '', 0, 0);
            swal("Muy Bien!", result.error, "success");
            this.mostrar_anestesia = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  UpdateAnestesia() {
    this.datos_anestesia.anestesia_status = 1;
    this.ConfiguracionService.UpdateAnestesia(this.token.token, this.datos_anestesia)
      .subscribe(result => {
        if (result.valido == 1) {
          this.Anestesiologos();
          swal("Muy Bien!", result.error, "success");
          this.mostrar_anestesia = false;
          this.datos_anestesia = new CatAnestesiaModel(0, '', 0, 0);
        } else {
          swal(result.error);
        }
      })
  }


  DeleteAnestesia(anestesiologo) {
    anestesiologo.anestesia_status = 0;
    this.ConfiguracionService.UpdateAnestesia(this.token.token, anestesiologo)
      .subscribe(result => {
        if (result.valido == 1) {
          this.Anestesiologos();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }
  getAnestesiaSelected(datos) {
    this.datos_anestesia = datos;
    this.mostrar_anestesia = true;
  }

  getColumnasAnestesia() {
    this.columnas_anestesia = [{
      title: '<label style="color : #3d615f; width: 500px !important; margin-left:-2%;">Nombre</label>',
      data: 'anestesia_desc'
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_anestesia" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Anestesia">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_anestesia" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Anestesia">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]
  }
  //CATALOGO HOSPITAL

  Hospitales() {
    this.getColumnasHospital();
    this.ConfiguracionService.getHospitales(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.hospitales = result.cat_hospital;
        initTableHospital('tabla_hospital', this.columnas_hospital, this.hospitales);
      })
  }

  addHospital() {
    if (this.datos_hospitales.hospital_desc == "" || this.datos_hospitales.hospital_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_hospitales.usuario_id = this.datos_usuario.usuario_id;
      this.datos_hospitales.hospital_status = 1;
      this.ConfiguracionService.addHospital(this.token.token, this.datos_hospitales)
        .subscribe(result => {
          if (result.valido == 1) {
            this.Hospitales();
            this.datos_hospitales = new CatHospitalModel(0, '', 0, 0)
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_hospital = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  updateHospital() {
    this.datos_hospitales.hospital_status = 1;
    this.ConfiguracionService.updateHospital(this.token.token, this.datos_hospitales)
      .subscribe(result => {
        if (result.valido == 1) {
          this.Hospitales();
          swal("Muy Bien!", result.error, "success");
          this.mostrar_actualizar_hospital = false;
          this.datos_hospitales = new CatHospitalModel(0, '', 0, 0)
        } else {
          swal(result.error);
        }
      })
  }


  DeleteHospital(hospital) {
    hospital.hospital_status = 0;
    this.ConfiguracionService.deleteHospital(this.token.token, hospital)
      .subscribe(result => {
        if (result.valido == 1) {
          this.Hospitales();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }

  getHospitalSelected(datos) {
    this.datos_hospitales = datos;
    this.mostrar_actualizar_hospital = true;
  }

  getColumnasHospital() {
    this.columnas_hospital = [{
      title: '<label style="color : #3d615f; width: 500px !important; margin-left:-2%">Nombre</label>',
      data: 'hospital_desc'
    }, {
      title: '<label style="color : #3d615f; margin-left:-2%;">Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_hospital" style ="margin-left: 27%"  type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Hospital">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f"; margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_hospital" style ="margin-left: 27%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Hospital">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]
  }
  //CATALOGO ASEGURADORAS
  getAseguradora() {
    this.getColumnasAseguradora()
    this.ConfiguracionService.getAseguradora(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.aseguradoras = result.cat_aseguradora;
        initTableAseguradora('tabla_aseguradora', this.columnas_aseguradora, this.aseguradoras)
      })
  }
  addAseguradora() {
    if (this.datos_aseguradora.aseguradora_desc == "" || this.datos_aseguradora.aseguradora_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_aseguradora.usuario_id = this.datos_usuario.usuario_id;
      this.datos_aseguradora.aseguradora_status = 1;
      this.ConfiguracionService.addAseguradora(this.token.token, this.datos_aseguradora)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getAseguradora();
            this.datos_aseguradora = new CatAseguradora(0, '', 0, 0);
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_aseguradora = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  updateAseguradora() {
    this.datos_aseguradora.aseguradora_status = 1;
    this.ConfiguracionService.updateAseguradora(this.token.token, this.datos_aseguradora)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getAseguradora();
          swal("Muy Bien!", result.error, "success");
          this.mostrar_actualizar_aseguradora = false;
          this.datos_aseguradora = new CatAseguradora(0, '', 0, 0);
        } else {
          swal(result.error);
        }
      })
  }


  deleteAseguradora(aseguradora) {
    aseguradora.aseguradora_status = 0;
    this.ConfiguracionService.deleteAseguradora(this.token.token, aseguradora)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getAseguradora();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }

  getColumnasAseguradora() {
    this.columnas_aseguradora = [{
      title: '<label style="color : #3d615f; width: 500px !important;margin-left:-2%;">Nombre</label>',
      data: 'aseguradora_desc'
    }, {
      title: '<label style="color : #3d615f"; margin-left:-2%;>Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_aseguradora" style ="margin-left: 27%"  type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Aseguradora">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f"; margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_aseguradora" style ="margin-left: 27%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Aseguradora">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]
  }

  getAseguradoraSelected(datos) {
    this.datos_aseguradora = datos;
    this.mostrar_actualizar_aseguradora = true;
  }
  //CATALOGO AYUDANTIA
  getAyudantia() {
    this.getColumnasAyudantia();
    this.ConfiguracionService.getAyudantia(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.ayudantias = result.cat_ayudantia;
        initTableAyudantia('tabla_ayudantia', this.columnas_ayudantia, this.ayudantias);
      })
  }

  getColumnasAyudantia() {
    this.columnas_ayudantia = [{
      title: '<label style="color : #3d615f; width: 200px !important;margin-left:-4%;">Nombre</label>',
      data: 'ayudantia_desc'
    }, {
      title: '<label style="color : #3d615f; width: 200px !important;margin-left:-4%;">Correo</label>',
      data: 'ayudantia_email'
    }, {
      title: '<label style="color : #3d615f">Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_ayudantia" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Ayudantia">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f">Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_ayudantia" type="button" style ="margin-left: 17%"  rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Ayudantia">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }

  addAyudantia() {
    if (this.datos_ayudantia.ayudantia_desc == "" || this.datos_ayudantia.ayudantia_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_ayudantia.usuario_id = this.datos_usuario.usuario_id;
      this.datos_ayudantia.ayudantia_status = 1;
      this.ConfiguracionService.addAyudantia(this.token.token, this.datos_ayudantia)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getAyudantia();
            this.datos_ayudantia = new CatAyudantiaModel(0, '', 0, 0, '');
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_ayudantia = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  updateAyudantia() {
    this.datos_ayudantia.ayudantia_status = 1;
    this.ConfiguracionService.updateAyudantia(this.token.token, this.datos_ayudantia)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getAyudantia();
          swal("Muy Bien!", result.error, "success");
          this.datos_ayudantia = new CatAyudantiaModel(0, '', 0, 0, '');
          this.mostrar_actualizar_ayudantia = false;
        } else {
          swal(result.error);
        }
      })
  }


  deleteAyudantia(datos) {
    datos.ayudantia_status = 0;
    this.ConfiguracionService.deleteAyudantia(this.token.token, datos)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getAyudantia();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }


  getAyudantiaSelected(dato) {
    this.mostrar_actualizar_ayudantia = true;
    this.datos_ayudantia = dato;

  }
  //CATALOGO PERSONAL

  getPersonal(tipo) {
    this.mostrar_actualizar_personal = false;
    this.getColumnasPersonal()
    this.ConfiguracionService.getPersonal(this.token.token, this.usuario_id, tipo)
      .subscribe(result => {
        this.cat_personal = result.cat_personal;
        initTablePersonal('tabla_personal' + tipo + '', this.columnas_personal, this.cat_personal);
      })
  }

  getColumnasPersonal() {
    this.columnas_personal = [{
      title: '<label style="color : #3d615f; width: 200px !important;margin-left:-5%;">Nombre</label>',
      data: 'persona_desc'
    }, {
      title: '<label style="color : #3d615f; width: 200px !important;margin-left:-5%;">Correo<label>',
      data: 'personal_email'
    }, {
      title: '<label style="color : #3d615f">Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_personal" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Personal">' +
          ' <i style="color : #3d615f;"  class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f">Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_personal" style ="margin-left: 17%"  type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Personal">' +
          '  <i style="color : #3d615f; " class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }

  addPersonal(tipo) {
    if (this.datos_personal.persona_desc == "" || this.datos_personal.persona_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_personal.usuario_id = this.datos_usuario.usuario_id;
      this.datos_personal.personal_status = 1;
      this.datos_personal.personal_tipo = tipo;
      this.ConfiguracionService.addPersonal(this.token.token, this.datos_personal)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getPersonal(tipo)
            this.datos_personal = new CatPersonalModel(0, '', 0, 0, 0, '');
            swal( "Muy Bien!",result.error,  "success");
          } else {
            swal(result.error);
          }
        })
    }

  }

  updatePersonal(tipo) {
    this.datos_personal.personal_status = 1;
    this.datos_personal.personal_tipo = tipo;
    this.ConfiguracionService.updatePersonal(this.token.token, this.datos_personal)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getPersonal(tipo)
          swal( "Muy Bien!",result.error,  "success");
          this.datos_personal = new CatPersonalModel(0, '', 0, 0, 0, '');
        } else {
          swal(result.error);
        }
      })
  }


  deletePersonal(personal, tipo) {
    personal.personal_status = 0;
    personal.personal_tipo = tipo;
    this.ConfiguracionService.deletePersonal(this.token.token, personal)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getPersonal(tipo)
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }

  getPersonalSelected(dato, tipo) {
    this.mostrar_actualizar_personal = true;
    this.datos_personal = dato;
  }

  //CATALOGO PROCEDIMIENTO

  getProcedimiento() {
    this.getColumnasProcedimiento();
    this.ConfiguracionService.getProcedimiento(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.cat_proce = result.cat_proce;
        initTableProcedimiento('tabla_procedimiento', this.columnas_procedimiento, this.cat_proce)
      })
  }

  addProcedimiento() {
    if (this.datos_procedimiento.proce_desc == "" || this.datos_procedimiento.proce_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_procedimiento.usuario_id = this.datos_usuario.usuario_id;
      this.datos_procedimiento.proce_status = 1;
      this.ConfiguracionService.addProcedimiento(this.token.token, this.datos_procedimiento)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getProcedimiento();
            this.datos_procedimiento = new CatProceModel(0, '', 0, 0);
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_procedimiento = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  updateProcedimiento() {
    this.datos_procedimiento.proce_status = 1;
    this.ConfiguracionService.updateProcedimiento(this.token.token, this.datos_procedimiento)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getProcedimiento();
          swal("Muy Bien!", result.error, "success");
          this.mostrar_actualizar_procedimiento = false;
          this.datos_procedimiento = new CatProceModel(0, '', 0, 0);
        } else {
          swal(result.error);
        }
      })
  }


  deleteProcedimiento(procedimiento) {
    procedimiento.proce_status = 0;
    this.ConfiguracionService.deleteProcedimiento(this.token.token, procedimiento)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getProcedimiento();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }
  getProcedimientoSelected(dato) {
    this.mostrar_actualizar_procedimiento = true;
    this.datos_procedimiento = dato;
  }
  getColumnasProcedimiento() {
    this.columnas_procedimiento = [{
      title: '<label style="color : #3d615f; width: 500px !important;margin-left:-2%;">Nombre</label>',
      data: 'proce_desc'
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_procedimiento" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Procedimiento">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_procedimiento" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Procedimiento">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }
  //ATALOGO FORMA PAGO

  getFormaPago() {
    this.getColumnasPago();
    this.ConfiguracionService.getFormaPago(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.cat_fpago = result.cat_fpago;
        initTablePago('tabla_pago', this.columnas_pago, this.cat_fpago)
      })
  }

  addFormaPago() {
    if (this.datos_forma_pago.fpago_desc == "" || this.datos_forma_pago.fpago_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_forma_pago.usuario_id = this.datos_usuario.usuario_id;
      this.datos_forma_pago.fpago_status = 1;
      this.ConfiguracionService.addFormaPago(this.token.token, this.datos_forma_pago)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getFormaPago();
            this.datos_forma_pago = new CatfPagoModel(0, '', 0, 0);
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_pago = false;
          } else {
            swal(result.error);
          }
        })
    }

  }

  updateFormaPago() {
    this.datos_forma_pago.fpago_status = 1;
    this.ConfiguracionService.updateFormaPago(this.token.token, this.datos_forma_pago)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getFormaPago();
          this.datos_forma_pago = new CatfPagoModel(0, '', 0, 0);
          swal("Muy Bien!", result.error, "success");
          this.mostrar_actualizar_pago = false;
        } else {
          swal(result.error);
        }
      })
  }


  deleteFormaPago(pago) {
    pago.fpago_status = 0;
    this.ConfiguracionService.deleteFormaPago(this.token.token, pago)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getFormaPago();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }

  getColumnasPago() {
    this.columnas_pago = [{
      title: '<label style="color:#3d615f; width:500px !important;margin-left:-2%;">Nombre</label>',
      data: 'fpago_desc'
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_pago" style ="margin-left: 17%"  type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Pago">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_pago" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Pago">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }

  getPagoSelected(dato) {
    this.mostrar_actualizar_pago = true;
    this.datos_forma_pago = dato;
  }
  //CATALOGO  BANCO

  getBanco() {
    this.getColumnasBanco();
    this.ConfiguracionService.getBanco(this.token.token, this.usuario_id)
      .subscribe(result => {
        this.cat_banco = result.cat_banco;
        initTableBanco('tabla_banco', this.columnas_banco, this.cat_banco)
      })
  }

  addBanco() {
    if (this.datos_banco.banco_desc == "" || this.datos_banco.banco_desc.trim() == "") {
      swal("Error", "No has agregado una descripción", "error")
    } else {
      this.datos_banco.usuario_id = this.datos_usuario.usuario_id;
      this.datos_banco.banco_status = 1;
      this.ConfiguracionService.addBanco(this.token.token, this.datos_banco)
        .subscribe(result => {
          if (result.valido == 1) {
            this.getBanco();
            this.datos_banco = new CatBancoModel(0, '', 0, 0);
            swal("Muy Bien!", result.error, "success");
            this.mostrar_actualizar_banco = false;
          } else {
            swal(result.error);
          }
        })
    }
  }

  updateBanco() {
    this.datos_banco.banco_status = 1;
    this.ConfiguracionService.updateBanco(this.token.token, this.datos_banco)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getBanco();
          this.datos_banco = new CatBancoModel(0, '', 0, 0);
          swal("Muy Bien!", result.error, "success");
          this.mostrar_actualizar_banco = false;
        } else {
          swal(result.error);
        }
      })
  }


  deleteBanco(banco) {
    banco.banco_status = 0;
    this.ConfiguracionService.deleteBanco(this.token.token, banco)
      .subscribe(result => {
        if (result.valido == 1) {
          this.getBanco();
          swal("Muy Bien!", result.error, "success");
        } else {
          swal(result.error);
        }
      })
  }

  getBancoSelected(dato) {
    this.mostrar_actualizar_banco = true;
    this.datos_banco = dato;
  }

  getColumnasBanco() {
    this.columnas_banco = [{
      title: '<label style="color : #3d615f; width: 500px !important;margin-left:-2%;">Nombre</label>',
      data: 'banco_desc'
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_banco" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Pago">' +
          '  <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f";margin-left:-2%;>Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_banco" style ="margin-left: 17%"  type="button" rel="tooltip" class="btn btn-round btn-just-icon btn-sm" title="Eliminar Pago">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }


  //AGREGAR COLABORADORES

  getPermisosAsignados() {
    this.ConfiguracionService.getPermisosAsignados(this.token.token)
      .subscribe(result => {
        if (result.valido == 1) {
          this.permisos_asignados = result.perfil_app;
        } else {
          swal(result.error);
        }
      })
  }

  addColaborador(papp_id) {
    this.datos_colaborador.papp_id = papp_id;
    this.datos_colaborador.accesso_pago == true ? this.datos_colaborador.accesso_pago = 0 : this.datos_colaborador.accesso_pago = 1;
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.datos_colaborador.usuario_nombre == "" || this.datos_colaborador.usuario_nombre == null || this.datos_colaborador.usuario_nombre == undefined || this.datos_colaborador.usuario_nombre.trim() == "") {
      swal("Error", "Debe llenar el campo Nombre", "error");
      this.verificar_colaborador = true;
    } else if (!emailRegex.test(this.datos_colaborador.usuario_email)) {
      swal("Error", "Correo Invalido", "error")
      this.verificar_colaborador = true;
    } else if (this.datos_colaborador.usuario_pw == "" || this.datos_colaborador.usuario_pw == null || this.datos_colaborador.usuario_pw == undefined || this.datos_colaborador.usuario_pw.trim() == "") {
      swal("Error", "El campo contraseña se encuentra vacio", "error")
    } else if (this.datos_colaborador.papp_id == 0 || this.datos_colaborador.papp_id == null || this.datos_colaborador.papp_id == undefined) {
      swal("Error", "Debe seleccionar un Permiso", "error")
      this.verificar_colaborador = true;
    } else {
      this.desactivar_perfiles = true;
      this.datos_colaborador.fecha_nacimiento = moment().format('YYYY-MM-DD');
      this.datos_colaborador.usuid_padre = this.datos_usuario.usuario_id;
      this.datos_colaborador.usuario_status = 1;
      this.IndexService.AddUsuario(this.datos_colaborador)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_colaborador = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
            swal( 'Muy Bien!',  'Creación exitosa, hemos enviado un mail a tu colaborador para la activación de su cuenta', "success");
            this.verificar_colaborador = false;
            $('#modal11').modal('hide');
            this.getColaboradores();
          } else {
            swal("Error", result.error, "error")
          }
        })
    }
  }

  getColaboradores() {

    this.ConfiguracionService.getColaboradores(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.colaboradores = result.usuid_hijos;
          this.rt_tabla_colaboradores = initTableColaborador('tabla_colaboradores', this.columnas_colaborador, this.colaboradores);
        } else {
          swal(result.error);
        }
      })
  }


  getColumnasColaborador() {
    this.columnas_colaborador = [{
      title: '<label style="color : #3d615f">Nombre</label>',
      data: 'usuario_nombre'
    }, {
      title: '<label style="color : #3d615f">Correo</label>',
      data: 'usuario_email',

    }, {
      title: '<label style="color : #3d615f">Estatus</label>',
      data: 'usu_status',

    }, {
      title: '<label style="color : #3d615f">Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_colaborador" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Colaborador">' +
          ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
          '</button>'
      }
    }, {
      title: '<label style="color : #3d615f">Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_colaborador" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Colaborador">' +
          '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
          '</button>'
      }
    }]
  }
  deleteColaborador(datos) {
    datos.usuid_padre = 0;
    this.ConfiguracionService.deleteColaborador(this.token.token, datos)
      .subscribe(result => {
        this.getColaboradores();
      })
  }

  updateSelectedColaborador(dato) {
    this.datos_colaborador = dato;

    //dato.acceso_pago si es 1 esta permitido si es 0 no
    dato.acceso_pago == 1 ? this.datos_colaborador.accesso_pago = false : this.datos_colaborador.accesso_pago = true;
    this.mostrar_boton_actualizar = true;
    this.getMenuApp(2,dato);
  }

  updateColaborador() {
    this.datos_colaborador.usuid_padre = this.usuario_id;
    this.datos_colaborador.accesso_pago == true ? this.datos_colaborador.accesso_pago = 0 : this.datos_colaborador.accesso_pago = 1;
    //actualizar acceso pago en la ruta de usuario
    console.log("UpdateColaborador",this.datos_colaborador)
    this.ConfiguracionService.deleteColaborador(this.token.token, this.datos_colaborador)
      .subscribe(result => {
        if (result.valido == 1) {
          //  swal({ title: 'Muy Bien!', text: 'Colaborador Actualizado Exitosamente', type: "success", timer: 2000, showConfirmButton: false });
          this.getColaboradores();
          $('#modal11').modal('hide');
          this.mostrar_boton_actualizar = false;
          this.datos_colaborador = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
        } else {
          swal(result.error)
        }

      })
  }

  /**************************Configuración de Perfil******************** */


  getColumnasPerfil() {
    if (!this.actualizar_perfil) {
      this.columnas_perfil = [{
        title: '<label style="color : #3d615f; width: 200px !important;"">Nombre</label>',
        data: 'papp_desc'
      }, {
        title: '<label style="color : #3d615f;">Editar</label>',
        render: function (data, type, full, meta) {
          return '<button id="actualizar_perfil"  style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar perfil">' +
            ' <i style="color : #3d615f" class="material-icons">edit</i> ' +
            '</button>'
        }
      }, {
        title: '<label style="color : #3d615f">Borrar2</label>',
        render: function (data, type, full, meta) {
          return '<button id="eliminar_perfil" style ="margin-left: 17%" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Perfil">' +
            '  <i style="color : #3d615f" class="material-icons">delete</i> ' +
            '</button>'
        }
      }]
    }
  }
  getPermisosAsignadosPerfil() {
    this.ConfiguracionService.getPermisosAsignados(this.token.token)
      .subscribe(result => {
        if (result.valido == 1) {
          this.permisos_asignados = result.perfil_app;
          initTablePerfil('tabla_perfil', this.columnas_perfil, this.permisos_asignados)
        } else {
          swal(result.error);
        }
      })
  }

  getMenuApp(accion,datos) {
    //1 guardar , 2 actualizar
    //this.papp_desc =  this.datos_colaborador.usuario_nombre

    if (accion == 1) {
      this.datos_colaborador = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
      this.actualizar_perfil = false;
    }
    this.getColumnasPerfil();
    this.ConfiguracionService.getMenuApp(this.token.token)
      .subscribe(result => {
        this.menu_app = result.menu_app;
        for (let key in this.menu_app) {
          this.menu_app[key].acceso = false;
        }
        this.getPermisosAsignadosPerfil();
        if(datos){
          this.ActualizarMenuPerfil(datos,1)
        }
       
      })


  }


  // AddPerfil() {
  //   this.perfiles_selected = [];
  //   if (this.papp_desc == "" || this.papp_desc == undefined || this.papp_desc == null) {
  //     swal("Ops!", "Debe Ingresar la Descripción del Perfil", "error")
  //   } else {
  //     this.ConfiguracionService.AddPerfilApp({ papp_desc: this.papp_desc })
  //       .subscribe(result => {
  //         if (result.valido == 1) {
  //           let papp_id = result.perfil_app[0].papp_id;
  //           for (let key in this.menu_app) {
  //             if (this.menu_app[key].acceso == true) {
  //               this.perfiles_selected.push({ papp_id: papp_id, menuapp_id: this.menu_app[key].menuapp_id });
  //             }
  //           }
  //           this.ConfiguracionService.AddMenuApp(this.perfiles_selected)
  //             .subscribe(result => {
  //               if (result.valido == 1) {
  //                 swal("Muy Bien", result.error, "success");
  //                 this.getMenuApp();
  //                 this.papp_desc == "";
  //               }
  //             })

  //         }
  //       })
  //   }

  // }

  AddPerfil() {
    debugger
    this.papp_desc = this.datos_colaborador.usuario_nombre
    this.perfiles_selected = [];
    if (this.papp_desc == "" || this.papp_desc == undefined || this.papp_desc == null) {
      swal("Ops!", "Debe Ingresar la Descripción del Perfil", "error")
    } else {
      this.ConfiguracionService.AddPerfilApp({ papp_desc: this.papp_desc })
        .subscribe(result => {
          if (result.valido == 1) {
            let papp_id = result.perfil_app[0].papp_id;
            this.addColaborador(papp_id);
            for (let key in this.menu_app) {
              if (this.menu_app[key].acceso == true) {
                this.perfiles_selected.push({ papp_id: papp_id, menuapp_id: this.menu_app[key].menuapp_id });
              }
            }
            this.ConfiguracionService.AddMenuApp(this.perfiles_selected)
              .subscribe(result => {
                if (result.valido == 1) {
                  // swal("Muy Bien", result.error, "success");
                  this.getMenuApp(1,'');
                  this.papp_desc == "";
                }
              })

          }
        })
    }

  }

  ActualizarMenuPerfil(datos,accion) {
    datos.acceso_pago == 1 ? datos.acceso_pago = true : datos.acceso_pago = false;
    this.actualizar_perfil = true;
    this.perfiles_cargados = [];

    this.papp_desc = datos.papp_desc;
    this.datos_papp_id = datos.papp_id;
    this.ConfiguracionService.getMenuAppByID(datos.papp_id, this.token.token)
      .subscribe(result => {
        if (result.valido == 1) {
          let menuapp_id = result.per_menuapp;
          for (let key in this.menu_app) {
            for (let i in menuapp_id) {
              if (this.menu_app[key].menuapp_id == menuapp_id[i].menuapp_id) {
                this.menu_app[key].acceso = true;

              }
            }
            if(accion==1){
              //checkbox permisos accion 1 
            
              $('#modal_colaborador').modal('hide');
              $('#modal11').modal('show'); 
            
             
            }
          }
          // this.menu_app = this.perfiles_cargados;
        }
      })
  }
  UpdatePerfil() {
    this.perfiles_selected = [];
    this.papp_desc = this.datos_colaborador.usuario_nombre;

    if (this.papp_desc == "" || this.papp_desc == undefined || this.papp_desc == null) {
      swal("Ops!", "Debe Ingresar la Descripción del Perfil", "error")
    } else {
      this.ConfiguracionService.UpdatePerfilApp({ papp_desc: this.papp_desc, papp_id: this.datos_papp_id, papp_status: 1 })
        .subscribe(result => {  
          if (result.valido == 1) {
            for (let key in this.menu_app) {
              if (this.menu_app[key].acceso == true) {
                if(this.menu_app[key].menuapp_id  == 9){
                  this.perfiles_selected.push({ papp_id: this.datos_papp_id, menuapp_id: 8 });
                }
                this.perfiles_selected.push({ papp_id: this.datos_papp_id, menuapp_id: this.menu_app[key].menuapp_id });
              }
            }
            this.ConfiguracionService.UpdateMenuApp(this.perfiles_selected)
              .subscribe(result => {
                if (result.valido == 1) {
                  swal("Muy Bien", result.error+"<br><strong>Para ver reflejado estos cambios en el dispositivo de tu colaborador, pídele que cierre la sesión actual y vuelva a iniciarla</strong>", "success");
                  this.papp_desc = "";
                  this.updateColaborador();
                  $('#modal11').modal('hide'); 
                  this.datos_colaborador = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
                  this.getMenuApp(2,'');
                  
                }
              })

          }
        })
    }
  }

  DeleteMenuPerfil(datos) {
    //perfil_app
    this.datos_papp_id = datos.papp_id;
    this.ConfiguracionService.DeleteMenuPerfil({ papp_id: this.datos_papp_id })
      .subscribe(result => {
        if (result.valido == 1) {
          this.ConfiguracionService.DeletePerfilApp({ papp_id: this.datos_papp_id })
            .subscribe(result => {
              if (result.valido == 1) {
                this.getPermisosAsignadosPerfil();
              }
            })
        }
      })
  }
  deleteFotoPerfil() {
    this.ConfiguracionService.deleteFotoPerfil(this.datos_usuario)
      .subscribe(result => {
        this.getPerfilUsuario()
      })
  }
}
