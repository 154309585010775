export class PendienteModel{
    constructor (
        public pendiente_id : number,
        public pendiente_desc : string,
        public pendiente_fav : number,
        public pendiente_fecha : string,
        public pendiente_status : number,
        public usuario_id : number
    ){ 

    }
}