import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }


  public getPerfilUsuario(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'usuario/getbyid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public UpdateInfo(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    usuario.usuario_status = 1;
    let params = JSON.stringify(usuario);
    return this.http.post(this.url + 'usuario/update-usuario', params, { headers: headers })
      .map(res => res.json());
  }



  // public getEspecialidad() {
  //   //consejo dejar solo los links que co autorizacion de esta forma, que no continen una session
  //   let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NDQyMDQ3ODcsImV4cCI6MTU0NTQxNDM4N30.MA7lOHNgb3cDpqMQvBy4XBRoj8Pt7Yc5z7A9Z6PjDjE';
  //   let headers = new Headers({'Content-Type' : 'application/json' , 'authorization': ': '+token+'' });
  //   return this.http.get(this.url + 'cat_especialidad/get', { headers: headers })
  //     .map(res => res.json());
  // }
  // }


  public AddImagenPerfil(url_liga: string, params: Array<string>, file: File) {
    let _url = this.url;
    return new Promise(function (resolve, reject) {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      if (file) {
        formData.append('image', file, file.name);
      }
      for (let key of params) {
        let tKey: any = key;
        for (const i in tKey) {
          formData.append(i, key[i]);
        }
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open('POST', _url + url_liga, true);
      xhr.send(formData);
    })
  }


  //Catalogo de anestesiologo 

  public getAnestesiologo(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_anestesia/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public AddAnestesia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_anestesia/add', params, { headers: headers })
      .map(res => res.json());
  }
  public UpdateAnestesia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_anestesia/update', params, { headers: headers })
      .map(res => res.json());
  }
  public DeleteAnestesia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_anestesia/update', params, { headers: headers })
      .map(res => res.json());
  }
  //Catalogo de hospitales 
  public getHospitales(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_hospital/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public addHospital(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_hospital/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateHospital(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_hospital/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteHospital(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_hospital/update', params, { headers: headers })
      .map(res => res.json());
  }
  //Catalogo de aseguradora

  public getAseguradora(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_aseguradora/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public addAseguradora(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_aseguradora/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateAseguradora(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_aseguradora/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteAseguradora(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_aseguradora/update', params, { headers: headers })
      .map(res => res.json());
  }

  //Catalogo de Ayudantia 

  public getAyudantia(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public addAyudantia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_ayudantia/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateAyudantia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_ayudantia/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteAyudantia(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_ayudantia/update', params, { headers: headers })
      .map(res => res.json());
  }

  //Catalogo Personal
  public getPersonal(token, usuario, tipo) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_personal/getbyusuarioid/' + usuario + '/' + tipo, { headers: headers })
      .map(res => res.json());
  }

  public addPersonal(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_personal/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updatePersonal(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_personal/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deletePersonal(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_personal/update', params, { headers: headers })
      .map(res => res.json());
  }
  //CATALOGO PROCEDIMIENTO
  public getProcedimiento(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public addProcedimiento(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_proce/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateProcedimiento(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_proce/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteProcedimiento(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_proce/update', params, { headers: headers })
      .map(res => res.json());
  }

  //CATALOGO FORMA DE PAGO

  public getFormaPago(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_fpago/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public addFormaPago(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_fpago/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateFormaPago(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_fpago/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteFormaPago(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_fpago/update', params, { headers: headers })
      .map(res => res.json());
  }

  //CATALOGO  BANCO


  public getBanco(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_banco/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public addBanco(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_banco/add', params, { headers: headers })
      .map(res => res.json());
  }
  public updateBanco(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_banco/update', params, { headers: headers })
      .map(res => res.json());
  }
  public deleteBanco(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'cat_banco/update', params, { headers: headers })
      .map(res => res.json());
  }
  //PERMISOS ASIGNADOS
  public getPermisosAsignados(token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'perfil_app/get', { headers: headers })
      .map(res => res.json())
  }

  public getColaboradores(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'usuario/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json())
  }


  public deleteColaborador(token, dato) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    let params = JSON.stringify(dato);
    return this.http.post(this.url + 'usuario/update-usuario', params, { headers: headers })
      .map(res => res.json());
  }
  /**********************Menu de Perfiles************** */
  public getMenuApp(token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'menu_app/get', { headers: headers })
      .map(res => res.json())
  }


  public AddPerfilApp(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'perfil_app/add', params, { headers: headers })
      .map(res => res.json());
  }

  public AddMenuApp(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'per_menuapp/add', params, { headers: headers })
      .map(res => res.json());
  }

  public getMenuAppByID(datos,token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'per_menuapp/getbyid/'+datos, { headers: headers })
      .map(res => res.json())
  }

  public getMenuAppByPadre(datos,token) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'per_menuapp/getbyPadre/'+datos, { headers: headers })
      .map(res => res.json())
  }
  
  public UpdatePerfilApp(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'perfil_app/update', params, { headers: headers })
      .map(res => res.json());
  }

  public UpdateMenuApp(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'per_menuapp/update', params, { headers: headers })
      .map(res => res.json());
  }
  public DeleteMenuPerfil(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'per_menuapp/delete', params, { headers: headers })
      .map(res => res.json());
  }
  public DeletePerfilApp(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'perfil_app/delete', params, { headers: headers })
      .map(res => res.json());
  }
  
  public deleteFotoPerfil(datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'usuario/delete-foto-perfil', params, { headers: headers })
      .map(res => res.json());
  }

  public VerificaPw (datos) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let params = JSON.stringify(datos);
    return this.http.post(this.url + 'usuario/usuario-velrifica-pw', params, { headers: headers })
      .map(res => res.json());
  }
  
  
}