
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import *  as moment from 'moment';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { CalendarioService } from '../../../services/calendario/calendario.service';
import { ObservableCalendario } from '../../../services/calendario/obserbable-calendario.service';
import { Subscription } from 'rxjs/Subscription';

import { ObservableExtraer } from 'src/app/services/extraer/extraer-observable.service';
import { Router } from '@angular/router';
import { DatosEventoService } from 'src/app/services/cirugia/datos-evento.service';
import { ObservableService } from 'src/app/observable.service';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
@Component({
  selector: 'app-calendario-semana',
  templateUrl: './calendario-semana.component.html',
  styleUrls: ['./calendario-semana.component.css']
})

export class CalendarioSemanaComponent implements OnInit, OnDestroy {
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public session: any
  public fecha_hoy;
  public mes_numero;
  public datos_calendario = new Array();
  private unsubscribe_fecha: Subscription = new Subscription();
  public datos_proveedor;
  public citas_semana = new Array();
  public datos_paciente;
  public datos_cirugia;
  public paciente_id: number;
  public datos_consulta: any;
  public detalle_pago;
  public tipo_calendario: any;
  public calendar_title;
  public mostrar_mes: boolean;
  datos_cal_title
  dateMount
  viewDates
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  locale: string = 'es';
  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions_consulta: CalendarEventAction[] = [
    {
      label: ' <img *ngIf="citas.tipo == 2" src="../../../../assets/images/menu-consulta-help.png"width="18" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      }
    }
  ];
  actions_cirugia: CalendarEventAction[] = [
    {
      label: ' <img *ngIf="citas.tipo == 2" src="../../../../assets/images/menu-cirugia-help.png"width="18" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      },
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [


  ];

  activeDayIsOpen: boolean = true;
  constructor(private modal: NgbModal,
    private ConfiguracionService: ConfiguracionService,
    private ObservableCalendario: ObservableCalendario,
    private DatosEventoService: DatosEventoService,
    private ObservableExtraer: ObservableExtraer,
    private router: Router,
    private ObservableService: ObservableService

  ) { }

  ngOnInit() {
    var fechaHoy = new Date();

    var options = { year: 'numeric', month: 'long' };
    var mesFormat = fechaHoy.toLocaleString('es', options)
    var remplazeFormat = mesFormat.replace('de ', '')
    this.dateMount = remplazeFormat.toUpperCase();
    console.log(this.dateMount.toLocaleString('es', options));
    this.mes_numero = moment().month()
    this.changeCalendar(1);
    const fecha_selected_observable = this.ObservableCalendario.calendarioSelectedMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        //1 es igual a dia, 2 igual  a semana , 3 igual a mes y 4 igual a ño
        if (result.numero == 2) {
          this.datos_cal_title = result.datos_calendario;
          this.GetCalendarioByUsuarioID(result.datos_calendario);
        }
      }
    })
    this.unsubscribe_fecha.add(fecha_selected_observable);
    this.Session()

  }
  ngOnDestroy() {
    this.unsubscribe_fecha.unsubscribe();
  }


  changeCalendar(dato) {
    this.mostrar_mes = true;
    this.calendar_title = this.viewDate.setDate(this.viewDate.getDate() + dato);
    this.mes_numero = this.viewDate.getMonth();
    var options = { year: 'numeric', month: 'long' };
    var mesFormat = this.viewDate.toLocaleString('es', options)
    var remplazeFormat = mesFormat.replace('de ', '')
    this.dateMount = remplazeFormat.toUpperCase();

    this.GetCalendarioByUsuarioID(this.datos_cal_title);
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.getPerfilUsuario();
    }
  }


  getPerfilUsuario() {
    this.usuario_id = this.datos_session.usuario_id
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];

        }
      })
  }

  GetCalendarioByUsuarioID(datos_calendario) {
    this.fecha_hoy = moment().locale('es').format('LL');
    this.datos_calendario = datos_calendario;
    this.events = [];
    //fechas en numero
    var label_imagen;
    for (let key in this.datos_calendario) {
      let mes_consulta = moment(this.datos_calendario[key].cirugia_fecha).month()
      if (mes_consulta == this.mes_numero) {

        if (this.datos_calendario[key].tipo == 1) {
          label_imagen = this.actions_cirugia;
        } else if (this.datos_calendario[key].tipo == 2) {
          label_imagen = this.actions_consulta;
        }
        //if (mes_consulta == this.mes_numero) {
        let fecha_string = this.datos_calendario[key].cirugia_fecha.split('-');
        let hora_cita = this.datos_calendario[key].cirugia_hora.split(':');
        let hora_formateada = parseInt(hora_cita[0]);
        let año = fecha_string[0];
        let mes = fecha_string[1];
        let dia = fecha_string[2];
        let año_inicio = parseInt(año);
        let mes_inicio = parseInt(mes) - 1;
        let dia_inicio = parseInt(dia);
        let fecha_inicio = new Date(año_inicio, mes_inicio, dia_inicio);
        let fecha_hoy = new Date();
        // addHours(startOfDay(fecha_inicio),
        //if (moment(fecha_inicio).week() == moment(fecha_hoy).week()) {
        this.citas_semana.push(this.datos_calendario[key])
        let evento =
        {
          start:
            addHours(startOfDay(fecha_inicio), hora_formateada),
          title: this.datos_calendario[key].paciente_nombre,
          color: colors.yellow,
          datos: this.datos_calendario[key],

          actions: label_imagen
        }

        this.events.push(evento);
        this.refresh.next();
      }
      //}
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }


  handleEvent(action: string, event): void {
    // tipo 1 cirugia , 2 consulta
    let resultado_busqueda = event.datos;
    if (resultado_busqueda.tipo == 1) {
      this.DatosEventoService.getCirugia(this.token.token, resultado_busqueda.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_cirugia = result.cirugia[0];
            this.paciente_id = this.datos_cirugia.paciente_id;
            this.DatosEventoService.getProveedorAyudantia(this.token.token, resultado_busqueda.cirugia_id)
              .subscribe(result => {
                if (result.valido == 1) {
                  this.datos_proveedor = result.cat_proveedor;
                  this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
                    .subscribe(result => {
                      this.datos_paciente = result.paciente[0];
                      if (result.valido == 1) {
                        let pago_id = this.datos_cirugia.detpg_id;
                        this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                          .subscribe(result => {
                            if (result.valido == 1) {
                              this.detalle_pago = result.detalle_pago[0];
                              this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPagoSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, actualizar: true })
                              this.ObservableExtraer.changedatosAyudantiaSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, datos_proveedor: this.datos_proveedor })
                              this.router.navigate(['/cirugia'])
                              // 1 consulta 2 cirugia
                              this.ObservableService.changeVistaMenu(2)
                            }
                          })
                      }
                    })
                }
              })
          }
        })
    } else if (resultado_busqueda.tipo == 2) {
      //resultado_busqueda.cirugia_id en tipo 2  es id_consutlta
      let id_consulta = resultado_busqueda.cirugia_id;
      this.DatosEventoService.getConsulta(this.token.token, id_consulta)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_consulta = result.consulta[0];
            this.paciente_id = this.datos_consulta.paciente_id;
            this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
              .subscribe(result => {
                this.datos_paciente = result.paciente[0];
                if (result.valido == 1) {
                  let pago_id = this.datos_consulta.detpg_id;
                  this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                    .subscribe(result => {
                      if (result.valido == 1) {
                        this.detalle_pago = result.detalle_pago[0];
                        this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPagoSelected({ datos_consulta: this.datos_consulta, detalle_pago: this.detalle_pago, actualizar: true })
                        this.router.navigate(['/consulta'])
                        // 1 consulta 2 cirugia
                        this.ObservableService.changeVistaMenu(1)
                      }
                    })
                }
              })
          }
        })
    }

  }

}


