/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pendientes.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./pendientes.component";
import * as i5 from "../configuracion/configuracion.service";
import * as i6 from "../../services/pendientes/pendientes.service";
var styles_PendientesComponent = [i0.styles];
var RenderType_PendientesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PendientesComponent, data: {} });
export { RenderType_PendientesComponent as RenderType_PendientesComponent };
function View_PendientesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col col-lg-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "MedBox"], ["class", "img-fluid pointer"], ["height", "auto"], ["src", "../../../assets/images/icon-star.png"], ["width", "16px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.UpdateFavDel(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_PendientesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col col-lg-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "MedBox"], ["class", "img-fluid pointer"], ["height", "auto"], ["src", "../../../assets/images/icon-star-off.png"], ["width", "16px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.UpdateFavAdd(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_PendientesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "fondonotasgray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PendientesComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PendientesComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "col md-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [["class", "detallecita text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵted(12, null, [" ", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "col col-lg-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", "MedBox"], ["class", "img-fluid pointer"], ["height", "auto"], ["src", "../../../assets/images/icon-trash.png"], ["width", "13px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DeleteFav(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.pendiente_fav == 1); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_v.context.$implicit.pendiente_fav == 0); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.pendiente_fecha, "dd/MM/yyyy")); _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.context.$implicit.pendiente_desc; _ck(_v, 12, 0, currVal_3); }); }
function View_PendientesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["for", "error_longitud"], ["style", "color:red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" La longitud m\u00E1xima del pendiente es de 140 caracteress"]))], null, null); }
function View_PendientesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "section", [["class", "containerfondo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "container-fluid"], ["style", "padding-bottom: 80px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 23, "div", [["class", "container pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 22, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-7 offset-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PendientesComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 18, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 17, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(11, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 7, "textarea", [["class", "letraCuadrosFormulario form-control"], ["id", "notasTextarea"], ["maxlength", "140"], ["name", "notas_area"], ["placeholder", "Escribir nota...(140 caracteres)"], ["rows", "16"], ["style", "font-family: Open Sans; height: 150px; color: white; text-align: left;"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "ngModelChange"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (_co.LongitudDescripcion() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.datos_pendientes.pendiente_desc = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(17, 540672, null, 0, i3.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.MaxLengthValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(20, 671744, [["name", 4]], 0, i3.NgModel, [[2, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(22, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PendientesComponent_5)), i1.ɵdid(24, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-form btn-block btn-lg font-weight-bold"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.AddPendiente() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["AGREGAR NOTA "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendientes_list; _ck(_v, 7, 0, currVal_0); var currVal_16 = "140"; _ck(_v, 17, 0, currVal_16); var currVal_17 = "notas_area"; var currVal_18 = _co.datos_pendientes.pendiente_desc; _ck(_v, 20, 0, currVal_17, currVal_18); var currVal_19 = _co.isMaxLength; _ck(_v, 24, 0, currVal_19); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 13).ngClassValid; var currVal_6 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (i1.ɵnov(_v, 17).maxlength ? i1.ɵnov(_v, 17).maxlength : null); var currVal_9 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 22).ngClassValid; var currVal_14 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_PendientesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PendientesComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mostrar_pendientes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PendientesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pendientes", [], null, null, null, View_PendientesComponent_0, RenderType_PendientesComponent)), i1.ɵdid(1, 114688, null, 0, i4.PendientesComponent, [i5.ConfiguracionService, i6.PendientesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PendientesComponentNgFactory = i1.ɵccf("app-pendientes", i4.PendientesComponent, View_PendientesComponent_Host_0, {}, {}, []);
export { PendientesComponentNgFactory as PendientesComponentNgFactory };
