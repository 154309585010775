import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ɵConsole } from '@angular/core';

export class ObservableExtraer {

  private datosSelectedIDSource = new BehaviorSubject<any>("");
  public datosSelectedGeneralMessage = this.datosSelectedIDSource.asObservable();

  private datosSelectedPagoIDSource = new BehaviorSubject<any>("");
  public datosSelectedPagoMessage = this.datosSelectedPagoIDSource.asObservable();


  private datosSelectedPacienteIDSource = new BehaviorSubject<any>("");
  public datosSelectedPacienteMessage = this.datosSelectedPacienteIDSource.asObservable();


  private datosSelectedAyudantiasIDSource = new BehaviorSubject<any>("");
  public datosSelectedAyudantiaMessage = this.datosSelectedAyudantiasIDSource.asObservable();
  //intercambia datos del paciente evento con paciente
  public changedatosSelectedGeneral(datos) {
    //debugger
    if (datos && datos.limpiar == undefined) {
      this.datosSelectedIDSource.next(datos);
    }
    if (datos.limpiar != undefined && datos.limpiar == true) {
      //this.datosSelectedIDSource.complete();
      this.datosSelectedIDSource.next(null);
    }

  }

  public changedatosPagoSelected(datos) {
    if (datos && datos.limpiar == undefined) {
      this.datosSelectedPagoIDSource.next(datos);
    }
    if (datos.limpiar != undefined && datos.limpiar == true) {
      //this.datosSelectedPagoIDSource.complete();
      this.datosSelectedPagoIDSource.next(null);
    }
  }

  public changedatosPacienteSelected(datos) {
    
      if (datos && datos.limpiar == undefined) {
        this.datosSelectedPacienteIDSource.next(datos);
      }
      if (datos.limpiar != undefined && datos.limpiar == true) {
        //this.datosSelectedPacienteIDSource.complete();
        this.datosSelectedPacienteIDSource.next(null);
      }
    
  }
  public changedatosAyudantiaSelected(datos) {
    if (datos && datos.limpiar == undefined) {
      this.datosSelectedAyudantiasIDSource.next(datos);
    }
    if (datos.limpiar != undefined && datos.limpiar == true) {
      //this.datosSelectedAyudantiasIDSource.complete();
      this.datosSelectedAyudantiasIDSource.next(null);
    }
  }
}