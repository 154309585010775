<!-- Barra redes sociales -->
<section class="py-2">
  <div class="container-fluid">
    <div class="row bg-barra">
      <div class="col-md-3 offset-md-9 justify-content-end">
        <a href="#" target="_blank">
          <img src="../../../assets/images/icon_fb.png" width="38" height="auto" alt="Facebook" class="img-fluid">
        </a>
        <a href="#" target="_blank">
          <img src="../../../assets/images/icon_tw.png" width="38" height="auto" alt="Twitter" class="img-fluid">
        </a>
        <a href="#" target="_blank">
          <img src="../../../assets/images/icon_ig.png" width="38" height="auto" alt="Intagram" class="img-fluid">
        </a>
        <a href="#" target="_blank">
          <img src="../../../assets/images/icon_lin.png" width="38" height="auto" alt="Linkdedin2" class="img-fluid">
        </a>
      </div>
    </div>
  </div>
</section>
<!-- Barra formulario ingreso -->
<section>
  <div class="container-fluid bg-barras" *ngIf="!confirma_new_pw">

    <div class="row align-items-center pt-2 pb-2" style="background-color: #3d615f;">
      <div class="col-md-6 offset-md-3">
        <form>
          <div class="form-row justify-content-center">
            <div class="col-md-4 pt-2">
              <input type="text" #name="ngModel" [(ngModel)]="datos_accesso.usuario_email" name="correo"
                class="form-control letraCuadrosFormulario mb-3" id="inlineFormMail" placeholder="Correo electrónico"
                required style="font-family: Open Sans; background-color: rgba(255, 255, 255, 0.35);">
            </div>
            <div class="col-md-4 pt-2">
              <input type="password" #name="ngModel" [(ngModel)]="datos_accesso.usuario_pw" name="pw"
                class="form-control letraCuadrosFormulario mb-3" id="inlineFormPassword" placeholder="Contraseña"
                style="font-family: Open Sans; background-color: rgba(255, 255, 255, 0.35);">
            </div>
            <div class="col-md-4 pt-2">
              <button type="submit" style="height: 33px;" (click)="Login()"
                class="btn btn-form btn-block btn-lg font-weight-bold">
                <span style="margin-top: -1%;">INICIAR SESIÓN</span>
              </button>
              <div class="row smallLink vinculo">
                <div class="col text-center">
                  <a class="pointer" (click)="muestra_recoverypw()">¿OLVIDASTE TU CONTRASEÑA?</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-2 offset-md-1">
        <a href="#">
          <img src="../../../assets/images/logo_blanco.png" width="50px" height="auto" alt="MedBox" class="img-fluid">
        </a>
      </div>
    </div>

  </div>


</section>
<!-- Sección logo y formulario registro -->
<section>
  <div class="container-fluid containerlogin">
    <div class="row py-2">
      <div *ngIf="!registro_codigo && !olvide_pw && !confirma_new_pw" class="row col-md-12">
        <div class="col-md-8">
          <div class="d-flex justify-content-end">
            <div>
              <img src="../../../assets/images/logo_home.png" width="350px" height="auto"
                class="img-fluid mx-auto d-block">
              <p style="font-size: 32px;" class="text-center pt-2 leyenda">Bienvenido</p>
              <p class="text-center pt-2 ley"> PagosMD una Herramienta con Innovación</p>
              <p class="text-center ley">Tecnológica y Diseño que Simplifica los Procesos de la</p>
              <p class="text-center ley">Administración Médica.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 pl-9">
          <img src="../../../assets/images/info2.png" alt="" width="250px" class="img-fluid mx-auto d-block">
        </div>

      </div>
      <div *ngIf="!registro_codigo && !olvide_pw && !confirma_new_pw" class="col-md-6 py-4">
        <!-- <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="nombre" #name="ngModel" name="nombre" [(ngModel)]="datos_registro.usuario_nombre" class="form-control letraCuadrosFormulario"
                id="inputNombre" placeholder="Nombre" required>
              <div *ngIf="datos_registro.usuario_nombre == '' && verificando_campos_registro" role="alert">
                <small style="color: red">
                  <b>*El campo nombre es Obligatorio</b>
                </small>
              </div>
            </div>


          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="apellidos" #name="ngModel" name="app" [(ngModel)]="datos_registro.usuario_apellidos " class="form-control letraCuadrosFormulario"
                id="inputApellidos" placeholder="Apellidos">
              <div *ngIf="datos_registro.usuario_apellidos == '' && verificando_campos_registro">
                <small style="color: red">
                  <b>*El campo Apellidos es Obligatorio</b>
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input style="    background-color: #7c9695;
              border-color: #7c9695;
              color: white;" id="datepicker" #name="ngModel"  [max]="max_fecha_nacimiento" [(ngModel)]="datos_registro.fecha_nacimiento " name="fec_nat" class="form-control letraCuadrosFormulario"
                placeholder="Fecha De Nacimiento" [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" readonly>
              <div *ngIf="datos_registro.fecha_nacimiento == '' && verificando_campos_registro">
                <small style="color: red">
                  <b>*El campo Fecha De Nacimiento es Obligatorio</b>
                </small>
              </div>
              <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>

            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              
              <select #name="ngModel" name="especialidad" [(ngModel)]="datos_registro.especiadidad_id " class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                style="font-size: 12px; font-weight: 300;" name="especialidades" id="especialidad">
                <option value="0">Especialidad Médica </option>
                <option [value]="especialidad.especiadidad_id" *ngFor="let especialidad of especialidades">{{especialidad.especialidad_desc}}</option>
              </select>
              <div *ngIf="datos_registro.especiadidad_id == 0 && verificando_campos_registro">
                <small style="color: red">
                  <b>*El campo Especialidad Médica es Obligatorio</b>
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="cedulaprofesional" [(ngModel)]="datos_registro.cedula_profesional" #name="ngModel" name="cedula" class="form-control letraCuadrosFormulario"
                id="inputCedulaProfesional" placeholder="Cédula Profesional">
              <div *ngIf="datos_registro.cedula_profesional == '' && verificando_campos_registro">
                <small style="color: red">
                  <b>*El campo Cédula Profesional es Obligatorio</b>
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="email"  [(ngModel)]="datos_registro.usuario_email" #emailField="ngModel" name="correo" class="form-control letraCuadrosFormulario"
                id="inputCorreoElectronico" placeholder="Correo Electrónico" pattern="^\S*[@]\S*[.]\S*$" email required>
              <div *ngIf="!emailField.valid && (emailField.touched || emailField.dirty)">
                <small style="color: red">
                  <b>*Correo Invalido</b>
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="password" [(ngModel)]="datos_registro.usuario_pw" #name="ngModel" name="pw" class="form-control letraCuadrosFormulario"
                id="inputPasswordindex" placeholder="Contraseña">
              <div *ngIf="datos_registro.usuario_pw == '' && verificando_campos_registro">
                <small style="color: red">
                  <b>*El campo Contraseña es Obligatorio</b>
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="password" #name="ngModel" name="pw_c" [(ngModel)]="datos_registro.usuario_pw_confirm" class="form-control letraCuadrosFormulario"
                id="inputConfirmarPassword" placeholder="Confirmar Contraseña">
              <div *ngIf="datos_registro.usuario_pw_confirm == '' && verificando_campos_registro">
                <small style="color: red">
                  <b>*Debe confirmar la contraseña</b>
                </small>
              </div>
              <div *ngIf="datos_registro.usuario_pw_confirm !=  datos_registro.usuario_pw && verificando_campos_registro && datos_registro.usuario_pw_confirm != ''">
                <small style="color: red">
                  <b>*Las Contraseñas deben ser Iguales</b>
                </small>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3 float-left text-center">
              <a href="{{terminos_condiciones}}"  target=”_blank” class="smallLink vinculo">TÉRMINOS Y CONDICIONES</a>
            </div>
            <div class="form-group col-md-3 float-right text-center">
              <a href="{{aviso_privasidad}}"  target=”_blank” class="smallLink vinculo">AVISO DE PRIVACIDAD</a>
            </div>
          </div>
          <div class="form-group col-md-6">
            <div class="form-check text-center">
              <input (change)="TerminosCoondiciones(1)" id="accep_term" class="form-check-input" type="checkbox" name="radio" value=""
                id="defaultCheck1">
              <label class="form-check-label" for="defaultCheck1">
                <label class="form-check-label aceptarCond" for="AceptarCondiciones">
                  ACEPTO TÉRMINOS Y CONDICIONES
                </label>
              </label>
            </div>
            <p></p>
            <button (click)="VerificaDatosUsuario()" type="submit" class="btn btn-form btn-block btn-lg font-weight-bold">REGISTRAR</button>
          </div>
        </form> -->
      </div>
      <div *ngIf="registro_codigo" style="margin-top: 211px;" class="col-md-6 py-4">

        <div class="form-row">
          <div class="form-group col-md-6">
            <input type="codigoactivacion" name="codigoactivacion" #name="ngModel"
              [(ngModel)]="datos_registro.codigo_confirmacion" class="form-control letraCuadrosFormulario"
              id="inputCodigoActivacion" placeholder="Código de Activación">
            <button (click)="ActivarCodigo()" type="submit"
              class="btn btn-form btn-block btn-lg font-weight-bold">Activar</button>
          </div>
        </div>
      </div>
      <div *ngIf="registro_codigo || olvide_pw || confirma_new_pw" class="col-md-6 py-4">
        <img src="../../../assets/images/logo_home.png" width="350px" height="auto" class="img-fluid mx-auto d-block">
        <p class="text-center pt-2 leyenda">BIENVENIDO</p>
        <p class="text-center pt-2 leyenda"> PagosMD una Herramienta con Innovación</p>
        <p class="text-center leyenda">Tecnológica y Diseño que Simplifica los Procesos de la</p>
        <p class="text-center leyenda">Administración Médica.</p>
      </div>
      <div *ngIf="olvide_pw" class="col-md-6 py-4">

        <div class="form-row">
          <div class="form-group col-md-6" style="padding-top: 99px">

            <input type="codigoactivacion" name="codigoactivacion" #emailFieldRc="ngModel"
              [(ngModel)]="datos_recovery_pw.usuario_email" class="form-control letraCuadrosFormulario"
              id="inputCodigoActivacion" pattern="^\S*[@]\S*[.]\S*$" email required placeholder="Ingresa tu Correo">
            <div *ngIf="!emailFieldRc.valid && (emailFieldRc.touched || emailFieldRc.dirty)">
              <small style="color: red">
                <b>*Correo Invalido</b>
              </small>
            </div>
            <br>
            <button (click)="RecoberyPw()" type="submit"
              class="btn btn-form btn-block btn-lg font-weight-bold">Enviar</button>
          </div>
        </div>
      </div>
      <div *ngIf="confirma_new_pw" class="col-md-6 py-4">
        <div class="form-row">
          <div class="form-group col-md-6" style="padding-top: 88px">

            <div>
              <input type="password" [(ngModel)]="datos_recovery_pw.usuario_pw" #name="ngModel" name="pw_rec"
                class="form-control letraCuadrosFormulario" id="pw_rec" placeholder="Nueva contraseña">
              <div *ngIf="datos_recovery_pw.usuario_pw == '' ">
                <small style="color: red">
                  <b>*El campo Contraseña es Obligatorio</b>
                </small>
              </div>
            </div>
            <br>

            <div>
              <input type="password" #name="ngModel" name="pw_c_n" [(ngModel)]="datos_recovery_pw.usuario_pw_confirm"
                class="form-control letraCuadrosFormulario" id="pw_c_n" placeholder="Confirmar Nueva contraseña">
              <div *ngIf="datos_recovery_pw.usuario_pw_confirm == ''">
                <small style="color: red">
                  <b>*Debe confirmar la contraseña</b>
                </small>
              </div>
              <div
                *ngIf="datos_recovery_pw.usuario_pw_confirm !=  datos_recovery_pw.usuario_pw  && datos_recovery_pw.usuario_pw_confirm != ''">
                <small style="color: red">
                  <b>*Las Contraseñas deben ser Iguales</b>
                </small>
              </div>
            </div>

            <br>
            <div>
              <input type="password" [(ngModel)]="datos_recovery_pw.usuario_rec_codigo" #name="ngModel"
                name="codigo_rec" class="form-control letraCuadrosFormulario" id="codigo_rec"
                placeholder="Código enviado por correo">
              <div *ngIf="datos_recovery_pw.usuario_rec_codigo == '' ">
                <small style="color: red">
                  <b>*El campo Codigo es Obligatorio</b>
                </small>
              </div>
            </div>

            <button (click)="ResetPw()" type="submit"
              class="btn btn-form btn-block btn-lg font-weight-bold">Aceptar</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>