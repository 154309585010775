<!-- Imágenes -->
<section *ngIf="datos_estadistica" style="padding-top: 30px; padding-bottom: 80px; background-image: url('../../../assets/images/Back-gradiente.jpg'); background-size: cover;">
    <div class="row">
        <div class="col-md-8 offset-md-2" style="border-bottom: 2px solid #ffffff; padding-bottom: 20px;display:grid;grid-template-columns:1fr 2fr 1fr;grid-template-rows:50px;">
            <img src="../../../assets/images/Recurso%201.png" style="grid-area:1 / 1 / 2 / 2;" width="100%" height="auto" />
            <img src="../../../assets/images/Recurso%201.png" style="grid-area:1 / 3 / 2 / 4;" width="100%" height="auto" />
            <div style="grid-area: 1 / 2 / 2 / 3; display: flex; margin-left: 30px;">
                <div style="color: white; padding-left: 15px; padding-right: 15px; font-family: Open Sans; font-weight: lighter;">
                    <img src="../../../assets/images/duracion-cirugia.png" width="200px" style="bottom: 15px; position: relative;" />
                </div>
                <div style="padding-left: 15px; margin-right: 15px; margin-top: -15px;">
                    <img src="../../../assets/images/Recurso%206.png" height="70px" width="auto" />
                </div>
                <div style="padding-left: 10px; margin-right: 10px;">
                    <h2 class="num-variable" style="font-size: 4em;">{{datos_estadistica.dur_cirugia}}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 offset-md-2" style="border-right: 2px solid #ffffff; padding-top: 45px; padding-bottom: 5px;">
            <div style="display: grid;">
                <img src="../../../assets/images/valor-hora.png" width="225px" style="margin-bottom: 25px; margin-left: auto; margin-right: auto;"
                    height="auto" />
            </div>
            <div style="background-image: url('../../../assets/images/Recurso%203.png'); background-size: contain; background-position: center center; background-repeat: no-repeat; height: 135px; padding-top: 15px;">
                <h2 class="Aton num-variable">$ {{datos_estadistica.valor_hr_trab}}</h2>
            </div>
        </div>
        <div style="border-right: 2px solid #ffffff; padding-top: 85px; padding-bottom: 25px;" class="col-md-1">
            <div>
                <h2 class="Aton num-variable " style="color: #3C615F; font-size: 5.2em;">{{datos_estadistica.prom_iedad}}</h2>
            </div>
            <div class="Aton" style="color: WHITE; 
                    color: white;
                    font-size: 3.5em;
                    text-align: center;">
                <img src="../../../assets/images/EDAD%20PROMEDIO.png" width="90px" class="cosa-stadis" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-6" style="border-right: 2px solid #ffffff;">
                    <div style="display: flex; padding-top: 25px; padding-bottom: 0px; color: white;">
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 1 ? '#ddbb13' : 'none'}">D</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 2 ? '#ddbb13' : 'none'}">L</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 3 ? '#ddbb13' : 'none'}">M</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 4 ? '#ddbb13' : 'none'}">M</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 5 ? '#ddbb13' : 'none'}">J</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 6 ? '#ddbb13' : 'none'}">V</h3>
                        <h3 class="dias-semana dias-semana-responsive" [ngStyle]="{'background-color' :  datos_estadistica.dia_sem === 7 ? '#ddbb13' : 'none'}">S</h3>
                    </div>
                    <div>
                        <img src="../../../assets/images/dia-sem-cirugias.png" width="190px" class="cosa-stadis" />
                    </div>
                </div>
                <div class="col-md-6" style="padding-top: 25px; padding-bottom: 25px; color: white; display: flex; padding-left: 25px;">
                    <h3 class="num-variable Aton " style="font-size: 7em; color: #3C615F; margin-top: 10px;">{{datos_estadistica.horas_trab}}</h3>
                    <div style="padding-left: 20px; margin-top: 20px;">
                        <img src="../../../assets/images/horas-trabajo.png" width="130px" class="cosa-stadis" />
                    </div>
                </div>
            </div>
            <div class="row" style="border-top: 2px solid #ffffff;">
                <div class="col-md-12" style="text-align: center; color: white; padding-top: 15px; padding-bottom: 15px;">
                    <h3 class="hospital" style=" font-family : Aton;  color: #3C615F; margin-bottom: 0px;">- {{datos_estadistica.hospital_mascomun}} -</h3>
                    <h4 class="hospital-2">HOSPITAL MÁS COMÚN</h4>
                    <div style="background-image: url('../../../assets/images/Recurso%209.png'); background-position: center center; background-repeat: no-repeat; background-size: contain; height: 81px; padding-top: 33px; margin-top: 15px;">
                        <h4 class="num-variable  " style=" color: #3C615F; font-size :2.1em ">- {{ datos_estadistica.fecha_mascir | date: 'dd/MM/yy' }} -</h4>
                    </div>
                    <h4 class="hospital-2" style="font-family: Open Sans;  margin-top: 10px;">FECHA CON MAYOR NÚMERO DE CIRUGÍAS</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8" style="border-top: 2px solid #ffffff;    padding-top: 15px; padding-bottom: 15px;display:grid;grid-template-columns:1.6fr 1.6fr;grid-template-rows:150px;grid-template-areas:'col-small .';">
            <div style="grid-area: 1 / 1 / 2 / 2; display: flex; border-right: 2px solid #ffffff;">
                <div style="padding-left: 10px; padding-right: 10px;">
                    <img src="../../../assets/images/mayor-cirugias.png" width="180px" />
                </div>
                <div style="padding-left: 10px; padding-right: 10px;">
                    <h2 class="num-variable fuente" style="color: #3C615F; font-size: 8em;  margin-top: 15px;">{{ datos_estadistica.no_cirpordia}}</h2>
                </div>
                <div style="padding-left: 10px; padding-right: 10px;">
                    <img src="../../../assets/images/Recurso%2010.png" height="120px" width="auto" />
                </div>
            </div>
            <div style="grid-area: 1 / 2 / 2 / 3; padding-top: 15px; padding-bottom: 15px; padding-left: 45px; display: flex;">
                <div style="margin-right: 8px; width: 115px; height: 115px; background-repeat: no-repeat; background-position: center center; background-size: contain; background-image: url('../../../assets/images/Recurso%2011.png');">
                    <h4 class="num-variable" style="font-size: 4em; margin-top: 25px;">{{datos_estadistica.cir_agendadas}}</h4>
                </div>
                <div style="margin-right: 8px; width: 115px; height: 115px; background-repeat: no-repeat; background-position: center center; background-size: contain; background-image: url('../../../assets/images/Recurso%2011.png');">
                    <h4 style="font-family: Open Sans; color: white; font-size: 1.1em; text-align: center; margin-top: 27px;">CIRUGÍAS
                        <br> AGENDADAS
                        <br> EN LA APP</h4>
                </div>
                <div style="margin-right: 8px; width: 115px; height: 115px; background-repeat: no-repeat; background-position: center center; background-size: contain; background-image: url('../../../assets/images/Recurso%2012.png');">
                    <h4 class="num-variable" style="font-size: 4em; margin-top: 25px;">{{datos_estadistica.cir_nopagadas}}</h4>
                </div>
                <div style="margin-right: 8px; width: 115px; height: 115px; background-repeat: no-repeat; background-position: center center; background-size: contain; background-image: url('../../../assets/images/Recurso%2012.png');">
                    <h4 style="font-family: Open Sans; color: white; font-size: 1.1em; text-align: center; margin-top: 27px;">CIRUGÍAS
                        <br> NO PAGADAS
                        <br> EN LA APP</h4>
                </div>
            </div>
        </div>
    </div>
</section>