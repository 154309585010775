<div class="form-row mt-3" style="padding-top: 3px;">
  <div class="form-group col-6">
    <input id="datepicker5" #name="ngModel" [(ngModel)]="datos_paciente.fecha_nacimiento" style="font-size: 12px; font-weight: 300;     margin-top: 0px;"
      class="read-on form-control letraCuadrosFormulario flecha_m" placeholder="Fecha Nacimiento" [owlDateTimeTrigger]="dt5"
      [owlDateTime]="dt5" readonly>
    <owl-date-time [pickerType]="'calendar'" #dt5></owl-date-time>
  </div>
  <div class="form-group col-6">
    <input type="nombre" #name="ngModel" [(ngModel)]="datos_paciente.paciente_rfc" name="rfc" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
      id="inputNombre" placeholder="R.F.C.">
  </div>
</div>
<div class="form-row">
  <div class="form-group col-6">
    <input type="nombre" #name="ngModel" [(ngModel)]="datos_paciente.paciente_tel" name="telefono_fijo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
      id="inputNombre" placeholder="Teléfono Fijo" onkeyup="this.value=this.value.replace(/\D+/g,'');" (change)="cleanPhone(1)" maxlength="15">
  </div>
  <div class="form-group col-6">
    <input type="nombre" #name="ngModel" [(ngModel)]="datos_paciente.paciente_cel" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
      id="inputNombre" placeholder="Teléfono Celular" onkeyup="this.value=this.value.replace(/\D+/g,'');" (change)="cleanPhone(1)" maxlength="15">
  </div>
</div>
<div class="form-row">
  <div class="form-group col-9">
    <input type="nombre" #name="ngModel" [(ngModel)]="datos_paciente.paciente_correo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
      id="inputNombre" placeholder="Correo Electrónico">
  </div>
  <div *ngIf="!enviar_correo" class="form-group col-3">
    <!-- <a class="pointer"  data-toggle="modal" data-target="#EmailEnviar"> -->


    <img style="width: 97%; height: 87%;" (click)="correoEnviar(1)" src="../../../../assets/images/button-mail.png" alt=email>

    <label></label>

  </div>
  <div *ngIf="enviar_correo" class="form-group col-3">
    <a class="pointer">
      <img style="width: 97%; height: 87%;" (click)="correoEnviar(0)" src="../../../../assets/images/mail-on.png" alt=email>
      <label></label>
    </a>
  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-12">
    <textarea class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" #name="ngModel" [(ngModel)]="datos_paciente.paciente_dir" maxlength="500"
      id="notasTextarea" rows="3" placeholder="Dirección"></textarea>
  </div>
</div>
<div class="form-row  mt-4" style="margin-top: 0px; width: 317px; margin-left: auto; margin-right: auto;">
  <button style="width: 317px; background-color: rgb(124,28,18);  margin-left: 2%;" *ngIf="datos_actualizar" (click)="DeleteConsulta()"
    type="button" class="btn btn-form-conf-verde btn-block btn-md">Eliminar Consulta
    <span style="position: absolute;right: 238px">
      &nbsp;
      <img src="../../../assets/images/icon-trash-transparent.png" width="14px">
    </span>
  </button>
</div>
<br>
<br>
<button type="submit" (click)="addNuevaConsulta()" class="btn btn-form btn-block btn-lg font-weight-bold" style="margin-top: 0px; width: 317px; margin-left: auto; margin-right: auto;">GUARDAR
</button>
x