import { Component, OnInit } from '@angular/core';
import * as  $ from 'jquery';
import { GLOBAL } from '../../helpers';
import { intervalCarruselHome } from '../../../assets/js/carrusel_home.js';
import { UsuarioModel } from '../../models/UsuarioModel';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { HomeService } from '../../services/home/home.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public session;
  public datos_session;
  public usuario_id;
  public token;
  public datos_usuario: UsuarioModel;
  public url_imagen: string;
  public array_img_home = new Array();
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private HomeService: HomeService
  ) {


  }

  ngOnInit() {
    this.datos_usuario = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.Session();
    this.url_imagen = GLOBAL.url;
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.getPerfilUsuario()
    }
  }
  getPerfilUsuario() {
    this.usuario_id = this.datos_session.usuario_id
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getImagenHome();
        }
      })
  }

  getImagenHome() {
    this.HomeService.getEducacion(this.token.token)
      .subscribe(result => {
        if (result.valido == 1) {
          this.array_img_home = result.home;
          intervalCarruselHome()
        }
      })
  }
}
