import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, AfterViewChecked, OnChanges } from '@angular/core';
import { OnDestroy } from "@angular/core";
import { ObservableCalendario } from '../../services/calendario/obserbable-calendario.service';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { CalendarioService } from '../../services/calendario/calendario.service';
import *  as moment from 'moment';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, isThursday, addSeconds } from 'date-fns'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarEventTitleFormatter } from 'angular-calendar';
import { Subject } from 'rxjs';
import { DatosEventoService } from '../../services/cirugia/datos-evento.service';
import { Router } from '@angular/router';
import { ObservableExtraer } from '../../services/extraer/extraer-observable.service';
import { ObservableService } from 'src/app/observable.service';
import { UpperCasePipe } from '@angular/common';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
const colors: any = {
  verdevarioseventos: {
    primary: '#3c615f',
    secondary: 'rgb(235, 187, 80);'
  },
  verdepocoseventos: {
    primary: '#7c9695',
    secondary: 'rgb(235, 187, 80);'
  },
  diaactual: {
    primary: '#7c9695',
    secondary: '#FDF1BA'
  },
};
@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [

    './calendario.component.css'
  ], providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})

export class CalendarioComponent implements OnInit, OnDestroy {
  public dia_calendario: boolean;
  public semana_calendario: boolean;
  public mes_calendario: boolean;
  public anio_calendario: boolean;
  public tipo_calendario: any;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public session: any;
  public array_fechas = {};
  public datos_calendario = new Array();
  public fecha_agendada;
  public mes_numero;
  public mes_diferencia;
  public cantidad_dias_mes = Array();
  public datos_paciente;
  public mostrar_calendario: boolean;
  locale: string = 'es';
  public datos_proveedor;
  public datos_cirugia;
  public detalle_pago;
  public paciente_id: number;
  public datos_consulta: any;
  public perfil_menu;
  public calendar_title;
  public calendar_title_default;
  public evento_datos;
  public mostrar_mes: boolean;
  public eventos_dia;
  public fecha_selected_calendario;
  activeDayIsOpen: boolean = false;
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  moda: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  CargaFechas() {

  }

  actions_consulta: CalendarEventAction[] = [
    {
      label: ' <img  src="../../../../assets/images/menu-consulta-help.png" width="23" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      }
    }
  ];
  actions_cirugia: CalendarEventAction[] = [
    {
      label: ' <img  src="../../../../assets/images/menu-cirugia-help.png" width="23" height="auto">',
      onClick: ({ event }: { event: CalendarEvent }): void => {

      },
    }
  ];
  events: CalendarEvent[] = [

  ];
  constructor(
    private ObservableCalendario: ObservableCalendario,
    private ConfiguracionService: ConfiguracionService,
    private CalendarioService: CalendarioService,
    private modal: NgbModal,
    private DatosEventoService: DatosEventoService,
    private router: Router,
    private ObservableExtraer: ObservableExtraer,
    private ObservableService: ObservableService
  ) {
    this.dia_calendario = false;
    this.semana_calendario = false;
    this.mes_calendario = true;
    this.anio_calendario = false;

  }

  ngOnInit() {
    this.calendar_title_default = this.viewDate.setMonth(this.viewDate.getMonth());
    this.mostrar_calendario = false;
    this.Session();
    // $(window).on('load', function() {
    //   // code here

    //  });
    $(document).ready(function () {
      setTimeout(() => {    //<<<---    using ()=> syntax
        const thingsNodeList = document.getElementsByClassName('cal-day-badge')
        const thingsArray = Array.from(thingsNodeList)
        thingsArray.forEach(thing => $(thing).addClass('add-margin'))
      }, 890);

    })
  }
  resetComponent(){
    window.location.reload()

  }
  ngOnDestroy() {
    this.ObservableCalendario.changeCalendarioSelected({ limpiar: true })
  }


  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
      this.MostrarMenu();

    }
  }

  setCSSCalendar() {

  }

  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == "4") {
        this.mostrar_calendario = true;
        this.tipo_calendario = 3;
      }
    }
  }
  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.GetCalendarioByUsuarioID();
        }
      })
  }

  SelectedCalendario(numero) {
    //*Definicion de numeros para la muestra de vistas 1 Dia, 2 Semane, 3 Mes, 4 Año
    this.dia_calendario = false;
    this.semana_calendario = false;
    this.mes_calendario = false;
    this.anio_calendario = false;
    this.tipo_calendario = numero;
    if (numero == 1) {
      this.ObservableCalendario.changeCalendarioSelected({ datos_calendario: this.datos_calendario, numero: 1 });
      this.dia_calendario = true;
      this.semana_calendario = false;
      this.mes_calendario = false;
      this.anio_calendario = false;
    } else if (numero == 2) {
      this.ObservableCalendario.changeCalendarioSelected({ datos_calendario: this.datos_calendario, numero: 2 });
      this.semana_calendario = true;
      this.dia_calendario = false;
      this.mes_calendario = false;
      this.anio_calendario = false;
    } else if (numero == 3) {
      this.mes_calendario = true;
      this.dia_calendario = false;
      this.semana_calendario = false;
      this.anio_calendario = false;
    } else if (numero == 4) {
      this.ObservableCalendario.changeCalendarioSelected({ datos_calendario: this.datos_calendario, numero: 4 });
      this.anio_calendario = true;
      this.dia_calendario = false;
      this.semana_calendario = false;
      this.mes_calendario = false;
    }
  }

  GetCalendarioByUsuarioID() {
    this.CalendarioService.GetCalendarioByUsuarioID(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_calendario = result.calendario;
          //fechas en numero
          for (let key in this.datos_calendario) {
            let llave = this.datos_calendario[key].cirugia_fecha;
            if (!this.array_fechas[llave]) this.array_fechas[llave] = 0;
            this.array_fechas[llave]++;
          }
          this.mes_numero = moment().month();
          this.mes_diferencia = 0;
          this.ClasesCalendario();
        }
      })
  }

  ClasesCalendario() {
    this.events = [];

    for (let key in this.datos_calendario) {
      let mes_consulta = moment(this.datos_calendario[key].cirugia_fecha).month();
      if (mes_consulta == this.mes_numero) {

          let fecha_string = this.datos_calendario[key].cirugia_fecha.split('-');;
          let año = fecha_string[0];
          let mes = fecha_string[1];
          let dia = fecha_string[2];
          let año_inicio = parseInt(año);
          let mes_inicio = parseInt(mes) - 1;
          let dia_inicio = parseInt(dia);
          let fecha_inicio = new Date(año_inicio, mes_inicio, dia_inicio);
          let color;
          let hospital = this.datos_calendario[key].hospital_desc != null ? this.datos_calendario[key].hospital_desc : '';
          let anestecia = this.datos_calendario[key].anestesia_desc != null ? this.datos_calendario[key].anestesia_desc : '';
          let personal = this.datos_calendario[key].persona_desc != null ? this.datos_calendario[key].persona_desc : '';
          let procedimiento = this.datos_calendario[key].proce_desc != null ? this.datos_calendario[key].proce_desc : '';
          var label_imagen;
          if (this.datos_calendario[key].tipo == 1) {
            label_imagen = this.actions_cirugia;
          } else if (this.datos_calendario[key].tipo == 2) {
            label_imagen = this.actions_consulta;
          }
          if (this.datos_calendario[key].detpg_pagada == 1) {
            var icono_pago = '<img src="../../../assets/images/icon-pago-over.png" width="23" height="auto">';
          } else {
            icono_pago = '<img src="../../../assets/images/icon-pago-grey.png" width="23" height="auto">';
          }
          if (this.datos_calendario[key].detpg_depositado == 1) {
            var icono_depositado = '<img src="../../../assets/images/icon-deposito-over.png" width="23" height="auto">';
          } else {
            icono_depositado = '<img src="../../../assets/images/icon-deposit-grey.png" width="23" height="auto">';
          }
          if (this.datos_calendario[key].detpg_recibo == 1) {
            var icono_recibo = '<img src="../../../assets/images/icon-recibo-over.png" width="23" height="auto">';
          } else {
            icono_recibo = '<img src="../../../assets/images/icon-recibo-grey.png" width="23" height="auto">';
          }
          if (this.array_fechas[this.datos_calendario[key].cirugia_fecha] >= 4) {
            color = colors.verdevarioseventos;
          }
          if (this.array_fechas[this.datos_calendario[key].cirugia_fecha] < 4) {
            color = colors.verdepocoseventos;
          }
          // let fecha_hoy = new Date();
          // if (fecha_inicio.toDateString() == fecha_hoy.toDateString()) {
          //   color = colors.diaactual;
          // }
          let evento =
          {
            start: fecha_inicio,
            end: fecha_inicio,
            title: '<table class="table table-sm">' +
              '<tr>' +
              '<td>Horario: ' + this.datos_calendario[key].cirugia_hora + '</td>' +
              '<td >Paciente: ' + this.datos_calendario[key].paciente_nombre + '</td>' +
              '<td >' + personal + '/ ' + procedimiento + '' +
              '' + anestecia + ' / ' + hospital + '</td>' +
              '</td>' +
              '<td>' +
              '' + icono_pago + '' +
              '</td>' +
              '<td>' +
              '' + icono_depositado + '' +
              '</td>' +
              '<td>' +
              '' + icono_recibo + '' +
              '</td>' +
              '</tr>' +
              '</table>',
            color: color,
            cssClass: 'test-color-calendar',
            actions: label_imagen,
            datos: this.datos_calendario[key],
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false
          };
          this.events.push(evento);
          this.refresh.next();
      }
    }
  }

  dayClicked({ date, events }: { date; events }): void {
    let eventos_dia = events;
    this.eventos_dia = [];
    for (let key in eventos_dia) {
      eventos_dia[key].datos.cirugia_fecha_formato = moment(eventos_dia[key].datos.cirugia_fecha).locale('es').format('LL');
      this.fecha_selected_calendario = eventos_dia[key].datos.cirugia_fecha_formato;
      this.eventos_dia.push(eventos_dia[key].datos)
    }
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  changeCalendar(dato) {
    
    if (this.tipo_calendario == 3) {
      this.mostrar_mes = true;
      this.calendar_title = this.viewDate.setMonth(this.viewDate.getMonth() + dato);
      this.mes_numero = this.mes_numero + dato;
      this.eventos_dia = [];
      this.ClasesCalendario();
    }
  }

  handleEvent(action, event): void {
    // tipo 1 cirugia , 2 consulta
    this.evento_datos = action;
    //this.evento_datos = event.datos;
    //let resultado_busqueda = event.datos;
    let resultado_busqueda = action;
    if (resultado_busqueda.tipo == 1) {
      this.DatosEventoService.getCirugia(this.token.token, resultado_busqueda.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_cirugia = result.cirugia[0];
            this.paciente_id = this.datos_cirugia.paciente_id;
            this.DatosEventoService.getProveedorAyudantia(this.token.token, resultado_busqueda.cirugia_id)
              .subscribe(result => {
                if (result.valido == 1) {
                  this.datos_proveedor = result.cat_proveedor;
                  this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
                    .subscribe(result => {
                      this.datos_paciente = result.paciente[0];
                      if (result.valido == 1) {
                        let pago_id = this.datos_cirugia.detpg_id;
                        this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                          .subscribe(result => {
                            if (result.valido == 1) {
                              this.detalle_pago = result.detalle_pago[0];
                              this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              this.ObservableExtraer.changedatosPagoSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, actualizar: true });
                              this.ObservableExtraer.changedatosAyudantiaSelected({ datos_cirugia: this.datos_cirugia, detalle_pago: this.detalle_pago, datos_proveedor: this.datos_proveedor });
                              this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_cirugia: this.datos_cirugia });
                              // 1 consulta 2 cirugia manda el color de cambio de menu(icono)
                              this.ObservableService.changeVistaMenu(2)
                              this.router.navigate(['/cirugia'])
                            }
                          })
                      }
                    })
                }
              })
          }
        })
    } else if (resultado_busqueda.tipo == 2) {
      //resultado_busqueda.cirugia_id en tipo 2  es id_consutlta
      let id_consulta = resultado_busqueda.cirugia_id;
      this.DatosEventoService.getConsulta(this.token.token, id_consulta)
        .subscribe(result => {
          if (result.valido == 1) {
            this.datos_consulta = result.consulta[0];
            this.paciente_id = this.datos_consulta.paciente_id;
            this.DatosEventoService.getPaciente(this.token.token, this.paciente_id)
              .subscribe(result => {
                this.datos_paciente = result.paciente[0];
                if (result.valido == 1) {
                  let pago_id = this.datos_consulta.detpg_id;
                  this.DatosEventoService.getDetallePago(this.token.token, pago_id)
                    .subscribe(result => {
                      if (result.valido == 1) {
                        this.detalle_pago = result.detalle_pago[0];
                        this.ObservableExtraer.changedatosSelectedGeneral({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPacienteSelected({ datos_paciente: this.datos_paciente, datos_consulta: this.datos_consulta });
                        this.ObservableExtraer.changedatosPagoSelected({ datos_consulta: this.datos_consulta, detalle_pago: this.detalle_pago, actualizar: true })
                        this.router.navigate(['/consulta'])
                        // 1 consulta 2 cirugia manda el color de cambio de menu(icono)
                        this.ObservableService.changeVistaMenu(1)
                      }
                    })
                }
              })
          }
        })
    }

  }



}


