import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class PendientesService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    AddPendiente(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'pendiente/add', params, { headers: headers })
            .map(res => res.json());
    }
    GetPendiente(token, usuario_id) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'pendiente/getbyusuarioid/' + usuario_id, { headers: headers })
            .map(res => res.json());
    }
    UpdateFav(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'pendiente/update-favorito', params, { headers: headers })
            .map(res => res.json());
    }
    DeleteFav(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'pendiente/delete-fav', params, { headers: headers })
            .map(res => res.json());
    }
}
PendientesService.ngInjectableDef = i0.defineInjectable({ factory: function PendientesService_Factory() { return new PendientesService(i0.inject(i1.Http)); }, token: PendientesService, providedIn: "root" });
