<section>
    <div class="container-fluid containerlogin" style="padding-bottom: 50px;">
        <div class="row align-items-center py-5" style="padding-bottom: 15px !important;width: 640px; /*can be in percentage also.*/height: auto;margin: 0 auto;padding: 10px;position: relative;">
            <div class="col col-lg-12 offset-lg-4" style="display: inline-flex;margin: 0 auto;padding: 10px;">
                    <div style="display: inline-block;width: 300px;">
                        <div *ngIf="!datos_usuario.foto_url" class="profile-pic"> 
                            <img src="../../../assets/images/defaultperson.jpg"  alt="First slide" id="img-slide-1-1">
                        </div>
                        <div *ngIf="datos_usuario.foto_url" class="profile-pic d-flex justify-content-center" >
                            <img  src="{{  url_imagen + datos_usuario.foto_url}}" alt="First slide" id="img-slide-1-1" style="max-height: 200px;">
                        </div>
                    </div>
                    <div style="display: inline-block;vertical-align: middle;width: 600px;margin-top: 10%;margin-left: 40px;">
                        <p class="bienvenido text-center">BIENVENIDO</p> 
                        <p class="doctor1 text-center">DR./DRA. {{datos_usuario.usuario_nombre | uppercase}} {{datos_usuario.usuario_apellidos | uppercase}}</p>
                    </div>
            </div>
            
        </div>
        <div>
            <div class="row" style="width: 640px;height: auto;margin: 0 auto;padding: 10px;position: relative;">
                <div class="col col-lg-12">
                    <div class="carousel slide"  data-ride="carousel" style="width: 650px; height: 690px;">
                        <div class="home_carrusel">
                            <div class="educa" *ngFor="let home of array_img_home" >
                                <div *ngIf="home">
                                    <a href="{{  home.home_url  }}" target="_blank">
                                    <img  style="width: 650px; height: 690px;" src="{{   url_imagen+home.home_img }}"  alt="First slide" id="img-slide-1-1">
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>