import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class AyudantiaProveedoresService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getAyudantia(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    BuscarPagoAyudantia(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'cirugia/busqueda-datos-proveedor', params, { headers: headers })
            .map(res => res.json());
    }
    getAyudantiaByID(cirugia, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + cirugia, { headers: headers })
            .map(res => res.json());
    }
    UpdateAyudantia(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'proveedor/update', params, { headers: headers })
            .map(res => res.json());
    }
    eliminarAyudantia(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'proveedor/delete', params, { headers: headers })
            .map(res => res.json());
    }
    getProveedorByID(usuario, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'proveedor/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    AddProveedor(datos, cirugia) {
        datos.cirugia_id = cirugia;
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'proveedor/add', params, { headers: headers })
            .map(res => res.json());
    }
}
AyudantiaProveedoresService.ngInjectableDef = i0.defineInjectable({ factory: function AyudantiaProveedoresService_Factory() { return new AyudantiaProveedoresService(i0.inject(i1.Http)); }, token: AyudantiaProveedoresService, providedIn: "root" });
