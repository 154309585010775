<!-- Div para mantener el fondo en las dos secciones -->
<!-- JavaScript files-->
<div *ngIf="mostrar_consulta" class="container-fluid containerfondo">
  <!-- Titulos -->
  <section>
    <div class="configura" style="padding-left: 80px; padding-right: 80px;">

      <div class="container">
        <div class="row pt-4">
          <!-- Primera columna -->
          <!-- Segunda columna -->

          <div class="col-md-4">

            <form>
              <app-datos-generales></app-datos-generales>

            </form>
          </div>
          <div class="col-md-4">
            <div class="fondo-titulo-campos">
              <h4 class="titulo-campos">DETALLES DE PAGO</h4>
            </div>
            <form>
              <app-detalle-pago></app-detalle-pago>
            </form>
          </div>
          <!-- Tercera columna -->
          <div class="col-md-4">
            <div class="fondo-titulo-campos">
              <h4 class="titulo-campos">DATOS DEL PACIENTE</h4>
            </div>
            <form style="margin-top: 72px;">
              <app-datos-paciente></app-datos-paciente>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="row offset-1">
            <div class="col-4">
              <input #elimnardatogeneralimg (change)="SubirImagenconsulta($event,1);" type="file" class="inputfile" data-toggle="popover"
                data-placement="top" title="Selecciona tu archivo" data-html="true" data-trigger="hover">
              <label>
                <div class="pg-empty-placeholder">
                  <img *ngIf="url_perfil" [src]="url_perfil" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                  <img *ngIf="imagen_general_principal" src="{{url_imagen+imagen_general_principal.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                </div>
              </label>
              <label class="imagen-add" *ngIf="perfil_file_imagen" for="1">Se ha Agregado una Imagen</label>
            </div>

            <div class="col-4">
              <input #eliminarimagendatog2 (change)="SubirImagenConsulta2($event,2);" type="file" class="inputfile">
              <label>
                <div class="pg-empty-placeholder">
                  <img *ngIf="url_consulta_img" [src]="url_consulta_img" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                  <img *ngIf="imagen_general_secundario" src="{{url_imagen+imagen_general_secundario.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                </div>
              </label>

              <label class="imagen-add" *ngIf="perfil_file_archivo" for="1">Se ha Agregado una Imagen</label>
            </div>
            <div class="col-1"></div>
            <div class="col-4">
              <img class="pointer" data-toggle="modal" *ngIf="url_perfil || imagen_general_principal " (click)="verImagenEvento(1)" data-target="#perfil_file_imagen"
                src="../../../assets/images/box-image.png" width="auto" height="80px">
            </div>

            <div class="col-3">
              <img class="pointer" data-toggle="modal" *ngIf="url_consulta_img || imagen_general_secundario" (click)="verImagenEvento(2)"
                data-target="#perfil_file_archivo" src="../../../assets/images/box-image.png" width="auto" height="80px">
            </div>
          </div>
        </div>
        <div *ngIf="datos_session.acceso_pago == 1" class="col-4">
          <div class="row offset-1">
            <div class="col-4">
              <input #myInput (change)="SubirImagenDetallePago($event,1);" type="file" class="inputfile" data-toggle="popover" data-placement="top"
                title="Selecciona tu archivo" data-html="true" data-trigger="hover">
              <label>
                <div class="pg-empty-placeholder">
                  <img *ngIf="url_detalle_pago" [src]="url_detalle_pago" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                  <img *ngIf="imagen_pago_principal" src="{{url_imagen+imagen_pago_principal.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                </div>
              </label>
              <label class="imagen-add" *ngIf="perfil_file_imagen_pago" for="1">Se ha Agregado una Imagen</label>
            </div>

            <div class="col-4">
              <input #elimnardetallepago2 (change)="SubirImagenDetallePago2($event,2);" type="file" class="inputfile">
              <label>
                <div class="pg-empty-placeholder">
                  <img *ngIf="url_detalle_pago2" [src]="url_detalle_pago2" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                  <img *ngIf="imagen_pago_secundario" src="{{url_imagen+imagen_pago_secundario.imagen_url}}" style="width: 85px; height: 85px; max-width: 85px; max-height: 85px; background-size: cover;">
                </div>
              </label>
              <label class="imagen-add" *ngIf="perfil_file_imagen_pago2" for="1">Se ha Agregado una Imagen</label>
            </div>
            <div class="col-1"></div>
            <div class="col-4">
              <img class="pointer" data-toggle="modal" *ngIf="url_detalle_pago || imagen_pago_principal " (click)="verImagenEvento(3)"
                data-target="#modal_archivo_preeliminar" src="../../../assets/images/box-image.png" width="auto" height="80px">
            </div>
            <div class="col-3">
              <img class="pointer" data-toggle="modal" *ngIf="url_detalle_pago2 || imagen_pago_secundario " (click)="verImagenEvento(4)"
                data-target="#modal_archivo_preeliminar2pago" src="../../../assets/images/box-image.png" width="auto" height="80px">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Cierra el fondo -->
  <!--detalle de pago 1-->
  <div class="modal fade pg-show-modal" id="modal_archivo_preeliminar" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
    data-pg-name="Colaboradores">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="display: unset!important;">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          <h4 class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 1</h4>
        </div>
        <div class="modal-body">
          <img *ngIf="url_detalle_pago" class="mx-auto d-block" [src]="url_detalle_pago" width="100%">
        </div>
        <div *ngIf="datos_actualizar && !url_detalle_pago " class="modal-body">
          <img *ngIf="imagen_pago_principal" class="mx-auto d-block" src="{{url_imagen+imagen_pago_principal.imagen_url}}" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" (click)="EliminarDetallePagoImg()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

  <!--detalle de pago 2-->
  <div class="modal fade pg-show-modal" id="modal_archivo_preeliminar2pago" tabindex="-1" role="dialog" aria-hidden="true"
    data-backdrop="true" data-pg-name="Colaboradores">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header " style="display: unset!important;">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          <h4 class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 2</h4>
        </div>
        <div class="modal-body">
          <img *ngIf="url_detalle_pago2" class="mx-auto d-block" [src]="url_detalle_pago2" width="100%">
        </div>
        <div *ngIf="datos_actualizar && !url_detalle_pago2 " class="modal-body">
          <img *ngIf="imagen_pago_secundario" class="mx-auto d-block" src="{{url_imagen+imagen_pago_secundario.imagen_url}}" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" (click)="EliminarDetallePagoImg2()" class="btn btn-form" data-dismiss="modal">Eliminar</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <!--Modal dato general imagen 1-->
  <div class="modal fade pg-show-modal" id="perfil_file_imagen" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
    data-pg-name="Colaboradores">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="display: unset!important;">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          <h4 class="modal-title text-center color-titulos">Imagen 1</h4>
        </div>
        <div class="modal-body">
          <img class="mx-auto d-block" *ngIf="url_perfil" [src]="url_perfil" width="100%">
        </div>
        <div *ngIf="datos_actualizar && !url_consulta_img " class="modal-body">
          <img class="mx-auto d-block" *ngIf="imagen_general_principal" src="{{url_imagen+imagen_general_principal.imagen_url}}" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" (click)="EliminarDatoGeneralImg()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <!--Modal dato general perfil_file_archivo 1 -->
  <div class="modal fade pg-show-modal" id="perfil_file_archivo" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
    data-pg-name="Colaboradores">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="display: unset!important;">
          <h4 class="modal-title text-center color-titulos" style="font-family: Open Sans;">Imagen 2</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
          <img class="mx-auto d-block" *ngIf="url_consulta_img" [src]="url_consulta_img" width="100%">
        </div>
        <div *ngIf="datos_actualizar && !url_consulta_img " class="modal-body">
          <img class="mx-auto d-block" *ngIf="imagen_general_secundario" src="{{url_imagen+imagen_general_secundario.imagen_url}}"
            width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" (click)="EliminarDatoGeneralImg2()" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Eliminar</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>