import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ObservableService } from "src/app/observable.service";
import { Subscription } from "rxjs/Subscription";
import { BuscadorHeaderModel } from "src/app/models/BuscadorHeader";
import { DatosGeneralesService } from "src/app/services/consulta/datos-generales/datos-generales.service";
import { ObservableBusqueda } from "src/app/services/buscar/buscar-obserbable.service";
import { BusquedaGeneralModel } from "../../models/BusquedaGeneralModel";
import { ObservableCirugia } from '../../services/cirugia/observable-cirugia.service';
import { ObservableExtraer } from '../../services/extraer/extraer-observable.service';
import swal from 'sweetalert2';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public datos_cirugia: BusquedaGeneralModel;
  public mostrar_consulta: boolean;
  public mostrar_cirugia: boolean;
  public mostrar_busqueda: boolean;
  public mostrar_calendario: boolean;
  public mostrar_pendientes: boolean;
  public mostrar_estadisticas: boolean;
  public mostrar_educacion: boolean;
  public mostrar_configuracion: boolean;
  public href: string = "";
  public perfil_menu;
  public cirugia_actived: boolean;
  public buscar_actived: boolean;
  public calendario_actived: boolean;
  public pendientes_actived: boolean;
  public estadisticas_actived: boolean;
  public educacion_actived: boolean;
  public configuracion_actived: boolean;
  public consulta_actived: boolean;
  public session;
  public texto_buscador;
  public datos_session;
  private datos_buscador: BuscadorHeaderModel;
  private unsubscribe: Subscription = new Subscription();
  public resultados_busqueda = new Array();
  constructor(
    private router: Router,
    private ObservableService: ObservableService,
    private DatosGeneralesService: DatosGeneralesService,
    private ObservableBusqueda: ObservableBusqueda,
    private ObservableCirugia: ObservableCirugia,
    private ObservableExtraer: ObservableExtraer
  ) {
    this.datos_cirugia = new BusquedaGeneralModel();
    this.href = this.router.url;
    this.cirugia_actived = false;
    this.buscar_actived = false;
    this.calendario_actived = false;
    this.pendientes_actived = false;
    this.estadisticas_actived = false;
    this.educacion_actived = false;
    this.configuracion_actived = false;
    this.consulta_actived = false;
    this.Session();
  }

  ngOnInit() {
    this.mostrar_consulta = false;
    this.mostrar_cirugia = false;
    this.mostrar_busqueda = false;
    this.mostrar_calendario = false;
    this.mostrar_pendientes = false;
    this.mostrar_estadisticas = false;
    this.mostrar_educacion = false;
    this.mostrar_configuracion = false;
    this.MostrarMenu();
    const menu_observable = this.ObservableService.vistaMenuMessage.subscribe(
      result => {
        if (result != null && result != undefined && result != "") {
          this.menu_actived(result);
        }
      }
    );
    this.unsubscribe.add(menu_observable);
  }
  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }
  Session() {
    this.datos_buscador = new BuscadorHeaderModel( "", "","", "",0,0,0,0,0,0,0,"",0,0,"",0);
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem("usuario");
    this.perfil_menu = JSON.parse(localStorage.getItem("app"));
    if (this.session != null) {
      this.datos_session = JSON.parse(this.session);
      if (this.router.url == "/consulta") {
        this.menu_actived(1);
      } else if (this.router.url == "/cirugia") {
        this.menu_actived(2);
      } else if (this.router.url == "/busqueda-general") {
        this.menu_actived(3);
      } else if (this.router.url == "/calendario") {
        this.menu_actived(4);
      } else if (this.router.url == "/pendientes") {
        this.menu_actived(5);
      } else if (this.router.url == "/estadisticas") {
        this.menu_actived(6);
      } else if (this.router.url == "/educacion") {
        this.menu_actived(7);
      } else if (this.router.url == "/configuracion") {
        this.menu_actived(8);
      } else if (this.router.url == "/home") {
        this.menu_actived(0);
      }
    } else if (this.router.url == "/confirmar/true") {
      this.ObservableService.changePw(true);
      this.router.navigate(["/index"]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  menu_actived(numero) {
    //this.playAudio();
    //numero 1 consulta, 2 cirugia, 3 buscar, 4 calendario,5 pendientes
    //6 estadisticas, 7 educacio , 8 configuracion
    this.cirugia_actived = false;
    this.buscar_actived = false;
    this.calendario_actived = false;
    this.pendientes_actived = false;
    this.estadisticas_actived = false;
    this.educacion_actived = false;
    this.configuracion_actived = false;
    this.consulta_actived = false;
    if (numero == 2) {
      this.cirugia_actived = true;
    } else if (numero == 3) {
      this.buscar_actived = true;
    } else if (numero == 4) {
      this.calendario_actived = true;
    } else if (numero == 5) {
      this.pendientes_actived = true;
    } else if (numero == 6) {
      this.estadisticas_actived = true;
    } else if (numero == 7) {
      this.educacion_actived = true;
    } else if (numero == 8) {
      this.configuracion_actived = true;
    } else if (numero == 1) {
      this.consulta_actived = true;
    }
  }

  CerrarSession() {
    localStorage.removeItem("usuario");
    localStorage.removeItem("token");
    //localStorage.clear();
    this.router.navigate(["/"]);
    this.Session();
  }

  BusquedaGeneral() {
    //this.datos_cirugia.usuario_id = this.datos_session.usuario_id;
    this.datos_cirugia.usuario_id =
        this.datos_session.usuid_padre != 0
          ? this.datos_session.usuid_padre
          : this.datos_session.usuario_id;
    this.datos_cirugia.tipo_busqueda = "busqueda";
    this.datos_cirugia.cirugia_search = 's';
    this.datos_cirugia.consulta_search = 's';
    this.DatosGeneralesService.BuscarDatos(this.datos_cirugia).subscribe(
      result => {
        this.resultados_busqueda= result.datos;
        console.log("El resultado", result);
        this.ObservableBusqueda.changeResultadoBusqueda({
          datos: this.resultados_busqueda
        });
        this.router.navigate(["/busqueda-general"]);
        this.ObservableService.changeVistaMenu(3);
        this.datos_cirugia = new BusquedaGeneralModel();
        // this.datos_buscador = new BuscadorHeaderModel("", "","","",0,0,
        //   0,
        //   0,
        //   0,
        //   0,
        //   0,
        //   "",
        //   0,
        //   0,
        //   "",
        //   0
        // );
      }
    );
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/sonido/click.mp3";
    audio.load();
    audio.play();
  }

  MostrarMenu() {
    console.log("*-*-*-",this.perfil_menu);
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == "1") {
        this.mostrar_consulta = true;
      } else if (this.perfil_menu[key].menuapp_id == "2") {
        this.mostrar_cirugia = true;
      } else if (this.perfil_menu[key].menuapp_id == "3") {
        this.mostrar_busqueda = true;
      } else if (this.perfil_menu[key].menuapp_id == "4") {
        this.mostrar_calendario = true;
      } else if (this.perfil_menu[key].menuapp_id == "5") {
        this.mostrar_pendientes = true;
      } else if (this.perfil_menu[key].menuapp_id == "6") {
        this.mostrar_estadisticas = true;
      } else if (this.perfil_menu[key].menuapp_id == "7") {
        this.mostrar_educacion = true;
      } else if (this.perfil_menu[key].menuapp_id == "9") {
        this.mostrar_configuracion = true;
      }
    }
  }

  resteCirugia(){
    swal({
      title: 'Ops',
      text: 'Si no guardas perderás los cambios realizados en la cirugía!',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonColor: "#EEC35A",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        console.log(result);
        
        this.datos_cirugia = new BusquedaGeneralModel();
        this.ObservableBusqueda.changeResultadoBusqueda( {limpiar: true, datos: null} );
        this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });
        this.ObservableExtraer.changedatosPagoSelected({ limpiar: true });
        this.ObservableCirugia.changeResetDatosCirugia({ limpiar: true });
        this.ObservableExtraer.changedatosAyudantiaSelected({ limpiar: true });
      }
    })
  }


}


// this.resultados_busqueda = [];
// let usuario_id = JSON.parse(this.session).usuario_id;
// this.datos_buscador.usuario_id = usuario_id;
// this.datos_buscador.tipo_filtro = 1;
// this.datos_buscador.paciente = this.texto_buscador;

// this.DatosGeneralesService.BuscarPago(this.datos_buscador)
//   .subscribe(result => {
//     var primer_resultado = result.datos;
//     if (result.valido == 1 && primer_resultado.length > 0) {
//       for (let key in primer_resultado) {
//         this.resultados_busqueda.push(primer_resultado[key]);
//       }
//     }
//     this.datos_buscador.cirugia_datosclinicos = this.texto_buscador
//     this.DatosGeneralesService.BuscarPago(this.datos_buscador)
//       .subscribe(result => {
//         var segundo_Resultado = result.datos;
//         if (result.valido == 1 && segundo_Resultado.length > 0) {
//           for (let i in segundo_Resultado) {
//             this.resultados_busqueda.push(segundo_Resultado[i]);
//           }
//         }
