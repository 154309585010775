import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class IndexService {
  public url: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
  }

  public getEspecialidad() {
    //consejo dejar solo los links que co autorizacion de esta forma, que no continen una session
    let headers = new Headers({'Content-Type' : 'application/json' });
    return this.http.get(this.url + 'cat_especialidad/get', { headers: headers })
      .map(res => res.json());
  }
  public AddUsuario(d){
    let headers = new Headers({'Content-Type' : 'application/json'});
    let params  = JSON.stringify(d);
    return this.http.post(this.url +'usuario/add-usuario',params,{headers : headers})
    .map(res => res.json())
  }

  public ActivarCodigo(d){
  let headers = new Headers({'Content-Type' : 'application/json'});
  return this.http.get(this.url +'usuario/usuario-verificacodigo_cd/'+d,{headers : headers})
  .map(res => res.json())
  }

  public Login(d){
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.get(this.url +'usuario/get-usuario-login/'+d.usuario_pw+'/'+d.usuario_email,{headers : headers})
    .map(res => res.json())
  }

  public RecoberyPw(d){
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.get(this.url +'usuario/get-recovery_pw/'+d,{headers : headers})
    .map(res => res.json())
  }


  public ResetPw(d){
    let headers = new Headers({'Content-Type' : 'application/json'});
    let params  = JSON.stringify(d);
    return this.http.post(this.url +'usuario/reset_pw_recovery',params,{headers : headers})
    .map(res => res.json())
  }
  public getAvisos(){
    let headers = new Headers({'Content-Type' : 'application/json'});
    return this.http.get(this.url +'usuario/get-avisos',{headers : headers})
    .map(res => res.json())
  }
  
  public getPerfilMenuApp(perfil, token){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url +'per_menuapp/getbyid/'+perfil,{headers : headers})
    .map(res => res.json())
  }
}





// public getEspecialidad() {
//   //consejo dejar solo los links que co autorizacion de esta forma, que no continen una session
//   let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NDQyMDQ3ODcsImV4cCI6MTU0NTQxNDM4N30.MA7lOHNgb3cDpqMQvBy4XBRoj8Pt7Yc5z7A9Z6PjDjE';
//   let headers = new Headers({'Content-Type' : 'application/json' , 'authorization': ': '+token+'' });
//   return this.http.get(this.url + 'cat_especialidad/get', { headers: headers })
//     .map(res => res.json());
// }
// }
