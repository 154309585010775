<div *ngIf="mostrar_busqueda">
  <section class="">
    <!-- Div para mantener el fondo en las dos secciones -->
    <div class="container-fluid containerfondobuscar2" data-pgc-define="buscador" data-pgc-define-name="buscador">
      <div class="container pt-3">
        <div class="row">
          <div class="col-md-10">
            <div style="flex-wrap: inherit;" class="row">
              <div class="col-md-8">
                <!-- <form *ngIf="tipo_menu == 1" > -->
                <form>

                  <div class="row">
                    <div class="form-group col-md-6">

                      <div class="d-flex">
                        <div class="p-1 flex-shrink-1"><img src="../../../assets/images/menu-consulta-help.png"
                            width="auto" height="30px" /></div>
                        <div class="p-2 w-100">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" [(ngModel)]="datos_cirugia.consultaSearch"
                              #ngModel="ngModel" id="consulta" type="checkbox" value="consulta"
                              (change)="typeSearchSelect(1)" name="consulta_search" ngModel>
                            <label class="custom-control-label consulta p-1" for="consulta">Consulta</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="form-group col-md-6">

                      <div class="d-flex">
                        <div class="p-1 flex-shrink-1"> <img src="../../../assets/images/menu-cirugia-help.png"
                            width="auto" height="30px" /></div>
                        <div class="p-2 w-100">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" [(ngModel)]="datos_cirugia.cirugiaSearch"
                              (change)="typeSearchSelect(2)" name="cirugia_search" id="cirugia" type="checkbox" gender
                              value="cirugia" ngModel>
                            <label class="custom-control-label consulta p-1" for="cirugia">Cirugia</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6">

                      <input id="d1" name="de_fecha" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_fecha_ini"
                        style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 268px;"
                        class="form-control letraCuadrosFormulario " placeholder="De Fecha" [owlDateTimeTrigger]="dat1"
                        [owlDateTime]="dat1" />

                      <owl-date-time [pickerType]="'calendar'" #dat1></owl-date-time>
                      <button class="read-on btn btn-default" type="button" (click)="clearFechaDe()"
                        style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: -3px;background-color: #7C9695;">
                        <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>

                    </div>
                    <div class="form-group col-6">
                      <input id="d2" name="a_fecha_cita" #name="ngModel" [(ngModel)]="datos_cirugia.cirugia_fecha_fin"
                        style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 268px;"
                        class="form-control letraCuadrosFormulario " placeholder="A Fecha" [owlDateTimeTrigger]="dat2"
                        [owlDateTime]="dat2" />

                      <owl-date-time [pickerType]="'calendar'" #dat2></owl-date-time>
                      <button class="read-on btn btn-default" type="button" (click)="clearADe()"
                        style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: -3px;background-color: #7C9695;">
                        <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6">
                      <input type="nombrepaciente" name="nombre_paciente" #name="ngModel"
                        [(ngModel)]="datos_cirugia.paciente" class="form-control letraCuadrosFormulario"
                        id="inputConfirmarPassword" placeholder="Paciente" />
                    </div>
                    <div class="form-group col-6">
                      <input OnlyNumber="true" type="edad_paciente" name="edad_paciente" #name="ngModel"
                        [(ngModel)]="datos_cirugia.paciente_edad" class="form-control letraCuadrosFormulario"
                        id="inputConfirmarPassword" placeholder="Edad" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <select style="font-size: 12px; font-weight: 300;" name="hospital" #name="ngModel"
                        [(ngModel)]="datos_cirugia.hospital_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="hospt">
                        <option [value]="''">Seleccione un Hospital/Consultorio</option>
                        <option *ngFor="let hospital of cat_hospital" [value]="hospital.hospital_id">
                          {{ hospital.hospital_desc }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <select [disabled]="!datos_cirugia.cirugiaSearch" name="cirujanos" #name="ngModel"
                        [(ngModel)]="datos_cirugia.cirujano_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="cirujano">
                        <option [value]="''">Cirujano</option>
                        <option *ngFor="let cirujano of cat_cirujanos" [value]="cirujano.personal_id">
                          {{ cirujano.persona_desc }}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <select [disabled]="!datos_cirugia.cirugiaSearch" name="procedimiento" #name="ngModel"
                        [(ngModel)]="datos_cirugia.proce_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                        id="procedimiento">
                        <option [value]="''">Procedimiento</option>
                        <option *ngFor="let procedimiento of cat_proce" [value]="procedimiento.proce_id">
                          {{ procedimiento.proce_desc }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <select [disabled]="!datos_cirugia.cirugiaSearch" name="anestesiologo" #name="ngModel"
                        [(ngModel)]="datos_cirugia.anestesiologo_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                        id="anestesiologoid">
                        <option [value]="''">Anestesiólogo</option>
                        <option *ngFor="let anestesiologo of cat_anestesiologo" [value]="anestesiologo.personal_id">
                          {{ anestesiologo.persona_desc }}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <select [disabled]="!datos_cirugia.cirugiaSearch" name="anestesia" #name="ngModel"
                        [(ngModel)]="datos_cirugia.anestesia_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="anestesiaid">
                        <option [value]="''">Anestesia</option>
                        <option *ngFor="let anestesia of cat_anestesia" [value]="anestesia.anestesia_id">
                          {{ anestesia.anestesia_desc }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <input type="text" name="cirugia_datosclinicos" #name="ngModel"
                        [(ngModel)]="datos_cirugia.cirugia_datosclinicos" class="form-control letraCuadrosFormulario"
                        id="datos_clinicos" placeholder="Datos Clínicos" />
                    </div>
                  </div>
                </form>
                <section class="containerfondobuscar2">
                  <br />
                  <br />
                  <!--**********************************FORMULARIO AYUDANTIA**********************************************************************+-->
                  <div style="margin-top: -39px;" class="form-row">
                    <div class="form-group col-12">
                      <select [disabled]="!datos_cirugia.cirugiaSearch" name="proveedor" #name="ngModel"
                        [(ngModel)]="datos_cirugia.proveedor_id"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="anestesiaid">
                        <option [value]="''">Ayudantia Y Proveedores</option>
                        <option *ngFor="let ayudantia of cat_ayudantia" [value]="ayudantia.ayudantia_id">
                          {{ ayudantia.ayudantia_desc }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6">
                      <input [disabled]="!datos_cirugia.cirugiaSearch" type="text" OnlyNumber="true" name="numero_monto"
                        #name="ngModel" [(ngModel)]="datos_cirugia.proveedor_monto"
                        class="btn btn-block letraCuadrosFormulario" id="monto_proveedor" placeholder="Monto" />
                    </div>
                    <div class="form-group col-6">
                      <input [disabled]="!datos_cirugia.cirugiaSearch" type="string" name="recibo" OnlyNumber="true"
                        #name="ngModel" [(ngModel)]="datos_cirugia.proveedor_recibo"
                        class="btn btn-block letraCuadrosFormulario" id="recibo_numero" placeholder="Recibo" />
                    </div>
                    <div class="form-group col-6">
                      <input [disabled]="!datos_cirugia.cirugiaSearch" name="fecha_deposito" #name="ngModel"
                        [(ngModel)]="
                        datos_cirugia.proveedor_fecha_ini
                        " id="datepicker5"
                        style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 268px;"
                        class="btn btn-block letraCuadrosFormulario " placeholder="De Fecha" [owlDateTimeTrigger]="dt3"
                        [owlDateTime]="dt3" />
                      <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
                      <button class="read-on btn btn-default" type="button" (click)="clearAyudnatiaFechaDe()"
                        style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                        <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                    </div>
                    <div class="form-group col-6">
                      <input [disabled]="!datos_cirugia.cirugiaSearch" placeholder="A Fecha" name="fecha_deposito_a"
                        #name="ngModel" [(ngModel)]="
                        datos_cirugia.proveedor_fecha_fin
                        " id="datepicker6"
                        style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 268px;"
                        class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                        [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" />
                      <owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time>
                      <button class="read-on btn btn-default" type="button" (click)="clearAyudnatiaFechaA()"
                        style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                        <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                    </div>
                    <div class="form-group col-md-12" style="margin-top: -15px;">
                      <label>Estas fechas corresponden al recibo</label>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12" style="margin-top: -15px;">
                      <input [disabled]="!datos_cirugia.cirugiaSearch" type="text" name="detalle_ayudantia"
                        #name="ngModel" [(ngModel)]="datos_cirugia.proveedor_det"
                        class="btn btn-block letraCuadrosFormulario" id="detalles_ayudatnta"
                        placeholder="Detalles de Ayudantía o Proveedor" />
                      <br />
                      <div class="form-row" style="margin-top: 20px">
                        <button type="button" class="btn btn-form btn-block btn-lg font-weight-bold"
                          (click)="BuscarDatos();scroll(targetResultados)" data-toggle="modal" data-target="#modal1">
                          BUSCAR
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--**********************************TERMINA FORMULARIO AYUDANTIA**********************************************************************+-->
                </section>
              </div>

              <section class="containerfondobuscar2 col-md-7 ">
                <div style="max-width: 85%;">
                  <!--**********************************FORMULARIO PAGO**********************************************************************+-->
                  <!-- Segunda columna -->
                  <div *ngIf="datos_session.acceso_pago == 1">
                    <form class="form-group col-md-12">

                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex">
                            <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-pago.png" width="auto"
                                height="20px" /></div>
                            <div class="p-1 w-100">
                              <p class="consulta">¿Pagado?</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 text-right">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" (change)="esPagada(1)" [(ngModel)]="consulta_pagada"
                                  type="checkbox" name="pagada" id="pagada">
                                <label class="custom-control-label consulta p-1" for="pagada">Si</label>
                              </div>
                            </li>
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" (change)="esPagada(2)"
                                  [(ngModel)]="consulta_nopagada" type="checkbox" name="noPagada" id="noPagada">
                                <label class="custom-control-label consulta p-1" for="noPagada">No</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-6">
                          <div>
                            <input [disabled]="consulta_nopagada" OnlyNumber="true" name="cantidad_pago" #name="ngModel"
                              [(ngModel)]="datos_cirugia.cantidad" type="text"
                              class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                              placeholder="Cantidad" />
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <select [disabled]="consulta_nopagada" #name="ngModel"
                            [(ngModel)]="datos_cirugia.id_formapago" class="btn btn-form-conf btn-block btn-lg"
                            name="forma_pago" id="forma de pago">
                            <option [value]="''"> Forma de Pago</option>
                            <option *ngFor="let pago of cat_fpago" [value]="pago.fpago_id">{{ pago.fpago_desc }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-6">
                          <select class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                            [(ngModel)]="datos_cirugia.aseguradora_id" #name="ngModel" name="aseguradora"
                            id="aseguradora">
                            <option [value]="''">Aseguradora</option>
                            <option *ngFor="let aseguradora of cat_aseguradora" [value]="aseguradora.aseguradora_id">
                              {{ aseguradora.aseguradora_desc }}</option>
                          </select>
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input name="fecha_pago_de" #name="ngModel" [(ngModel)]="datos_cirugia.fecha_pago_ini"
                            id="datepicker1"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;padding-left: 2px;"
                            class="form-control letraCuadrosFormulario " placeholder="De" [owlDateTimeTrigger]="dt5"
                            [owlDateTime]="dt5" />
                          <owl-date-time [pickerType]="'calendar'" #dt5></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearPagadoDe()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input placeholder="A :" name="fecha_pago_a" #name="ngModel"
                            [(ngModel)]="datos_cirugia.fecha_pago_fin" id="datepicker2" [owlDateTimeTrigger]="dt6"
                            [owlDateTime]="dt6"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;height: 32px;padding-left: 2px;margin-left: 10px;"
                            class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" />

                          <owl-date-time [pickerType]="'calendar'" #dt6></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearPagadoA()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex">
                            <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-deposit.png"
                                width="auto" height="20px" /></div>
                            <div class="p-1 w-100">
                              <p class="consulta">¿Depositado?</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 text-right">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" [(ngModel)]="deposito_pagado"
                                  (change)="esDeposito(1)" #name="ngModel" type="checkbox" name="pagado" id="pagado"
                                  [value]="true">
                                <label class="custom-control-label consulta p-1" for="pagado">Si</label>
                              </div>
                            </li>
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" [(ngModel)]="deposito_nopagado"
                                  (change)="esDeposito(2)" #name="ngModel" type="checkbox" name="nopagado" id="nopagado"
                                  [value]="false">
                                <label class="custom-control-label consulta p-1" for="nopagado">No</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-6">
                          <select [disabled]="deposito_nopagado" #name="ngModel" [(ngModel)]="datos_cirugia.id_banco"
                            class="btn btn-form-conf btn-block btn-lg" name="banco_rec" id="banco_Rec">
                            <option [value]="''">Banco Receptor</option>
                            <option *ngFor="let banco of banco_desc" [value]="banco.banco_id">{{ banco.banco_desc }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input name="fecha_deposito" #name="ngModel" [(ngModel)]="datos_cirugia.fecha_deposito_ini"
                            id="datepicker3"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;height: 32px;padding-left: 2px;"
                            class="form-control letraCuadrosFormulario " placeholder="De" [owlDateTimeTrigger]="dt7"
                            [owlDateTime]="dt7" />
                          <owl-date-time [pickerType]="'calendar'" #dt7></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearDepositadoDe()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input placeholder="A : " name="fecha_depo_a" #name="ngModel"
                            [(ngModel)]="datos_cirugia.fecha_deposito_fin" id="datepicker4" [owlDateTimeTrigger]="dt8"
                            [owlDateTime]="dt8"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;height: 32px;padding-left: 2px;margin-left: 10px;"
                            class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" />
                          <owl-date-time [pickerType]="'calendar'" #dt8></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearDepositadoA()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex">
                            <div class="p-1 flex-shrink-1"><img src="../../../assets/images/icon-recibo.png"
                                width="auto" height="20px" /></div>
                            <div class="p-1 w-100">
                              <p class="consulta">¿Recibo?</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 text-right">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" [(ngModel)]="recibo_pagado" (change)="esRecibo(1)"
                                  #name="ngModel" type="checkbox" name="reciboPagado" id="reciboPagado">
                                <label class="custom-control-label consulta p-1" for="reciboPagado">Si</label>
                              </div>
                            </li>
                            <li class="list-inline-item">
                              <div class="custom-control custom-radio">
                                <input class="custom-control-input" [(ngModel)]="recibo_nopagado" (change)="esRecibo(2)"
                                  #name="ngModel" type="checkbox" name="recibonoPagado" id="recibonoPagado">
                                <label class="custom-control-label consulta p-1" for="recibonoPagado">No</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="form-row mb-1">
                        <div class="form-group col-6">
                          <input [disabled]="recibo_nopagado" OnlyNumber="true" type="text" placeholder="No de Recibo"
                            name="recibo" #name="ngModel" [(ngModel)]="datos_cirugia.num_recibo"
                            class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" />
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input name="fecha_recibo_ini" #name="ngModel" [(ngModel)]="datos_cirugia.fecha_recibo_ini"
                            id="datepicker5"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;height: 32px;padding-left: 2px;"
                            class="form-control letraCuadrosFormulario " placeholder="De" [owlDateTimeTrigger]="dt9"
                            [owlDateTime]="dt9" />
                          <owl-date-time [pickerType]="'calendar'" #dt9></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearReciboDe()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                        <div class="form-group col-3" style="display: contents;">
                          <input placeholder="A :" name="fecha_deposito_a" #name="ngModel"
                            [(ngModel)]="datos_cirugia.fecha_recibo_fin" id="datepicker6"
                            style="font-size: 12px; font-weight: 300;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0;width: 72px;height: 32px;padding-left: 2px;margin-left: 10px;"
                            class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                            [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" />
                          <owl-date-time [pickerType]="'calendar'" #dt10></owl-date-time>
                          <button class="read-on btn btn-default" type="button" (click)="clearReciboA()"
                            style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;margin-top: 0px;background-color: #7C9695;">
                            <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <input type="nombrepaciente" name="detalle_pago" #name="ngModel"
                            [(ngModel)]="datos_cirugia.detalle_pago" class="form-control letraCuadrosFormulario"
                            id="detail_pago" placeholder="Detalles Del Pago" />
                        </div>
                      </div>
                      <br />
                    </form>
                  </div>

                  <!--**********************************FORMULARIO PAGO**********************************************************************+-->
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="max-width: 80%; margin-left: 8%;" class="col-md-4">
      <!--**********************************FORMULARIO EXTRAER DATOS**************************************************************-->
      <section class="containerfondobuscar2" #targetResultados>

        <div style="font-size: 18px;padding-bottom: 8px;" class="row consulta pl-4 pt-1 pb-3">
          RESULTADOS:
          <span style="font-size: 18px; font-weight: bold; color: #737373;" class="consulta2"> &nbsp;
            {{resultado_busqueda.length}}</span>
        </div>
        <hr class="hrstopp" style="margin-bottom: 0px;margin-top: 0px;">

        <div style="margin-bottom: 2%; overflow-x: hidden;margin-top: 0px;" id="table-wrapper">


          <table *ngFor="let resultado of resultado_busqueda" class="table table-sm table-borderless"
            style="margin-bottom: 0px; width: 100%;">
            <tbody>

              <tr>
                <th colspan="8" class="letraresultadosbuscar2">
                  <ul class="list-inline" style="margin-bottom: 0px;">
                    <li class="list-inline-item">{{resultado.paciente_nombre}}</li>
                    <li class="list-inline-item">FECHA: {{resultado.cirugia_fecha | date: 'dd/MM/yyyy'}}</li>
                    <li class="list-inline-item">HORA: {{resultado.cirugia_hora}}</li>
                    <!--Persona desc es el nombre de cirujano-->
                    <li *ngIf="resultado.tipo == 1" class="letraresultadosbuscar">{{resultado.cirujano_desc}} /
                      {{resultado.anestesiologo_desc}}</li>
                    <li class="letraresultadosbuscar">{{resultado.proce_desc}}/ {{resultado.hospital_desc}} /
                      {{resultado.anestesia_desc}}</li>
                  </ul>
                </th>
              </tr>

              <tr>
                <td colspan="2" style="width:128px;padding-top:0;padding-bottom: 0;">
                  <div class="media" *ngIf="datos_session.acceso_pago == 1">
                    <img *ngIf="resultado.detpg_pagada == 1" class="mr-3" src="../../../assets/images/icon-pago.png"
                      width="30">
                    <img *ngIf="resultado.detpg_pagada == 0" class="mr-3"
                      src="../../../assets/images/icon-pago-grey.png" width="30" height="auto">
                    <div class="media-body " *ngIf="datos_session.acceso_pago == 1 && resultado.detpg_pagada == 1">
                      <span>
                        ${{resultado.detpg_cantidad}} <br>
                        {{resultado.fpago_desc}} <br> </span>
                       <span *ngIf="resultado.detpg_fechapago != '0000-00-00'"> {{resultado.detpg_fechapago | date: 'dd/MM/yyyy'}}</span>
                    </div>
                  </div>
                </td>

                <td colspan="2" style="width:128px;padding-top:0;padding-bottom: 0;">
                  <div class="media" *ngIf="datos_session.acceso_pago == 1">
                    <img *ngIf="resultado.detpg_depositado == 1" class="mr-3"
                      src="../../../assets/images/icon-deposit.png" width="30" height="auto">
                    <img *ngIf="resultado.detpg_depositado == 0" class="mr-3"
                      src="../../../assets/images/icon-deposit-grey.png" width="30" height="auto">
                    <div class="media-body " *ngIf="resultado.detpg_depositado == 1"> 
                     
                        {{resultado.banco_desc}}
                        <br>
                        <span *ngIf="resultado.detpg_fechadepo != '0000-00-00'">{{resultado.detpg_fechadepo | date: 'dd/MM/yyyy'}}</span>
                      
                    </div>
                  </div>
                </td>

                <td colspan="2" style="width:128px;padding-top:0;padding-bottom: 0;" >
                  <div class="media" *ngIf="datos_session.acceso_pago == 1">
                    <img *ngIf="resultado.detpg_recibo == 1" class="mr-3" src="../../../assets/images/icon-recibo.png" width="30"
                      height="auto">
                    <img *ngIf="resultado.detpg_recibo == 0" class="mr-3" src="../../../assets/images/icon-recibo-grey.png"
                      width="30" height="auto">
                    <div class="media-body " *ngIf="resultado.detpg_recibo == 1">
                      . {{resultado.detpg_recibonum}} <br>
                      <span *ngIf="resultado.detpg_fecharecibo != '0000-00-00'">{{resultado.detpg_fecharecibo | date: 'dd/MM/yyyy'}}</span>
                    
                    </div>
                  </div>
                </td>

                <td colspan="2" style="width:128px;padding-top:0;padding-bottom: 0;">
                  <img src="../../../assets/images/icon-plus.png" class="pointer" (click)="ResultadoSelected(resultado)"
                  width="16" height="auto">
                </td>

              </tr>

              <tr *ngIf="datos_session.acceso_pago == 1">
                <td colspan="8" class="letraresultadosbuscar" style="padding-top:0px;padding-bottom: 0px;">
                  <span class="letraresultadosbuscar2">Detalles de pago: </span>{{resultado.detpg_detalle}}.
                </td>
              </tr>

              <tr>
                <td colspan="8" style="padding-top: 0px;padding-bottom: 0px;">
                  <hr class="hrs" style="margin-top: 0px;margin-bottom: 0px;">
                </td>
              </tr>

            </tbody>
          </table>

        </div>
        <div *ngIf="resultado_busqueda.length >= 1" style=" margin-bottom: 80px;" class="container bg-barras2 pt-3 mt-1"
          style="margin-bottom: 80px;height: 110px;">
          <div class="row">
            <div class="col-md-12 letraCuadrosFormulario text-center letraBuscar"
              style="font-family: Open Sans; margin-right: 10px;">
              ¿QUÉ TIPO DE REPORTE DESEAS ENVIAR?
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-12 text-center letraCuadrosFormulario">
              <div class="form-check form-check-inline" style="padding-top: 5px;vertical-align: top;">
                <input (change)="formaBusqueda(buscar)" [(ngModel)]="buscar" [value]="1" class="form-check-input"
                  type="radio" name="radios1" id="radios1">
                <label class="form-check-label pr-1 letraBuscar" for="radios1" style="font-family: Open Sans;">
                  Generales
                </label>
              </div>
              <div class="form-check form-check-inline" style="padding-top: 5px;vertical-align: top;">
                <input class="form-check-input" type="radio" (change)="formaBusqueda(buscar)" [(ngModel)]="buscar"
                  [value]="3" name="radios1" id="radios3">
                <label class="form-check-label pr-1 letraBuscar" for="radios3" style="font-family: Open Sans;">
                  Ingresos
                </label>
              </div>
              <div class="form-check form-check-inline" style="padding-top: 5px;vertical-align: top;">
                <input class="form-check-input" type="radio" (change)="formaBusqueda(buscar)" [(ngModel)]="buscar"
                  [value]="2" name="radios1" id="radios2">
                <label class="form-check-label pr-1 letraBuscar" for="radios2" style="font-family: Open Sans;">
                  Egresos
                </label>
              </div>

              <div class="form-check form-check-inline">
                <img (click)="infoTip()" src="../../../assets/images/question.png"
                  style="width:25px; height:29px; padding-bottom:2px;" />
              </div>
            </div>
            <div class="col-md-12 text-center letraCuadrosFormulario">

              <div class=" col-md-12" style="float:left">
                <button type="button" (click)="tipoEnvio(1)" style="width: 150px; font-size: 12px; margin-right: 10px;"
                  class="btn btn-form2 font-weight-bold btn-sm">ENVIAR PDF POR MAIL</button>

                <button type="button" (click)="tipoEnvio(2)" style="width: 150px; font-size: 12px; margin-left: 10px;"
                  class="btn btn-form2 font-weight-bold btn-sm">ENVIAR XLS POR MAIL</button>
              </div>

            </div>

          </div>

        </div>

      </section>
      <!--**********************************TERMINA FORMULARIO EXTRAER DATOS**************************************************************-->
    </div>
  </section>

  <!-- Cierra el fondo -->
</div>