<!-- Barra redes sociales -->
<!-- Barra menú -->
<section *ngIf="session">
    <section class="py-2" data-pgc="top-top-menu">
        <div class="container-fluid">
            <div class="row bg-barra">
                <div class="col-md-2 offset-md-1 offset-sm-0">
                    <a [routerLink]="['home']" (click)="menu_actived(0)">
                        <img src="../../../assets/images/logo_pleca.png" width="40" height="auto" alt="MedBox" class="img-fluid">
                    </a>
                </div>
                <div class="col-md-3 offset-md-6 justify-content-end">
                    <a href="https://www.facebook.com/pagosMD" target="_blank">
                        <img src="../../../assets/images/icon_fb.png" width="38" height="auto" alt="Facebook" class="img-fluid">
                    </a>
                    <a href="https://twitter.com/search?q=%40pagosMD&src=typd" target="_blank">
                        <img src="../../../assets/images/icon_tw.png" width="38" height="auto" alt="Twitter" class="img-fluid">
                    </a>
                    <a href="https://www.instagram.com/pagosmd/" target="_blank">
                        <img src="../../../assets/images/icon_ig.png" width="38" height="auto" alt="Intagram" class="img-fluid">
                    </a>
                    <a href="https://mx.linkedin.com/company/pagosmd" target="_blank">
                        <img src="../../../assets/images/icon_lin.png" width="38" height="auto" alt="Linkdedin2" class="img-fluid">
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container-fluid bg-barras" data-pgc-define="menu" data-pgc-define-name="menu-md" data-pgc-define-auto-update="false"
            style="padding-left: 10px;">
            <nav class="navbar navbar-expand-sm py-0 pl-5" style="padding-left: 8.333333% !important;">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#opciones">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="opciones">
                    <div *ngIf="mostrar_consulta" class="col">
                        <div *ngIf="!consulta_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(1)" class="pointer" [routerLink]="['consulta']">
                                    <img src="../../../assets/images/menu-consulta.png" alt="Consulta" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(1)" class="pointer" [routerLink]="['consulta']">
                                    <img src="../../../assets/images/menu-consulta-over.png" alt="Consulta" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="consulta_actived" class="col">

                            <a (click)="menu_actived(1)" class="pointer" [routerLink]="['consulta']">
                                <img src="../../../assets/images/menu-consulta-over.png" alt="Consulta" width="25" height="29" class="img-top">
                            </a>
                        </div>
                    </div>

                    <div *ngIf="mostrar_cirugia" class="col">
                        <div *ngIf="!cirugia_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(2)" [routerLink]="['cirugia']">
                                    <img src="../../../assets/images/menu-cirugia.png" alt="Cirugía" width="25" height="29">

                                </a>
                                <a (click)="menu_actived(2)" [routerLink]="['cirugia']"c>
                                    <img src="../../../assets/images/menu-cirugia-over.png" alt="Cirugía" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="cirugia_actived" class="col">
                            <a (click)="menu_actived(2);resteCirugia();" [routerLink]="['cirugia']">
                                <img src="../../../assets/images/menu-cirugia-over.png" alt="Cirugía" width="25" height="29" class="img-top">
                            </a>
                        </div>
                    </div>

                    <div *ngIf="mostrar_busqueda" class="col">
                        <div *ngIf="!buscar_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(3)" [routerLink]="['busqueda-general']">
                                    <img src="../../../assets/images/menu-search.png" alt="Buscar" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(3)" [routerLink]="['busqueda-general']">
                                    <img src="../../../assets/images/menu-search-over.png" alt="Buscar" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="buscar_actived" class="col">
                            <div>
                                <a (click)="menu_actived(3)" [routerLink]="['busqueda-general']">
                                    <img src="../../../assets/images/menu-search-over.png" alt="Buscar" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mostrar_calendario" class="col">
                        <div *ngIf="!calendario_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(4)" [routerLink]="['calendario']">
                                    <img src="../../../assets/images/menu-cal.png" alt="Calendario" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(4)" [routerLink]="['calendario']">
                                    <img src="../../../assets/images/menu-cal-over.png" alt="Calendario" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="calendario_actived" class="col">
                            <div>

                                <a (click)="menu_actived(4)" [routerLink]="['calendario']">
                                    <img src="../../../assets/images/menu-cal-over.png" alt="Calendario" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="mostrar_pendientes" class="col">
                        <div *ngIf="!pendientes_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(5)" [routerLink]="['pendientes']">
                                    <img src="../../../assets/images/menu-notas.png" alt="Notas" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(5)" [routerLink]="['pendientes']">
                                    <img src="../../../assets/images/menu-notas-over.png" alt="Notas" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="pendientes_actived" class="col">
                            <div>
                                <a (click)="menu_actived(5)" [routerLink]="['pendientes']">
                                    <img src="../../../assets/images/menu-notas-over.png" alt="Notas" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mostrar_estadisticas" class="col">
                        <div *ngIf="!estadisticas_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(6)" [routerLink]="['estadisticas']">
                                    <img src="../../../assets/images/menu-estadisticas.png" alt="Estadísticas" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(6)" [routerLink]="['estadisticas']">
                                    <img src="../../../assets/images/menu-estadisticas-over.png" alt="Estadísticas" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="estadisticas_actived" class="col">
                            <div>

                                <a (click)="menu_actived(6)" [routerLink]="['estadisticas']">
                                    <img src="../../../assets/images/menu-estadisticas-over.png" alt="Estadísticas" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mostrar_educacion" class="col">
                        <div *ngIf="!educacion_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(7)" [routerLink]="['educacion']">
                                    <img src="../../../assets/images/menu-educacion.png" alt="Educación" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(7)" [routerLink]="['educacion']">
                                    <img src="../../../assets/images/menu-educacion-over.png" alt="Educación" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="educacion_actived" class="col">
                            <div>
                                <a (click)="menu_actived(7)" [routerLink]="['educacion']">
                                    <img src="../../../assets/images/menu-educacion-over.png" alt="Educación" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mostrar_configuracion" class="col">
                        <div *ngIf="!configuracion_actived" class="col">
                            <div class="botonbarra">
                                <a (click)="menu_actived(8)" [routerLink]="['configuracion']">
                                    <img src="../../../assets/images/menu-configuracion.png" alt="Configuración" width="25" height="29">
                                </a>
                                <a (click)="menu_actived(8)" [routerLink]="['configuracion']">
                                    <img src="../../../assets/images/menu-configuracion-over.png" alt="Configuración" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                        <div *ngIf="configuracion_actived" class="col">
                            <div>
                                <a (click)="menu_actived(8)" [routerLink]="['configuracion']">
                                    <img src="../../../assets/images/menu-configuracion-over.png" alt="Configuración" width="25" height="29" class="img-top">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="dropdown pl-2 pt-1 justify-content-center" style="margin-top: 0px; padding-top: 0px !important;">
                            <button class="btn dropdown-toggle btn-form-home btn-sm font-weight-bold" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" style="height: 38px; border-radius: 0px;background-color: #7c9695;color: #EEC35A">AYUDA</button>
                            <div class="dropdown-menu text-muted" style="max-width: 300px; padding-top: 0px; padding-bottom: 0px;">
                                <div class="row">
                                    <div class="col">
                                        <a target="_blank" href="https://www.youtube.com/channel/UCxpDH7cBBmZo432tAPpjR0g">
                                        <img src="../../../assets/images/ayuda-completo.png" width="300px" height="auto">
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form class="form-inline form-row justify-content-center pl-4">
                        <input name="buscador" #name="ngModel" [(ngModel)]="datos_cirugia.texto" class="form-control letraCuadrosFormularioHome" type="search"
                            placeholder="Búsqueda rápida" aria-label="Buscar" style="border-radius: 0px; font-family: Open Sans; background-color: white; height: 40px;">
                        <button (click)="BusquedaGeneral();" type="button" class="btn btn-form btn-sm" type="submit" style="border-radius: 0px; height: 40px;">
                            <img class="invertir" src="../../../assets/images/buscar.png" alt="Buscar" width="30" height="30">
                        </button>
                    </form>
                    <div class="col">
                        <div class="botonbarra">
                            <a (click)="CerrarSession()">
                                <img src="../../../assets/images/menu-logout.png" alt="Cerrar sesión" width="25" height="29">
                            </a>
                            <a (click)="CerrarSession()">
                                <img src="../../../assets/images/menu-logout-over.png" alt="Cerrar sesión" width="25" height="29" class="img-top">
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</section>