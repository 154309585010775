<!-- Div para mantener el fondo en las dos secciones -->
<div>
  <!-- Calendario -->
  <!-- <section style="font-family: Open Sans;">
    <div class="container">
      <div class="row pt-5">
        <div class="col-12 pr-4">
          <div class="row align-items-center" data-pgc="calendario">
            <div class="text-center letracalendarioresaltada col-6">
              <select class="btn btn-block  letraCuadrosFormulario letraCuadrosLista" (change)="MesSelected($event.target.value)" name="meses"
                id="mes">
                <option [value]="0">Seleccione un Mes</option>
                <option [value]="0">Enero</option>
                <option [value]="1">Febrero</option>
                <option [value]="2">Marzo</option>
                <option [value]="3">Abril</option>
                <option [value]="4">Mayo</option>
                <option [value]="5">Junio</option>
                <option [value]="6">Julio</option>
                <option [value]="7">Agosto</option>
                <option [value]="8">Septiembre</option>
                <option [value]="9">Octubre</option>
                <option [value]="10">Noviembre</option>
                <option [value]="11">Diciembre</option>
              </select>
            </div>
            <div class="text-center letracalendarioresaltada col-6">
              <select class="btn btn-block  letraCuadrosFormulario letraCuadrosLista" (change)="anioSelected($event.target.value)" name="anios"
                id="anios">
                <option value="0">Seleccione un Año</option>
                <option [value]="anio.anio" *ngFor="let anio of anios">{{anio.anio}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div class="col-md-12">
    <div class="row text-center">
      <div style="margin-left : 27%">
        <label class="pointer flecha-calendar" (click)="loadingCalendar(-1)">
          < </label>
      </div>
      <div style="margin-left : 2%" class="col-md-4">
        <h3 id="mes" *ngIf=" mostrar_fecha" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{fecha_mostrar | uppercase}}</h3>
        <h3 id="mes" *ngIf="fecha_hoy && !mostrar_fecha" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{fecha_hoy | uppercase}}</h3>
        <!-- <h3 id="mes"  style = "font-weight: bold; color:#3C615F">{{ viewDate | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }}</h3> -->
      </div>
      <div id="titulo" style="margin-left: -15%">
        <h3 class="colortext">{{ anio_calendario }}</h3>
      </div>

      <div style="margin-left : 23%">
        <label class="pointer flecha-calendar" (click)="loadingCalendar(1)">
          > </label>
      </div>
    </div>
    <img *ngIf="loading" src="../../../../assets/images/loading.gif" alt="">
    <section>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="row text-center">
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate | date:'MMMM':'es-MX' | uppercase }} </h3>
                <div style="display: table-row; ">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day,1)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate2 | date:'MMMM':'es-MX' | uppercase }}</h3>
                <div style="display: table-row;">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate2" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen2" (dayClicked)="dayClicked($event.day,2)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate3 | date:'MMMM':'es-MX' | uppercase }}</h3>
                <div style="display: table-row;">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate3" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen3" (dayClicked)="dayClicked($event.day,3)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate4 | date:'MMMM':'es-MX' | uppercase }}</h3>
                <div style="display: table-row;">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate4" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen4" (dayClicked)="dayClicked($event.day,4)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate5 | date:'MMMM':'es-MX' | uppercase }}</h3>
                <div style="display: table-row;">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate5" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen5" (dayClicked)="dayClicked($event.day,5)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 id="mes" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{ viewDate6 | date:'MMMM':'es-MX' | uppercase }}</h3>
                <div style="display: table-row;">
                  <div [ngSwitch]="view" style="color:#3C615F !important; display: table-cell; width: 45%;padding: 50px;">
                    <mwl-calendar-month-view weekStartsOn="1" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate6" [events]="events" [refresh]="refresh"
                      [locale]="locale" [activeDayIsOpen]="activeDayIsOpen6" (dayClicked)="dayClicked($event.day,6)" (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </section>

    <!-- Cierra el fondo -->
  </div>
  <!-- JavaScript files-->