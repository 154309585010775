<div class="container-fluid containerfondo">
  <!-- Titulos -->
  <section>
    <div class="configura">
      <div class="container">
        <div class="row pt-4">
          <!-- Primera columna -->
          <!-- Segunda columna -->
          <div class="col py-4">
            <div
              *ngIf="mostrar_configuracion_perfil"
              class="fondo-titulo-campos"
            >
              <h6 class="titulo-campos">MI PERFIL</h6>
            </div>
            <div *ngIf="mostrar_configuracion_perfil" class="row">
              <div *ngIf="mostrar_configuracion_perfil" class="col-sm-3">
                <input
                  (change)="UploadImagen($event)"
                  type="file"
                  name="file"
                  id="file"
                  class="inputfile altura-file"
                />
                <label> </label>
              </div>
              <div class="col-sm-3">
                <img
                  id="img_p"
                  *ngIf="!datos_usuario.foto_url && !url_perfil"
                  src="../../../assets/images/defaultperson.jpeg"
                  class="foto_p"
                  width="auto"
                  height="80px"
                />
                <img
                  *ngIf="url_perfil"
                  [src]="url_perfil"
                  class="foto_p"
                  height="85px"
                  width="85px"
                />
                <div
                  [hidden]="
                    url_perfil ||
                    datos_usuario.foto_url == '' ||
                    datos_usuario.foto_url == null ||
                    datos_usuario.foto_url == undefined
                  "
                >
                  <div *ngIf="datos_usuario.foto_url">
                    <img
                      *ngIf="
                        datos_usuario.foto_url != '' ||
                        datos_usuario.foto_url != 'null'
                      "
                      class="foto_p"
                      height="85px"
                      width="85px"
                      src="{{ url_imagen + datos_usuario.foto_url }}"
                    />
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div class="col-sm-3" *ngIf="mostrar_configuracion_perfil">
                <img
                  class="pointer trash"
                  height="20px"
                  width="17px"
                  (click)="deleteFotoPerfil()"
                  src="../../../assets/images/icon-trash.png"
                  alt=""
                />
              </div>
            </div>
            <br />
            <br />
            <form *ngIf="mostrar_configuracion_perfil">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="nombre"
                    #name="ngModel"
                    [(ngModel)]="datos_usuario.usuario_nombre"
                    name="nombre"
                    class="form-control letraCuadrosFormulario"
                    id="inputNombreperf"
                    placeholder="Nombre"
                  />
                  <div
                    *ngIf="
                      datos_usuario.usuario_nombre == '' ||
                      datos_usuario.usuario_nombre.trim() == ''
                    "
                  >
                    <small style="color: red">
                      <b>*El campo Nombre es Obligatorio</b>
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="apellidos"
                    #name="ngModel"
                    name="apellidos"
                    [(ngModel)]="datos_usuario.usuario_apellidos"
                    class="form-control letraCuadrosFormulario"
                    id="inputApellidos"
                    placeholder="Apellidos"
                  />
                </div>
                <div
                  *ngIf="
                    datos_usuario.usuario_apellidos == '' ||
                    datos_usuario.usuario_apellidos.trim() == ''
                  "
                >
                  <small style="color: red">
                    <b>*El campo Apellidos es Obligatorio</b>
                  </small>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    id="datepicker1"
                    #name="ngModel"
                    name="fecha_cumple"
                    [max]="max_fecha_nacimiento"
                    [(ngModel)]="datos_usuario.fecha_nacimiento"
                    style="font-size: 12px; font-weight: 300;"
                    class="form-control letraCuadrosFormulario flecha_b"
                    placeholder="Fecha de Nacimiento"
                    [owlDateTimeTrigger]="dt1"
                    [owlDateTime]="dt1"
                    readonly
                  />

                  <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                  <!--
                  <div *ngIf="datos_usuario.fecha_nacimiento == ''">
                    <small style="color: red">
                      <b>*El campo Fecha de Nacimiento es Obligatorio</b>
                    </small>
                  </div>
                  -->
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <select
                    #name="ngModel"
                    name="especialidad"
                    [(ngModel)]="datos_usuario.especiadidad_id"
                    class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                    style="font-size: 12px; font-weight: 300;"
                    name="especialidades"
                    id="especialidad"
                  >
                    <option value="0">Especialidad Médica </option>
                    <option
                      [value]="especialidad.especiadidad_id"
                      *ngFor="let especialidad of especialidades"
                    >
                      {{ especialidad.especialidad_desc }}</option
                    >
                  </select>
                  <div *ngIf="datos_usuario.especiadidad_id == 0">
                    <small style="color: red">
                      <b>*El campo Especialidad Médica es Obligatorio</b>
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    [disabled]="true"
                    type="correoelectronico"
                    pattern="^\S*[@]\S*[.]\S*$"
                    email
                    required
                    #emailField="ngModel"
                    name="correo"
                    [(ngModel)]="datos_usuario.usuario_email"
                    class="form-control letraCuadrosFormulario"
                    id="inputCorreoElectronico"
                    placeholder="Correo Electrónico"
                  />
                  <div
                    *ngIf="
                      !emailField.valid &&
                      (emailField.touched || emailField.dirty)
                    "
                  >
                    <small style="color: red">
                      <b>*Correo Invalido</b>
                    </small>
                  </div>
                </div>
              </div>
              <!--
              <div class="form-row">
                <div class="form-group col-md-12">
                  
                  <input
                    autocomplete="off"
                    type="cedulaprofesional"
                    #name="ngModel"
                    name="cedula"
                    [(ngModel)]="datos_usuario.cedula_profesional"
                    class="form-control letraCuadrosFormulario"
                    id="inputCedulaProfesional"
                    placeholder="Cédula Profesional"
                  />
                  
                  <div
                    *ngIf="
                      datos_usuario.cedula_profesional == '' ||
                      datos_usuario.cedula_profesional.trim() == ''
                    "
                  >
                    <small style="color: red">
                      <b>*El campo Cédula Profesionalo es Obligatorio</b>
                    </small>
                  </div>
                  
                </div>
              </div>
              -->
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="password"
                    #name="ngModel"
                    name="pw"
                    [(ngModel)]="datos_usuario.usuario_pw"
                    class="form-control letraCuadrosFormulario"
                    id="inputPasswordconfig"
                    placeholder="Contraseña"
                  />
                  <div
                    *ngIf="
                      datos_usuario.usuario_pw == '' ||
                      datos_usuario.usuario_pw.trim() == ''
                    "
                  >
                    <small style="color: red">
                      <b
                        >*El campo Contraseña es Obligatorio para Actualizar sus
                        datos</b
                      >
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <button
                  type="submit"
                  (click)="UpdateInfo()"
                  class="btn btn-form btn-block btn-lg font-weight-bold"
                  style="width: 317px; margin-left: auto; margin-right: auto;"
                >
                  GUARDAR
                </button>
              </div>
            </form>
          </div>
          <div class="col py-4">
            <div
              style="padding-top: 12px; padding-bottom: 12px; background-color: #bcbec0; border-radius: 5px;"
              class="fondo-titulo-campos"
            >
              <h6
                style="font-family: Open Sans; font-size: 12PX; font-weight: 600; color: #3C615F; text-align: center; margin-bottom: 0px;"
              >
                CAMPOS DEL SISTEMA
              </h6>
            </div>
            <form *ngIf="mostrar_configuracion_campos">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getPersonal(1)"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal1"
                  >
                    Cirujanos
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getPersonal(2)"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal6"
                  >
                    Anestesiólogos
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-row" style="padding-top: 0px; margin-top: 0px;">
                <div class="form-group col-md-12 mt-1 pb-1">
                  <button
                    (click)="Hospitales()"
                    type="button"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal3"
                  >
                    Hospitales / Consultorios
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    (click)="getAseguradora()"
                    type="button"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal4"
                  >
                    Aseguradoras
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    (click)="getAyudantia()"
                    type="button"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal5"
                  >
                    Ayudantías / Proveedores
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    (click)="Anestesiologos()"
                    type="button"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal2"
                  >
                    Tipos De Anestesia
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getProcedimiento()"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal7"
                  >
                    Tipos De Procedimientos
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getFormaPago()"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal8"
                  >
                    Formas de Pago
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getBanco()"
                    class="btn btn-form-conf btn-block btn-lg"
                    data-toggle="modal"
                    data-target="#modal9"
                  >
                    Banco Receptor
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- Tercera columna -->
          <div class="col py-4">
            <div
              style="padding-top: 12px; padding-bottom: 12px; background-color: #bcbec0; border-radius: 5px;"
              class="fondo-titulo-campos"
            >
              <h6
                style="font-family: Open Sans; font-size: 12PX; font-weight: 600; color: #3C615F; text-align: center; margin-bottom: 0px;"
              >
                AGREGAR COLABORADORES
              </h6>
             
            </div>
            <form *ngIf="mostrar_configuracion_colaborador">

              <!-- <div class="form-row">
                <div class="form-group col-md-12">
                  <select #name="ngModel" [(ngModel)]="datos_colaborador.papp_id" class="btn btn-form-conf btn-block btn-lg" name="permisos"
                    id="permiso">
                    <option [value]="0">Seleccione el Perfil</option>
                    <option *ngFor="let permiso of permisos_asignados" [value]="permiso.papp_id">{{permiso.papp_desc}}</option>
                  </select>
                </div>
              </div> -->
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    (click)="getMenuApp(1)"
                    class="btn btn-form btn-block btn-lg font-weight-bold"
                    data-toggle="modal"
                    data-target="#modal11"
                  >
                    Agregar Colaborador
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                </div>
              </div>
              <!-- <div *ngIf="!mostrar_boton_actualizar" class="form-row" style="margin-bottom: 35px;">
                <button type="submit" (click)="addColaborador()" class="btn btn-form btn-block btn-lg font-weight-bold" style="margin-left: auto; margin-right: auto; width: 317px;">GUARDAR COLABORADOR </button>
              </div> -->

              <div
                *ngIf="mostrar_boton_actualizar"
                class="form-row"
                style="margin-bottom: 35px;"
              >
                <!-- <button type="submit" (click)="updateColaborador()" class="btn btn-form btn-block btn-lg font-weight-bold" style="margin-left: auto; margin-right: auto; width: 317px;">ACTUALIZAR COLABORADOR </button> -->
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button
                    type="button"
                    class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                    data-toggle="modal"
                    data-target="#modal_colaborador"
                    style="font-size: 12px; font-weight: 300;"
                  >
                    Ver Colaboradores
                    <span class="derecha">
                      <img
                        src="../../../assets/images/icon-add.png"
                        width="24px"
                        class="icon-plus"
                      />
                    </span>
                  </button>
                  <br />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Cierra el fondo -->
</div>
<!-- Div para mantener el fondo en las dos secciones -->
<section data-pg-name="Modals">
  <div
    class="modal fade bd-example-modal-lg"
    id="modal1"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Cirujanos Modal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="color : #3d615f; font-family: Open Sans;"
          >
            Cirujanos
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <div class="col-md-12">
            <input
              type="text"
              #name="ngModel"
              name="Nombre"
              [(ngModel)]="datos_personal.persona_desc"
              class="btn btn-block dropdown-toggle form-modal"
              placeholder="Nombre"
              style="margin-bottom: 10px;"
            />

            <input
              type="text"
              #name="ngModel"
              name="correo_personal"
              [(ngModel)]="datos_personal.personal_email"
              class="btn btn-block dropdown-toggle form-modal"
              placeholder="Correo"
              style="margin-bottom: 10px;"
            />

            <div *ngIf="!mostrar_actualizar_personal">
              <button
                type="button"
                (click)="addPersonal(1)"
                class="btn btn-form btn-block btn-lg font-weight-bold"
                style="font-family: Open Sans;"
              >
                Guardar
              </button>
            </div>
          </div>

          <div *ngIf="mostrar_actualizar_personal">
            <button
              type="button"
              (click)="updatePersonal(1)"
              class="btn btn-form btn-block btn-lg font-weight-bold"
              style="font-family: Open Sans;"
            >
              Actualizar cambios
            </button>
          </div>
          <br />
        </div>
        <div class="modal-body tablas">
          <table
            id="tabla_personal1"
            class="table table-striped table-bordered"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>

        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal2"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Anestesiólogos Modal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f"
          >
            Tipos de Anestesia
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_anestesia.anestesia_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <div *ngIf="!mostrar_anestesia">
            <button
              type="button"
              (click)="AddAnestesia()"
              class="btn btn-form btn-block btn-lg font-weight-bold"
              style="font-family: Open Sans;"
            >
              Guardar
            </button>
          </div>

          <div *ngIf="mostrar_anestesia">
            <button
              type="button"
              (click)="UpdateAnestesia()"
              class="btn btn-form btn-block btn-lg font-weight-bold"
              style="font-family: Open Sans;"
            >
              Actualizar cambios
            </button>
          </div>
        </div>
        <div class="modal-body tablas">
          <table
            id="tabla_anestesia"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal3"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Hospitales Modal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f"
          >
            Hospitales / Consultorios
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_hospitales.hospital_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <button
            *ngIf="!mostrar_actualizar_hospital"
            type="button"
            (click)="addHospital()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>
        <button
          *ngIf="mostrar_actualizar_hospital"
          type="button"
          (click)="updateHospital()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_hospital"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal4"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Aseguradoras"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans; color : #3d615f"
          >
            Aseguradoras
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_aseguradora.aseguradora_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <button
            type="button"
            *ngIf="!mostrar_actualizar_aseguradora"
            (click)="addAseguradora()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>
        <button
          type="button"
          *ngIf="mostrar_actualizar_aseguradora"
          (click)="updateAseguradora()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_aseguradora"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal5"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Ayudantías"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans; color: #3d615f"
          >
            Ayudantías / Proveedores
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_ayudantia.ayudantia_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_ayudantia.ayudantia_email"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Correo"
            style="margin-bottom: 10px;"
          />

          <button
            type="button"
            *ngIf="!mostrar_actualizar_ayudantia"
            (click)="addAyudantia()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>

        <button
          type="button"
          *ngIf="mostrar_actualizar_ayudantia"
          (click)="updateAyudantia()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_ayudantia"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal6"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Tipos anestesia"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f"
          >
            Anestesiólogos
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_personal.persona_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <input
            type="text"
            #name="ngModel"
            name="correo_personal"
            [(ngModel)]="datos_personal.personal_email"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Correo"
            style="margin-bottom: 10px;"
          />

          <div *ngIf="!mostrar_actualizar_personal">
            <button
              type="button"
              (click)="addPersonal(2)"
              class="btn btn-form btn-block btn-lg font-weight-bold"
              style="font-family: Open Sans;"
            >
              Guardar
            </button>
          </div>
        </div>

        <div *ngIf="mostrar_actualizar_personal">
          <button
            type="button"
            (click)="updatePersonal(2)"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Actualizar cambios
          </button>
        </div>
        <br />
        <br />
        <div class="modal-body tablas">
          <table
            id="tabla_personal2"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>

        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal7"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Tipos procedimiento"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans; color : #3d615f"
          >
            Tipos de Procedimientos
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_procedimiento.proce_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <button
            type="button"
            *ngIf="!mostrar_actualizar_procedimiento"
            (click)="addProcedimiento()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>

        <button
          type="button"
          *ngIf="mostrar_actualizar_procedimiento"
          (click)="updateProcedimiento()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_procedimiento"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal8"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Formas de pago"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f;"
          >
            Formas de Pago
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_forma_pago.fpago_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <button
            type="button"
            *ngIf="!mostrar_actualizar_pago"
            (click)="addFormaPago()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>
        <button
          type="button"
          *ngIf="mostrar_actualizar_pago"
          (click)="updateFormaPago()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_pago"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    id="modal9"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Banco receptor"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f;"
          >
            Banco Receptor
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body filtra">
          <input
            type="text"
            #name="ngModel"
            name="Nombre"
            [(ngModel)]="datos_banco.banco_desc"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Nombre"
            style="margin-bottom: 10px;"
          />

          <button
            type="button"
            *ngIf="!mostrar_actualizar_banco"
            (click)="addBanco()"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>
        <button
          type="button"
          *ngIf="mostrar_actualizar_banco"
          (click)="updateBanco()"
          class="btn btn-form btn-block btn-lg font-weight-bold"
          style="font-family: Open Sans;"
        >
          Actualizar cambios
        </button>

        <div class="modal-body tablas">
          <table
            id="tabla_banco"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>

        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade pg-show-modal"
    id="modal10"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Agregar usuario"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-center" style="font-family: Open Sans;">
            Agregar Usuario
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Campo 1"
            style="margin-bottom: 10px;"
          />
          <input
            type="text"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Campo 2"
            style="margin-bottom: 10px;"
          />
          <input
            type="text"
            class="btn btn-block dropdown-toggle form-modal"
            placeholder="Campo 3"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-form btn-block btn-lg font-weight-bold"
            style="font-family: Open Sans;"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--********************************************Modal Colaboradores*******************************************************-->
  <div
    class="modal fade pg-show-modal"
    id="modal_colaborador"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Colaboradores"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans;color : #3d615f;"
          >
            Colaboradores
          </h4>
          <!-- <img
          src="../../../assets/images/loading.gif"
        
        /> -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <table
            id="tabla_colaboradores"
            class="table table-striped table-bordered"
            style="width: 100%;"
            role="grid"
            aria-describedby="datatables_info"
            width="100%"
            cellspacing="0"
          ></table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--*******************************************Modal Configuracion Perfil*****************************************************-->

  <div
    class="modal fade bd-example-modal-lg"
    id="modal11"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="true"
    data-pg-name="Agregar usuario"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width:100%">
        <div class="modal-header">
          <h4
            class="modal-title text-center font-weight-bold"
            style="font-family: Open Sans; color : #3d615f;"
          >
            Configuración Colaborador
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12" style="margin-bottom: 15px;padding: 15px">
                <p>
                  Los colaboradores son las personas que forman parte de tu equipo de trabajo, en la parte médica y administrativa.  A ellos les puedes dar acceso a tu sistema de pagosMD, para concentrar, organizar y faciliar tu administración médica.
                </p>
                <p>
                  INFORMACIÓN IMPORTANTE:  Los Colaboradores <strong>NO</strong> pueden: <br>
                  - Agregar nuevos colaboradores. <br>
                  - Modificar la configuración que tú le asignes. <br>
                  - Modificar las configuraciones de otros colaboradores. <br>
                  - Modificar tu perfil <br>
                  - Modificar tu pasword <br>
                </p>
                <p>
                  <strong>
                    Para ver reflejados estos cambios en el dispositivo de tu colaborador, pídele que cierre la sesión actual y vuelva a iniciarla.
                  </strong>
                </p>
                <p>
                  Selecciona los módulos a los que tendrá acceso tu colaborador:                
                </p>
            </div>
            <div
              class="col-md-4"
              style="margin-top : -48px; padding-left: 40px">
              <!-- <input type="text" #name="ngModel" [(ngModel)]="papp_desc" class="btn btn-block dropdown-toggle form-modal" placeholder="Descripción Perfil"> -->
              <br />
              <br />
              <div *ngFor="let menu of menu_app" class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <img
                        *ngIf="menu.menuapp_id == 1"
                        src="../../../assets/images/menu-consulta-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 2"
                        src="../../../assets/images/menu-cirugia-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 3"
                        src="../../../assets/images/menu-search-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 4"
                        src="../../../assets/images/menu-cal-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 5"
                        src="../../../assets/images/menu-notas-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 6"
                        src="../../../assets/images/menu-estadisticas-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 7"
                        src="../../../assets/images/menu-educacion-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 8"
                        src="../../../assets/images/picture.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                      <img
                        *ngIf="menu.menuapp_id == 9"
                        src="../../../assets/images/menu-configuracion-help.png"
                        style="width:25px; height:29px; padding-bottom:2px;"
                      />
                    </div>
                    <div  style="padding-left: 2rem!important;" class="custom-control custom-checkbox col-md-10">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customControlAutosizing_{{ menu.menuapp_id }}"
                        [(ngModel)]="menu.acceso"
                        [checked]="menu.acceso"
                        name="{{ menu.menuapp_id }}"
                      />
                      <label
                        class="custom-control-label"
                        for="customControlAutosizing_{{ menu.menuapp_id }}"
                        >{{ menu.menuapp_desc }}</label
                      >
                      <div class="tooltip">
                        <img
                          *ngIf="menu.menuapp_id == 9"
                          src="../../../assets/images/question_red.png"
                          style="width:25px; height:25px;padding-bottom:2px;margin-left: 5px;"
                        />
                        <span class="tooltiptext">
                          Los Campos del sistema tienen las diferentes opciones que se desplegarán al dar click en la flecha.
                          <p></p>
                          Si seleccionas este módulo, tu colaborador podrá agregar y editar los campos de:
                          <p></p>
                          <ul>
                          <li>Cirujanos </li>
                          <li>Anestesiologos </li>
                          <li>Hospitales/Consultorios </li>
                          <li>Aseguradoras </li>
                          <li>Ayudantias/Proveedores </li>
                          <li>Tipos de Anestesia </li>
                          <li>Tipos de Procedimientos </li>
                          <li>Formas de Pago </li>
                          <li>Banco Receptor </li>
                          </ul>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="text"
                    maxlength="100"
                    name="nom_colaborador"
                    #name="ngModel"
                    [(ngModel)]="datos_colaborador.usuario_nombre"
                    class="form-control letraCuadrosFormulario"
                    id="inputNombre"
                    placeholder="Nombre"
                  />
                  <div
                    *ngIf="
                      (datos_colaborador.usuario_nombre == '' &&
                        verificar_colaborador) ||
                      (datos_colaborador.usuario_nombre == 'null' &&
                        verificar_colaborador) ||
                      (datos_colaborador.usuario_nombre == 'undefined' &&
                        verificar_colaborador) ||
                      (datos_colaborador.usuario_nombre.trim() == '' &&
                        verificar_colaborador)
                    "
                    role="alert"
                  >
                    <small style="color: red">
                      <b>*El campo nombre es Obligatorio</b>
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="email"
                    pattern="^\S*[@]\S*[.]\S*$"
                    email
                    required
                    maxlength="50"
                    name="correo_colab"
                    [(ngModel)]="datos_colaborador.usuario_email"
                    #emailFieldRc="ngModel"
                    class="form-control letraCuadrosFormulario"
                    id="Correo"
                    placeholder="Correo Electrónico"
                  />
                  <div
                    *ngIf="
                      !emailFieldRc.valid &&
                      (emailFieldRc.touched || emailFieldRc.dirty)
                    "
                  >
                    <small style="color: red">
                      <b>*Correo Invalido</b>
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="password"
                    name="pw_cola"
                    maxlength="15"
                    #name="ngModel"
                    [(ngModel)]="datos_colaborador.usuario_pw"
                    class="form-control letraCuadrosFormulario"
                    id="inputPasswordc"
                    placeholder="Contraseña"
                  />
                  <div
                    *ngIf="
                      verificar_colaborador &&
                      (datos_colaborador.usuario_pw == '' ||
                        datos_colaborador.usuario_pw == 'null' ||
                        datos_colaborador.usuario_pw == 'undefined' ||
                        datos_colaborador.usuario_pw.trim() == '')
                    "
                  >
                    <small style="color: red">
                      <b>*El campo Contraseña es Obligatorio</b>
                    </small>
                  </div>
                </div>
              </div>
              <button
                *ngIf="!actualizar_perfil"
                type="button"
                class="btn btn-form btn-block btn-lg font-weight-bold"
                (click)="AddPerfil()"
                style="font-family: Open Sans;"
              >
                Guardar
              </button>
              <button
                *ngIf="actualizar_perfil"
                type="button"
                class="btn btn-form btn-block btn-lg font-weight-bold"
                (click)="UpdatePerfil()"
                style="font-family: Open Sans;"
              >
                Actualizar cambios
              </button>
            </div>
            <!-- <div class="col-md-8">
              <table   id="tabla_perfil" class="table table-striped table-bordered" style="width: 100%;" role="grid" aria-describedby="datatables_info"
                width="100%" cellspacing="0">
              </table>
            </div> -->
            <hr style="border: 1px solid #e9ecef; width: 100%;">
            <div class="custom-checkbox col-md-12" style="margin-top: 25px">
                <input
                  type="checkbox"
                  name="permiso_pagos"
                  #name="ngModel"
                  [(ngModel)]="datos_colaborador.accesso_pago"
                  class="custom-control-input"
                  id="permiso_pagos"
                />
                <label
                  style="margin-left: 82px;width: 83%;"
                  for="permiso_pagos"
                  class="custom-control-label">Al seleccionar esta casilla tu colaborador NO TENDRA VISIBLES los datos de pagos/depósitos/facturas que se puedan mostrar en los diferentes menús seleccionados.</label>
                  <div class="tooltip">
                    <img
                      src="../../../assets/images/question_red.png"
                      style="width:25px; height:25px;padding-bottom:2px;margin-left: 5px;"
                    />
                    <span class="tooltiptext">
                        ¿ Por qué necesitaría NO dejar visibles los datos de pagos/depósitos/facturas a algún colaborador ?
                      <p></p>
                        En ocasiones tienes personas que forman parte de tu equipo que necesitan datos del evento, pero no necesariamente quieres compartir datos financieros con ellos, por ejemplo; un doctor que pasa visita a tus pacientes, o un doctor/ayudante que te ayuda con el llenado del expediente, o una enfermera/asistente que no deseas que tenga los datos financieros pero sí la logística de tus eventos.
                      <p></p>  
                        pagosMD te da la flexibilidad de personalizar a cada colaborador para ayudarte a concentrar, organizar y facilitar tu administración médica.                        
                      <p></p>
                    </span>
                  </div>
                <img
                  src="../../../assets/images/interrogacion_red.png"
                  style="width:25px; height:25px; padding-bottom:2px;margin-top: -70px;"
                />
                <img
                  src="../../../assets/images/pesossolido_red.png"
                  style="width:25px; height:25px; padding-bottom:2px;margin-top: -70px;"
                />
            </div>
          </div>
        </div>
        
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-form"
            data-dismiss="modal"
            style="font-family: Open Sans;"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- JavaScript files-->
