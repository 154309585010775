import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import  'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';
@Injectable({
  providedIn: 'root'
})
export class PendientesService {
  public url : string;
  constructor( private http : Http) { 
    this.url = GLOBAL.url;
  }

  public AddPendiente(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url+'pendiente/add', params ,{headers : headers})
    .map(res =>res.json());
  }

  public GetPendiente(token,usuario_id){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url+'pendiente/getbyusuarioid/'+usuario_id,{ headers : headers})
    .map(res =>res.json());
  }

  public UpdateFav(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url+'pendiente/update-favorito', params ,{headers : headers})
    .map(res =>res.json());
  }

  public DeleteFav(datos){
    let params = JSON.stringify(datos);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(this.url+'pendiente/delete-fav', params ,{headers : headers})
    .map(res =>res.json());
  }
}
