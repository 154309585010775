export function CarruselHome(){
    if ($('.home_carrusel').hasClass('slick-initialized')) {
         $('.home_carrusel').slick('destroy');
     } 
     $(document).ready(function(){

         
         $('.home_carrusel').slick({
             dots: true,
             slidesToShow: 1,
             slidesToScroll: 1,
             autoplay: true,
             autoplaySpeed: 7000,
             dotsClass: 'slick-dots',
             swipe: true,
              mobileFirst:true
         });
      
     });
   
   }
  

export function intervalCarruselHome (){
   CarruselHome();
}