import { Component, OnInit, Input } from '@angular/core';
import { DatosGeneralesService } from '../../../services/consulta/datos-generales/datos-generales.service';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { PacienteModel } from '../../../models/PacienteModel';
import { ConsultaModel } from '../../../models/ConsultaModel';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { ObservableService } from '../../../observable.service';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { Router } from '@angular/router';
import { CorreoModel } from '../../../models/CorreoModel';
import { IfStmt } from '../../../../../node_modules/@angular/compiler';

declare var $: any;
@Component({
  selector: 'app-datos-generales',
  templateUrl: './datos-generales.component.html',
  styleUrls: ['./datos-generales.component.css']
})
export class DatosGeneralesComponent implements OnInit, OnDestroy {

  public session;
  public datos_session;
  public token;
  public usuario_id;
  public datos_usuario;
  public cat_hospital;
  public usuarionuevo: boolean;
  public edad_paciente = new Array();
  public datos_paciente: PacienteModel;
  public datos_consulta: ConsultaModel;
  public datos_guardar: {};
  public datos_correo: CorreoModel;
  public pacientes = new Array();
  public usuario_selected: number;
  public default_existe_usuario: boolean;
  private subscription: Subscription = new Subscription();
  public modo_actualizar: boolean;
  public datos_actualizar: boolean;
  public usuario_nuevo: boolean;
  private unsubs: Subscription = new Subscription();
  public correo_array = new Array();
  constructor(
    private DatosGeneralesService: DatosGeneralesService,
    private ConfiguracionService: ConfiguracionService,
    private ObservableService: ObservableService,
    private ObservableVerificar: ObservableService,
    private ObservableExtraer: ObservableExtraer,
    private router: Router

  ) {
    const subscription = this.ObservableService.datosConsultaResetMessage.subscribe(result => {
      if (result.limpiar) {
        this.correo_array = [{ id_categoria: 1, descripcion: 'Mi usuario', status: false }, { id_categoria: 2, descripcion: 'Colaboradores', status: false }, { id_categoria: 3, descripcion: 'Todos', status: false }, { id_categoria: 4, descripcion: 'Ninguno', status: true }];
        this.default_existe_usuario = true;
        this.datos_paciente = new PacienteModel(0, '', '', '', '', '', '', '', '', '', 0);
        this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
      }
    })
    this.datos_correo = new CorreoModel();
    this.subscription.add(subscription);
  }

  ngOnInit() {
    this.correo_array = [{ id_categoria: 1, descripcion: 'Mi usuario', status: false }, { id_categoria: 2, descripcion: 'Colaboradores', status: false }, { id_categoria: 3, descripcion: 'Todos', status: false }, { id_categoria: 4, descripcion: 'Ninguno', status: true }];
    this.modo_actualizar = false;
    this.Session();
    this.usuarionuevo = true;
    this.default_existe_usuario = true;
    const subscription = this.ObservableVerificar.VerificaConsultaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (result.paciente == 1) {
          this.verificacionDatosG(result);
        }
      }

    });
    const unsubscribe = this.ObservableExtraer.datosSelectedGeneralMessage.subscribe(result => {
      if (result != undefined && result != null && result != "") {
        this.datos_actualizar = true;
        this.datos_consulta = result.datos_consulta;

        this.datos_paciente = result.datos_paciente;
        this.default_existe_usuario = false;
        this.usuario_selected = this.datos_paciente.paciente_id;
        let fecha_formateada = moment(this.datos_consulta.consulta_fecha).format('YYYY-M-DD HH:mm:ss');
        let split_fecha_inicio = fecha_formateada.split('-');
        let split_hora = this.datos_consulta.consulta_hora.split(':')
        let consulta_duracion = result.datos_consulta.consulta_duracion;
        let split_duracion = consulta_duracion ? consulta_duracion.split(':') : '';
        if (split_hora.length == 2) {
          let año_i = split_fecha_inicio[0];
          let mes_i = split_fecha_inicio[1];
          let dia_i = split_fecha_inicio[2];
          let año_inicio = parseInt(año_i);
          let mes_inicio = parseInt(mes_i) - 1;
          let dia_inicio = parseInt(dia_i);
          let hora = parseInt(split_hora[0]);
          let minuto = parseInt(split_hora[1]);
          let hora_duracion = 0;
          let minuto_duracion = 0;
          if(split_duracion){
            hora_duracion = parseInt(split_duracion[0]);
            minuto_duracion = parseInt(split_duracion[1]);
          }
          let fecha_inicio_1 = new Date(año_inicio, mes_inicio, dia_inicio, hora, minuto);
          let duracion_evento = new Date(año_inicio, mes_inicio, dia_inicio, hora_duracion, minuto_duracion);
          this.datos_consulta.consulta_hora = fecha_inicio_1;
          this.datos_consulta.constulata_duracion = duracion_evento;
          this.modo_actualizar = true;
        }

      } else {
        this.datos_paciente = new PacienteModel(0, '', '', '', '', '', '', '', '', '', 0);
        this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
      }

    })
    this.unsubs.add(unsubscribe);
    this.subscription.add(subscription);

  }
  ngOnDestroy() {
    this.unsubs.unsubscribe();
    this.ObservableExtraer.changedatosSelectedGeneral({ limpiar: true });
    this.ObservableService.changedatosPaciente({ limpiar: true });
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.datos_consulta = new ConsultaModel(0, '', '', '', 0, '', 0, 0);
    }

  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getHospital();
          this.edades();
          this.getPacientes();
          this.ObservableService.changedatosPaciente(this.datos_paciente);

        }
      })
  }


  ExisteUsuario(event) {
    this.default_existe_usuario = event;
    this.SelectedUsuario('');

  }



  //*CATALOGOS
  getHospital() {
    this.DatosGeneralesService.getHospital(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        this.cat_hospital = result.cat_hospital;
      })
  }
  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  edades() {
    for (let i = 1; i <= 180; i++) {
      this.edad_paciente.push({ edad: i });
    }
  }
  //*Verificacion de datos
  verificacionDatosG(datos_paciente) {
    if (this.default_existe_usuario == true && (this.datos_paciente.paciente_nombre == null || this.datos_paciente.paciente_nombre == undefined || this.datos_paciente.paciente_nombre.trim() == "")) {
      swal("Error", "El campo Nombre del Paciente esta vacio", "error");
    } else if (this.default_existe_usuario == false && (this.datos_paciente.paciente_nombre == null || this.datos_paciente.paciente_nombre == undefined || this.datos_paciente.paciente_nombre.trim() == "")) {
      swal("Error", "Debe seleccionar un Paciente", "error");
    } else {
      if (this.modo_actualizar) {
        //faltan imagenes de guardado
        // this.GuardarArchivoImagenSecundarioUpdate(this.datos_cirugia.cirugia_id);
        // this.GuardarArchivoImagenUpdate(this.datos_cirugia.cirugia_id);
      }
      this.datos_paciente = datos_paciente;
      //if (datos_paciente.datos_paciente.enviar_correo == 1) {
      $('#EmailEnviar').modal({ backdrop: 'static', keyboard: false })
      $('#EmailEnviar').modal('show');

      // }// else {

      // this.ObservableService.changeDatosCorrectoGeneralConsulta({ datos_generales: this.datos_consulta, datos_paciente: datos_paciente.datos_paciente, valido: 1 , destinatarios : 0 })
      // }
      this.default_existe_usuario = true;
    }
  }

  enviarEmail(categoria_selected) {
    //  1 mi usuario , 2 colaboradores , 3 todos , 4 ninguno.. se alimenta del array correo_array

    for (let key in this.correo_array) {
      if (categoria_selected == 4 && this.correo_array[key].id_categoria == 4 && this.correo_array[key].status == false) {
        this.correo_array[key].status = true;
      } else {
        if (categoria_selected != 3) {
          this.correo_array[key].status = false;
        } else if (categoria_selected == 3) {
          if (this.correo_array[key].id_categoria == 3 && this.correo_array[key].status == true) {
            this.correo_array[key].status = false;
          }
          if (this.correo_array[key].id_categoria != 4) {
            this.correo_array[key].status = true;
          }
        }
        if (categoria_selected == 1 && this.correo_array[key].id_categoria == 1) {
          this.correo_array[key].status = true;
        } else if (categoria_selected == 1 && this.correo_array[key].status && this.correo_array[key].id_categoria == 1) {
          this.correo_array[key].status = false;
        }
        if (categoria_selected == 2 && this.correo_array[key].id_categoria == 2) {
          this.correo_array[key].status = true;
        } else if (categoria_selected == 2 && this.correo_array[key].id_categoria == 2 && this.correo_array[key].status) {
          this.correo_array[key].status = false;
        }
        if (this.correo_array[key].id_categoria == 4) {
          this.correo_array[key].status = false;
        }
      }

    }
  }

  /*////VerificaObserbable///////*/



  //provicional
  getPacientes() {
    this.DatosGeneralesService.getPacientes(this.datos_usuario.usuario_id, this.token.token)
      .subscribe(result => {
        this.pacientes = result.pacientes;
        for (let key in this.pacientes) {
          let fecha_formateada = moment(this.pacientes[key].fecha_nacimiento).format('YYYY-M-DD HH:mm:ss');
          this.pacientes[key].fecha_nacimiento = fecha_formateada
        }
      })
  }
  SelectedUsuario(i) {

    if (this.default_existe_usuario == false && i != '' && i != null) {
      this.datos_paciente = i[0].value;
      this.ObservableService.changedatosPaciente(this.datos_paciente);
    } else if (this.default_existe_usuario == true) {
      this.datos_paciente.paciente_nombre = '';
      this.datos_paciente.paciente_edad = 9;
      this.usuario_selected = 0;
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.ObservableService.changedatosPaciente(this.datos_paciente);
    }


  }

  EdadPaciente(edad) {
    this.datos_paciente.paciente_edad = edad.edad;
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
  DeleteConsulta() {
    this.datos_consulta.consulta_status = 0
    this.DatosGeneralesService.DeleteConsulta(this.datos_consulta)
      .subscribe(result => {
        swal({
          title: 'Muy Bien!',
          text: result.error,
          type: "success",
          timer: 2500,
          showConfirmButton: false
        });
        this.router.navigate(['/buscar'])
        this.ObservableService.changeVistaMenu(3);
      })
  }

  guardarConfEmail() {
    let datos_paciente: any;
    datos_paciente = this.datos_paciente;
    this.ObservableService.changeDatosCorrectoGeneralConsulta({ datos_generales: this.datos_consulta, datos_paciente: datos_paciente.datos_paciente, valido: 1, destinatarios: this.correo_array })
    $('#EmailEnviar').modal('hide');
  }

  numeroEdad() {
    if (this.datos_paciente.paciente_edad > 120) {
      this.datos_paciente.paciente_edad = 120;
    }
  }

}
