import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConsultaService } from '../../services/consulta/consulta.service';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { ObservableService } from '../../observable.service';
import { Subscription } from 'rxjs/Subscription';
import { OnDestroy } from "@angular/core";
import { ObservableExtraer } from '../../services/extraer/extraer-observable.service';
import { GLOBAL } from '../../helpers';
import { Router } from '../../../../node_modules/@angular/router';
@Component({
  selector: 'confirmar-pw',
  templateUrl: './confirmar-pw.html'
})
export class ConfirmarPw implements OnInit {

  constructor(
    private router  : Router
  ) {


  }
  ngOnInit() {
    this.router.navigate(['/'])
  }
  
 
}


