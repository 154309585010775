<!-- Imágenes -->
<section *ngIf="mostrar_educacion" class="containerfondo">

        <div class="row">
                <div class="col col-lg-12" style="height: 100%;display: flex;align-items: center;justify-content: center;">
                    <div *ngIf="array_img_capacitaciont1" style="display: flex;">
                        <div *ngFor="let capacitacion of array_img_capacitaciont1" class="educa presenta">
                            <div class="text-center">
                                <a href="{{   capacitacion.capacitacion_url}}" target="_blank">
                                    <img src="{{url_imagen +  capacitacion.capacitacion_img}}" width="303px" height="90.09px" style="margin-bottom: 30px; "
                                    />
                                </a>
                                <br>


                            </div>
                        </div>
                </div>
            </div>
        </div>

    <div>
        <div class="row">
            <div class="col col-lg-12" style="height: 100%;display: flex;align-items: center;justify-content: center;">

                <div  style="width: 650px;height: 690px;" id="carousel1" data-ride="carousel" >
                    <div class="educacion_carrusel ">
                        <div  style="width: 650px;height: 690px;" *ngFor="let capacitacion2 of array_img_capacitacion_t2">
                            <a href="{{  capacitacion2.capacitacion_url}}" target="_blank">
                                <img   style="width: 650px;height: 690px;" src="{{url_imagen +  capacitacion2.capacitacion_img}}"  alt="First slide" id="img-slide-1-1">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- The Modal -->
    <div id="myModal" class="modal">
        <!-- The Close Button -->
        <span class="close">&times;</span>
        <!-- Modal Content (The Image) -->
        <img class="modal-content" id="img01">
        <!-- Modal Caption (Image Text) -->
        <div id="caption"></div>
    </div>
</section>