import { Component, OnInit, HostListener } from '@angular/core';
import { AyudantiaProveedoresService } from '../../../services/cirugia/ayudantia-proveedores.service';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { ProveedorModel } from '../../../models/ProveedorModel';
import swal from 'sweetalert2';
import { ObservableCirugia } from '../../../services/cirugia/observable-cirugia.service';
import { OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import { initTableAyudantiaCirugia } from '../../../../assets/js/tablas/cirugiaayudantia_tabla';
import { DatosEventoService } from 'src/app/services/cirugia/datos-evento.service';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/observable.service';
import { MOMENT } from '../../../../../node_modules/angular-calendar';
import *  as moments from 'moment';
@Component({
  selector: 'app-ayudantia-proveedores',
  templateUrl: './ayudantia-proveedores.component.html',
  styleUrls: ['./ayudantia-proveedores.component.css']
})
export class AyudantiaProveedoresComponent implements OnInit, OnDestroy {
  public session: string;
  public datos_session: any;
  public token: any;
  public datos_usuario: any;
  public usuario_id: string;
  public cat_ayudantia: any;
  public ingresacantidad: string;
  public datos_proveedor: ProveedorModel;
  public array_proveedor = [];
  private subscription: Subscription = new Subscription();
  private subscription_guardado: Subscription = new Subscription();
  public columnas_ayudantias;
  public datos_actualizar: boolean;
  public datos_proveedor_actualizar;
  public datos_cirugia;
  public actualziar_ayudantia: boolean;
  
  constructor(
    private AyudantiaProveedoresService: AyudantiaProveedoresService,
    private ConfiguracionService: ConfiguracionService,
    private ObservableCirugia: ObservableCirugia,
    private ObservableExtraer: ObservableExtraer,
    private DatosEventoService: DatosEventoService,
    private router: Router,
    private ObservableService: ObservableService
  ) {
    this.datos_proveedor = new ProveedorModel(0, '', 0, '', '', '', 0, 0);
    this.datos_proveedor_actualizar = new ProveedorModel(0, '', 0, '', '', '', 0, 0);
  }

  ngOnInit() {
    this.actualziar_ayudantia = false;
    this.datos_actualizar = false;
    this.Session();
    if (this.datos_proveedor.proveedor_monto == 0) {
      this.datos_proveedor.proveedor_monto = null;
    }
    const subscription_ayudantia = this.ObservableExtraer.datosSelectedAyudantiaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.datos_actualizar = true;
        let datos = result.datos_proveedor;
        
        for (let key in datos) {
          if(datos[key].proveedor_fecha == 'Invalid date' || datos[key].proveedor_fecha == '0000-00-00') datos[key].proveedor_fecha = '';
          else  datos[key].proveedor_fecha = moments(datos[key].proveedor_fecha).format('YYYY-MM-DD');
        }
        //console.log("---->>",datos)
        this.array_proveedor = datos;
        this.datos_cirugia = result.datos_cirugia;
        if (this.datos_actualizar) {
          this.getAyudantiasByID()
          this.getProveedoresByID();
        }
      } else {
        this.datos_actualizar = false;
        this.array_proveedor = [];
        this.datos_cirugia = null;
      }
    })

    // const subscription_data = this.ObservableCirugia.resetDatosCirugiaMessage.subscribe(result => {
    //   if (result != null && result != "" ) {
    //     this.array_proveedor = new Array();
    //   }
    // })
    //this.subscription_guardado.add(subscription_data);
    this.subscription.add(subscription_ayudantia)
  }
  @HostListener("window:callSomeClickHandler", ['$event.detail'])
  someClickHandler(info) {
    let datos = info.param;
    if (info.accion == 'actualizar_ayudantia') {
      this.actualizarAyudantia(datos);
      this.actualziar_ayudantia = true;
    } else if (info.accion == 'eliminar_ayudantia') {
      this.eliminarAyudantia(datos);
    }
  }
  ngOnDestroy() {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    this.subscription.unsubscribe();
    this.subscription_guardado.unsubscribe();
    this.ObservableCirugia.changedatosAyudantia({ limpiar: true });
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();

    }
  }

  getProveedoresByID() {
    if (this.datos_cirugia != undefined) {
      this.AyudantiaProveedoresService.getProveedorByID(this.datos_cirugia.cirugia_id, this.token.token)
        .subscribe(result => {
        })
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getAyudantia(this.datos_usuario);
        }
      })
  }
  getAyudantia(datos_usuario) {
    this.AyudantiaProveedoresService.getAyudantia(this.token.token, datos_usuario.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.cat_ayudantia = result.cat_ayudantia;
        }
      })
  }

  getColumnasAyudantias() {
    this.columnas_ayudantias = [{
      title: '<label style="color : #3d615f; margin-left: -7px;">Ayudantia o Proveedor</label>',
      data: 'proveedor_nombre'

    }, {
      title: '<label style="color : #3d615f; margin-left: -7px;">Monto</label>',
      data: 'proveedor_monto'
    }, {
      title: '<label style="color : #3d615f; margin-left: -7px;">Recibo</label>',
      data: 'proveedor_recibo'
    }, {
      title: '<label style="color : #3d615f; margin-left: -7px;">Fecha</label>',
      data: 'proveedor_fecha'
    }, {
      title: '<label style="color : #3d615f; margin-left: -7px;">Detalle</label>',
      data: 'proveedor_det'
    }, {
      title: '<label style="color : #3d615f; margin-left: 6px;">Editar</label>',
      render: function (data, type, full, meta) {
        return '<button id="actualizar_ayudantia""  type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Actualizar Ayudantia">' +
          ' <i style="color : #3d615f;  margin-left: 18px;" class="material-icons">edit</i> ' +
          '</button>'
      }
    },
    {
      title: '<label style="color : #3d615f">Borrar</label>',
      render: function (data, type, full, meta) {
        return '<button id="eliminar_ayudantia" type="button" rel="tooltip" class="btn  btn-round btn-just-icon btn-sm" title="Eliminar Ayudantia">' +
          '  <i style="color : #3d615f;  margin-left: 18px; " class="material-icons">delete</i> ' +
          '</button>'
      }
    }]

  }
  AddProveedor() {
  
    if (this.datos_proveedor.ayudantia_id == 0 || this.datos_proveedor.ayudantia_id == null || this.datos_proveedor.ayudantia_id == undefined) {
      swal("Error", "Debe seleccionar una Ayudantia", "error");
    } else {

      for (let key in this.cat_ayudantia) {
        if (this.cat_ayudantia[key].ayudantia_id == this.datos_proveedor.ayudantia_id) {
          this.datos_proveedor.proveedor_nombre = this.cat_ayudantia[key].ayudantia_desc;
          if(this.datos_proveedor.proveedor_fecha) this.datos_proveedor.proveedor_fecha = moments(this.datos_proveedor.proveedor_fecha).format('YYYY-MM-DD')
        }
      }
      this.getColumnasAyudantias()
      if (this.array_proveedor.length < 20) {
        this.array_proveedor.push(this.datos_proveedor)
      } else {
        swal({
          title: 'Ops',
          text: 'Alcanzaste el limite de Ayudantias',
          type: 'error',
          confirmButtonColor: "#EEC35A"
        })
      }

      this.actualziar_ayudantia = false;
      initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
      if (this.datos_actualizar == true) {
        
        this.AyudantiaProveedoresService.AddProveedor(this.datos_proveedor, this.datos_cirugia.cirugia_id)
          .subscribe(result => {
          })
      }
      this.datos_proveedor = new ProveedorModel(0, '', 0, '', '', '', 0, 0);
    }
  }
  DeleteAyudantia(i) {
    this.array_proveedor.splice(i, 1);
  }


  //initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias,this.cat_ayudantia );
  verificarDatos() {
    if (this.array_proveedor.length == 0) {
      this.ObservableCirugia.changedatosAyudantia({ ayudantia: 0 });
    } else {
      this.ObservableCirugia.changedatosAyudantia(this.array_proveedor);

    }
  }
  getAyudantiasByID() {
    this.getColumnasAyudantias();
    this.getAyudantia(this.datos_session);
    initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
  }

  eliminarAyudantia(datos_ayudantia) {
    if (datos_ayudantia.proveedor_id == 0) {
      let index = this.array_proveedor.findIndex(x => x.proveedor_nombre == datos_ayudantia.proveedor_nombre)
      this.array_proveedor.splice(index, 1);
      initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
    } else {
      this.AyudantiaProveedoresService.eliminarAyudantia(datos_ayudantia)
        .subscribe(result => {
          if (result.valido == 1) {
            this.AyudantiaProveedoresService.getProveedorByID(this.datos_cirugia.cirugia_id, this.token.token)
              .subscribe(result => {
                if (result.valido == 1) {
                  let proveedores = result.cat_proveedor;
                  for (let key in proveedores) {
                    if(proveedores[key].proveedor_fecha == 'Invalid date' || proveedores[key].proveedor_fecha == '0000-00-00') proveedores[key].proveedor_fecha = '';
                    else  proveedores[key].proveedor_fecha = moments(proveedores[key].proveedor_fecha).format('YYYY-MM-DD');
                  }
                  this.array_proveedor = proveedores;
                  initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
                }
              })

          }
        })
    }

  }

  actualizarAyudantia(datos) {
    this.datos_proveedor = datos;
    this.eliminarAyudantia(datos)
  }

  UpdateAyudantia() {
    if (this.datos_proveedor_actualizar.proveedor_id == 0) {
      let index = this.array_proveedor.findIndex(x => x.proveedor_nombre == this.datos_proveedor_actualizar.proveedor_nombre)
      this.array_proveedor.splice(index, 1);
      for (let key in this.cat_ayudantia) {
        if (this.cat_ayudantia[key].ayudantia_id == this.datos_proveedor_actualizar.ayudantia_id) {
          this.datos_proveedor_actualizar.proveedor_nombre = this.cat_ayudantia[key].ayudantia_desc
        }
      }
      this.array_proveedor.push(this.datos_proveedor_actualizar);
      initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
    } else {
      if (this.datos_proveedor_actualizar.ayudantia_id == 0 || this.datos_proveedor_actualizar.ayudantia_id == null || this.datos_proveedor_actualizar.ayudantia_id == undefined) {
        swal("Error", "Debe seleccionar un Ayudantia", "error");
      } else {
        for (let key in this.cat_ayudantia) {
          if (this.cat_ayudantia[key].ayudantia_id == this.datos_proveedor_actualizar.ayudantia_id) {
            this.datos_proveedor_actualizar.proveedor_nombre = this.cat_ayudantia[key].ayudantia_desc
          }
        }
        this.datos_proveedor_actualizar.cirugia_id = this.datos_cirugia.cirugia_id
        this.AyudantiaProveedoresService.UpdateAyudantia(this.datos_proveedor_actualizar)
          .subscribe(result => {
            this.getAyudantia(this.datos_session);
            if (result.valido == 1) {
              swal({
                title: 'Muy Bien!',
                text: result.error,
                type: "success",
                timer: 2500,
                showConfirmButton: false
              });
              this.actualziar_ayudantia = false;
              initTableAyudantiaCirugia('tabla_ayudantia', this.columnas_ayudantias, this.array_proveedor);
            }
          })
      }
    }
  }


  DeleteCirugia() {
    swal({
      title: 'Estas seguro?',
      text: "se eliminara tu cirugia!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#9b2c18 !important',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    })
      .then((result) => {
        if (result.value) {
          this.DatosEventoService.DeleteCirugia(this.datos_cirugia)
            .subscribe(result => {
              swal({
                title: 'Muy Bien!',
                text: result.error,
                type: "success",
                timer: 2500,
                showConfirmButton: false
              });
              this.router.navigate(['/buscar'])
              this.ObservableService.changeVistaMenu(3);
            })
        } else {
          swal("No se ha eliminado!");
        }
      });
  }

  clearValue(){
    this.datos_proveedor.proveedor_fecha = '';

  }
}
