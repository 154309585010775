import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from '../../models/UsuarioModel';
import { EstadisticasService } from '../../services/estadisticas/estadisticas.service';
import { ConfiguracionService } from '../configuracion/configuracion.service';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css']
})
export class EstadisticasComponent implements OnInit {
  public datos_estadistica = new Array();
  public session;
  public datos_session;
  public usuario_id;
  public token;
  public perfil_menu;
  public datos_usuario: UsuarioModel;
  public mostrar_estadistica: boolean;
  constructor(
    private EstadisticasService: EstadisticasService,
    private ConfiguracionService: ConfiguracionService
  ) { }

  ngOnInit() {
    this.mostrar_estadistica = false;
    this.datos_usuario = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.Session();
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario()
      this.MostrarMenu();
    }
  }

  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == "6") {
        this.mostrar_estadistica = true;
      }
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getEstadisticas();
        }
      })
  }

  getEstadisticas() {
    this.EstadisticasService.getEstadisticas(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          result.estadistica[0].cir_agendada == null ? result.estadistica[0].cir_agendada = 0 : result.estadistica[0].cir_agendada = result.estadistica[0].cir_agendada;
          result.estadistica[0].cir_nopagadas == null ? result.estadistica[0].cir_nopagadas = 0 : result.estadistica[0].cir_nopagadas = result.estadistica[0].cir_nopagadas;
          result.estadistica[0].dia_sem == null ? result.estadistica[0].dia_sem = 0 : result.estadistica[0].dia_sem = result.estadistica[0].dia_sem;
          result.estadistica[0].dur_cirugia == null ? result.estadistica[0].dur_cirugia = 0 : result.estadistica[0].dur_cirugia = result.estadistica[0].dur_cirugia;
          result.estadistica[0].horas_trab == null ? result.estadistica[0].horas_trab = 0 : result.estadistica[0].horas_trab = result.estadistica[0].horas_trab;
          result.estadistica[0].no_cirpordia == null ? result.estadistica[0].no_cirpordia = 0 : result.estadistica[0].no_cirpordia = result.estadistica[0].no_cirpordia;
          result.estadistica[0].prom_iedad == null ? result.estadistica[0].prom_iedad = 0 : result.estadistica[0].prom_iedad = result.estadistica[0].prom_iedad;
          result.estadistica[0].valor_hr_trab == null ? result.estadistica[0].valor_hr_trab = 0 : result.estadistica[0].valor_hr_trab = result.estadistica[0].valor_hr_trab;
          this.datos_estadistica = result.estadistica[0];
        }
      })
  }
}
