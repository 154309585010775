export class CatPersonalModel{
    constructor (
        public personal_id : number,
        public persona_desc : string,
        public personal_tipo : number,
        public personal_status : number,
        public usuario_id : number,
        public personal_email : string
    ){

    }
}