import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';
import { GLOBAL } from '../../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class DatosGeneralesService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getHospital(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_hospital/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    //provicional
    getPacientes(id, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'paciente/getbyusuarioid/' + id, { headers: headers })
            .map(res => res.json());
    }
    // Nuevo paciente
    AddConsultaPaciente(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'paciente/add', params, { headers: headers })
            .map(res => res.json());
    }
    // Agrega un nueva consulta general
    AddConsultaGenerales(datos) {
        console.log("AddConsultaGenerales", datos);
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'consulta/add', params, { headers: headers })
            .map(res => res.json());
    }
    //agrega una nueva consulta de pago 
    AddConsultaPago(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'detalle_pago/add', params, { headers: headers })
            .map(res => res.json());
    }
    // BUSCADOR por datos generales
    BuscarPago(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'cirugia/busqueda-datos-generales', params, { headers: headers })
            .map(res => res.json());
    }
    // Buscador vista buscar de detalles de pago
    BuscarDetallePago(datos) {
        console.log("DATOS ENVIADOS", datos);
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'cirugia/busqueda-datos-pago', params, { headers: headers })
            .map(res => res.json());
    }
    // elimina con el estatus de la cosulta
    DeleteConsulta(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'consulta/delete', params, { headers: headers })
            .map(res => res.json());
    }
    //vista bsuqueda general
    BuscarDatos(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'cirugia/busqueda', params, { headers: headers })
            .map(res => res.json());
    }
}
DatosGeneralesService.ngInjectableDef = i0.defineInjectable({ factory: function DatosGeneralesService_Factory() { return new DatosGeneralesService(i0.inject(i1.Http)); }, token: DatosGeneralesService, providedIn: "root" });
