export class CatAyudantiaModel {
    constructor(
        public ayudantia_id: number,
        public ayudantia_desc: string,
        public ayudantia_status: number,
        public usuario_id: number,
        public ayudantia_email : string
    ) {

    }
}