<!-- Barra redes sociales -->
<section>


    <!-- Div para mantener el fondo en las dos secciones -->
    <!-- JavaScript files-->
    <div class=" py-2 container-fluid">
        <!-- Botones presentación calendario -->
        <section>
            <!-- <div class="container container-small pt-5">
               <div class="row">
                    
                    <div *ngIf="fecha_hoy" class="col md-2">
                        <p class="text-center pt-3"> <span class="yearsign"> <span class="leyenda ml-2" style="font-weight: bold; color:#3C615F; font-size: 1.75rem;"> {{fecha_hoy |
                                    uppercase}}</span> <span class="year"></span> <span class="yearsign ml-2"></span></span></p>
                    </div>
                </div>
                <hr style="height:6px; margin: 0.1em; border:none; color:rgb(60,97,95); background-color:rgb(60,97,95);">
                <hr style="height:2px; margin: 0.2em; border:none; color:rgb(60,97,95); background-color:rgb(60,97,95);">
            </div> -->
            <div class="col-md-12">
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="pointer" style="margin-left : 95%">
                            <label class="pointer flecha-calendar" (click)="changeCalendar(-1)">
                                < </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <h3 id="mes" *ngIf=" mostrar_fecha" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{fecha_mostrar | uppercase}}</h3>
                        <h3 id="mes" *ngIf="fecha_hoy && !mostrar_fecha" style="font-weight: bold; color:#3C615F;font-size: 20px;">{{fecha_hoy | uppercase}}</h3>
                        <!-- <h3 id="mes"  style = "font-weight: bold; color:#3C615F">{{ viewDate | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }}</h3> -->
                    </div>
                    <div class="col-md-4">
                        <div class="pointer" style="margin-right : 120%">
                            <label class="pointer flecha-calendar" (click)="changeCalendar(1)">
                                >
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Lista -->
        <section>
            <div class="container container-small pt-2 pl-5">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let citas of citas_dia" class="pointer" (click)="BusquedaCita(citas)">
                            <th scope="row"></th>
                            <td>
                                <img *ngIf="citas.tipo == 2" src="../../../../assets/images/menu-consulta-help.png" width="18" height="auto">
                                <img *ngIf="citas.tipo == 1" src="../../../../assets/images/menu-cirugia-help.png" width="18" height="auto">
                            </td>
                            <td class="paciente">{{citas.cirugia_hora}}</td>
                            <td class="paciente">{{citas.paciente_nombre}}
                                <br>
                                <span class="detallecita">{{citas.persona_desc}} / {{citas.proce_desc}}
                                    <br> {{citas.hospital_desc}} / {{citas.anestesia_desc}}</span>
                            </td>
                            <td>
                                <img *ngIf="citas.detpg_pagada == 1" src="../../../../assets/images/icon-pago-over.png" width="18" height="auto">
                                <img *ngIf="citas.detpg_pagada == 0" src="../../../../assets/images/icon-pago-grey.png" width="18" height="auto">
                            </td>
                            <td>
                                <img *ngIf="citas.detpg_depositado == 1" src="../../../../assets/images/icon-deposito-over.png" width="18" height="auto">
                                <img *ngIf="citas.detpg_depositado == 0" src="../../../../assets/images/icon-deposit-grey.png" width="18" height="auto">
                            </td>
                            <td>
                                <img *ngIf="citas.detpg_recibo == 1" src="../../../../assets/images/icon-recibo-over.png" width="18" height="auto">
                                <img *ngIf="citas.detpg_recibo == 0" src="../../../../assets/images/icon-recibo-grey.png" width="18" height="auto">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <!-- Cierra el fondo -->
    </div>

</section>