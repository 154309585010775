<button *ngIf="array_proveedor.length  >= 0" type="button" class="btn btn-form-conf-verde btn-block btn-lg" data-toggle="modal"
  data-target="#modal1">Ver Ayudantias
</button>

<br>

<div class="form-row">
  <button style="width: 317px; background-color:rgb(124,28,18);  margin-left: 2%;" *ngIf="datos_actualizar" (click)="DeleteCirugia()"
    type="button" class="btn btn-form-conf-verde btn-lg btn-block">
    <span>
      <img class="" src="../../../assets/images/icon-trash-transparent.png" width="14px"> &nbsp; Eliminar Cirugía </span>
  </button>
  <button type="submit" (click)="verificarDatos()" class="btn btn-form btn-block btn-lg font-weight-bold" style="width: 317px; margin-left: auto; margin-right: auto;">GUARDAR
  </button>
</div>

<div class="modal fade pg-show-modal" id="modal11" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style="font-family: Open Sans; color: black"> Ayudantía o Proveedor</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div *ngFor="let proveedor of array_proveedor; let i = index">
          <br>
          <button (click)="DeleteAyudantia(i)" type="button" class="btn btn-form-conf-verde btn-block btn-md">{{proveedor.proveedor_nombre}}
            <span style="position: absolute;right: 184px">
              <img src="../../../assets/images/icon-trash-transparent.png" width="14px">
            </span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <table id="p" class="table table-striped table-bordered" role="grid" aria-describedby="datatables_info" width="70%" cellspacing="0">
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" style="font-family: Open Sans;">Close</button>

      </div>
    </div>
  </div>
</div>


<div class="modal fade bd-example-modal-lg" id="modal1" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="true"
  data-pg-name="Ayudantia">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="width:114%">
      <div class="modal-header">
        <h4 class="modal-title text-center font-weight-bold" style="font-family: Open Sans; color:#3C615F">
          Ayudantía/Proveedor</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <!-- *ngIf="!datos_actualizar" -->
      <div class="modal-body filtra">
        <!--Inicio de modificacion-->

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select name="ayudantia" #name="ngModel" [(ngModel)]="datos_proveedor.ayudantia_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                id="ayudantia">
                <option [value]="0">Ayudantía o Proveedor</option>
                <option *ngFor="let ayudantia of cat_ayudantia" [value]="ayudantia.ayudantia_id">
                  {{ayudantia.ayudantia_desc}}</option>
              </select>
              <!-- Requiere el script al final de la página-->

            </div>
            <div class="form-group">
              <textarea class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" name="descripcion" #name="ngModel"
                [(ngModel)]="datos_proveedor.proveedor_det" id="notasTextarea" rows="4" placeholder="Detalles De Ayudantía o Proveedor"></textarea>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 space">
                <div class="form-group">
                  <input type="text" name="monto" min=0 #name="ngModel" [(ngModel)]="datos_proveedor.proveedor_monto" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                    id="inputNombre" placeholder="Monto">
                </div>
              </div>
              <div class="col-md-4 space">
                <div class="form-group">
                  <input type="nombre" name="recibo" #name="ngModel" [(ngModel)]="datos_proveedor.proveedor_recibo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                    id="recibo" placeholder="Recibo">
                </div>
              </div>
              <div class="col-md-4 space">
                <div class="form-group">
                    <div class="trigger input-group-btn" style="display: flex;">
                        <input name="fecha" #name="ngModel" [(ngModel)]="datos_proveedor.proveedor_fecha" id="datepicker1" style="font-size: 12px; font-weight: 3000;display: inline-block; width: -webkit-fill-available;border-bottom-right-radius: 0;border-top-right-radius: 0"
                        class="read-on top-medio form-control letraCuadrosFormulario flecha_s" placeholder="Fecha" [owlDateTimeTrigger]="dt7"
                        [owlDateTime]="dt7" readonly >
                      <button class="read-on btn btn-default" type="button" (click)="clearValue()" style="width: 10px; height: 32px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #b9b9b9;">
                        <i class="material-icons md-16" style="font-size: 17px;">&#xE14C;</i></button>
                    </div>
                  <owl-date-time [pickerType]="'calendar'" #dt7></owl-date-time>
                </div>
              </div>
              <div class="col-md-12 space">
                <div class="form-group">
                  <button type="button" class="btn form-control letraCuadrosFormulario" style="background-color: #EEC35A;"
                    (click)="AddProveedor()">
                    <b *ngIf="!actualziar_ayudantia">
                      Agregar Ayudantía o Proveedor
                    </b>
                    <b *ngIf="actualziar_ayudantia">
                      Guardar Cambios Ayudantía o Proveedor
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--termino modificacion-->
      <!--
<div class="col-md-4">
          <div *ngFor="let proveedor of array_proveedor; let i = index">
            <br>
            <button (click)="DeleteAyudantia(i)" type="button" class="btn btn-form-conf-verde btn-block btn-md">{{proveedor.proveedor_nombre}}
              <span >
                <img src="../../../assets/images/icon-trash-transparent.png" width="14px">
              </span>
            </button>
          </div>
        </div>
-->

      <!--
      <div class="modal-body">
       
        <div class="row">
          <div class="col-md-4">
            <div class="form-row">
              <div  class="form-group col-md-12">
                <select name="ayudantia" #name="ngModel" [(ngModel)]="datos_proveedor_actualizar.ayudantia_id" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                  id="ayudantia">
                  <option [value]="0">Ayudantía o Proveedor</option>
                  <option *ngFor="let ayudantia of cat_ayudantia" [value]="ayudantia.ayudantia_id">{{ayudantia.ayudantia_desc}}</option>
                </select>
                

              </div>
            </div>
            <div class="form-row">
              <div  class="form-group col-4">
                <input type="text" name="monto" min=0 #name="ngModel" [(ngModel)]="datos_proveedor_actualizar.proveedor_monto"
                  class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista" id="inputNombre"
                  placeholder="Monto">
              </div>
              <div  class="form-group col-4">
                <input type="nombre" name="recibo" #name="ngModel" [(ngModel)]="datos_proveedor_actualizar.proveedor_recibo" class="btn btn-block dropdown-toggle letraCuadrosFormulario letraCuadrosLista"
                  id="recibo" placeholder="Recibo">
              </div>
              <div  class="form-group col-4">
                <input name="fecha" #name="ngModel" [(ngModel)]="datos_proveedor_actualizar.proveedor_fecha" id="datepicker1"
                  style="font-size: 12px; font-weight: 300; " class="read-on top-medio form-control letraCuadrosFormulario flecha_s"
                  placeholder="Fecha" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly>
                <owl-date-time style="z-index: 9999;" [pickerType]="'calendar'" #dt8></owl-date-time>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <textarea  style ="height: 50px;" class="form-control letraCuadrosFormulario" name="descripcion"
                  #name="ngModel" [(ngModel)]="datos_proveedor_actualizar.proveedor_det" id="notasTextarea" rows="3" placeholder="Detalles De Ayudantía o Proveedor"></textarea>
              </div>

            </div>
           
          <button   type="button" class="btn btn-form btn-block btn-lg font-weight-bold" (click)="UpdateAyudantia()"
            style="font-family: Open Sans;">Actualizar
            cambios</button>
          </div>

          -->
      <div class="modal-body tablas">
        <table id="tabla_ayudantia" class="table table-striped table-bordered " role="grid" aria-describedby="datatables_info" width="100%"
          cellspacing="0">
        </table>
      </div>

      <div class="modal-footer" style="justify-content: center">
        <button type="button" class="btn btn-form" data-dismiss="modal" style="font-family: Open Sans;">Cerrar</button>
      </div>


    </div>
  </div>
</div>