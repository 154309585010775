import { Component, OnInit } from "@angular/core";
import { ConfiguracionService } from "../configuracion/configuracion.service";
import { BusquedaGeneralModel } from "../../models/BusquedaGeneralModel";
import { DatosGeneralesService } from "../../services/consulta/datos-generales/datos-generales.service";
import { DatosEventoService } from "../../services/cirugia/datos-evento.service";
import { AyudantiaProveedoresService } from "../../services/cirugia/ayudantia-proveedores.service";
import { DetallePagoService } from "../../services/consulta/detalle-pago/detalle-pago.service";
import { ObservableExtraer } from "../../services/extraer/extraer-observable.service";
import { ObservableService } from "../../observable.service";
import { Router } from "../../../../node_modules/@angular/router";
import swal from "sweetalert2";
import { Subscription } from 'rxjs/Subscription';
import { ObservableBusqueda } from "../../services/buscar/buscar-obserbable.service";
@Component({
  selector: "app-busqueda-general",
  templateUrl: "./busqueda-general.component.html",
  styleUrls: ["./busqueda-general.component.css"]
})
export class BusquedaGeneralComponent implements OnInit {
  public datos_cirugia: BusquedaGeneralModel;
  public session: string;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public mostrar_busqueda: boolean;
  public perfil_menu;
  public usuarionuevo: boolean;
  public tipo_menu: number;
  public gato: boolean;
  public cat_hospital = new Array();
  public pacientes = new Array();
  public edad_paciente = new Array();
  public cat_anestesia = new Array();
  public cat_proce = new Array();
  public cat_cirujanos = new Array();
  public cat_anestesiologo = new Array();
  public cat_ayudantia = new Array();
  public cat_fpago = new Array();
  public banco_desc = new Array();
  public cat_aseguradora = new Array();
  public resultado_busqueda = new Array();
  public datos_consulta;
  public datos_paciente;
  public datos_proveedor;
  public paciente_id;
  public detalle_pago;
  public buscar;
  public datos_cirugia_select;
  public consulta_pagada: boolean;
  public consulta_nopagada: boolean;
  public deposito_pagado: boolean;
  public deposito_nopagado: boolean;
  public recibo_nopagado : boolean;
  public recibo_pagado : boolean;
  private unsubscribe_busqueda: Subscription = new Subscription();
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private DatosGeneralesService: DatosGeneralesService,
    private DatosEventoService: DatosEventoService,
    private AyudantiaProveedoresService: AyudantiaProveedoresService,
    private DetallePagoService: DetallePagoService,
    private ObservableExtraer: ObservableExtraer,
    private ObservableService: ObservableService,
    private ObservableBusqueda: ObservableBusqueda,
    private router: Router
  ) {
    this.datos_cirugia = new BusquedaGeneralModel();
  }

  ngOnInit() {
    this.mostrar_busqueda = false;
    this.Session();

    //observable de la busqueda rapida(componente header)
    const busqueda = this.ObservableBusqueda.resultadoBusquedaMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        console.log("mi observable", result)
        this.resultado_busqueda = result.datos;
      }
    })
    this.unsubscribe_busqueda.add(busqueda);
  }

  ngOnDestroy() {
    this.unsubscribe_busqueda.unsubscribe();

  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem("usuario");
    let token = localStorage.getItem("token");

    this.perfil_menu = JSON.parse(localStorage.getItem("app"));
    this.token = { token: JSON.parse(token) };
    if (this.session != null) {
      this.datos_session = JSON.parse(this.session);
      //console.log("session",this.datos_session);
      if(this.datos_session.usuid_padre){
        this.usuario_id = this.datos_session.usuid_padre;
      } else {
        this.usuario_id = this.datos_session.usuario_id;
      }

      this.getPerfilUsuario();
      this.MostrarMenu();
      this.getHospital();
      this.edades();
      this.getPacientes();
      this.getAnestesia();
      this.getProcedimiento();
      this.getCirujanos();
      this.getAnestesiologos();
      this.getAyudantia();
      this.getPagos();
      this.getBanco();
      this.getAseguradora();
      this.consulta_pagada = true;
      this.consulta_nopagada = true;
      this.deposito_pagado = true;
      this.deposito_nopagado = true;
      this.recibo_nopagado = true;
      this.recibo_pagado = true;
      this.datos_cirugia.recibo = '';
      this.datos_cirugia.depositado = '';
      this.datos_cirugia.pagada = '';
      console.log("this.session ", this.usuario_id);
    }
  }
  /*************INICIO DE CATALOGOS*********** */

  getAyudantia() {
    this.AyudantiaProveedoresService.getAyudantia(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      console.log("Ayudantias", result);
      if (result.valido == 1) {
        this.cat_ayudantia = result.cat_ayudantia;
      }
    });
  }

  getHospital() {
    this.DatosGeneralesService.getHospital(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.cat_hospital = result.cat_hospital;
    });
  }

  getPacientes() {
    this.DatosGeneralesService.getPacientes(
      this.usuario_id,
      this.token.token
    ).subscribe(result => {
      this.pacientes = result.pacientes;
    });
  }

  edades() {
    for (let i = 1; i <= 180; i++) {
      this.edad_paciente.push({ edad: i });
    }
  }
  getAnestesia() {
    this.DatosEventoService.getAnestesia(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.cat_anestesia = result.cat_anestesia;
    });
  }

  getProcedimiento() {
    this.DatosEventoService.getProcedimiento(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.cat_proce = result.cat_proce;
    });
  }
  getCirujanos() {
    // tipo 1 cirujano tipo 2 anestesiologo
    this.DatosEventoService.getPersonal(
      this.token.token,
      this.usuario_id,
      1
    ).subscribe(result => {
      this.cat_cirujanos = result.cat_personal;
    });
  }
  getAnestesiologos() {
    // tipo 1 cirujano tipo 2 anestesiologo
    this.DatosEventoService.getPersonal(
      this.token.token,
      this.usuario_id,
      2
    ).subscribe(result => {
      this.cat_anestesiologo = result.cat_personal;
    });
  }

  getPagos() {
    this.DetallePagoService.getPagos(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.cat_fpago = result.cat_fpago;
    });
  }
  getBanco() {
    this.DetallePagoService.getBanco(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.banco_desc = result.cat_banco;
    });
  }

  getAseguradora() {
    this.DetallePagoService.getAseguradora(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      this.cat_aseguradora = result.cat_aseguradora;
    });
  }
  /*************TERMINA DE CATALOGOS*********** */

  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == 3) {
        this.mostrar_busqueda = true;
      }
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(
      this.token.token,
      this.usuario_id
    ).subscribe(result => {
      if (result.valido == 1) {
        this.datos_usuario = result.usuario[0];
      }
    });
  }

  /****seccion formulario pago */
  esPagada(seleccionPagada) {
    // 1 pagaada 2 no pagada
    if (seleccionPagada == 1) {
      this.datos_cirugia.pagada = '0';
      this.consulta_nopagada = true;
      if(this.consulta_nopagada && this.consulta_pagada) this.datos_cirugia.pagada = ''
      console.log("PAGADAAA11",this.datos_cirugia.pagada)
    } else if (seleccionPagada == 2) {

      //
      this.datos_cirugia.cantidad = '';
      this.datos_cirugia.id_formapago = '';
      this.datos_cirugia.pagada = '1';
      this.consulta_pagada = true;
      if(this.consulta_nopagada && this.consulta_pagada) this.datos_cirugia.pagada = ''
      console.log("PAGADAAA22",this.datos_cirugia)
    } 
  }

  esDeposito(seleccionDeposito) {
    //1 depositado 2 no depositado
    if (seleccionDeposito == 1) {
      this.datos_cirugia.depositado = '0';
      this.deposito_nopagado = true;
      if(this.deposito_nopagado && this.deposito_pagado) this.datos_cirugia.depositado = ''
      console.log("11111",  this.deposito_pagado,"No pagado",this.deposito_nopagado,"datos",this.datos_cirugia.depositado);

    } else if (seleccionDeposito == 2) {
      this.datos_cirugia.id_banco = '';
      this.datos_cirugia.depositado = '1';
      this.deposito_pagado = true;
      if(this.deposito_nopagado && this.deposito_pagado) this.datos_cirugia.depositado = ''
      console.log("2222",  this.deposito_pagado,"No pagado",this.deposito_nopagado,"datos",this.datos_cirugia.depositado);
    } 
   
  }
  esRecibo(reciboPagado) {
    // 1 pagado 2 no pagado
    if (reciboPagado == 1) {
      this.datos_cirugia.recibo = '0';
      this.recibo_nopagado = true;
      if(this.recibo_nopagado && this.recibo_pagado) this.datos_cirugia.recibo = ''
      console.log("11111",  this.recibo_pagado,"No pagado",this.recibo_nopagado,"datos",this.datos_cirugia.recibo);

    } else if (reciboPagado == 2) {
      this.datos_cirugia.num_recibo = '';
      this.datos_cirugia.recibo = '1';
      this.recibo_pagado = true;
      if(this.recibo_nopagado && this.recibo_pagado) this.datos_cirugia.recibo = ''
      console.log("2222",  this.recibo_pagado,"No pagado",this.recibo_nopagado,"datos",this.datos_cirugia.recibo);
    } 
  }

  /***termina seccion formulario pago */

  /*Empieza funcion de bsuqueda */

  BuscarDatos() {
    this.datos_cirugia.tipo_busqueda = "busqueda";
    this.usuario_id =
      this.datos_session.usuid_padre != 0
        ? this.datos_session.usuid_padre
        : this.datos_session.usuario_id;
    //s y n son los parametros que debe recibir el servicio para poder realizar la consulta
    this.datos_cirugia.consulta_search = this.datos_cirugia.consultaSearch == true ? 's' : 'n';
    this.datos_cirugia.cirugia_search = this.datos_cirugia.cirugiaSearch == true ? 's' : 'n';
    if(this.datos_cirugia.cirugia_fecha_ini == null) this.datos_cirugia.cirugia_fecha_ini = '';
    if(this.datos_cirugia.cirugia_fecha_fin == null) this.datos_cirugia.cirugia_fecha_fin = '';
    if(this.datos_cirugia.proveedor_fecha_ini == null) this.datos_cirugia.proveedor_fecha_ini = '';
    if(this.datos_cirugia.proveedor_fecha_fin == null) this.datos_cirugia.proveedor_fecha_fin = '';
    if(this.datos_cirugia.fecha_pago_ini == null) this.datos_cirugia.fecha_pago_ini = '';
    if(this.datos_cirugia.fecha_pago_fin == null) this.datos_cirugia.fecha_pago_fin = '';
    if(this.datos_cirugia.fecha_deposito_ini == null) this.datos_cirugia.fecha_deposito_ini = '';
    if(this.datos_cirugia.fecha_deposito_fin == null) this.datos_cirugia.fecha_deposito_fin = '';
    if(this.datos_cirugia.fecha_recibo_ini == null) this.datos_cirugia.fecha_recibo_ini = '';
    if(this.datos_cirugia.fecha_recibo_fin == null) this.datos_cirugia.fecha_recibo_fin = '';

    console.log("MIS BSUQUEDAS", this.datos_cirugia);
    this.datos_cirugia.usuario_id = parseInt(this.usuario_id);

    this.DatosGeneralesService.BuscarDatos(this.datos_cirugia).subscribe(
      result => {
        this.buscar = 1;
        this.resultado_busqueda = result.datos;
        console.log("El resultado", result);
      }
    );
  }

  /*Termina funcion de bsuqueda */

  /**************seleccion de consulta busqueda de resultado * */

  ResultadoSelected(resultado_busqueda) {
    //console.log(
    //  "******************************************************",
    //  resultado_busqueda
    //);
    // tipo 1 cirugia , 2 consulta
    if (resultado_busqueda.tipo == 1 || resultado_busqueda.tipo == undefined) {
      this.DatosEventoService.getCirugia(
        this.token.token,
        resultado_busqueda.cirugia_id
      ).subscribe(result => {
        if (result.valido == 1) {
          this.datos_cirugia_select = result.cirugia[0];

          this.paciente_id = this.datos_cirugia_select.paciente_id;
          this.DatosEventoService.getProveedorAyudantia(
            this.token.token,
            resultado_busqueda.cirugia_id
          ).subscribe(result => {
            if (result.valido == 1) {
              
              this.datos_proveedor = result.cat_proveedor;
              this.DatosEventoService.getPaciente(
                this.token.token,
                this.paciente_id
              ).subscribe(result => {
                this.datos_paciente = result.paciente[0];
                if (result.valido == 1) {
                  let pago_id = this.datos_cirugia_select.detpg_id;
                  this.DatosEventoService.getDetallePago(
                    this.token.token,
                    pago_id
                  ).subscribe(result => {
                    if (result.valido == 1) {
                      this.detalle_pago = result.detalle_pago[0];
                      console.log("detalle_pago", this.detalle_pago);
                      this.ObservableExtraer.changedatosSelectedGeneral({
                        datos_paciente: this.datos_paciente,
                        datos_cirugia: this.datos_cirugia_select
                      });
                      this.ObservableExtraer.changedatosPacienteSelected({
                        datos_paciente: this.datos_paciente,
                        datos_cirugia: this.datos_cirugia_select
                      });
                      this.ObservableExtraer.changedatosPagoSelected({
                        datos_cirugia: this.datos_cirugia_select,
                        detalle_pago: this.detalle_pago,
                        actualizar: true
                      });
                      this.ObservableExtraer.changedatosAyudantiaSelected({
                        datos_cirugia: this.datos_cirugia_select,
                        detalle_pago: this.detalle_pago,
                        datos_proveedor: this.datos_proveedor
                      });
                      this.router.navigate(["/cirugia"]);
                      this.ObservableService.changeVistaMenu(2);
                    }
                  });
                }
              });
            }
          });
        }
      });
    } else if (resultado_busqueda.tipo == 2) {
      //resultado_busqueda.cirugia_id en tipo 2  es id_consutlta
      let id_consulta = resultado_busqueda.cirugia_id;
      this.DatosEventoService.getConsulta(
        this.token.token,
        id_consulta
      ).subscribe(result => {
        if (result.valido == 1) {
          this.datos_consulta = result.consulta[0];
          this.paciente_id = this.datos_consulta.paciente_id;
          this.DatosEventoService.getPaciente(
            this.token.token,
            this.paciente_id
          ).subscribe(result => {
            this.datos_paciente = result.paciente[0];
            if (result.valido == 1) {
              let pago_id = this.datos_consulta.detpg_id;
              this.DatosEventoService.getDetallePago(
                this.token.token,
                pago_id
              ).subscribe(result => {
                if (result.valido == 1) {
                  this.detalle_pago = result.detalle_pago[0];
                  this.ObservableExtraer.changedatosSelectedGeneral({
                    datos_paciente: this.datos_paciente,
                    datos_consulta: this.datos_consulta
                  });
                  this.ObservableExtraer.changedatosPacienteSelected({
                    datos_paciente: this.datos_paciente,
                    datos_consulta: this.datos_consulta
                  });
                  this.ObservableExtraer.changedatosPagoSelected({
                    datos_consulta: this.datos_consulta,
                    detalle_pago: this.detalle_pago,
                    actualizar: true
                  });
                  this.ObservableService.changeVistaMenu(1);
                  this.router.navigate(["/consulta"]);
                }
              });
            }
          });
        }
      });
    }
  }
  /**************termina de consulta busqueda de resultado * */
  /****extraccion de archivo */
  formaBusqueda(categoria_busqueda) {
    //  categoria_busqueda = 1 todos , 2 ayudantia , 3 pagos
    this.datos_cirugia.tipo_busqueda = categoria_busqueda;

  }

  typeSearchSelect(typeSearch) {
    //1 consulta 2 cirugia
    if (typeSearch == 1) {
      this.datos_cirugia.cirugiaSearch = true;
    } else {
      this.datos_cirugia.consultaSearch = true;
    }
  }

  tipoEnvio(tipo_envio) {
    //tipo_envio 1 pdf , 2  excel
    this.datos_cirugia.tipo_envio = tipo_envio;
    this.datos_cirugia.tipo_busqueda = this.buscar == 1 ? this.datos_cirugia.tipo_busqueda = '1' : this.datos_cirugia.tipo_busqueda = this.datos_cirugia.tipo_busqueda;
    console.log("Mi busqueda", this.datos_cirugia.tipo_busqueda);
    this.usuario_id = this.datos_session.usuario_id;
    console.log("Usuario Id  this.usuario_id ", this.usuario_id)
    this.datos_cirugia.usuario_id = parseInt(this.usuario_id);
    this.datos_cirugia.usuarioidPadre =  this.datos_session.usuid_padre ;
    this.DatosGeneralesService.BuscarDatos(this.datos_cirugia).subscribe(
      result => {
        if (result.valido == 1) {
          //this.resultado_busqueda = result.datos;
          swal("Muy bien", "Se ha enviado el documento a su email", "success");
        } else {
          swal("Error", "Vuelva a intentarlo", "error");
        }

        console.log("El resultado de exportar", result);
      }
    );
  }

  infoTip(){
     
      swal({
        title: "<p style='color:#3A5B58'>Información</p>",
        html: "<p style='text-align:justify;color:#3A5B58'><b>Generales:</b> Contiene los datos generales de todos tus eventos  desplegados tu búsqueda. (fecha, procedimiento, anestesiólogo, etc.).</p>"+
              "<p style='text-align:justify;color:#3A5B58'><b>Ingresos:</b> Contiene los datos de pago/deposito/recibo de todos tus eventos desplegados tu búsqueda.</p>"+
              "<p style='text-align:justify;color:'#3A5B58'><b>Egresos:</b> Contiene los datos de ayudantías registradas en los eventos desplegados en tu búsqueda. Si tus eventos desplegados NO presentan ayudantías, estos NO aparecerán en el reporte.</p>",
        imageUrl: "../../../assets/images/logo_pleca.png",
        imageWidth: 50

      })
  }
  /****termina extraccion de archivo */
  /*******************DATOS DE PAGO botton selected****************** */


  /*************************************************** */

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  clearFechaDe(){
    this.datos_cirugia.cirugia_fecha_ini = '';
  }

  clearADe(){
    this.datos_cirugia.cirugia_fecha_fin = '';
  }

  clearAyudnatiaFechaDe(){
    this.datos_cirugia.proveedor_fecha_ini = '';
  }

  clearAyudnatiaFechaA(){
    this.datos_cirugia.proveedor_fecha_fin = '';
  }

  clearPagadoDe(){
    this.datos_cirugia.fecha_pago_ini ='';
  }

  clearPagadoA(){
    this.datos_cirugia.fecha_pago_fin ='';
  }

  clearDepositadoDe(){
    this.datos_cirugia.fecha_deposito_ini = '';
  }

  clearDepositadoA(){
    this.datos_cirugia.fecha_deposito_fin = '';
  }

  clearReciboDe(){
    this.datos_cirugia.fecha_recibo_ini = '';
  }

  clearReciboA(){
    this.datos_cirugia.fecha_recibo_fin = '';
  }
}
