import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { OnDestroy } from "@angular/core";
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { DatosGeneralesService } from '../../../services/consulta/datos-generales/datos-generales.service';
import { CirugiaModel } from '../../../models/CirugiaModel';
import { PacienteModel } from '../../../models/PacienteModel';
import { ConsultaImgModel } from '../../../models/ConsultaImgModel';
import { ConsultaModel } from '../../../models/ConsultaModel';
import { DatosEventoService } from '../../../services/cirugia/datos-evento.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConsultaService } from '../../../services/consulta/consulta.service';
import { Subscription } from 'rxjs/Subscription';
import { ObservableCirugia } from '../../../services/cirugia/observable-cirugia.service';
import { ObservableExtraer } from '../../../services/extraer/extraer-observable.service';
import * as moment from 'moment';
import { GLOBAL } from '../../../helpers';
import { CirugiaService } from '../../../services/cirugia/cirugia.service';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/observable.service';
@Component({
  selector: 'app-datos-evento',
  templateUrl: './datos-evento.component.html',
  styleUrls: ['./datos-evento.component.css']
})
export class DatosEventoComponent implements OnInit, OnDestroy {
  public edad_paciente = new Array();
  public session: string;
  public datos_session: any;
  public token: any;
  public usuario_id: string;
  public datos_usuario: any;
  public pacientes = new Array();
  public cat_hospital = new Array();
  public cat_anestesia = new Array();
  public cat_anestesiologo = new Array();
  public cat_proce = new Array();
  public cat_cirujanos = new Array();
  public datos_cirugia: CirugiaModel;
  public datos_paciente: PacienteModel;
  public usuarionuevo: boolean;
  public usuario_selected: number;
  public usuario_nuevo: boolean;
  public formato: number;
  public detalle_imagen_categoria_evento: number;
  public file_imagen_evento: File;
  public url_imagen_evento: string;
  public detalle_imagen_categoria_evento_secundaria: number;
  public file_imagen_evento_secundaria: File;
  public url_imagen_evento_secundaria: string;
  private subscription: Subscription = new Subscription();
  private subscription_guardado: Subscription = new Subscription();
  private unsubs: Subscription = new Subscription();
  public datos: any;
  public ayudantia: any;
  public cirugia_id: number;
  public datos_actualizar: boolean;
  public imagen_evento_principal: any;
  public imagen_evento_secundario: any;
  public url_imagen: string;
  public modo_actualizar: boolean;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private DatosGeneralesService: DatosGeneralesService,
    private DatosEventoService: DatosEventoService,
    private ObservableCirugia: ObservableCirugia,
    private ObservableExtraer: ObservableExtraer,
    private CirugiaService: CirugiaService,
    private ObservableService: ObservableService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.Session();
    this.url_imagen = GLOBAL.url;
    this.usuarionuevo = true;
    this.usuario_nuevo = true;
    this.modo_actualizar = false;
    const subscription = this.ObservableCirugia.datosAyudantiaMessage.subscribe(result => {
      if (result != null && result != "") {
        if (result.ayudantia != 0) {
          this.ayudantia = result;
        } else {
          this.ayudantia = 0;
        }
        this.verificaDatosEvento();
      }
    })
    const subscription_guardado = this.ObservableCirugia.resetDatosCirugiaMessage.subscribe(result => {
      if (result != null && result != "") {
        this.cirugia_id = result;
        this.GuardarArchivoImagenSecundaria(this.cirugia_id);
        this.GuardarArchivoImagen(this.cirugia_id)
      }
    })
    const unsubscribe = this.ObservableExtraer.datosSelectedGeneralMessage.subscribe(result => {
      console.log("*-*-1*-*-*-*-",result)
      if (result != undefined && result != null && result != "") {
        this.datos_actualizar = true;
        this.modo_actualizar = true;

        this.datos_cirugia = result.datos_cirugia;
        this.datos_paciente = result.datos_paciente;
        this.usuario_nuevo = false;
        this.usuario_selected = this.datos_paciente.paciente_id;
        let fecha_formateada = moment(this.datos_cirugia.cirugia_fecha).format('YYYY-M-DD HH:mm:ss');
        let split_fecha_inicio = fecha_formateada.split('-');
        let split_hora = this.datos_cirugia.cirugia_hora.split(':')
        if(this.datos_cirugia.hospital_id == null) this.datos_cirugia.hospital_id = 0;
        let split_duracion = "";
        if(this.datos_cirugia.cirugia_duracion) split_duracion = this.datos_cirugia.cirugia_duracion.split(':');
        if (split_hora.length == 2) {
          let año_i = split_fecha_inicio[0];
          let mes_i = split_fecha_inicio[1];
          let dia_i = split_fecha_inicio[2];
          let año_inicio = parseInt(año_i);
          let mes_inicio = parseInt(mes_i) - 1;
          let dia_inicio = parseInt(dia_i);
          let hora = parseInt(split_hora[0]);
          let minuto = parseInt(split_hora[1]);
          let hora_duracion;
          let minuto_duracion;
          if(split_duracion.length >= 2){
            hora_duracion = parseInt(split_duracion[0]);
            minuto_duracion = parseInt(split_duracion[1]);
          }
          let fecha_inicio_1 = new Date(año_inicio, mes_inicio, dia_inicio, hora, minuto);
          let duracion_evento = new Date(año_inicio, mes_inicio, dia_inicio, hora_duracion, minuto_duracion);
          this.datos_cirugia.cirugia_hora = fecha_inicio_1;
          this.datos_cirugia.cirugia_duracion = duracion_evento;
          this.modo_actualizar = true;

        }

        if (this.datos_actualizar) {
          this.verImagenEvento(1);
          this.verImagenEvento(2);
          this.verImagenEvento(3);
          this.verImagenEvento(4);
        }
      } else {
        this.datos_paciente = new PacienteModel(0, '', '', '', '', '', '', '', '', '', 0);
        this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0);
      }

    })
    this.unsubs.add(unsubscribe);
    this.subscription.add(subscription);
    this.subscription_guardado.add(subscription_guardado);

  }

  ngOnDestroy() {
    this.ObservableExtraer.changedatosSelectedGeneral({ limpiar: true });
    this.unsubs.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0)
      this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });
    }
    if (this.subscription_guardado) {
      this.subscription_guardado.unsubscribe();
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0)
      this.ObservableCirugia.changedatosPacienteCirugia({ limpiar: true });
    }
  }
  ExisteUsuario(event) {
    this.usuario_nuevo = event;
    this.SelectedUsuario('');
  }

  SelectedUsuario(i) {
    if (this.usuario_nuevo == false && i != '' && i != null) {
      this.datos_paciente = i[0].value;
      this.ObservableCirugia.changedatosPacienteCirugia(this.datos_paciente);

    } else if (this.usuario_nuevo == true) {
      //limpia los campos al cambiar que el usuario es nuevo
      this.datos_paciente.paciente_nombre = '';
      this.usuario_selected = 0;
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.datos_paciente.paciente_edad = 0;
      this.ObservableCirugia.changedatosPacienteCirugia(this.datos_paciente);
    }

  }
  DesplegarDropDown() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  EdadPaciente(edad) {
    this.datos_paciente.paciente_edad = edad.edad;
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
    }
  }

  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_usuario = result.usuario[0];
          this.getHospital();
          this.edades();
          this.getPacientes();
          this.getAnestesia();
          this.getProcedimiento();
          this.getCirujanos();
          this.getAnestesiologos();
        }
      })
  }
  /*************INICIO DE CATALOGOS*********** */
  getHospital() {
    this.DatosGeneralesService.getHospital(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        this.cat_hospital = result.cat_hospital;

      })
  }

  getPacientes() {
    this.DatosGeneralesService.getPacientes(this.datos_usuario.usuario_id, this.token.token)
      .subscribe(result => {
        this.pacientes = result.pacientes;
      })
  }

  edades() {
    for (let i = 1; i <= 180; i++) {
      this.edad_paciente.push({ edad: i });
    }
  }
  getAnestesia() {
    this.DatosEventoService.getAnestesia(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        this.cat_anestesia = result.cat_anestesia;
      })
  }

  getProcedimiento() {
    this.DatosEventoService.getProcedimiento(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        this.cat_proce = result.cat_proce;
      })
  }
  getCirujanos() {
    // tipo 1 cirujano tipo 2 anestesiologo
    this.DatosEventoService.getPersonal(this.token.token, this.datos_usuario.usuario_id, 1)
      .subscribe(result => {
        this.cat_cirujanos = result.cat_personal;

      })
  }
  getAnestesiologos() {
    // tipo 1 cirujano tipo 2 anestesiologo
    this.DatosEventoService.getPersonal(this.token.token, this.datos_usuario.usuario_id, 2)
      .subscribe(result => {
        this.cat_anestesiologo = result.cat_personal;
      })
  }
  /*************TERMINA DE CATALOGOS*********** */
  verificaDatosEvento() {
    if (this.datos_paciente.paciente_nombre == "" || this.datos_paciente.paciente_nombre == null || this.datos_paciente.paciente_nombre == undefined) {
      swal("Error", "Debes Ingresar el Nombre del Paciente", "error");
    } else {
      if (this.modo_actualizar) {
        this.GuardarArchivoImagenSecundarioUpdate(this.datos_cirugia.cirugia_id);
        this.GuardarArchivoImagenUpdate(this.datos_cirugia.cirugia_id);
      }
      this.datos = { datos_paciente: this.datos_paciente, datos_evento: this.datos_cirugia, datos_ayudantia: this.ayudantia }
      this.ObservableCirugia.chengeVerificaDatosPacienteCirugia(this.datos);


    }


  }
  /**********Subir Imagen***********/
  SubirImagenEvento($event) {
    //formato 1 imagen, tipo 2 archivo
    //detalle_imagen_categoria_evento 1 consulta 2 cirugia

    this.formato = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_categoria_evento = 2;
    this.file_imagen_evento = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_imagen_evento = event.target.result;
    };
    reader.readAsDataURL(file);
  }
  //Eliminar Imagen Evento
  @ViewChild('imagenEvento')
  imagenEvento: any;
  EliminarImagenEvento() {
    if (this.datos_actualizar && this.imagen_evento_principal) {
      this.imagen_evento_principal.imagen_status = 0;
      this.CirugiaService.EliminarImagenDetallePago(this.imagen_evento_principal)
        .subscribe(result => {
          this.verImagenEvento(1);
        })
    } else {
      this.url_imagen_evento = null;
      this.file_imagen_evento = null;
      this.imagenEvento.nativeElement.value = "";
    }
  }

  GuardarArchivoImagen(valor_id) {
    if (this.file_imagen_evento != undefined) {
      let params = new Array();
      this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_categoria_evento + '/' + valor_id + '/' + this.formato, params, this.file_imagen_evento, this.formato)
        .then(
          (result: any) => {
            this.EliminarImagenEvento();
            this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0);
            this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
            this.usuario_nuevo = true;
          })
    } else {
      this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0);
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.usuario_nuevo = true;
    }
  }

  /*****************************/

  /*********SUBIR IMAGEN SEGUNDARIA EVENTO******************* */
  SubirImagenEventoSeguncaria($event) {
    //formato 1 imagen, tipo 2 archivo
    //detalle_imagen_categoria_evento 1 consulta 2 cirugia
    this.formato = 1;
    let file: File = $event.target.files[0];
    this.detalle_imagen_categoria_evento_secundaria = 2;
    this.file_imagen_evento_secundaria = file;
    var reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.url_imagen_evento_secundaria = event.target.result;
    };
    reader.readAsDataURL(file);
  }
  //Eliminar Imagen Evento
  @ViewChild('imagenEventoSecundaria')
  imagenEventoSecundaria: any;
  EliminarImagenEventoSecundaria() {
    if (this.datos_actualizar && this.imagen_evento_secundario) {
      this.imagen_evento_secundario.imagen_status = 0;
      this.CirugiaService.EliminarImagenDetallePago(this.imagen_evento_secundario)
        .subscribe(result => {
          this.verImagenEvento(2);
        })
    } else {
      this.url_imagen_evento_secundaria = null;
      this.file_imagen_evento_secundaria = null;
      this.imagenEventoSecundaria.nativeElement.value = "";
    }
  }

  GuardarArchivoImagenSecundaria(valor_id) {
    if (this.file_imagen_evento_secundaria != undefined) {
      let params = new Array();
      this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_categoria_evento_secundaria + '/' + valor_id + '/' + this.formato, params, this.file_imagen_evento_secundaria, this.formato)
        .then(
          (result: any) => {
            this.EliminarImagenEventoSecundaria();
            this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0);
            this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
            this.usuario_nuevo = true;
          })
    } else {
      this.datos_cirugia = new CirugiaModel(0, '', '', '', 0, 0, 0, 0, 0, 0, 0, '', 0);
      this.datos_paciente = new PacienteModel(0, '', 0, '', '', '', '', '', '', '', 0);
      this.usuario_nuevo = true;
    }
  }

  verImagenEvento(no_imagen) {
    if (this.datos_actualizar) {
      let imagen_tipo = 2;
      this.CirugiaService.GetImagenesTipo(this.token.token, imagen_tipo, this.datos_cirugia.cirugia_id)
        .subscribe(result => {
          if (result.valido == 1) {
            if (no_imagen == 1) {
              this.imagen_evento_principal = result.imagenes[0];
            } else if (no_imagen == 2) {
              this.imagen_evento_secundario = result.imagenes[1];
            }
          }
        })
    }

  }

  /*****************************/
  ///IMAGENES ACTUALIZAR/

  GuardarArchivoImagenSecundarioUpdate(valor_id) {
    if (this.file_imagen_evento_secundaria != undefined) {
      let params = new Array();
      if (this.imagen_evento_secundario != undefined) {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen-update/' + this.detalle_imagen_categoria_evento_secundaria + '/' + valor_id + '/' + this.formato + '/' + this.imagen_evento_secundario.imagen_id, params, this.file_imagen_evento_secundaria, this.formato)
          .then(
            (result: any) => {
              this.url_imagen_evento_secundaria = null;
              this.file_imagen_evento_secundaria = null;
              this.imagenEventoSecundaria.nativeElement.value = "";
            })
      } else {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_categoria_evento_secundaria + '/' + valor_id + '/' + this.formato, params, this.file_imagen_evento_secundaria, this.formato)
          .then(
            (result: any) => {
              this.url_imagen_evento_secundaria = null;
              this.file_imagen_evento_secundaria = null;
              this.imagenEventoSecundaria.nativeElement.value = "";
            })
      }

    }
  }

  GuardarArchivoImagenUpdate(valor_id) {
    if (this.file_imagen_evento != undefined) {
      let params = new Array();
      if (this.imagen_evento_principal != undefined) {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen-update/' + this.detalle_imagen_categoria_evento + '/' + valor_id + '/' + this.formato + '/' + this.imagen_evento_principal.imagen_id, params, this.file_imagen_evento, this.formato)
          .then(
            (result: any) => {
              this.url_imagen_evento = null;
              this.file_imagen_evento = null;
              this.imagenEvento.nativeElement.value = "";
            })
      } else {
        this.DatosEventoService.AddImagenCirugia('cirugia_img/cirugia-archivos-imagen/' + this.detalle_imagen_categoria_evento + '/' + valor_id + '/' + this.formato, params, this.file_imagen_evento, this.formato)
          .then(
            (result: any) => {
              this.url_imagen_evento = null;
              this.file_imagen_evento = null;
              this.imagenEvento.nativeElement.value = "";
            })
      }

    }
  }
  DeleteCirugia() {
    this.DatosEventoService.DeleteCirugia(this.datos_cirugia)
      .subscribe(result => {
        swal({
          title: 'Muy Bien!',
          text: result.error,
          type: "success",
          timer: 2500,
          showConfirmButton: false
        });
        this.router.navigate(['/buscar'])
        this.ObservableService.changeVistaMenu(3);
      })

  }
  numeroEdad() {

    if (this.datos_paciente.paciente_edad > 120) {
      this.datos_paciente.paciente_edad = 120;
    }
  }
}

