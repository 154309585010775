import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndexService } from './index.service';
import { resource } from 'selenium-webdriver/http';
import { shallowEqualArrays } from '@angular/router/src/utils/collection';
import swal from 'sweetalert2';
import { UsuarioModel } from '../../models/UsuarioModel';
import * as moment from 'moment'
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { AccesoModel } from '../../models/AccesoModel';
import { RecoveryPwModel } from '../../models/RecoveryPwModel';
import { ObservableService } from '../../observable.service';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, OnDestroy {
  public especialidades;
  public datos_registro: UsuarioModel;
  public verificando_campos_registro: boolean;
  public errores_registro: boolean;
  public registro_codigo: boolean;
  public datos_accesso: AccesoModel;
  public olvide_pw: boolean;
  public confirma_new_pw: boolean;
  public datos_recovery_pw: RecoveryPwModel;
  public session;
  public terminos_condiciones;
  public aviso_privasidad;
  private unsubscribe_reset_pw: Subscription = new Subscription();
  //menos 96 meses es decir la  fecha maxima es de 18 años permitidos
  public max_fecha_nacimiento;
  constructor(
    private IndexService: IndexService,
    private router: Router,
    private ObservableService: ObservableService
  ) {
    this.verificando_campos_registro = false;
    this.datos_registro = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
    this.datos_accesso = new AccesoModel('', '');
    this.datos_recovery_pw = new RecoveryPwModel('', '', '', '', '');
    this.errores_registro = false;
    this.registro_codigo = false;
    this.olvide_pw = false;
    this.confirma_new_pw = false;

    // reset paswword dede url
    const reset_pw = this.ObservableService.PasswordResetMessage.subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.resetPWURL(result);
      }
    })
    this.unsubscribe_reset_pw.add(reset_pw);
    this.Session();
  }
  ngOnDestroy() {
    this.unsubscribe_reset_pw.unsubscribe();

  }
  ngOnInit() {
    $('#datepicker').click(function () { $(this).val('readonly'); });
    this.getEspecialidad();
    //fecha actual menos 205 meses es decir la  fecha maxima es de 18 años permitidos
    var fecha_actual = new Date();
    fecha_actual.setFullYear(fecha_actual.getFullYear(), fecha_actual.getMonth() - 205);
    this.max_fecha_nacimiento = fecha_actual;
    var year = fecha_actual.getFullYear()
    this.getAvisos();
  }

  resetPWURL(dato) {
    this.confirma_new_pw = true;
  }

  Session() {
    this.session = localStorage.getItem('usuario');
    if (this.session != null) {
      this.router.navigate(['/home'])
    }
  }
  getAvisos() {
    this.IndexService.getAvisos()
      .subscribe(result => {
        if (result.valido == 1) {
          for (let key in result.avisos) {

            if (result.avisos[key].setting_desc.toLowerCase().includes('terminos')) {
              this.terminos_condiciones = result.avisos[key].setting_valor;
            }

            if (result.avisos[key].setting_desc.toLowerCase().includes('aviso')) {
              this.aviso_privasidad = result.avisos[key].setting_valor;
            }
          }
        }
      })
  }
  getEspecialidad() {
    this.IndexService.getEspecialidad()
      .subscribe(result => {
        if (result.valido == 1) {
          this.especialidades = result.especialidades;
        } else {
          swal("Error", "No se pudo obtener especialidades", "error");
        }
      })
  }

  VerificaDatosUsuario() {
    this.errores_registro = false;
    this.verificando_campos_registro = true;
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.datos_registro.usuario_nombre == "" || this.datos_registro.usuario_nombre == null || this.datos_registro.usuario_nombre == undefined || this.datos_registro.usuario_nombre.trim() == "") {
      this.errores_registro = true;
    } else if (this.datos_registro.usuario_apellidos == "" || this.datos_registro.usuario_apellidos == null || this.datos_registro.usuario_apellidos == undefined || this.datos_registro.usuario_apellidos.trim() == "") {
      this.errores_registro = true;
    } else if (this.datos_registro.fecha_nacimiento == "" || this.datos_registro.fecha_nacimiento == null || this.datos_registro.fecha_nacimiento == undefined) {
      this.errores_registro = true;
    } else if (this.datos_registro.especiadidad_id == 0 || this.datos_registro.especiadidad_id == null || this.datos_registro.especiadidad_id == undefined) {
      this.errores_registro = true;
    } else if (this.datos_registro.cedula_profesional == "" || this.datos_registro.cedula_profesional == null || this.datos_registro.cedula_profesional == undefined) {
      this.errores_registro = true;
    } else if (this.datos_registro.usuario_email == "" || this.datos_registro.usuario_email == null || this.datos_registro.usuario_email == undefined || this.datos_registro.usuario_email.trim() == "") {
      this.errores_registro = true;
    } else if (!emailRegex.test(this.datos_registro.usuario_email)) {
      this.errores_registro = true;
    } else if (this.datos_registro.usuario_pw == "" || this.datos_registro.usuario_pw == null || this.datos_registro.usuario_pw == undefined || this.datos_registro.usuario_pw.trim() == "") {
      this.errores_registro = true;
    } else if (this.datos_registro.usuario_pw != this.datos_registro.usuario_pw_confirm) {
      this.errores_registro = true;
    } else if (this.datos_registro.terminos_condiciones == 0) {
      swal("Error", "Debe Aceptar los Terminos y COndiciones", "error")
    } else {
      this.AddUsuario();
      this.errores_registro = false;
    }

  }
  TerminosCoondiciones() {
    this.datos_registro.terminos_condiciones = 1;
  }
  AddUsuario() {
    let fecha_nacimiento = moment(this.datos_registro.fecha_nacimiento).format('YYYY-MM-DD');
    this.datos_registro.fecha_nacimiento = fecha_nacimiento;
    this.datos_registro.usuario_status = 2;
    this.IndexService.AddUsuario(this.datos_registro)
      .subscribe(result => {
        if (result.valido == 1) {
          this.datos_registro = new UsuarioModel(0, '', '', '', 0, '', '', '', 0, 0, 0, '', '', 0, '', false);
          this.registro_codigo = true;
          $('#accep_term').prop('checked', false);
          swal("Muy Bien", "Verifica tu correo", "success");
        } else {
          this.datos_registro.fecha_nacimiento = '';
          swal("Error", result.error, "error")
        }
      })
  }

  ActivarCodigo() {
    this.IndexService.ActivarCodigo(this.datos_registro.codigo_confirmacion)
      .subscribe(result => {
        if (result.valido == 1) {
          swal("Muy Bien", result.error, "success")
          this.registro_codigo = false;
          this.verificando_campos_registro = false;
          this.router.navigate(['/'])
        } else if (result.valido == 2) {
          swal("Ops", result.error, "error")
        }
      })
  }

  Login() {
    if (this.datos_accesso.usuario_email == "" || this.datos_accesso.usuario_email == null || this.datos_accesso.usuario_email == undefined || this.datos_accesso.usuario_email.trim() == "") {
      swal({
        title: "Error",
        text: "Debe ingresar un correo electronico",
        type: 'error',
        confirmButtonColor: "#EEC35A"
      })
    } else if (this.datos_accesso.usuario_pw == "" || this.datos_accesso.usuario_pw == null || this.datos_accesso.usuario_pw == undefined || this.datos_accesso.usuario_pw.trim() == "") {
      swal({
        title: "Error",
        text: "Debe ingresar una Contraseña",
        type: 'error',
        confirmButtonColor: "#EEC35A"
      })
    } else {
      this.IndexService.Login(this.datos_accesso)
        .subscribe(result => {
          let datos_usuario = result;
          if (result.valido == 1) {
            this.IndexService.getPerfilMenuApp(datos_usuario.usu.papp_id, datos_usuario.token)
              .subscribe(result => {
                let permisos_app = result.per_menuapp;
                location.reload();
                this.router.navigate(['/home'])
                // swal({
                //   title: 'Bienvenido!',
                //   // html: '<a href="#" onclick="swal.closeModal(); return false;">I want more Checkout</a>',
                //   text: datos_usuario.error,
                //   type: "success",
                //   timer: 4000,
                //   showConfirmButton: false
                // });
                localStorage.setItem('usuario', JSON.stringify(datos_usuario.usu));
                localStorage.setItem('app', JSON.stringify(permisos_app));
                localStorage.setItem('token', JSON.stringify(datos_usuario.token));
                //swal.close();
              })

          } else {
            swal({
              title: "Error",
              text: datos_usuario.error,
              type: 'error',
              confirmButtonColor: "#EEC35A"
            })

          }
        })
    }

  }
  muestra_recoverypw() {
    this.registro_codigo = false;
    this.olvide_pw = true;
  }
  RecoberyPw() {
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(this.datos_recovery_pw.usuario_email)) {
      swal({
        title: "Error",
        text: "Correo Invalido",
        type: 'error',
        confirmButtonColor: "#EEC35A"
      })
    } else {
      this.IndexService.RecoberyPw(this.datos_recovery_pw.usuario_email)
        .subscribe(result => {
          if (result.valido == 1) {
            this.olvide_pw = false;
            this.confirma_new_pw = true;
            // swal("Muy Bien", result.error, "success" )
            swal({
              title: 'Muy Bien!',
              text: result.error,
              type: 'success',
              confirmButtonColor: "#EEC35A"
            })
          } else {
            swal({
              title: 'Ops',
              text: result.error,
              type: 'error',
              confirmButtonColor: "#EEC35A"
            })
          }
        })
    }
  }
  ResetPw() {
    if (this.datos_recovery_pw.usuario_rec_codigo == '' || this.datos_recovery_pw.usuario_rec_codigo == null || this.datos_recovery_pw.usuario_rec_codigo == undefined || this.datos_recovery_pw.usuario_rec_codigo.trim() == "") {
      swal("Error", "El campo codigo esta vacio", "error")
    } else if (this.datos_recovery_pw.usuario_pw == '' || this.datos_recovery_pw.usuario_pw == null || this.datos_recovery_pw.usuario_pw == undefined || this.datos_recovery_pw.usuario_pw.trim() == "") {
      swal("Error", "El campo contraseña esta vacio", "error")
    } else if (this.datos_recovery_pw.usuario_pw_confirm == '' || this.datos_recovery_pw.usuario_pw_confirm == null || this.datos_recovery_pw.usuario_pw_confirm == undefined) {
      swal("Error", "El campo confirma contraseña esta vacio", "error")
    } else if (this.datos_recovery_pw.usuario_pw != this.datos_recovery_pw.usuario_pw_confirm) {
      swal("Error", "Las contraseñas no coinciden", "error")
    } else {
      this.IndexService.ResetPw(this.datos_recovery_pw)
        .subscribe(result => {
          if (result.valido == 1) {
            this.olvide_pw = false;
            this.confirma_new_pw = false;
            this.datos_recovery_pw = new RecoveryPwModel('', '', '', '', '');
            swal("Muy Bien", result.error, "success")
          } else {
            swal("Ops", result.error, "error")
          }
        })
    }
    // campo de recueprar contraseña se ve mal y el menu aparece cuando se da algo en la url de params
  }
}
