import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Response, Headers } from '@angular/http';
import { GLOBAL } from '../../../helpers';

@Injectable({
  providedIn: 'root'
})
export class DetallePagoService {
  public url: string;
  constructor(private http: Http) {
    this.url = GLOBAL.url;
   }

   public getPagos(token, usuario) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_fpago/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }

  public getBanco(token, usuario){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_banco/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  public getAseguradora(token, usuario){
    let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
    return this.http.get(this.url + 'cat_aseguradora/getbyusuarioid/' + usuario, { headers: headers })
      .map(res => res.json());
  }
  
}











