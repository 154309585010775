<section class="">

  <!-- Div para mantener el fondo en las dos secciones -->
  <div class="container-fluid">
    <!-- Titulos -->
    <section>
      <div class="calendariosem">

        <div class="container">

          <ng-template #modalContent let-close="close">
            <div class="modal-header">
              <h5 class="modal-title">Event action occurred</h5>
              <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
              </div>
              <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
            </div>
          </ng-template>
          <div class="col-md-12">
            <div class="row text-center">
              <div class="col-md-4">
                <div style="margin-left : 95%">
                  <label class="pointer flecha-calendar" (click)="changeCalendar(-7)">
                    < </label>
                </div>
              </div>
              <div class="col-md-4">
                <h3 id="mes" *ngIf="mostrar_mes" style="font-weight: bold; color:#3C615F;font-size: 20px;"> {{dateMount}}</h3>
                <!-- {{ calendar_title | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }} -->
                <!-- <h3 id="mes"  style = "font-weight: bold; color:#3C615F">{{ viewDate | calendarDate:(view + 'ViewTitle'): 'es' | uppercase }}</h3> -->
              </div>
              <div class="col-md-4">
                <div style="margin-left : -95%">
                  <label class="pointer flecha-calendar" (click)="changeCalendar(7)">
                    > </label>
                </div>
              </div>
            </div>
          </div>
          <div [ngSwitch]="view">
            <mwl-calendar-week-view weekStartsOn="1" [dayStartHour]="1" [dayEndHour]="24" *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
              [events]="events" [locale]="locale" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
          </div>

        </div>
      </div>
    </section>
    <!-- Cierra el fondo -->
  </div>

</section>