import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../../helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
export class ConfiguracionService {
    constructor(http) {
        this.http = http;
        this.url = GLOBAL.url;
    }
    getPerfilUsuario(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'usuario/getbyid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    UpdateInfo(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        usuario.usuario_status = 1;
        let params = JSON.stringify(usuario);
        return this.http.post(this.url + 'usuario/update-usuario', params, { headers: headers })
            .map(res => res.json());
    }
    // public getEspecialidad() {
    //   //consejo dejar solo los links que co autorizacion de esta forma, que no continen una session
    //   let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NDQyMDQ3ODcsImV4cCI6MTU0NTQxNDM4N30.MA7lOHNgb3cDpqMQvBy4XBRoj8Pt7Yc5z7A9Z6PjDjE';
    //   let headers = new Headers({'Content-Type' : 'application/json' , 'authorization': ': '+token+'' });
    //   return this.http.get(this.url + 'cat_especialidad/get', { headers: headers })
    //     .map(res => res.json());
    // }
    // }
    AddImagenPerfil(url_liga, params, file) {
        let _url = this.url;
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            var xhr = new XMLHttpRequest();
            if (file) {
                formData.append('image', file, file.name);
            }
            for (let key of params) {
                let tKey = key;
                for (const i in tKey) {
                    formData.append(i, key[i]);
                }
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', _url + url_liga, true);
            xhr.send(formData);
        });
    }
    //Catalogo de anestesiologo 
    getAnestesiologo(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_anestesia/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    AddAnestesia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_anestesia/add', params, { headers: headers })
            .map(res => res.json());
    }
    UpdateAnestesia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_anestesia/update', params, { headers: headers })
            .map(res => res.json());
    }
    DeleteAnestesia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_anestesia/update', params, { headers: headers })
            .map(res => res.json());
    }
    //Catalogo de hospitales 
    getHospitales(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_hospital/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addHospital(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_hospital/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateHospital(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_hospital/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteHospital(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_hospital/update', params, { headers: headers })
            .map(res => res.json());
    }
    //Catalogo de aseguradora
    getAseguradora(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_aseguradora/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addAseguradora(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_aseguradora/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateAseguradora(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_aseguradora/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteAseguradora(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_aseguradora/update', params, { headers: headers })
            .map(res => res.json());
    }
    //Catalogo de Ayudantia 
    getAyudantia(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_ayudantia/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addAyudantia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_ayudantia/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateAyudantia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_ayudantia/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteAyudantia(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_ayudantia/update', params, { headers: headers })
            .map(res => res.json());
    }
    //Catalogo Personal
    getPersonal(token, usuario, tipo) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_personal/getbyusuarioid/' + usuario + '/' + tipo, { headers: headers })
            .map(res => res.json());
    }
    addPersonal(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_personal/add', params, { headers: headers })
            .map(res => res.json());
    }
    updatePersonal(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_personal/update', params, { headers: headers })
            .map(res => res.json());
    }
    deletePersonal(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_personal/update', params, { headers: headers })
            .map(res => res.json());
    }
    //CATALOGO PROCEDIMIENTO
    getProcedimiento(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_proce/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addProcedimiento(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_proce/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateProcedimiento(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_proce/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteProcedimiento(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_proce/update', params, { headers: headers })
            .map(res => res.json());
    }
    //CATALOGO FORMA DE PAGO
    getFormaPago(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_fpago/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addFormaPago(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_fpago/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateFormaPago(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_fpago/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteFormaPago(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_fpago/update', params, { headers: headers })
            .map(res => res.json());
    }
    //CATALOGO  BANCO
    getBanco(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'cat_banco/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    addBanco(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_banco/add', params, { headers: headers })
            .map(res => res.json());
    }
    updateBanco(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_banco/update', params, { headers: headers })
            .map(res => res.json());
    }
    deleteBanco(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'cat_banco/update', params, { headers: headers })
            .map(res => res.json());
    }
    //PERMISOS ASIGNADOS
    getPermisosAsignados(token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'perfil_app/get', { headers: headers })
            .map(res => res.json());
    }
    getColaboradores(token, usuario) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'usuario/getbyusuarioid/' + usuario, { headers: headers })
            .map(res => res.json());
    }
    deleteColaborador(token, dato) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        let params = JSON.stringify(dato);
        return this.http.post(this.url + 'usuario/update-usuario', params, { headers: headers })
            .map(res => res.json());
    }
    /**********************Menu de Perfiles************** */
    getMenuApp(token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'menu_app/get', { headers: headers })
            .map(res => res.json());
    }
    AddPerfilApp(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'perfil_app/add', params, { headers: headers })
            .map(res => res.json());
    }
    AddMenuApp(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'per_menuapp/add', params, { headers: headers })
            .map(res => res.json());
    }
    getMenuAppByID(datos, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'per_menuapp/getbyid/' + datos, { headers: headers })
            .map(res => res.json());
    }
    getMenuAppByPadre(datos, token) {
        let headers = new Headers({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' });
        return this.http.get(this.url + 'per_menuapp/getbyPadre/' + datos, { headers: headers })
            .map(res => res.json());
    }
    UpdatePerfilApp(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'perfil_app/update', params, { headers: headers })
            .map(res => res.json());
    }
    UpdateMenuApp(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'per_menuapp/update', params, { headers: headers })
            .map(res => res.json());
    }
    DeleteMenuPerfil(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'per_menuapp/delete', params, { headers: headers })
            .map(res => res.json());
    }
    DeletePerfilApp(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'perfil_app/delete', params, { headers: headers })
            .map(res => res.json());
    }
    deleteFotoPerfil(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'usuario/delete-foto-perfil', params, { headers: headers })
            .map(res => res.json());
    }
    VerificaPw(datos) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let params = JSON.stringify(datos);
        return this.http.post(this.url + 'usuario/usuario-velrifica-pw', params, { headers: headers })
            .map(res => res.json());
    }
}
ConfiguracionService.ngInjectableDef = i0.defineInjectable({ factory: function ConfiguracionService_Factory() { return new ConfiguracionService(i0.inject(i1.Http)); }, token: ConfiguracionService, providedIn: "root" });
