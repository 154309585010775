import { Component, OnInit } from '@angular/core';
import { PendienteModel } from '../../models/PendienteModel';
import { ConfiguracionService } from '../configuracion/configuracion.service';
import { PendientesService } from '../../services/pendientes/pendientes.service';
import swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pendientes',
  templateUrl: './pendientes.component.html',
  styleUrls: ['./pendientes.component.css']
})

export class PendientesComponent implements OnInit {
  public datos_pendientes: PendienteModel;
  public session;
  public datos_session;
  public token;
  public usuario_id;
  public datos_usuario;
  public pendientes_list = new Array();
  public isMaxLength: boolean;
  public perfil_menu;
  public mostrar_pendientes: boolean;
  constructor(
    private ConfiguracionService: ConfiguracionService,
    private PendientesService: PendientesService
  ) {

  }

  ngOnInit() {
    this.mostrar_pendientes = false;
    this.datos_pendientes = new PendienteModel(0, '', 0, '', 0, 0);
    this.Session();
    this.isMaxLength = false;
  }

  Session() {
    // se trae el url para al cargar pagina seleccione de color amarillo el icono de la vista 
    // nota : url no se usa en el click por que le una url anterior
    this.session = localStorage.getItem('usuario');
    let token = localStorage.getItem('token');
    this.perfil_menu = JSON.parse(localStorage.getItem('app'));
    this.datos_session = JSON.parse(this.session);
    this.token = { token: JSON.parse(token) }
    if (this.session != null) {
      this.usuario_id = this.datos_session.usuid_padre != 0 ? this.datos_session.usuid_padre : this.datos_session.usuario_id;
      this.getPerfilUsuario();
      this.MostrarMenu();
    }
  }
  MostrarMenu() {
    for (let key in this.perfil_menu) {
      if (this.perfil_menu[key].menuapp_id == "5") {
        this.mostrar_pendientes = true;
      }
    }
  }
  getPerfilUsuario() {
    this.ConfiguracionService.getPerfilUsuario(this.token.token, this.usuario_id)
      .subscribe(result => {
        if (result.valido == 1) {

          this.datos_usuario = result.usuario[0];
          this.GetPendiente();
        }
      })
  }

  AddPendiente() {
    this.datos_pendientes.pendiente_status = 1;
    this.datos_pendientes.usuario_id = this.datos_usuario.usuario_id;
    this.PendientesService.AddPendiente(this.datos_pendientes)
      .subscribe(result => {
        if (result.valido == 1) {
          swal({
            title: result.error,
            // html: '<a href="#" onclick="swal.closeModal(); return false;">I want more Checkout</a>',
            text: result.error,
            type: "success",
            timer: 3000,
            showConfirmButton: false
          });
          this.GetPendiente();
          this.datos_pendientes = new PendienteModel(0, '', 0, '', 0, 0);
          this.isMaxLength = false;
        }
      })
  }

  GetPendiente() {
    this.PendientesService.GetPendiente(this.token.token, this.datos_usuario.usuario_id)
      .subscribe(result => {
        this.pendientes_list = result.pendientes;
      })
  }

  UpdateFavAdd(pendientes) {
    pendientes.pendiente_fav = 1;
    this.PendientesService.UpdateFav(pendientes)
      .subscribe(result => {
        this.GetPendiente();
      })
  }

  UpdateFavDel(pendientes) {
    pendientes.pendiente_fav = 0;
    this.PendientesService.UpdateFav(pendientes)
      .subscribe(result => {
        this.GetPendiente();
      })
  }

  DeleteFav(pendientes) {
    pendientes.pendiente_fav = 0;
    this.PendientesService.DeleteFav(pendientes)
      .subscribe(result => {
        this.GetPendiente();
      })
  }

  LongitudDescripcion() {
    if (this.datos_pendientes.pendiente_desc.length >= 140) {
      this.isMaxLength = true;
    } else {
      this.isMaxLength = false;
    }

  }
}
